import {GetEvent200Response, GetEventsGroup200Response} from "../../services/network";

export interface PaginationInterface {
    from: number;
    to: number;
    total: number;
    last_page: number;
}
export const PAGINATION_DEFAULT: PaginationInterface = {
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
}

export function paginationUpdateOrDefault(response: GetEventsGroup200Response | undefined): PaginationInterface {
    if(!response || !response.data || !response.meta) {
        return PAGINATION_DEFAULT;
    }
    const {meta} = response;
    const {from, to, total, last_page} = meta
    return {
        from: from ?? PAGINATION_DEFAULT.from,
        to: to ?? PAGINATION_DEFAULT.to,
        total: total ?? PAGINATION_DEFAULT.total,
        last_page: last_page ?? PAGINATION_DEFAULT.last_page
    };
}