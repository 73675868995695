import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: false,
    reducers: {
        login: () => {
            return true
        },
        logout: () => {
            return false
        }
    }
})

export const {login, logout} = authSlice.actions
export default authSlice.reducer