import { AxiosError } from "axios"
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { batch } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxCustomHooks'
import { closeStoreModal, showStoreModal } from '../../../redux/reducers/confirmationModal.slice'
import { closeCta } from '../../../redux/reducers/cta.slice'
import { moveMode } from "../../../redux/reducers/group.slice"
import { ApiClient } from "../../../services/ApiClient"
import { UpdateEventsGroupRequest } from '../../../services/network'
import { RequiredError } from "../../../services/network/base"
import { buildError } from "../../../utils"
import { EARTHQUAKE_LIST_TABLE } from "../../CustomTable/TableComposer"
import { changeDisplay } from "../utils"
import './CtaGroupEvent.css'

interface CtaGroupEventProps {
    onUpdate: (id: number | string | undefined, title: string, reason: string, isError: boolean) => void,
}

/* this component is used to show the `remove from group`, `change group` and `make owner` options in the cta for the elements in a group */
const CtaGroupEvent = ({ onUpdate }: CtaGroupEventProps) => {

    const dispatch = useAppDispatch();
    const cta = useAppSelector((state) => state.cta);
    const table = useAppSelector((state) => state.table);
    const { t } = useTranslation();

    const updatePosition = () => {
        let container = document.getElementById('cta-container')
        let button = document.getElementById(cta.callerId)
        if (container && button) {
            changeDisplay(container, button, cta.show, EARTHQUAKE_LIST_TABLE);
        } else {
            console.log('CtaGroupEvent => no button', cta.callerId);
        }
    }

    useEffect(() => {
        updatePosition();
        let table = document.getElementById(`${EARTHQUAKE_LIST_TABLE}-content`)
        if (table) {
            table.onscroll = null;
            if (cta.show) {
                table.onscroll = updatePosition;
            }
        }
    }, [cta]);

    const confirmRemoveEvent = async () => {
        let eventoToRemove = Number(cta.callerId.replaceAll('cta-group-', ''))

        await update(
            eventoToRemove, eventoToRemove, [eventoToRemove], [],
            `cta_group_remove_success`, `cta_group_remove_success_text`
        )

        batch(() => {
            dispatch(closeCta());
            dispatch(closeStoreModal());
        })
    }

    // the selected event become an event by itself
    const removeEvent = () => {
        // ownerId: the id of the `rappresentante` of the group
        // callerId: the id of the event that we want to remove from the group
        let { callerId, ownerId } = cta
        // we avoid the confirmation modal if the callerId does not belong to a cta-group type button
        if (!callerId || !callerId.startsWith('cta-group-')) {
            dispatch(closeCta())
            onUpdate(callerId.replaceAll('cta-group-', ''), 'cta_remove_event_error', 'cta_remove_event_client_error', true)
            return
        }

        let eventToRemove = callerId.replaceAll('cta-group-', '')
        // we avoid the confirmation modal if the callerId is the same of the `rappresentante`
        if (eventToRemove === ownerId) {
            dispatch(closeCta())
            onUpdate(callerId.replaceAll('cta-group-', ''), 'cta_remove_event_error', 'cta_remove_event_error_text', true)
            return
        }

        dispatch(showStoreModal({
            show: true,
            title: t('event_actions__remove__title'),
            text: t('event_actions__remove__text'),
            cancel: () => {
                dispatch(closeStoreModal())
            },
            confirm: confirmRemoveEvent
        }))
    }

    // the selected event becomes the owner of the group
    const ownerizeGroup = async () => {
        // ownerId: the id of the `rappresentante` of the group
        // callerId: the id of the event that is selected to be the new `rappresentante`
        let oldOwner = Number(cta.ownerId.replaceAll('cta-group-', ''));
        let newOwner = Number(cta.callerId.replaceAll('cta-group-', ''));

        // the api call is avoided if we're trying to make a `rappresentante` an event that already is `rappresentante`
        if (oldOwner === newOwner) {
            dispatch(closeCta());
            onUpdate(
                newOwner,
                'cta_ownerize_event_ownerize_error',
                'cta_ownerize_event_duplicate_error_text',
                true
            );
            return;
        }

        // the api call is avoided if we don't have the data about the children of the `rappresentante`.
        // This is and edge case that should not be present unless there is a problem with the store.
        let group = table.find((t) => t.parent === cta.ownerId);
        if (!group) {
            dispatch(closeCta())
            onUpdate(
                oldOwner,
                'cta_ownerize_event_ownerize_error',
                'cta_ownerize_event_notfound_error_text',
                true
            );
            return;
        }

        await update(
            newOwner, newOwner, group.children.map(gc => Number(gc.id)), [],
            `cta_group_ownerize_success`, `cta_group_ownerize_success_text`
        );
    }

    const update = async function (
        callerId: number, event_group_id: number, eventids: number[], event_group_ids: number[],
        successTitle: string, successText: string
    ) {
        try {
            let request: UpdateEventsGroupRequest = {
                data: { event_group_id, eventids, event_group_ids }
            }
            console.log('CtaGroupEvent => updating => new-api =>', { event_group_id, eventids, event_group_ids })
            let response = await ApiClient.updateApi().updateEventsGroup(request)
            if (response.status === 200) {
                onUpdate(event_group_id, successTitle, successText, false)
            }
        } catch (e) {
            let code = 500;
            let error = buildError(
                e as AxiosError<RequiredError>,
                (httpCode: number) => code = httpCode
            )
            error = error.replaceAll('<br />', ', ')
            onUpdate(callerId, `Error ${code}`, `Event ${callerId}: ${error}`, true)
        }

        dispatch(closeCta())
    }

    return (<>
        <div className='group-options-container'>
            <h4>{t('group_options')}</h4>
            <div className='divider-container'>
                <div className='divider' />
            </div>
            <div
                className='item'
                onClick={removeEvent}
            >
                <p>{t('cta_eventgroup_remove')}</p>
            </div>
            <div
                className='item'
                onClick={requestMoveConfirmation}
            >
                <p>{t('cta_eventgroup_move')}</p>
            </div>
            <div
                className='item'
                onClick={requestOwnerConfirmation}
            >
                <p>{t('cta_eventgroup_ownerize')}</p>
            </div>
        </div>
    </>);

    function requestOwnerConfirmation(): void {
        dispatch(showStoreModal({
            show: true,
            title: t('event_actions__owner__title'),
            text: t('event_actions__owner__text'),
            cancel: () => {
                dispatch(closeStoreModal())
            },
            confirm: ownerizeGroup
        }))
    }

    function requestMoveConfirmation(): void {
        let { callerId, ownerId } = cta
        if (!callerId || !callerId.startsWith('cta-group-')) {
            dispatch(closeCta());
            onUpdate(callerId.replaceAll('cta-group-', ''), 'cta_move_event_error', 'cta_move_event_client_error', true);
            return;
        }

        if (`cta-group-${ownerId}` === callerId) {
            dispatch(closeCta());
            onUpdate(callerId.replaceAll('cta-group-', ''), 'cta_move_event_error', 'cta_remove_event_error_text', true);
            return;
        }

        // for the move mode we should specify the source and the destination so that whenever the user
        // chooses a new owner we can update the destination field. In this case, the source is the callerId eventid
        // and the destination is the ownerId
        // In this situation the confirmation is asked via modal in the EarthquakeList page
        dispatch(moveMode([callerId.replaceAll('cta-group-', ''), ownerId]));
    }
}

export default CtaGroupEvent