import './Loader.css'
import {InlineLoading} from '@carbon/react'

export function Loader(){

    return <>
            <div className='loader-container'>
                <h1 className='loader-loading'>Caricamento in corso...</h1>
                <div><InlineLoading className="loader-loading-circle" /></div>
            </div>
        </>
}