import { TFunction } from "i18next"
import { PickFirstmotion } from "../../services/network"

const evaluateUncertainty = (upperUncertainty: number | null | undefined, lowerUncertainty: number | null | undefined): string => {
    if (upperUncertainty && lowerUncertainty) {
        return `${lowerUncertainty} / ${upperUncertainty}`
    }

    if (upperUncertainty) {
        return `${upperUncertainty}`
    }

    if (lowerUncertainty) {
        return `${lowerUncertainty}`
    }

    return '--'
}

const evaluatePolarity = (firstmotion: PickFirstmotion | null | undefined, t: TFunction) => {
    switch (firstmotion) {
        case 'U':
            return t('component__arrival_table__polarity_positive')
        case 'D':
            return t('component__arrival_table__polarity_negative')
        default:
            return t('component__arrival_table__polarity_undecidable')
    }
}

const evaluateEvMode = (magType: string | undefined) => {
    return '--' // TODO: Ask INGV about this!
}

export {
    evaluateEvMode, evaluatePolarity, evaluateUncertainty,
}