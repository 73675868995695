import './VersionComponent.css'

const VersionComponent = () => {
    return <>
        <ul className='version'>
            <li className='version-active'>BEA versione: {process.env.REACT_APP_BEA_PROVENANCE_VERSION} del 22 marzo 2024</li>
            <li className='version-active'>BEA versione: v2.0.22 del 11 marzo 2024</li>
            <li className='version-active'>BEA versione: v2.0.21 del 05 marzo 2024</li>
            <li className='version-active'>BEA versione: v2.0.20 del 28 febbraio 2024</li>
            <li className='version-active'>BEA versione: v2.0.19 del 21 febbraio 2024</li>
            <li className='version-active'>BEA versione: v2.0.18 del 14 febbraio 2024</li>
            <li className='version-active'>BEA versione: v2.0.17 del 05 febbraio 2024</li>
            <li className='version-active'>BEA versione: v2.0.16 del 30 gennaio 2024</li>
            <li className='version-active'>BEA versione: v2.0.15 del 25 gennaio 2024</li>
            <li className='version-active'>BEA versione: v2.0.14 del 23 gennaio 2024</li>
            <li className='version-active'>BEA versione: v2.0.13 del 17 gennaio 2024</li>
            <li className='version-active'>BEA versione: v2.0.12 del 10 gennaio 2024</li>
            <li className='version-active'>BEA versione: v2.0.11 del 08 gennaio 2024</li>
            <li className='version-active'>BEA versione: v2.0.10 del 30 dicembre 2023</li>
            <li className='version-active'>BEA versione: v2.0.9 del 28 dicembre 2023</li>
            <li className='version-active'>BEA versione: v2.0.8 del 27 dicembre 2023</li>
            <li className='version-active'>BEA versione: v2.0.7 del 22 dicembre 2023</li>
            <li className='version-active'>BEA versione: v2.0.6 del 28 novembre 2023</li>
            <li className='version-active'>BEA versione: v2.0.5 del 21 novembre 2023</li>
            <li className='version-active'>BEA versione: v2.0.4 del 06 novembre 2023</li>
        </ul>
    </>
}

export default VersionComponent