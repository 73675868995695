import './DropdownDownload.css'
import { Option } from '../CustomSelector/CustomSelector';
import { useAppSelector } from '../../hooks/reduxCustomHooks';
import { CIRCLE_GEOFENCE, CROWN_GEOFENCE, RECTANGULAR_GEOFENCE } from '../GeofenceSelector/GeofenceSelector';
import { exportData } from './download';

type DropdownDownloadProps = {
    closeDropdown: () => void
    singleEvent?: boolean
    origin?: string | number
    onErrorCallback: (error: string) => void
}

const DropdownDownload = function ({ closeDropdown, singleEvent, origin, onErrorCallback }: DropdownDownloadProps) {

    const filters = useAppSelector((state) => state.filters)
    const earthquake = useAppSelector((state) => state.earthquake)

    const handleDownloadSelectetion = (download: string): void => {
        let query = `${process.env.REACT_APP_API_BASE_URL?.replace('/api', '')}/fdsnws/query?`
        if (singleEvent) {
            if (origin) {
                query = `${query}originid=${origin}`
            } else {
                query = `${query}eventid=${earthquake.id}`
            }
            if (download === 'full-xml')
                query = `${query}&includeallmagnitudes=true&includeallorigins=true&includearrivals=true&includeallstationsmagnitudes=true&format=xml`
            else
                query = `${query}&format=${download}`
        } else {
            let { starttime, endtime } = filters
            if (starttime) {
                starttime = starttime.replaceAll(/\+\d+:\d+/g, 'Z')
                query = `${query}starttime=${starttime}&`
            }
            if (endtime)
                query = `${query}endtime=${endtime}&`
            let { minmag, maxmag } = filters
            if (minmag)
                query = `${query}minmag=${minmag}&`
            if (maxmag)
                query = `${query}maxmag=${maxmag}&`
            let { mindepth, maxdepth } = filters
            if (mindepth)
                query = `${query}mindepth=${mindepth}&`
            if (maxdepth)
                query = `${query}maxdepth=${maxdepth}&`
            if (filters.shape) {
                let { shape } = filters
                if (shape === RECTANGULAR_GEOFENCE) {
                    let { minlat, maxlat, minlon, maxlon } = filters
                    if (minlat && maxlat && minlon && maxlon) {
                        query = `${query}minlat=${minlat}&maxlat=${maxlat}&minlon=${minlon}&maxlon=${maxlon}&`
                    }
                } else if (shape === CIRCLE_GEOFENCE || shape === CROWN_GEOFENCE) {
                    let { minradiuskm, maxradiuskm, lat, lon } = filters
                    if (lat && lon && minradiuskm && maxradiuskm) {
                        query = `${query}lat=${lat}&lon=${lon}&minradiuskm=${minradiuskm}&maxradiuskm=${maxradiuskm}&`
                    }
                }
            }
            let { products } = filters
            if (products) {
                query = `${query}whereflagsin=${products}&`
            }
            query = `${query}&format=${download}`.replace('&&', '&')
        }

        exportData(query, download, onErrorCallback)

        closeDropdown()
    }

    const eventsListOptions: Option[] = [
        { key: 'xml', value: 'Quake ML - Ridotto' },
        { key: 'geojson', value: 'GeoJSON' },
        { key: 'text', value: 'Text' },
        { key: 'kml', value: 'KML' },
        { key: 'atom', value: 'Atom' },
    ]

    const singleEventOptions: Option[] = [
        { key: 'xml', value: 'Quake ML - Ridotto' },
        { key: 'full-xml', value: 'Quake ML - Completo' },
        { key: 'phs', value: 'Phs' },
        { key: 'nll', value: 'Nll' },
        { key: 'text', value: 'Text' },
        { key: 'kml', value: 'KML' },
        { key: 'atom', value: 'Atom' },
        { key: 'geojson', value: 'GeoJSON' },
    ]

    return (
        <>
            <div className="dropdown-menu-48" tabIndex={0} onBlur={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                <div className='dropdown-download-container'>
                    <p className="download-title">Download</p>
                    <div className='divider mt-16 mh-12'></div>
                    {
                        singleEvent
                            ? singleEventOptions.map((o) => <div key={o.key} className="download-item mt-8" onClick={() => handleDownloadSelectetion(o.key)}>{o.value}</div>)
                            : eventsListOptions.map((o) => <div key={o.key} className="download-item mt-8" onClick={() => handleDownloadSelectetion(o.key)}>{o.value}</div>)
                    }
                </div>
            </div>
        </>
    );
}

export { DropdownDownload };