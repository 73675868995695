import {
    InlineNotification
} from '@carbon/react';
import { useKeycloak } from '@react-keycloak/web';
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { ReactComponent as DoneIcon } from '../../assets/done.svg';
import { ReactComponent as StarIcon } from '../../assets/preferred-star.svg';
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import { closeStoreModal, showStoreModal } from "../../redux/reducers/confirmationModal.slice";
import { ApiClient } from "../../services/ApiClient";
import { ObjectEvent, ObjectFocalmechanism, ObjectOrigin, UpdateEventRequest } from "../../services/network";
import { BeaInlineNotification, defaultNotificationState, onErrorCallbackHandler, onSuccessCallbackHandler } from '../../utils/InlineNotifications';
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import EmptyList from "../EmptyList/EmptyList";
import { LoaderTranslated } from "../Loader/LoaderTranslated";
import FocalMechanismModal, { FocalMechanismModalProps } from "../MagnitudeModal/FocalMechanismModal";
import { Toast } from '../Toast/Toast';
import { GenericTable, IHeader, ProvenanceCell, Row, localspace, singleRow, twoRows } from "./GenericTable";
import './GenericTable.css';
import { Cta } from "./components/Cta";
import { modifiedDesc } from "./sortutils";

interface FaultMechanismTableProps {
    updateCallback: () => void;
    originId?: string;
}

const tableHeaders: IHeader[] = [
    { tag: 'id_tag', label: 'ot__id__label', sortable: false },
    { tag: 'ball_tag', label: 'ot__ball__label', sortable: false },
    { tag: 'mode_tag', label: 'ot__mode__label', sortable: false },
    { tag: 'mag_tag', label: 'ot__mag__label', sortable: false },
    { tag: 'np_1_tag', label: 'ot__np_1__label', sortable: false },
    { tag: 'np_2_tag', label: 'ot__np_2__label', sortable: false },
    { tag: 'qty_tag', label: 'ot__qty__label', sortable: false },
    { tag: 'localspace_tag', label: 'ot__localspace__label', sortable: false },
    { tag: 'id_origin_tag', label: 'ot__id_origin__label', sortable: false },
    { tag: 'moment_tensor_tag', label: 'ot__moment_tensor__label', sortable: false },
    { tag: 'provenance_tag', label: 'ot__provenance__label', sortable: false },
    { tag: 'cta_tag', label: 'ot__cta__label', sortable: false },
]


function FaultMechanismTable({ updateCallback, originId }: FaultMechanismTableProps) {
    const { t } = useTranslation();
    const earthquake = useAppSelector((state) => state.earthquake);
    const [focalmechanisms, setFocalmechanisms] = useState<ObjectFocalmechanism[] | undefined>(undefined);
    const [showNotification, setShowNotification] = useState<BeaInlineNotification>(defaultNotificationState);
    const [rows, setRows] = useState<Row[] | undefined>(undefined);
    const { keycloak } = useKeycloak();
    const [selectedRow, setSelectedRow] = useState('');
    const [showModal, setShowModal] = useState<FocalMechanismModalProps>({
        show: false,
        onClose: () => undefined,
        focalmechanism: undefined,
        focalmechanisms: []
    });
    const [isCatalog, setCatalog] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        let origins: ObjectOrigin[] = earthquake && earthquake.origins ? [...earthquake.origins] : [];
        let fm: ObjectFocalmechanism[] = [];
        if (originId) {
            origins = origins.filter((o) => `${o.id}` === originId);
        }
        for (const o of origins) {
            if (o.focalmechanisms) {
                fm = [...fm, ...o.focalmechanisms];
            }
        }
        fm.sort(modifiedDesc);
        setFocalmechanisms(fm);
    }, [earthquake]);

    useEffect(() => {
        if (earthquake) {
            if (earthquake.localspace) {
                if (earthquake.localspace.environment) {
                    setCatalog(earthquake.localspace.environment === 'catalog');
                }
            }
        }
    }, [earthquake]);

    useEffect(() => {
        if (focalmechanisms) {
            const temp: Row[] = focalmechanisms.map((fm, index) => {
                const link: string = '#';
                return [
                    {
                        tag: 'id_tag',
                        id: `${fm.id}`,
                        element: id(link, fm, earthquake.preferred_focalmechanism_id)
                    },
                    {
                        tag: 'ball_tag',
                        id: `${fm.id}`,
                        element: <Link to={link}>
                            {fm ? getBall(fm) : <span>--</span>}
                        </Link>
                    },
                    {
                        tag: 'mode_tag',
                        id: `${fm.id}`,
                        element: getMode(link, fm)
                    },
                    {
                        tag: 'mag_tag',
                        id: `${fm.id}`,
                        element: getMagnitude(link, fm)
                    },
                    {
                        tag: 'np_1_tag',
                        id: `${fm.id}`,
                        element: getNp1(link, fm)
                    },
                    {
                        tag: 'np_2_tag',
                        id: `${fm.id}`,
                        element: getNp2(link, fm)
                    },
                    {
                        tag: 'qty_tag',
                        id: `${fm.id}`,
                        element: <Link to={link}>
                            {getQuality(link, fm)}
                        </Link>
                    },
                    {
                        tag: 'localspace_tag',
                        id: `${fm.id}`,
                        element: localspace(link, fm.localspace)
                    },
                    {
                        tag: 'id_origin_tag',
                        id: `${fm.id}`,
                        element: idOrigin(link, fm)
                    },
                    {
                        tag: 'moment_tensor_tag',
                        id: `${fm.id}`,
                        element: <Link to={link}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '4px',
                                flex: '1 0 0',
                                alignSelf: 'stretch',
                                padding: '8px 0'
                            }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
                                    {!!fm.momenttensor && <DoneIcon />}
                                </div>
                            </div>
                        </Link>
                    },
                    {
                        tag: 'provenance_tag',
                        id: `${fm.id}`,
                        element: <ProvenanceCell link={link} p={fm.provenance} reverse={reverse(index, fm.provenance ? Object.entries(fm.provenance).length : 0)} />
                    },
                    {
                        tag: 'cta_tag',
                        id: `${fm.id}`,
                        element: !isPreferredFocalmechanism(earthquake, fm)
                            ? <Cta
                                classWidth='ot-focalmechanism'
                                key={`${fm.id}-cta`}
                                id={`${fm.id}`}
                                reverse={reverse(index, 1)}
                                label={'cta__focalmechanism__title'}
                                menu={[
                                    { tag: '', label: 'cta__focalmechanism__preferred', callback: () => updateEventConfirmation(fm.id) }
                                ]} />
                            : <></>

                    }
                ];
            });
            setRows(temp);
        }
    }, [focalmechanisms])

    function reverse(index: number, options: number): boolean {
        if (!focalmechanisms || focalmechanisms.length === 0) {
            return false;
        }

        const ot: HTMLElement | null = document.getElementById('origin-table');
        if (!ot) {
            return false;
        }

        const rowHeight: number = 68;
        const optionHeight: number = 36;
        const baseDropdownHeight: number = 50;
        const dropdownHeight = baseDropdownHeight + (options * optionHeight);
        if (index <= (focalmechanisms.length - Math.ceil(dropdownHeight / rowHeight) - 1)) {
            return false;
        }

        if (ot.scrollHeight === ot.clientHeight) {
            return false;
        }

        return ot.scrollHeight > (ot.clientHeight - dropdownHeight);
    }

    function isPreferredFocalmechanism(e: ObjectEvent, fm: ObjectFocalmechanism): boolean {
        const prefFocalmechanismID: number = e?.preferred_focalmechanism_id ?? -1;
        const focalmechanismId: number = fm?.id ?? -2;
        return prefFocalmechanismID === focalmechanismId;
    }

    return (
        <div className={'origin-table-container'}>
            <div className={'origin-button-container'}>
                <div className={'preferred-label-container'} style={{ marginRight: '16px' }}>
                    <StarIcon />
                    <span className={'label'}>{t('preferred_value')}</span>
                </div>
            </div>
            <>
                {
                    earthquake && focalmechanisms && rows
                        ? (
                            rows.length > 0
                                ? (<GenericTable
                                    rows={rows}
                                    headers={headers()}
                                    selectedRow={selectedRow}
                                    onClickCallback={(id: string) => {
                                        setSelectedRow(id);
                                        setShowModal({
                                            show: true,
                                            onClose: closeModal,
                                            focalmechanism: focalmechanisms.filter((fm) => `${fm.id}` === id)[0],
                                            focalmechanisms
                                        });
                                    }}
                                    gridDefinition={gridDefinition()} />)
                                : <EmptyList title={'fm_not_found_title'} />
                        )
                        : <LoaderTranslated />
                }

                {showNotification.show &&
                    (showNotification.kind === 'success'
                        ? <Toast
                            className='notification-hover'
                            title={t(showNotification.reason)}
                            onClose={() => setShowNotification(defaultNotificationState)}
                        />
                        : <InlineNotification
                            className='notification-hover'
                            kind={showNotification.kind}
                            lowContrast
                            actionbuttonlabel='Action'
                            ariaLabel='closes notification'
                            onClose={() => setShowNotification(defaultNotificationState)}
                            onCloseButtonClick={function noRefCheck() { }}
                            statusIconDescription='notification'
                            subtitle={t(showNotification.reason)}
                            title={showNotification.title}
                        />)}


                {showModal.show && <FocalMechanismModal
                    show={showModal.show}
                    onClose={closeModal}
                    focalmechanism={showModal.focalmechanism}
                    focalmechanisms={focalmechanisms ?? []}
                    prev={onPrev}
                    next={onNext}
                />}
            </>
        </div >
    )

    function onPrev(): void {
        if (!focalmechanisms || !showModal.focalmechanism || !showModal.show) {
            return;
        }

        const index: number = focalmechanisms.findIndex((m) => m.id === showModal?.focalmechanism?.id);
        if (index <= 0) {
            return;
        }
        setSelectedRow(`${focalmechanisms[index - 1].id}`);
        setShowModal({
            ...showModal,
            focalmechanism: focalmechanisms[index - 1]
        });
    }

    function onNext(): void {
        if (!focalmechanisms || !showModal.focalmechanism || !showModal.show) {
            return;
        }

        const index: number = focalmechanisms.findIndex((m) => m.id === showModal?.focalmechanism?.id);
        if (index >= (focalmechanisms.length - 1)) {
            return;
        }
        setSelectedRow(`${focalmechanisms[index + 1].id}`);
        setShowModal({
            ...showModal,
            focalmechanism: focalmechanisms[index + 1]
        })
    }

    function closeModal(): void {
        setSelectedRow('');
        setShowModal({
            show: false,
            onClose: () => undefined,
            focalmechanism: undefined,
            focalmechanisms: []
        });
    }

    function gridDefinition(): string {
        let grid: string = ``;
        if (originId) {
            grid = 'od-fps';
        } else {
            grid = 'ed-fps';
        }
        return (!!keycloak.authenticated && !isCatalog) ? `${grid}-grid` : `${grid}-pub-grid`
    }

    function headers(): IHeader[] {
        let heads: IHeader[] = tableHeaders;
        if (originId) {
            heads = heads.filter((h) => h.tag !== 'id_origin_tag');
        }

        if (keycloak) {
            if (keycloak.authenticated) {
                if (!isCatalog) {
                    return heads;
                }
            }
        }
        return heads.filter((h) => h.tag !== 'cta_tag');
    }

    function getBall(fm: ObjectFocalmechanism): JSX.Element {
        let url: string = '#';
        if (fm.momenttensor) {
            let { mtt, mpp, mrr, mtp, mrt, mrp } = fm.momenttensor;
            const mxx = mtt ? mtt / Math.pow(10, 13) : mtt;
            const myy = mpp ? mpp / Math.pow(10, 13) : mpp;
            const mzz = mrr ? mrr / Math.pow(10, 13) : mrr;
            const mxy = mtp ? (-1 * mtp) / Math.pow(10, 13) : mtp;
            const mxz = mrt ? mrt / Math.pow(10, 13) : mrt
            const myz = mrp ? (-1 * mrp) / Math.pow(10, 13) : mrp;

            url = `http://webservices.ingv.it/ws/mopad/mopad.php?plot_arg=${mxx},${myy},${mzz},${mxy},${mxz},${myz}&color=109,139,160`;
        } else {
            const { strike1, dip1, rake1 } = fm;
            url = `http://webservices.ingv.it/ws/mopad/mopad.php?plot_arg=${strike1},${dip1},${rake1}&color=109,139,160`;
        }
        return <img src={url} width={24} height={24} />
    }

    function getNp1(link: string, fm: ObjectFocalmechanism): JSX.Element {
        const { strike1, dip1, rake1 } = fm;
        return singleRow(link, `${strike1} / ${dip1} / ${rake1}`, `${strike1} / ${dip1} / ${rake1}`);
    }

    function getNp2(link: string, fm: ObjectFocalmechanism): JSX.Element {
        const { strike2, dip2, rake2 } = fm;
        return singleRow(link, `${strike2} / ${dip2} / ${rake2}`, `${strike2} / ${dip2} / ${rake2}`);
    }

    function getMode(link: string, fm: ObjectFocalmechanism): JSX.Element {
        let row: string = '--';
        let tooltip: string = '';

        if (fm) {
            const { provenance } = fm;
            if (provenance) {
                const { evaluationmode } = provenance;
                if (evaluationmode) {
                    return singleRow(link, evaluationmode === 'automatic' ? 'A' : 'M', evaluationmode);
                }
            }
        }

        return singleRow(link, row, tooltip);
    }

    function getMagnitude(link: string, fm: ObjectFocalmechanism): JSX.Element {
        let row: string = '--';
        let tooltip: string = '';

        if (fm.momenttensor) {
            let { magnitudeid_out } = fm.momenttensor;
            if (magnitudeid_out) {
                let magnitudes = earthquake
                    ?.origins
                    ?.flatMap(o => o?.magnitudes ?? [])
                    .filter(m => m.id === magnitudeid_out)
                    ?? []
                if (magnitudes.length > 0) {
                    const { type_magnitude, mag } = magnitudes[0];
                    row = mag ? `${type_magnitude} ${mag.toFixed(2)}` : '--';
                    tooltip = mag ? `${type_magnitude} ${mag}` : '--';
                }
            }
        }

        return singleRow(link, row, tooltip);
    }

    function getQuality(link: string, fm: ObjectFocalmechanism): JSX.Element {
        const { quality } = fm;
        return twoRows(
            link,
            `${quality ?? '--'}`,
            ``, /* removed for now since not present */
            quality
                ? `${quality} (${quality})`
                : ''
        );
    }

    function updateEventConfirmation(fmId?: number) {
        if (fmId === null || fmId === undefined) {
            return;
        }
        dispatch(showStoreModal({
            show: true,
            cancel: close,
            confirm: () => updateEvent(fmId),
            title: 'ot__make_preferred__confirmation',
            text: 'ot__make_preferred__confirmation__text'
        }))
    }

    function close(): void {
        dispatch(closeStoreModal());
    }

    async function updateEvent(fmId?: number): Promise<void> {
        if (!fmId) {
            dispatch(closeStoreModal());
            return;
        }
        if (!earthquake || !earthquake.id) {
            dispatch(closeStoreModal());
            return;
        }

        let request: UpdateEventRequest = {
            data: {
                event: {
                    preferred_focalmechanism_id: fmId
                }
            }
        }

        try {
            let response = await ApiClient.updateApi().updateEvent(earthquake.id, request)
            console.log('FaultMechanismTable => updateEvent => response =>', response);
            if (response.status === 200) {
                onSuccessCallbackHandler(
                    setShowNotification,
                    t('cta__focalmechanism__preferred_success__title'),
                    t('cta__focalmechanism__preferred_success', { fm: `${fmId}`, event: `${earthquake.id}` })
                );

                updateCallback();
            } else {
                const title: string = `${t('cta__focalmechanism__preferred_error_title')} (${response.status})`;
                const m: string = t('cta__focalmechanism_table__preferred_error', { fm: `${fmId}`, error: `Unknown error` });
                onErrorCallbackHandler(title, m, setShowNotification);
            }
        } catch (e) {
            let { response } = (e as AxiosError);
            console.log('cta__focalmechanism__preferred_error_title', response);
            const title: string = `${t('cta__focalmechanism__preferred_error_title')} (${response?.status ?? 500})`;
            const m: string = t('cta__focalmechanism_table__preferred_error', { fm: `${fmId}`, error: (response as AxiosResponse)?.data?.detail ?? 'Unknown error' });
            console.log('onErrorCallbackHandler => m', 2, m);
            onErrorCallbackHandler(
                title,
                t('cta__focalmechanism_table__preferred_error', { fm: `${fmId}`, error: (response as AxiosResponse)?.data?.detail ?? 'Unknown error' }),
                setShowNotification
            );
        }
        dispatch(closeStoreModal());
    }

    function id(link: string, fm: ObjectFocalmechanism, preferredFocalmechanism?: number | null | undefined): JSX.Element {
        if (!fm) {
            return singleRow(link, '--', '');
        }

        const { id } = fm;
        const isPreferred: boolean = !(Number.isNaN(id) || Number.isNaN(preferredFocalmechanism)) && id === preferredFocalmechanism;

        return (<CustomTooltip placement="top" title={`${id}`} arrow>
            <Link to={link}>
                <div className={'magnitude'}>
                    <div>
                        {isPreferred && <StarIcon />}
                        <span>{`${id}`}</span>
                    </div>
                </div>
            </Link>
        </CustomTooltip>);
    }

    function idOrigin(link: string, fm: ObjectFocalmechanism): JSX.Element {
        if (!fm) {
            return singleRow(link, '--', '');
        }

        const idOrigin: string = getIdOrigin(fm);
        if (idOrigin === '--') {
            return singleRow(link, '--', '');
        }
        return <CustomTooltip placement="top" arrow title={idOrigin}>
            <Link to={`/earthquake/${earthquake.id}/origin/${idOrigin}`}>
                <span className="link">{idOrigin}</span>
            </Link>
        </CustomTooltip>
    }

    function getIdOrigin(fm: ObjectFocalmechanism): string {
        if (earthquake && earthquake.origins && fm) {
            const { origins } = earthquake;
            const { id } = fm;

            const found: ObjectOrigin | undefined = origins.find((o) => {
                const { focalmechanisms } = o;
                if (focalmechanisms) {
                    return focalmechanisms.some((fm) => fm.id === id);
                }
                return false;
            });

            if (found) {
                return `${found.id}`;
            }
        }
        return '--';
    }
}

export { FaultMechanismTable };
