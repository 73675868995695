import CustomTable from "./CustomTable"
import { castedEventDetailArrivalSorting, EventDetailArrival, origindetail_arrivals_headers } from "./flavor/CustomArrivalTable"
import { castedEventDetailCitiesSorting, EventDetailCity, eventdetail_citytable_headers } from "./flavor/CustomCityTable"
import { auth_eventdetail_origintable_headers, EventDetailOriginsTable, eventdetail_origintable_headers } from "./flavor/CustomOriginTable"
import { AMPLITUDE_TAG, castedOriginDetailMlStationMagnitudeSorting, OriginDetailMlStationMagnitude, origindetail_ml_stationmagnitude_headers, PERIOD_TAG, TIMEREF_WINDOW_TAG } from "./flavor/CustomStationMagnitudesTable"
import {
    auth_earthquake_headers,
    DEPTH_TAG,
    EarthquakeTableEvent,
    earthquake_headers,
    DATETIME_TAG,
    MAGNITUDE_TAG,
    SORT_ASC,
    ID_TAG,
    SCNL_TAG,
    OriginDetailMwTdmtStation,
    origindetail_mw_tdmtstation_headers,
    STATION_TAG,
    EP_DISTANCE_TAG,
    EP_AZ_TAG,
    VARIANCE_TAG,
    ZCOR_TAG,
    DISTANCE_TAG,
    auth_earthquake_magnitudes_headers
} from "./TableUtils"
import {
    settings_eventtable_headers,
    settings_origintable_headers,
    settings_magnitudetable_headers,
    settings_localspacetable_headers,
    settings_provenancetable_headers,
    SettingsTable,
    castedSettingsSorting
} from "./flavor/CustomSettingsTable";
import {origindetail_productstable_headers, OriginDetailProducts} from "./flavor/CustomOriginProductsTable";
import {RouteType} from "../../redux/reducers/filters.slice";

export const EARTHQUAKE_LIST_TABLE = "EARTHQUAKE_LIST_TABLE"
export const EVENTDETAIL_ORIGINS_TABLE = "EVENTDETAIL_ORIGINS_TABLE"
export const EVENTDETAIL_CITIES_TABLE = "EVENTDETAIL_CITIES_TABLE"
export const EVENTDETAIL_CITIES_TABLE_50 = "EVENTDETAIL_CITIES_TABLE_50"
export const ORIGINDETAIL_ML_STATIONMAGNITUDE = "ORIGINDETAIL_ML_STATIONMAGNITUDE"
export const ORIGINDETAIL_MW_TDMTSTATION = "ORIGINDETAIL_MW_TDMTSTATION"
export const ORIGINDETAIL_ARRIVALS_TABLE = "ORIGINDETAIL_ARRIVALS_TABLE"
export const ORIGINDETAIL_PRODUCTS_TABLE = "ORIGINDETAIL_PRODUCTS_TABLE"

export const SETTINGS_EVENT_TABLE = "SETTINGS_EVENT_TABLE"
export const SETTINGS_ORIGIN_TABLE = "SETTINGS_ORIGIN_TABLE"
export const SETTINGS_MAGNITUDE_TABLE = "SETTINGS_MAGNITUDE_TABLE"
export const SETTINGS_LOCALSPACE_TABLE = "SETTINGS_LOCALSPACE_TABLE"
export const SETTINGS_PROVENANCE_TABLE = "SETTINGS_PROVENANCE_TABLE"

export type GenericEventId = EventDetailCity|EarthquakeTableEvent
export type GenericEventMag = EarthquakeTableEvent|OriginDetailMlStationMagnitude
export type GenericEvent = GenericEventMag|OriginDetailMwTdmtStation|EventDetailArrival|GenericEventId|EventDetailOriginsTable|SettingsTable|OriginDetailProducts

export const buildTable = (type: string, auth: boolean, body: Array<GenericEvent|any>) => {
    switch(type) {
        case ORIGINDETAIL_PRODUCTS_TABLE:
            return buildOriginDetailProductsTable(body)
        case SETTINGS_EVENT_TABLE:
            return buildSettingEventTable(body)
        case SETTINGS_ORIGIN_TABLE:
            return buildSettingOriginTable(body)
        case SETTINGS_MAGNITUDE_TABLE:
            return buildSettingMagnitudeTable(body)
        case SETTINGS_LOCALSPACE_TABLE:
            return buildSettingLocalspaceTable(body)
        case SETTINGS_PROVENANCE_TABLE:
            return buildSettingProvenanceTable(body)
        case EARTHQUAKE_LIST_TABLE:
            return buildEarthquakeTable(auth, 'events-group', body)
        case EVENTDETAIL_ORIGINS_TABLE:
            return buildEventDetailOriginTable(body, auth)
        case EVENTDETAIL_CITIES_TABLE:
        case EVENTDETAIL_CITIES_TABLE_50:
            return buildEventDetailCityTable(body, type)
        case ORIGINDETAIL_ML_STATIONMAGNITUDE:
            return buildOriginDetailMlStationMagnitudes(body)
        case ORIGINDETAIL_ARRIVALS_TABLE:
            return buildOriginDetailArrivalsTable(body)
        default:
            return <></>
    }
}

export const buildOriginDetailProductsTable = function(body: Array<OriginDetailProducts>) {
    return <CustomTable headers={origindetail_productstable_headers} body={body} className={['settings-generic-table']} type={ORIGINDETAIL_PRODUCTS_TABLE} />
}

export const buildSettingEventTable = function(body: Array<SettingsTable>) {
    return <CustomTable headers={settings_eventtable_headers} body={body} className={['settings-generic-table']} type={SETTINGS_EVENT_TABLE} />
}
export const buildSettingOriginTable = function(body: Array<SettingsTable>) {
    return <CustomTable headers={settings_origintable_headers} body={body} className={['settings-origin-type-table']} type={SETTINGS_ORIGIN_TABLE} />
}

export const buildSettingMagnitudeTable = function(body: Array<SettingsTable>) {
    return <CustomTable headers={settings_magnitudetable_headers} body={body} className={['settings-magnitude-table']} type={SETTINGS_MAGNITUDE_TABLE} />
}

export const buildSettingLocalspaceTable = function(body: Array<SettingsTable>) {
    return <CustomTable headers={settings_localspacetable_headers} body={body} className={['settings-magnitude-table']} type={SETTINGS_LOCALSPACE_TABLE} />
}

export const buildSettingProvenanceTable = function(body: Array<SettingsTable>) {
    return <CustomTable headers={settings_provenancetable_headers} body={body} className={['settings-provenance-table']} type={SETTINGS_PROVENANCE_TABLE} />
}

export const buildEventDetailCityTable = (body: Array<EventDetailCity>, type: string) => {
    return <CustomTable
                logged={false}
                type={type}
                headers={eventdetail_citytable_headers}
                className={['cities']}
                body={body}
                sticky={false}
            />
}

// CAN BE REMOVED
export const buildEventDetailOriginTable = (body: Array<EventDetailOriginsTable>, auth: boolean) => {
    return <CustomTable
                logged={auth}
                type={EVENTDETAIL_ORIGINS_TABLE}
                headers={auth ? auth_eventdetail_origintable_headers : eventdetail_origintable_headers}
                className={[auth ? 'private-origins' : 'public-origins']}
                body={body}
            />
}

const buildOriginDetailArrivalsTable = (body: Array<EventDetailArrival>) => {
    return <CustomTable
                logged={false}
                type={ORIGINDETAIL_ARRIVALS_TABLE}
                headers={origindetail_arrivals_headers}
                className={['arrivals']}
                body={body}
            />
}

export const buildOriginDetailMwTdmtStation = (body: Array<OriginDetailMwTdmtStation>) => {
    return <CustomTable
                logged={false}
                type={ORIGINDETAIL_MW_TDMTSTATION}
                headers={origindetail_mw_tdmtstation_headers}
                className={['mw-content']}
                body={body}
                sticky={false}
            />
}

const buildOriginDetailMlStationMagnitudes = (body: Array<OriginDetailMlStationMagnitude>) => {
    return  <CustomTable
                logged={false}
                type={ORIGINDETAIL_ML_STATIONMAGNITUDE}
                headers={origindetail_ml_stationmagnitude_headers}
                className={['station-magnitudes']}
                body={body}
            />
}

export const buildEarthquakeTable = (auth: boolean, route: RouteType, body: Array<EarthquakeTableEvent>) => {
    return <CustomTable
        logged={auth}
        type={EARTHQUAKE_LIST_TABLE}
        headers={auth ? (route === 'events-group' ? auth_earthquake_headers : auth_earthquake_magnitudes_headers) : earthquake_headers}
        className={auth
            ? (route === 'events-group' ? ['events-group-route'] : ['all-route'])
            : ['public']
        }
        body={body}
    />
}

// OriginDetailMwTdmtStation
const castedOriginDetailMwTdmtStationSorting = (tag: string, elements: Array<OriginDetailMwTdmtStation>, direction: number) => {
    let copy = [...elements]
    if(tag === STATION_TAG) {                
        return copy.sort((e1, e2) => {
            return direction === SORT_ASC 
                ? e1[tag].localeCompare(e2[tag])
                : -(e1[tag].localeCompare(e2[tag]))
        })
    }

    if(tag === EP_DISTANCE_TAG || tag === EP_AZ_TAG || tag === VARIANCE_TAG || tag === ZCOR_TAG) {                
        return copy.sort((e1, e2) => {
            let v1 = Number(e1[tag].replaceAll(/[^.\-\+\d+]/g, ""))
            let v2 = Number(e2[tag].replaceAll(/[^.\-\+\d+]/g, ""))
            return direction === SORT_ASC 
                ? v1 - v2
                : -(v1 - v2)
        })
    }

    return elements
}

const castedEarthquakeTableEventSorting = (tag:string, elements:Array<EarthquakeTableEvent>, direction:number) => {

    let copy = [...elements]

    if(tag === DEPTH_TAG) {                
        return copy.sort((e1, e2) => {
            let v1 = Number(e1[tag].replaceAll(/[^.\-\+\d+]/g, ""))
            let v2 = Number(e2[tag].replaceAll(/[^.\-\+\d+]/g, ""))
            return direction === SORT_ASC 
                ? v1 - v2
                : -(v1 - v2)
        })
    }

    return elements
}

export const sortingCallbacks = {
    [SETTINGS_EVENT_TABLE]: (tag: string, elements: Array<SettingsTable>, direction: number) => {
        return castedSettingsSorting(tag, elements, direction)
    },
    [EVENTDETAIL_CITIES_TABLE]: (tag: string, elements: Array<EventDetailCity>, direction: number) => {
        console.log('TableComposer => ', EVENTDETAIL_CITIES_TABLE, '=> sorting =>', direction, tag)
        return castedEventDetailCitiesSorting(tag, elements, direction)
    },
    [EVENTDETAIL_CITIES_TABLE_50]: (tag: string, elements: Array<EventDetailCity>, direction: number) => {
        console.log('TableComposer => ', EVENTDETAIL_CITIES_TABLE_50, '=> sorting =>', direction, tag)
        return castedEventDetailCitiesSorting(tag, elements, direction)
    },
    [ORIGINDETAIL_ARRIVALS_TABLE]: (tag: string, elements: Array<EventDetailArrival>, direction: number) => {
        return castedEventDetailArrivalSorting(tag, elements, direction)
    },
    /* EventDetailArrival */
    [DISTANCE_TAG]: (elements: Array<EventDetailArrival>, direction: number) => {
        return castedEventDetailArrivalSorting(DISTANCE_TAG, elements, direction)
    },
    /* OriginDetailMwTdmtStation */
    [STATION_TAG]: (elements: Array<OriginDetailMwTdmtStation>, direction: number) => {
        return castedOriginDetailMwTdmtStationSorting(STATION_TAG, elements, direction)
    },
    /* OriginDetailMwTdmtStation */
    [EP_DISTANCE_TAG]: (elements: Array<OriginDetailMwTdmtStation>, direction: number) => {
        return castedOriginDetailMwTdmtStationSorting(EP_DISTANCE_TAG, elements, direction)
    },
    /* OriginDetailMwTdmtStation */
    [EP_AZ_TAG]: (elements: Array<OriginDetailMwTdmtStation>, direction: number) => {
        return castedOriginDetailMwTdmtStationSorting(EP_AZ_TAG, elements, direction)
    },
    /* OriginDetailMwTdmtStation */
    [VARIANCE_TAG]: (elements: Array<OriginDetailMwTdmtStation>, direction: number) => {
        return castedOriginDetailMwTdmtStationSorting(VARIANCE_TAG, elements, direction)
    },
    /* OriginDetailMwTdmtStation */
    [ZCOR_TAG]: (elements: Array<OriginDetailMwTdmtStation>, direction: number) => {
        return castedOriginDetailMwTdmtStationSorting(ZCOR_TAG, elements, direction)
    },
    /* OriginDetailMlStationMagnitude|EventDetailArrival */
    [SCNL_TAG]: (elements: Array<OriginDetailMlStationMagnitude|EventDetailArrival>, direction: number) => {
        let copy = [...elements]
        return copy.sort((e1, e2) => {
            return direction === SORT_ASC ? e1[SCNL_TAG].localeCompare(e2[SCNL_TAG]) : -(e1[SCNL_TAG].localeCompare(e2[SCNL_TAG]))
        })
    },
    /* OriginDetailMlStationMagnitude */
    [AMPLITUDE_TAG]: (elements: Array<OriginDetailMlStationMagnitude>, direction: number) => {
        return castedOriginDetailMlStationMagnitudeSorting(AMPLITUDE_TAG, elements, direction)
    },
    /* OriginDetailMlStationMagnitude */
    [PERIOD_TAG]: (elements: Array<OriginDetailMlStationMagnitude>, direction: number) => {
        return castedOriginDetailMlStationMagnitudeSorting(PERIOD_TAG, elements, direction)
    },
    /* OriginDetailMlStationMagnitude */
    [TIMEREF_WINDOW_TAG]: (elements: Array<OriginDetailMlStationMagnitude>, direction: number) => {
        return castedOriginDetailMlStationMagnitudeSorting(TIMEREF_WINDOW_TAG, elements, direction)
    },
    /* EarthquakeTableEvent */
    [ID_TAG]: (elements: Array<GenericEventId>, direction: number): Array<GenericEventId> => {
        let copy = [...elements]
        return copy.sort((e1, e2) => {
            return direction === SORT_ASC 
                ? e1.id.toLocaleLowerCase().localeCompare(e2.id.toLocaleLowerCase()) 
                : -e1.id.toLocaleLowerCase().localeCompare(e2.id.toLocaleLowerCase()) 
        })
    },
        /* EarthquakeTableEvent */
    [DEPTH_TAG]: (elements: Array<EarthquakeTableEvent>, direction: number): Array<EarthquakeTableEvent> => {
        return castedEarthquakeTableEventSorting(DEPTH_TAG, elements, direction)
    },
    /* EarthquakeTableEvent | EarthquakeTableEvent */
    [MAGNITUDE_TAG]: (elements: Array<GenericEventMag>, direction: number): Array<GenericEvent> => {
        let copy = [...elements]
        return copy.sort((e1, e2) => {
            return direction === SORT_ASC ? (e1 as GenericEventMag).mag - (e2 as GenericEventMag).mag : -((e1 as GenericEventMag).mag - (e2 as GenericEventMag).mag)
        })
    }, 
    /* OriginDetailMlStationMagnitude | EarthquakeTableEvent*/
    [DATETIME_TAG]: (elements: Array<GenericEvent>, direction: number): Array<GenericEvent> => {
        let copy = [...elements]
        return copy.sort((e1, e2) => {
            return direction === SORT_ASC 
                ? (e1 as GenericEventMag).datetime.toLocaleLowerCase().localeCompare((e2 as GenericEventMag).datetime.toLocaleLowerCase()) 
                : -(e1 as GenericEventMag).datetime.toLocaleLowerCase().localeCompare((e2 as GenericEventMag).datetime.toLocaleLowerCase()) 
        })
    },
}