import { useEffect } from 'react'
import { ReactComponent as IconDanger } from '../../assets/danger.svg'
import './ErrorComponent.css'
import {useTranslation} from "react-i18next";

interface ErrorComponentProps {
    title: string,
    description: string,
    asHtml?: boolean
}

const ErrorComponent = ({title, description, asHtml}:ErrorComponentProps) => {

    const {t} = useTranslation();

    useEffect(() => {
        if(asHtml) {
            let text = document.getElementById('error-text')
            if(text) {
                text.innerHTML = description
            }
        }
    })

    return <>
            <div className='error-container'>
                <div><IconDanger /></div>
                <h1 className='error-title'>{t(title)}</h1>
                {
                    asHtml
                        ? <p id="error-text" className='error-text'></p>
                        : <p id="error-text" className='error-text'>{description}</p>
                }                
            </div>
    </>
}

export default ErrorComponent