import { Button, Dropdown, InlineNotification, TextInput, Toggle } from "@carbon/react";
import { useKeycloak } from "@react-keycloak/web";
import { AxiosError, AxiosResponse } from 'axios';
import dayjs from "dayjs";
import Leaflet, { LatLngTuple, Map as LeafletMap } from "leaflet";
import React, { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    MapContainer,
    Marker,
    TileLayer,
    ZoomControl,
} from "react-leaflet";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BreadCrumbComposer } from "../../components/BreadcrumbComposer/BreadcrumbComposer";
import { IntervalViewer } from "../../components/IntervalViewer/IntervalViewer";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import { fetchEarthquakeEvent, resetEvent } from "../../redux/reducers/earthquake.slice";
import { ApiClient } from "../../services/ApiClient";
import {
    AddEvent201Response,
    AddMagnitudeRequest, AddOrigin201ResponseData, AddOriginRequest, ObjectOrigin,
    ObjectTableTypeEvent,
    ObjectTableTypeMagnitude,
    TypeOriginName,
} from "../../services/network";
import { RequiredError } from "../../services/network/base";
import { buildError, isValidLatitude, isValidLongitude } from "../../utils";
import {
    BeaInlineNotification,
    defaultNotificationState,
    onErrorCallbackHandler,
} from "../../utils/InlineNotifications";
import * as utility from "../../utils/index";
import {
    LOCALSPACE_LIST,
    TYPE_ORIGIN_LIST
} from "../../utils/process";
import "./CreateEvent.css";
import { buildEventRequestObject, buildMagnitudeRequestObject, buildOriginRequestObject } from "./apiObject";
import { elementIds, pageId, quality, shouldShowEventFields, shouldShowOriginFields } from "./utils";
import {
    isValidLocalspace,
    isValidTypeOrigin,
    validateMagnitudeCreationForm,
    validateOriginCreationForm
} from "./validate";
import CustomModal from "../../components/CustomModal/CustomModal";
import { closeStoreModal, showStoreModal } from "../../redux/reducers/confirmationModal.slice";
import { Toast } from "../../components/Toast/Toast";

interface CreateDetailProps {
    eventId?: string,
    originId?: string
    magnitudeId?: string
}

export interface EventCreationForm {
    localspace?: string;
    eventype?: string;
    toBeSent?: boolean;
    provenance?: string;
}

export interface OriginCreationForm {
    ot?: string;
    err_ot?: string;
    lat?: string;
    lon?: string;
    err_lat?: string;
    err_lon?: string;
    depth?: string;
    err_h?: string;
    rms?: string;
    azim_gap?: string;
    origin_quality?: string;
}

export interface MagnitudeCreationForm {
    mag?: string;
    lower_uncertainty?: string;
    upper_uncertainty?: string;
    symmetric_error?: boolean;
    mag_quality?: string;
    type_magnitude?: string;
}

export interface TypeOriginCreationForm {
    name?: TypeOriginName,
    version_name?: string,
    version_value?: number
}

interface SelectedDropDown {
    selectedItem: string;
}

const CreateEvent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [eventTypes, setEventTypes] = useState<Array<ObjectTableTypeEvent>>(
        []
    );
    const [magnitudeTypes, setMagnitudeTypes] = useState<
        Array<ObjectTableTypeMagnitude>
    >([]);

    const [eventTypeOptions, setEventTypeOptions] = useState([""]);
    const [magnitudeTypesOptions, setMagnitudeTypesOptions] = useState([""]);
    const [origin, setOrigin] = useState<ObjectOrigin>()
    const [eventForm, setEventForm] = useState<EventCreationForm>({
        toBeSent: false,
    });
    const [originForm, setOriginForm] = useState<OriginCreationForm>({});
    const [magnitudeForm, setMagnitudeForm] = useState<MagnitudeCreationForm>({
        symmetric_error: false,
    });
    const [typeOriginForm, setTypeOriginForm] = useState<TypeOriginCreationForm>({});
    const [markerCoords, setMarkerCoords] = useState<LatLngTuple>([0, 0]);
    const { keycloak } = useKeycloak();
    const [timeInvalid, setTimeInvalid] = useState(false);
    const [saveInvalid, setSaveInvalid] = useState(false);
    const map = createRef<LeafletMap>();

    const earthquake = useAppSelector((state) => state.earthquake)
    const location = useLocation()
    const { eventId, originId } = useParams<keyof CreateDetailProps>() as CreateDetailProps
    const [showNotification, setShowNotification] = useState<BeaInlineNotification>(defaultNotificationState);
    const modalSpec = useAppSelector((state) => state.confirmationModal);

    const handleDropDownOrigin = (selected: SelectedDropDown) => {
        setOriginForm({ ...originForm, origin_quality: selected.selectedItem });
    };

    const handleDropDownMagQuality = (selected: SelectedDropDown) => {
        setMagnitudeForm({
            ...magnitudeForm,
            mag_quality: selected.selectedItem,
        });
    };
    const handleDropDownMagType = (selected: SelectedDropDown) => {
        setMagnitudeForm({
            ...magnitudeForm,
            type_magnitude: selected.selectedItem,
        });
    };

    function isValidDate(dateString: string): boolean {
        let date = dayjs.utc(dateString);
        return date.isValid()
    }

    const updateOriginForm = function (key: string, value: string) {
        setOriginForm({
            ...originForm,
            [key]: value
        })
    }

    const handleLat = (e: React.FormEvent<HTMLInputElement>) => {
        updateOriginForm('lat', e.currentTarget.value)
        if (isValidLatitude(e.currentTarget.value, false)) {
            setMarkerCoords([+e.currentTarget.value, markerCoords[1]])
            map.current?.flyTo([+e.currentTarget.value, markerCoords[1]], 8)
        }
    };

    const handleLong = (e: React.FormEvent<HTMLInputElement>) => {
        updateOriginForm('lon', e.currentTarget.value)
        if (isValidLongitude(e.currentTarget.value, false)) {
            setMarkerCoords([markerCoords[0], +e.currentTarget.value])
            map.current?.flyTo([markerCoords[0], +e.currentTarget.value], 8)
        }
    };

    const handleErr_lon = (e: React.FormEvent<HTMLInputElement>) => updateOriginForm('err_lon', e.currentTarget.value)
    const handleErr_lat = (e: React.FormEvent<HTMLInputElement>) => updateOriginForm('err_lat', e.currentTarget.value)

    const handleDateMilliseconds = (ot: string) => {
        updateOriginForm('ot', ot)

        isValidDate(ot)
            ? setTimeInvalid(false)
            : setTimeInvalid(true);
    };

    const handleDepth = (e: React.FormEvent<HTMLInputElement>) => updateOriginForm('depth', e.currentTarget.value)
    const handleDepth_err = (e: React.FormEvent<HTMLInputElement>) => updateOriginForm('err_h', e.currentTarget.value)
    const handleRMS = (e: React.FormEvent<HTMLInputElement>) => updateOriginForm('rms', e.currentTarget.value)
    const handleGap = (e: React.FormEvent<HTMLInputElement>) => updateOriginForm('gap', e.currentTarget.value)
    const handleDateErr = (e: React.FormEvent<HTMLInputElement>) => updateOriginForm('err_ot', e.currentTarget.value)

    const updateMagnitudeForm = function (key: string, value: string) {
        setMagnitudeForm({
            ...magnitudeForm,
            [key]: value
        })
    }
    const handleMag = (e: React.FormEvent<HTMLInputElement>) => updateMagnitudeForm('mag', e.currentTarget.value)
    const handleMagUpper_uncertainty = (e: React.FormEvent<HTMLInputElement>) => updateMagnitudeForm('upper_uncertainty', e.currentTarget.value)
    const handleMagLower_uncertainty = (e: React.FormEvent<HTMLInputElement>) => updateMagnitudeForm('lower_uncertainty', e.currentTarget.value)

    const handleTypeOrigin = (selected: SelectedDropDown) => {
        let nameValueSplit = selected.selectedItem.split(" (")
        if (nameValueSplit.length !== 2) {
            onErrorCallbackHandler(
                'Wrong format',
                `String ${selected.selectedItem} is not supported`,
                setShowNotification
            )
            return
        }
        setTypeOriginForm({
            ...typeOriginForm,
            version_name: nameValueSplit[0],
            version_value: +(nameValueSplit[1].replaceAll(/[()]/g, "")),
            name: "hypocenter"
        });
    }

    const handleLocalSpaces = (selected: SelectedDropDown) => {
        setEventForm({
            ...eventForm,
            localspace: selected.selectedItem,
        });
    }

    const handleEventType = (selected: SelectedDropDown) => {
        setEventForm({
            ...eventForm,
            eventype: selected.selectedItem,
        });
    }
    const handleSaveButton = () => {
        if (!isValidLocalspace(eventForm.localspace)) {
            console.log('handleSaveButton => invalid localspace')
            setSaveInvalid(true)
            return
        }

        if (shouldShowEventFields(location.pathname)) {
            if (!eventForm.eventype || eventForm.eventype === '') {
                console.log('handleSaveButton => invalid eventtype')
                setSaveInvalid(true)
                return;
            }
        } else {
            console.log('handleSaveButton => eventForm => avoiding check the event type')
        }

        console.log('handleSaveButton => originForm =>', originForm)
        if (shouldShowOriginFields(location.pathname)) {
            if (!validateOriginCreationForm(originForm, true)) {
                console.log('handleSaveButton => invalid originForm')
                setSaveInvalid(true)
                return
            }

            if (timeInvalid) {
                console.log('handleSaveButton => invalid time')
                setSaveInvalid(true)
                return
            }
        } else {
            console.log('handleSaveButton => originForm => avoiding check the origin')
        }

        console.log('handleSaveButton => typeOriginForm =>', typeOriginForm)
        if (shouldShowOriginFields(location.pathname)) {
            if (!isValidTypeOrigin(typeOriginForm.name, typeOriginForm.version_name, typeOriginForm.version_value)) {
                console.log('handleSaveButton => invalid typeOriginForm')
                setSaveInvalid(true)
                return
            }
        } else {
            console.log('handleSaveButton => typeOriginForm => avoiding check the type origin')
        }

        console.log('handleSaveButton => magnitudeForm =>', magnitudeForm)
        if (!validateMagnitudeCreationForm(magnitudeForm)) {
            console.log('handleSaveButton => invalid magnitudeForm')
            setSaveInvalid(true);
            return
        }

        setSaveInvalid(false);
    };

    const getEventTypes = async () => {
        try {
            let res = await ApiClient.getApi().getTypeEvent();
            console.log("CreateEvent => getEventTypes", res.data.data);
            setEventTypes(res.data.data ?? []);
        } catch (e) {
            console.log("CreateEvent => getEventTypes => error", e);
        }
    };

    const getTypeMagnitude = async () => {
        try {
            let res = await ApiClient.getApi().getTypeMagnitude();
            setMagnitudeTypes(res.data.data ?? []);
        } catch (e) {
            console.log("CreateEvent => getTypeMagnitude => error", e);
        }
    };

    function handleSubmitConfirmation(): void {

        let title: string;
        let text: string;
        if (location.pathname.includes('create/event')) {
            title = 'create__event__confirmation'
        } else if (location.pathname.includes('create/origin')) {
            title = 'create__origin__confirmation'
        } else if (location.pathname.includes('create/magnitude')) {
            title = 'create__magnitude__confirmation'
        } else {
            return;
        }

        dispatch(showStoreModal({
            show: true,
            cancel: () => dispatch(closeStoreModal()),
            confirm: handleSubmit,
            title: title,
            text: `${title}__text`
        }))
    }

    //Method to POST new event
    const handleSubmit = function () {
        if (location.pathname.includes('create/origin')) {
            postOrigin().then(() => console.log('postOrigin sent'))
                .finally(() => dispatch(closeStoreModal()));
            return;
        }
        if (location.pathname.includes('create/magnitude')) {
            postMagnitude().then(() => console.log('postMagnitude sent'))
                .finally(() => dispatch(closeStoreModal()));
            return;
        }

        postEvent().then(() => console.log('postEvent sent'))
            .finally(() => dispatch(closeStoreModal()));
    }

    const postOrigin = async function () {
        if (!eventId) {
            onErrorCallbackHandler('No eventId found', 'Cannot continue', setShowNotification)
            return
        }

        const originToSend: AddOriginRequest = buildOriginRequestObject(eventId, eventForm, originForm, magnitudeForm, typeOriginForm, keycloak);

        try {
            let res = await ApiClient.storeApi().addOrigin(originToSend);
            console.log('postOrigin =>', res);
            if (res.status === 200 || res.status === 201) {
                if (res.data) {
                    let { data } = res.data
                    if (data) {
                        let { origins } = (data as AddOrigin201ResponseData)
                        if (eventId && origins && origins.length > 0) {
                            await updateOriginFlag(+eventId, origins[0]?.id ?? 0)
                            return
                        }
                    }
                }
                onErrorCallbackHandler('Cannot continue', 'Update origin flag failed', setShowNotification)
                // navigate(`/earthquake/${eventId}`, {replace: true})
            } else {
                onErrorCallbackHandler(res.statusText, (res as AxiosResponse)?.data?.detail ?? '--', setShowNotification)
            }
        } catch (e) {
            console.log("CreateEvent => PostOrigin => error", e);
            let code = 500;
            let error = buildError(e as AxiosError<RequiredError>, (httpCode: number) => code = httpCode)
            onErrorCallbackHandler(`Error ${code}`, `Error in POST /origin ${error}`, setShowNotification)
        }
    }

    const postMagnitude = async function () {
        if (!originId) {
            onErrorCallbackHandler('No originId found', 'Cannot continue', setShowNotification)
            return
        }

        const magnitudeToSend: AddMagnitudeRequest = buildMagnitudeRequestObject(originId, eventForm, originForm, magnitudeForm, typeOriginForm, keycloak);

        try {
            let res = await ApiClient.storeApi().addMagnitude(magnitudeToSend);
            console.log('postMagnitude =>', res);
            if (res.status === 200 || res.status === 201) {
                // navigate(`/earthquake/${eventId}/origin/${res.data.data?.origin[0]?.id}`, {replace: true})
                let data = res?.data?.data
                if (data) {
                    if (data.magnitudes && data.magnitudes.length > 0) {
                        navigate(`/earthquake/${eventId}/origin/${originId}`, { replace: true })
                    } else {
                        onErrorCallbackHandler("Empty Data", (res as AxiosResponse)?.data?.detail ?? '--', setShowNotification)
                    }
                } else {
                    onErrorCallbackHandler("No Data", (res as AxiosResponse)?.data?.detail ?? '--', setShowNotification)
                }
            } else {
                onErrorCallbackHandler(res.statusText, (res as AxiosResponse)?.data?.detail ?? '--', setShowNotification)
            }
        } catch (e) {
            console.log("CreateEvent => PostMagnitude => error", e);
            let code = 500;
            let error = buildError(e as AxiosError<RequiredError>, (httpCode: number) => code = httpCode)
            onErrorCallbackHandler(`Error ${code}`, `Error in POST /magnitude ${error}`, setShowNotification)
        }
    }

    const postEvent = async () => {
        const eventToSend = buildEventRequestObject(eventForm, originForm, magnitudeForm, typeOriginForm, keycloak);

        try {
            let res = await ApiClient.storeApi().addEvent(eventToSend);
            console.log(res);
            if (res.status === 200 || res.status === 201) {
                // navigate(`/earthquake/${res.data.data?.event?.id}`, {replace: true})
                let { data } = (res.data as AddEvent201Response)
                if (data) {
                    let { event } = data
                    if (event) {
                        let { id, origins } = event
                        if (id && origins && origins.length > 0) {
                            await updateOriginFlag(id, origins[0]?.id ?? 0)
                            return
                        }
                    }
                }
                onErrorCallbackHandler('Cannot continue', 'Update origin flag failed', setShowNotification)
            } else {
                onErrorCallbackHandler(res.statusText, (res as AxiosResponse)?.data?.detail ?? '--', setShowNotification)
            }
        } catch (e) {
            console.log("CreateEvent => PostEvent => error", e);
            let code = 500;
            let error = buildError(e as AxiosError<RequiredError>, (httpCode: number) => code = httpCode)
            onErrorCallbackHandler(`Error ${code}`, `Error in POST /event: ${error}`, setShowNotification)
        }
    };

    const updateOriginFlag = async function (eventId: number, originId: number) {
        try {
            let res = await ApiClient.storeApi().addOriginFlag(
                {
                    data: {
                        originid: originId,
                        "origin-flag": [{ name: 'DPC', value: 1 }]
                    }
                }
            )
            if (res.status === 200 || res.status === 201) {
                navigate(`/earthquake/${eventId}`, { replace: true })
            } else {
                onErrorCallbackHandler(res.statusText, (res as AxiosResponse)?.data?.detail ?? '--', setShowNotification)
            }
        } catch (e) {
            console.log("CreateEvent => PostEvent => error", e);
            let code = 500;
            let error = buildError(e as AxiosError<RequiredError>, (httpCode: number) => code = httpCode)
            onErrorCallbackHandler(`Error ${code}`, `Error in POST /origin-flag ${error}`, setShowNotification)
        }
    }

    useEffect(() => {
        getEventTypes().then(() => console.log('getEventTypes sent'));
        getTypeMagnitude().then(() => console.log('getTypeMagnitude sent'));
    }, []);

    useEffect(() => {
        setEventTypeOptions(eventTypes.map((et) => et?.name ?? '--'));
    }, [eventTypes]);

    useEffect(() => {
        setMagnitudeTypesOptions(magnitudeTypes.map((mt) => mt?.name ?? ''));
    }, [magnitudeTypes]);

    useEffect(() => {
        if (location.pathname.includes('create/event')) return
        if (!earthquake || earthquake.id === 0) {
            dispatch(fetchEarthquakeEvent(+eventId!))
                .unwrap()
                .catch((e) => {
                    let { name } = e
                    if (name === 'AxiosError') {
                        let code = 500;
                        let error = buildError(e as AxiosError<RequiredError>, (httpCode: number) => code = httpCode)
                        onErrorCallbackHandler(`Error ${code}`, `Error in POST /origin-flag ${error}`, setShowNotification)
                        dispatch(resetEvent())
                    }
                })

            return
        }

        if (originId && earthquake && earthquake.origins) {
            setOrigin(earthquake.origins.find((o) => o.id === +originId))
        }
    }, [earthquake])

    useEffect(() => {
        if (origin) {
            setOriginForm({
                ot: origin.ot,
                err_ot: origin.err_ot ? `${origin.err_ot}` : undefined,
                lat: origin.lat ? `${origin.lat}` : undefined,
                err_lat: origin.err_lat ? `${origin.err_lat}` : undefined,
                lon: origin.lon ? `${origin.lon}` : undefined,
                err_lon: origin.err_lon ? `${origin.err_lon}` : undefined,
                depth: origin.depth ? `${origin.depth}` : undefined,
                err_h: origin.err_depth ? `${origin.err_depth}` : undefined,
                origin_quality: origin.quality?.toUpperCase() ?? undefined,
                rms: origin.rms ? `${origin.rms}` : undefined,
                azim_gap: origin.azim_gap ? `${origin.azim_gap}` : undefined,
            })
        }
    }, [origin])

    useEffect(() => {
        if (origin) {
            if (origin.type_origin) {
                setTypeOriginForm({
                    name: 'hypocenter',
                    version_name: origin.type_origin.version_name,
                    version_value: origin.type_origin.version_value,
                })
            }
        }
    }, [origin])

    useEffect(() => {
        if (origin) {
            setMarkerCoords([origin.lat, origin.lon])
            map?.current?.flyTo([origin.lat, origin.lon], 8)
        }
    }, [origin])

    useEffect(() => {
        if (origin) {
            if (origin.flags && origin.flags.includes('DPC')) {
                setEventForm({
                    toBeSent: true
                })
            }
        }
    }, [origin])

    useEffect(() => {
        handleSaveButton();
    }, [eventForm, magnitudeForm, originForm, typeOriginForm]);

    return (
        <>
            <div className="create-event-container">
                <div className="create-event-content">
                    <BreadCrumbComposer pageId={pageId(location.pathname)} elementIds={elementIds(location.pathname, eventId, originId)} />
                    <div className="create-event-rows">
                        {/* data source */}
                        <div className="create-box-flex">
                            <h4>{t("create_event_data_source")}</h4>

                            <div className="origin-row-form">
                                <div className="form-item-big">
                                    <Dropdown
                                        titleText={t("create_event_localspace")}
                                        onChange={handleLocalSpaces}
                                        items={LOCALSPACE_LIST}
                                        initialSelectedItem={t(
                                            "create_event_choose_value"
                                        )}
                                    />
                                </div>

                                <div className="form-item-verylittle" />

                                <div className="form-item-verybig">
                                    <h5>{t('create_event_provenance')}</h5>
                                    <h3 className="bea-h3">{`name: ${process.env.REACT_APP_BEA_PROVENANCE_NAME}`}<br />{`softwarename: ${process.env.REACT_APP_BEA_PROVENANCE_SOFTWARENAME}`}<br />{`version: ${process.env.REACT_APP_BEA_PROVENANCE_VERSION}`}</h3>
                                </div>
                            </div>
                        </div>

                        {/* event */}
                        <div className="create-box-flex">
                            <h4>{t("create_event_event")}</h4>
                            <div className="origin-row-form">
                                <div className="form-item-big">
                                    <Dropdown
                                        titleText={t("create_event_event_type")}
                                        onChange={handleEventType}
                                        items={eventTypeOptions}
                                        disabled={!shouldShowEventFields(location.pathname)}
                                        initialSelectedItem={shouldShowEventFields(location.pathname)
                                            ? t("create_event_choose_value")
                                            : earthquake?.type_event
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        {/* origin */}
                        <div className="create-box-flex">
                            <h4>{t("create_event_origin")}</h4>
                            <div className="origin-row-form">
                                <div className={"two-columns"}>
                                    <div className="form-item-big">
                                        <h5 className={!shouldShowOriginFields(location.pathname) ? 'disabled-label' : ''}>
                                            {t('create_event_timestamp')}
                                        </h5>
                                        <IntervalViewer
                                            startDate={originForm.ot}
                                            enabled={shouldShowOriginFields(location.pathname)}
                                            dateConfirm={handleDateMilliseconds}
                                        />
                                    </div>
                                    <div className="form-item-little">
                                        <TextInput
                                            labelText="+/- (sec)"
                                            className="input"
                                            id="err_ot"
                                            value={originForm.err_ot}
                                            onChange={handleDateErr}
                                            invalid={
                                                originForm.err_ot !== undefined
                                                    ? isNaN(+originForm.err_ot!)
                                                    : false
                                            }
                                            invalidText={t(
                                                "create_event_not_valid_input"
                                            )}
                                            disabled={!shouldShowOriginFields(location.pathname)}
                                        />
                                    </div>
                                </div>

                                <div className={"two-columns"}>
                                    <div className="form-item-big">
                                        {
                                            shouldShowOriginFields(location.pathname)
                                                ? <Dropdown
                                                    style={{ width: '100%' }}
                                                    titleText={t('create_event_type_origin')}
                                                    onChange={handleTypeOrigin}
                                                    items={TYPE_ORIGIN_LIST}
                                                    initialSelectedItem={t("create_event_choose_value")}
                                                    invalid={typeOriginForm.name && !isValidTypeOrigin(typeOriginForm.name, typeOriginForm.version_name, typeOriginForm.version_value)}
                                                    invalidText={t('create_event_not_valid_input')}
                                                />
                                                : <TextInput
                                                    labelText={t("create_event_type_origin")}
                                                    className="input"
                                                    id="RMS"
                                                    value={`${typeOriginForm.version_name} (${typeOriginForm.version_value})`}
                                                    disabled={true}
                                                />
                                        }
                                    </div>
                                    <div className="form-item-big">
                                        <h5 style={{ alignSelf: "start" }}
                                            className={!shouldShowOriginFields(location.pathname) ? 'disabled-label' : ''}
                                        >
                                            {t("create_event_comunication_event")}
                                        </h5>
                                        <Toggle
                                            className={"mt-8"}
                                            id="toBeSent"
                                            defaultToggled={eventForm.toBeSent}
                                            disabled={!shouldShowOriginFields(location.pathname)}
                                            onToggle={() =>
                                                setEventForm({
                                                    ...eventForm,
                                                    toBeSent: !eventForm.toBeSent,
                                                })
                                            }
                                            hideLabel
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="origin-row-form">
                                <div className={"two-columns"}>
                                    <div className="form-item-big">
                                        <TextInput
                                            labelText={t("create_event_latitude")}
                                            className="input"
                                            id="latitude"
                                            value={originForm.lat}
                                            onChange={handleLat}
                                            invalid={
                                                originForm.lat !== undefined
                                                    ? !utility.isValidLatitude(
                                                        originForm.lat
                                                    )
                                                    : false
                                            }
                                            invalidText={t(
                                                "create_event_not_valid_input"
                                            )}
                                            disabled={!shouldShowOriginFields(location.pathname)}
                                        />
                                    </div>
                                    <div className="form-item-little">
                                        <TextInput
                                            labelText={t("create_event_lat_error")}
                                            className="input"
                                            id="err_lat"
                                            value={originForm.err_lat}
                                            onChange={handleErr_lat}
                                            invalid={
                                                originForm.err_lat !== undefined
                                                    ? !utility.isValid(
                                                        originForm.err_lat,
                                                        Number.MIN_SAFE_INTEGER,
                                                        Number.MAX_SAFE_INTEGER,
                                                        true
                                                    )
                                                    : false
                                            }
                                            invalidText={t(
                                                "create_event_not_valid_input"
                                            )}
                                            disabled={!shouldShowOriginFields(location.pathname)}
                                        />
                                    </div>
                                </div>

                                <div className={"two-columns me-4"}>
                                    <div className="form-item-big">
                                        <TextInput
                                            labelText={t("create_event_longitude")}
                                            className="input"
                                            id="longitude"
                                            value={originForm.lon}
                                            onChange={handleLong}
                                            invalid={
                                                originForm.lon !== undefined
                                                    ? !utility.isValidLongitude(
                                                        originForm.lon
                                                    )
                                                    : false
                                            }
                                            invalidText={t(
                                                "create_event_not_valid_input"
                                            )}
                                            disabled={!shouldShowOriginFields(location.pathname)}
                                        />
                                    </div>
                                    <div className="form-item-little">
                                        <TextInput
                                            labelText={t("create_event_lon_error")}
                                            className="input"
                                            id="err_lon"
                                            value={originForm.err_lon}
                                            onChange={handleErr_lon}
                                            invalid={
                                                originForm.err_lon !== undefined
                                                    ? !utility.isValid(
                                                        originForm.err_lon,
                                                        Number.MIN_SAFE_INTEGER,
                                                        Number.MAX_SAFE_INTEGER,
                                                        true
                                                    )
                                                    : false
                                            }
                                            invalidText={t(
                                                "create_event_not_valid_input"
                                            )}
                                            disabled={!shouldShowOriginFields(location.pathname)}
                                        />
                                    </div>
                                </div>

                                <div className={"two-columns ms-4"}>
                                    <div className="form-item-big">
                                        <TextInput
                                            labelText={t("create_event_depth")}
                                            className="input"
                                            id="depth"
                                            value={originForm.depth}
                                            onChange={handleDepth}
                                            invalid={
                                                originForm.depth !== undefined
                                                    ? !utility.isValidDepth(
                                                        originForm.depth ?? "",
                                                        true
                                                    )
                                                    : false
                                            }
                                            invalidText={t(
                                                "create_event_not_valid_input"
                                            )}
                                            disabled={!shouldShowOriginFields(location.pathname)}
                                        />
                                    </div>
                                    <div className="form-item-little">
                                        <TextInput
                                            labelText="+/- (Km)"
                                            className="input"
                                            id="err_h"
                                            value={originForm.err_h}
                                            onChange={handleDepth_err}
                                            invalid={
                                                originForm.err_h !== undefined
                                                    ? isNaN(+originForm.err_h!)
                                                    : false
                                            }
                                            invalidText={t(
                                                "create_event_not_valid_input"
                                            )}
                                            disabled={!shouldShowOriginFields(location.pathname)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="origin-row-form">
                                <div className={"two-columns"}>
                                    <div className="form-item-big">
                                        {
                                            shouldShowOriginFields(location.pathname)
                                                ? <Dropdown
                                                    titleText={t(
                                                        "create_event_quality_localization"
                                                    )}
                                                    onChange={handleDropDownOrigin}
                                                    items={quality}
                                                    initialSelectedItem={t("create_event_choose_value")}
                                                />
                                                : <TextInput
                                                    labelText={t("create_event_quality_localization")}
                                                    className="input"
                                                    id="RMS"
                                                    value={originForm.origin_quality}
                                                    disabled={true}
                                                />
                                        }
                                    </div>
                                    <div className="form-item-little mt-2">
                                        <TextInput
                                            labelText={t("create_event_rms")}
                                            className="input"
                                            id="RMS"
                                            value={originForm.rms}
                                            onChange={handleRMS}
                                            invalid={
                                                originForm.rms !== undefined ? isNaN(+originForm.rms!) : false
                                            }
                                            invalidText={t(
                                                "create_event_not_valid_input"
                                            )}
                                            disabled={!shouldShowOriginFields(location.pathname)}
                                        />
                                    </div>
                                    <div className="form-item-little mt-2">
                                        <TextInput
                                            labelText={t("create_event_gap")}
                                            className="input"
                                            id="azim_gap"
                                            value={originForm.azim_gap}
                                            onChange={handleGap}
                                            invalid={
                                                originForm.azim_gap !== undefined
                                                    ? isNaN(+originForm.azim_gap!)
                                                    : false
                                            }
                                            invalidText={t(
                                                "create_event_not_valid_input"
                                            )}
                                            disabled={!shouldShowOriginFields(location.pathname)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* magnitude */}
                        <div className="create-box-flex">
                            <h4>{t('magnitude')}</h4>
                            <div className="origin-row-form">
                                <div className="form-item-big">
                                    <Dropdown
                                        titleText={t(
                                            "create_event_quality_mag"
                                        )}
                                        onChange={handleDropDownMagQuality}
                                        items={quality}
                                        initialSelectedItem={t(
                                            "create_event_choose_value"
                                        )}
                                    />
                                </div>
                                <div className="form-item-big">
                                    <Dropdown
                                        titleText={t(
                                            "create_event_typology_mag"
                                        )}
                                        onChange={handleDropDownMagType}
                                        items={magnitudeTypesOptions}
                                    />
                                </div>
                                <div className="form-item-big mt-2">
                                    <TextInput
                                        labelText={t("create_event_value_mag")}
                                        className="input"
                                        id="ValoreMagnitudo"
                                        value={magnitudeForm.mag}
                                        onChange={handleMag}
                                        invalid={
                                            magnitudeForm.mag !== undefined
                                                ? !utility.isValidMagnitude(magnitudeForm.mag ?? "", true) //isNaN(+magnitudeForm.mag!)
                                                : false
                                        }
                                        invalidText={t(
                                            "create_event_not_valid_input"
                                        )}
                                    />
                                </div>
                                <div className={"two-columns"}>
                                    {!magnitudeForm.symmetric_error &&
                                        <div className="form-item-little">
                                            <TextInput
                                                labelText={magnitudeForm.symmetric_error ? '+/-' : '+'}
                                                className="input"
                                                id="ValoreMagnitudoPlus"
                                                value={magnitudeForm.upper_uncertainty ?? ''}
                                                onChange={handleMagUpper_uncertainty}
                                                invalid={
                                                    magnitudeForm.upper_uncertainty !==
                                                        undefined
                                                        ? isNaN(
                                                            +magnitudeForm.upper_uncertainty!
                                                        )
                                                        : false
                                                }
                                                invalidText={t(
                                                    "create_event_not_valid_input"
                                                )}
                                            />
                                        </div>
                                    }
                                    <div className="form-item-little">
                                        <TextInput
                                            labelText={magnitudeForm.symmetric_error ? '+/-' : '-'}
                                            className="input"
                                            id="ValoreMagnitudoMinus"
                                            value={magnitudeForm.lower_uncertainty ?? ''}
                                            onChange={handleMagLower_uncertainty}
                                            invalid={
                                                magnitudeForm.lower_uncertainty !==
                                                    undefined
                                                    ? isNaN(
                                                        +magnitudeForm.lower_uncertainty!
                                                    )
                                                    : false
                                            }
                                            invalidText={t(
                                                "create_event_not_valid_input"
                                            )}
                                        />
                                    </div>
                                    <div className="form-item-big">
                                        <h5 style={{ alignSelf: "start" }}>
                                            {t("create_event_symmetrical_error")}
                                        </h5>
                                        <Toggle
                                            id="symmetric_error"
                                            className={"mt-8"}
                                            toggled={magnitudeForm.symmetric_error}
                                            onToggle={() =>
                                                setMagnitudeForm({
                                                    ...magnitudeForm,
                                                    symmetric_error: !magnitudeForm.symmetric_error,
                                                    upper_uncertainty: undefined,
                                                    lower_uncertainty: undefined,
                                                })
                                            }
                                            hideLabel
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* buttons */}
                        <div className="create-box-flex">
                            <div className="origin-row-form right">
                                <div className="form-item-buttonCancel">
                                    <Button
                                        className="button-undo"
                                        onClick={() => navigate(-1)}
                                    >
                                        {t("create_event_button_cancel")}
                                    </Button>
                                </div>
                                <div className="form-item-buttonSave">
                                    <Button
                                        className="button-save"
                                        onClick={handleSubmitConfirmation}
                                        disabled={saveInvalid}
                                    >
                                        {t("create_event_button_save")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="create-event-map">
                    <MapContainer
                        bounds={[[90, -180], [-90, 180],]}
                        zoomControl={true}
                        scrollWheelZoom={true}
                        minZoom={5}
                        style={
                            {
                                height: "calc(100vh - 56px)",
                            } /* 56px is the size of the navbar */
                        }
                        ref={map}
                    >
                        <ZoomControl position="bottomright" />
                        <TileLayer
                            url={`${process.env.REACT_APP_BEA_TILE_LAYER}`}
                            attribution={process.env.REACT_APP_BEA_TILE_LAYER_DESCRIPTION}
                        />
                        {/*reference layer*/}
                        {/* removed as done on EE Front End */}
                        {/*<TileLayer
                            url={`${process.env.REACT_APP_BEA_REFERENCE_TILE_LAYER}`}
                        />*/}
                        <Marker
                            position={markerCoords}
                            icon={
                                new Leaflet.DivIcon({
                                    html: `<div class='origin-marker'>
                                <div class='origin-marker-circle'></div>
                            </div>`,
                                    className: "no-show",
                                })
                            }
                        />
                    </MapContainer>
                </div>
            </div>
            {
                showNotification.show &&
                (showNotification.kind === 'success'
                    ? <Toast
                        className='settings-notification'
                        title={t(showNotification.reason)}
                        onClose={() => setShowNotification(defaultNotificationState)}
                    />
                    : <InlineNotification
                        className="settings-notification"
                        kind={showNotification.kind}
                        lowContrast
                        actionbuttonlabel="Action"
                        ariaLabel="closes notification"
                        onClose={() => setShowNotification(defaultNotificationState)}
                        onCloseButtonClick={function noRefCheck() { }}
                        statusIconDescription="notification"
                        subtitle={t(showNotification.reason)}
                        title={t(showNotification.title)}
                    />)
            }
            <CustomModal
                {
                ...modalSpec
                } />
        </>
    );
};

export default CreateEvent;
