import { useEffect, useState } from 'react'
import './DoubleRowPublished.css'
import CustomTooltip from '../CustomTooltip/CustomTooltip'

interface DoubleRowPublishedParams {
    str: string
}

function DoubleRowPublished({str}: DoubleRowPublishedParams) {

    let tokens = str.split('#')
    const [title, setTitle] = useState('--')
    const [content, setContent] = useState('--')

    useEffect(() => {
        if(tokens.length >= 2) {
            setTitle(tokens[0])
            setContent(tokens[1])
        } else if(tokens.length === 1) {
            setTitle(tokens[0])
            setContent('(--)')
        } else {
            setTitle('--')
            setContent('(--)')
        }
    }, [str])
    

    return (
        <div className='doublerow-published-container'>
            <CustomTooltip placement="top" title={`${title} - ${content}`} arrow>
                <span className='doublerow-published-title'>{title}</span>
            </CustomTooltip>
            <span className='doublerow-published-content'>{content}</span>
        </div>
    )
}

export { DoubleRowPublished }