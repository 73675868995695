import './SettingsCreateForm.css'
import {useTranslation} from "react-i18next";
import { TextInput } from '@carbon/react';
import React, {useEffect, useState} from "react";
import {StateAllowedSettingsType} from "./SettingsGenericTable";
import { ObjectTableProvenance } from '../../services/network';

interface SettingsCreateFormProps {
    element: ObjectTableProvenance
    onUpdateElement: (element: ObjectTableProvenance) => void
}

const SettingsProvenanceCreateForm = ({
    element, onUpdateElement
}: SettingsCreateFormProps) => {

    const {t} = useTranslation()
    const [localElement, setLocalElement] = useState<ObjectTableProvenance>(element)

    const updateName = (e: React.FormEvent<HTMLInputElement>) => setLocalElement({
        ...localElement,
        name: e.currentTarget.value
    })
    const updateSoftwareName = (e: React.FormEvent<HTMLInputElement>) => setLocalElement({
        ...localElement, softwarename: e.currentTarget.value
    })
    const updateVersion = (e: React.FormEvent<HTMLInputElement>) => setLocalElement({
        ...localElement, version: e.currentTarget.value
    })
    const updateModel = (e: React.FormEvent<HTMLInputElement>) => setLocalElement({
        ...localElement, model: e.currentTarget.value
    })
    const updateMethod = (e: React.FormEvent<HTMLInputElement>) => setLocalElement({
        ...localElement, method: e.currentTarget.value
    })
    const updateDescription = (e: React.FormEvent<HTMLInputElement>) => setLocalElement({
        ...localElement,
        description: e.currentTarget.value
    })

    useEffect(() => {
        onUpdateElement(localElement)
    }, [localElement])

    return(<>
        <div className={'settings-create-form'}>
            <span className={'label mb-8'}>{t('settings_table_form_name')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-name"
                hideLabel
                value={localElement.name}
                onChange={updateName}
            />
            <span className={'label mb-8 mt-24'}>{t('settings_table_form_softwarename')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-name"
                hideLabel
                value={localElement.softwarename}
                onChange={updateSoftwareName}
            />
            <span className={'label mb-8 mt-24'}>{t('settings_table_form_version')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-name"
                hideLabel
                value={localElement.version}
                onChange={updateVersion}
            />
            <span className={'label mb-8 mt-24'}>{t('settings_table_form_model')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-name"
                hideLabel
                value={localElement.model}
                onChange={updateModel}
            />
            <span className={'label mb-8 mt-24'}>{t('settings_table_form_method')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-name"
                hideLabel
                value={localElement.method}
                onChange={updateMethod}
            />
            <span className={'label mb-8 mt-24'}>{t('settings_table_form_description')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-description"
                hideLabel
                value={localElement.description}
                onChange={updateDescription}
            />
        </div>
    </>)
}

export {
    SettingsProvenanceCreateForm
}