import './StatusButton.css'
import { IconButton } from '@carbon/react'
import { ReactComponent as IconStatus } from '../../assets/status.svg'

type StatusButtonProps = {
    callback: object
}

const StatusButton = ({callback}: StatusButtonProps) => {

    return <div className='status-btn-container'>
            <IconButton className="status-btn" label='info' onClick={callback}>
                <IconStatus />
            </IconButton>
        </div>
}

export { StatusButton }