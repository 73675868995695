import { ObjectArrival, ObjectEvent, ObjectFocalmechanism, ObjectMagnitude, ObjectOrigin, ObjectTableLocalspace, ObjectTableProvenance, ObjectTableTypeEvent, ObjectTableTypeMagnitude, ObjectTableTypeOrigin } from "../../services/network";
import { DATETIME_TAG } from "../CustomTable/TableUtils";
import { EVALUATION_TAG, EventDetailArrival, PHASE_TAG, POLARITY_TAG, UNCERTAINTY_TAG, WEIGHT_TAG } from "../CustomTable/flavor/CustomArrivalTable";

export type SortableById = ObjectEvent | ObjectOrigin | ObjectMagnitude | ObjectTableTypeEvent | ObjectTableTypeOrigin | ObjectTableTypeMagnitude | ObjectTableProvenance | ObjectTableLocalspace
export type SortableByOt = ObjectOrigin
export type SortableByMinDist = ObjectOrigin | ObjectMagnitude
export type SortableByQty = ObjectOrigin | ObjectMagnitude
export type SortableByMag = ObjectMagnitude
export type SortableByModified = ObjectEvent | ObjectOrigin | ObjectMagnitude | ObjectFocalmechanism
export type SortableByInserted = ObjectEvent | ObjectOrigin | ObjectMagnitude | ObjectFocalmechanism
export type SortableByName = ObjectTableTypeEvent | ObjectTableTypeOrigin | ObjectTableTypeMagnitude | ObjectTableLocalspace | ObjectTableProvenance
export type SortableByPriority = ObjectTableTypeOrigin | ObjectTableTypeMagnitude


export function nameAsc(o1: SortableByName, o2: SortableByName): number {
    return `${o1?.name ?? ''}`.localeCompare(`${o2?.name ?? ''}`);
}

export function nameDesc(o1: SortableById, o2: SortableById): number {
    return -nameAsc(o1, o2);
}

export function softwareNameAsc(o1: ObjectTableProvenance, o2: ObjectTableProvenance): number {
    return `${o1?.softwarename ?? ''}`.localeCompare(`${o2?.softwarename ?? ''}`);
}

export function softwareNameDesc(o1: ObjectTableProvenance, o2: ObjectTableProvenance): number {
    return -softwareNameAsc(o1, o2);
}

export function modelAsc(o1: ObjectTableProvenance, o2: ObjectTableProvenance): number {
    return `${o1?.model ?? ''}`.localeCompare(`${o2?.model ?? ''}`);
}

export function modelDesc(o1: ObjectTableProvenance, o2: ObjectTableProvenance): number {
    return -modelAsc(o1, o2);
}

export function methodAsc(o1: ObjectTableProvenance, o2: ObjectTableProvenance): number {
    return `${o1?.method ?? ''}`.localeCompare(`${o2?.method ?? ''}`);
}

export function methodDesc(o1: ObjectTableProvenance, o2: ObjectTableProvenance): number {
    return -methodAsc(o1, o2);
}

export function versionAsc(o1: ObjectTableProvenance, o2: ObjectTableProvenance): number {
    return `${o1?.version ?? ''}`.localeCompare(`${o2?.version ?? ''}`);
}

export function versionDesc(o1: ObjectTableProvenance, o2: ObjectTableProvenance): number {
    return -versionAsc(o1, o2);
}

export function versionNameAsc(o1: ObjectTableTypeOrigin, o2: ObjectTableTypeOrigin): number {
    return `${o1?.version_name ?? ''}`.localeCompare(`${o2?.version_name ?? ''}`);
}

export function versionNameDesc(o1: ObjectTableTypeOrigin, o2: ObjectTableTypeOrigin): number {
    return -versionNameAsc(o1, o2);
}

export function versionValueAsc(o1: ObjectTableTypeOrigin, o2: ObjectTableTypeOrigin): number {
    const id1: number = o1.version_value ?? -2;
    const id2: number = o2.version_value ?? -2;
    if (id1 > id2) {
        return 1;
    } else if (id1 < id2) {
        return -1;
    } else {
        return 0;
    }
}

export function versionValueDesc(o1: ObjectTableTypeOrigin, o2: ObjectTableTypeOrigin): number {
    return -versionValueAsc(o1, o2);
}

function idAsc(o1: SortableById, o2: SortableById): number {
    const id1: number = o1.id ?? -2;
    const id2: number = o2.id ?? -2;
    if (id1 > id2) {
        return 1;
    } else if (id1 < id2) {
        return -1;
    } else {
        return 0;
    }
}

export function priorityDesc(o1: SortableByPriority, o2: SortableByPriority): number {
    return -priorityAsc(o1, o2);
}

export function priorityAsc(o1: SortableByPriority, o2: SortableByPriority): number {
    const id1: number = o1.priority === undefined ? -2 : o1.priority;
    const id2: number = o2.priority === undefined ? -2 : o2.priority;

    if (o1.id === 2011 || o2.id === 2011) {
        console.log('o1', o1.priority, id1);
        console.log('o2', o2.priority, id2);
    }
    if (id1 > id2) {
        return 1;
    } else if (id1 < id2) {
        return -1;
    } else {
        return 0;
    }
}

function idDesc(o1: SortableById, o2: SortableById): number {
    return -idAsc(o1, o2);
}

function otAsc(o1: SortableByOt, o2: SortableByOt): number {
    return `${o1?.ot ?? ''}`.localeCompare(`${o2?.ot ?? ''}`);
}

function otDesc(o1: SortableByOt, o2: SortableByOt): number {
    return -otAsc(o1, o2);
}

function mindistAsc(o1: SortableByMinDist, o2: SortableByMinDist): number {
    const d1: number = o1.min_distance ?? Number.MAX_VALUE;
    const d2: number = o2.min_distance ?? Number.MAX_VALUE;

    if (d1 > d2) {
        return 1;
    }

    if (d1 < d2) {
        return -1;
    }

    return 0;
}

function mindistDesc(o1: SortableByMinDist, o2: SortableByMinDist): number {
    return -mindistAsc(o1, o2);
}

function qtyAsc(o1: SortableByQty, o2: SortableByQty): number {
    return `${o1?.quality ?? ''}`.localeCompare(`${o2?.quality ?? ''}`);
}

function qtyDesc(o1: SortableByQty, o2: SortableByQty): number {
    return -qtyAsc(o1, o2);
}

function modifiedAsc(o1: SortableByModified, o2: SortableByModified): number {
    return `${o1?.modified ?? ''}`.localeCompare(`${o2?.modified ?? ''}`);
}

function modifiedDesc(o1: SortableByModified, o2: SortableByModified): number {
    return -modifiedAsc(o1, o2);
}

function insertedAsc(o1: SortableByInserted, o2: SortableByInserted): number {
    return `${o1?.inserted ?? ''}`.localeCompare(`${o2?.inserted ?? ''}`);
}

function insertedDesc(o1: SortableByInserted, o2: SortableByInserted): number {
    return -insertedAsc(o1, o2);
}

function magAsc(o1: SortableByMag, o2: SortableByMag): number {
    return `${o1?.mag ?? ''}`.localeCompare(`${o2?.mag ?? ''}`);
}

function magDesc(o1: SortableByMag, o2: SortableByMag): number {
    return -magAsc(o1, o2);
}

function scnlAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return o1.scnl.localeCompare(o2.scnl);
}

function scnlDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -scnlAsc(o1, o2);
}

function weightAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    if (o1.w > o2.w) { return 1; }
    else if (o1.w < o2.w) { return -1; }
    else { return 0; }
}

function weightDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -weightAsc(o1, o2);
}

function residualAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    if (o1.r > o2.r) { return 1; }
    else if (o1.r < o2.r) { return -1; }
    else { return 0; }
}

function residualDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -residualAsc(o1, o2);
}

function restAngleAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    if (o1.ra > o2.ra) { return 1; }
    else if (o1.ra < o2.ra) { return -1; }
    else { return 0; }
}

function restAngleDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -restAngleAsc(o1, o2);
}

function distanceAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    if (o1.d > o2.d) { return 1; }
    else if (o1.d < o2.d) { return -1; }
    else { return 0; }
}

function distanceDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -distanceAsc(o1, o2);
}

function azAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    if (o1.a > o2.a) { return 1; }
    else if (o1.a < o2.a) { return -1; }
    else { return 0; }
}

function azDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -azAsc(o1, o2);
}

function phaseAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return o1[PHASE_TAG].localeCompare(o2[PHASE_TAG]);
}

function phaseDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -phaseAsc(o1, o2);
}

function evaluationAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return o1[EVALUATION_TAG].localeCompare(o2[EVALUATION_TAG]);
}

function evaluationDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -evaluationAsc(o1, o2);
}

function datetimeAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return o1[DATETIME_TAG].localeCompare(o2[DATETIME_TAG]);
}

function datetimeDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -datetimeAsc(o1, o2);
}

function uncertaintyAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return o1[UNCERTAINTY_TAG].localeCompare(o2[UNCERTAINTY_TAG]);
}

function uncertaintyDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -datetimeAsc(o1, o2);
}

function polarityAsc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return o1[POLARITY_TAG].localeCompare(o2[POLARITY_TAG]);
}

function polarityDesc(o1: EventDetailArrival, o2: EventDetailArrival): number {
    return -datetimeAsc(o1, o2);
}


export {
    idAsc, idDesc, otAsc, otDesc, mindistAsc, mindistDesc, qtyAsc, qtyDesc,
    magAsc, magDesc,
    insertedAsc, insertedDesc, modifiedAsc, modifiedDesc,
    scnlAsc, scnlDesc, weightAsc, weightDesc, residualAsc, residualDesc,
    restAngleAsc, restAngleDesc, distanceAsc, distanceDesc, azAsc, azDesc, phaseAsc, phaseDesc,
    evaluationAsc, evaluationDesc, datetimeAsc, datetimeDesc, uncertaintyAsc, uncertaintyDesc, polarityAsc, polarityDesc
}