import React, { useEffect, useState } from 'react';
import './NewDateTimeSelector.css'
import { useTranslation } from "react-i18next";
import './DateTimeSelector.css'
import { DatePicker, DatePickerInput, Dropdown, TextInput } from '@carbon/react'
import { Option } from "../CustomSelector/CustomSelector";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import { setStoreFullInterval } from "../../redux/reducers/timepicker.slice";
import { validInterval } from "./utils";

export interface RelativeRange {
    days: number
}

export interface AbsoluteRange {
    startDate: string,
    startHour: string,
    endDate?: string,
    endHour?: string
}

export type NewIntervalType = RelativeRange | AbsoluteRange

interface SelectedItem {
    selectedItem: Option
}

const NewDateTimeSelector = function () {

    const { t } = useTranslation()
    const [initialItem, setInitialItem] = useState<number>(0)

    const [startDate, setStartDate] = useState('')
    const [startHour, setStartHour] = useState('')
    const [tmpStartHour, setTmpStartHour] = useState<string>('')
    const [isChangingStartHour, setIsChangingStartHour] = useState(false)

    const [endDate, setEndDate] = useState<string | undefined>('')
    const [endHour, setEndHour] = useState<string | undefined>('')
    const [tmpEndHour, setTmpEndHour] = useState<string>('')
    const [isChangingEndHour, setIsChangingEndHour] = useState(false)

    const [invalid, setInvalid] = useState(false)

    const interval = useAppSelector((state) => state.timepicker.fullInterval)
    const dispatch = useAppDispatch()

    const findOptionIndex = function (key: string): number {
        let index = options.findIndex((o) => o.key === key)
        return index >= 0 ? index : 0;
    }

    // Here we set the initial state of the dropdown.
    // It should be necessary in further requests
    // useEffect[interval]
    useEffect(() => {
        const tag = `NewDateTimeSelector => useEffect[interval] =>`

        console.log(tag, interval)

        if ('days' in interval) {
            if (interval.days) {
                setInitialItem(findOptionIndex(`${interval.days}`))
            }
            return
        }

        if ('startDate' in interval) {
            console.log(tag, 'startDate')
            setInitialItem(options.length - 1)
            return
        }
    }, [interval])

    // Here we populate the boxes accordingly to the interval selected
    // useEffect[interval] 2
    useEffect(() => {
        console.log('NewDateTimeSelector => useEffect[interval] 2 => ', interval)
        if ('days' in interval) {
            setStartDate(moment().subtract(interval.days, 'days').format('DD/MM/YYYY'))
            setStartHour('00:00:00')
            setEndDate(moment().format('DD/MM/YYYY'))
            setEndHour('23:59:59')
        } else if ('startDate' in interval) {
            setStartDate(interval.startDate)
            setStartHour(interval.startHour)
            setEndDate(interval?.endDate ?? '')
            setEndHour(interval?.endHour ?? '')
        }
    }, [interval])

    // useEffect[interval] 3
    useEffect(() => {
        setInvalid(!validInterval(interval))
    }, [interval])

    const handleStartDateSelection = function (selected: SelectedItem) {
        let { key } = selected.selectedItem
        console.log('NewDateTimeSelector => handleStartDateSelection =>', key)
        if (key === 'custom') {
            const _interval = {
                startDate: startDate,
                startHour: startHour,
                endDate: endDate,
                endHour: endHour
            }
            console.log('NewDateTimeSelector => _interval =>', _interval)
            dispatch(setStoreFullInterval(_interval))
        } else {
            dispatch(setStoreFullInterval({ days: Number(key) }))
            setEndDate(moment().format('DD/MM/YYYY'))
            setEndHour('23:59:59')
        }
        setInitialItem(findOptionIndex(key))
    }

    const handleStartDateInput = (dates: any) => {
        // this is triggered only when the date is typed
        const [inputDate] = dates
        console.log('NewDateTimeSelector => handleStartDateInput =>', inputDate as Date)
        let text = moment(inputDate).format('DD/MM/YYYY')
        console.log('NewDateTimeSelector => handleStartDateInput =>', text)
        setInitialItem(options.length - 1)

        dispatch(setStoreFullInterval({
            startDate: text,
            startHour,
            endDate,
            endHour
        }))
    }

    const handleEndDateSelection = (dates: any) => {
        // this is triggered only when the date is typed
        const [inputDate] = dates
        console.log('NewDateTimeSelector => handleEndDateSelection => inputDate =>', inputDate as Date)
        let text = ''
        let _endHour: string = ''
        if (inputDate) {
            text = moment(inputDate).format('DD/MM/YYYY')
            if (endHour) {
                _endHour = endHour === '' ? '23:59:59' : endHour
            } else {
                _endHour = '23:59:59'
            }
        }

        setInitialItem(options.length - 1)

        dispatch(setStoreFullInterval({
            startDate,
            startHour,
            endDate: text,
            endHour: _endHour
        }))
    }

    const handleStartHourFocus = () => {
        // this is triggered only when the Hour is typed
        console.log('NewDateTimeSelector => handleStartHourFocus =>')
        setTmpStartHour(startHour)
        setIsChangingStartHour(true)
        // if new Hour is different from old
    }

    const handleStartHourSelection = (e: React.FormEvent<HTMLInputElement>) => {
        // this is triggered only when the date is typed
        console.log('NewDateTimeSelector => handleStartDateInput =>', e.currentTarget.value)
        setInitialItem(options.length - 1)

        setTmpStartHour(e.currentTarget.value)

    }

    const handleStartHourBlur = () => {
        // this is triggered only when the date is typed
        console.log('NewDateTimeSelector => handleStartHourBlur => startHour =>', startHour)
        console.log('NewDateTimeSelector => handleStartHourBlur => tmpStartHour =>', tmpStartHour)
        if (validHour(tmpStartHour, true)) {
            console.log('NewDateTimeSelector => handleStartHourBlur => valid hour')
            setStartHour(tmpStartHour)
            dispatch(setStoreFullInterval({
                startDate,
                startHour: tmpStartHour,
                endDate,
                endHour
            }))
        } else {
            console.log('NewDateTimeSelector => handleStartHourBlur => invalid hour')
        }
        setIsChangingStartHour(false)
        setTmpStartHour('')
    }

    const handleEndHourFocus = () => {
        // this is triggered only when the Hour is typed
        console.log('NewDateTimeSelector => handleStartHourFocus =>')
        setTmpEndHour(endHour ?? '')
        setIsChangingEndHour(true)
        // if new Hour is different from old
    }

    const handleEndHourSelection = (e: React.FormEvent<HTMLInputElement>) => {
        // this is triggered only when the date is typed
        console.log('NewDateTimeSelector => handleEndHourSelection =>', e.currentTarget.value)
        setTmpEndHour(e.currentTarget.value)

    }

    const handleEndHourBlur = () => {
        // this is triggered only when the date is typed
        console.log('NewDateTimeSelector => handleEndHourBlur => endHour =>', endHour)
        console.log('NewDateTimeSelector => handleEndHourBlur => tmpEndHour =>', tmpEndHour)
        if (validHour(tmpEndHour, false)) {
            setEndHour(tmpEndHour)
            dispatch(setStoreFullInterval({
                startDate,
                startHour,
                endDate,
                endHour: tmpEndHour
            }))
        }
        setIsChangingEndHour(false)
        setTmpEndHour('')
    }

    /**
     *
     * @param hour
     * @param isStartDate true if we're checking startHour; false if we're checking endHour
     */
    const validHour = function (hour: string, isStartDate: boolean): boolean {
        if (hour === '') {
            if (isStartDate) {
                if (!startDate) return true
                if (startDate === '') return true
            } else {
                console.log('NewDateTimeSelector => validHour => endDate =>', endDate)
                if (!endDate) return true
                if (endDate === '') return true
            }
            return false
        } else {
            if (isStartDate) {
                if (startDate === '') return false
            } else {
                if (!endDate) return false
                if (endDate === '') return false
            }
        }

        if (!hour.match(/\d{2}:\d{2}:\d{2}/g))
            return false
        let tokens = hour.split(':').map((h) => Number(h))
        if (tokens.length !== 3) return false
        if (tokens[0] < 0 || tokens[0] > 23) return false
        if (tokens[1] < 0 || tokens[1] > 59) return false
        return !(tokens[2] < 0 || tokens[2] > 59);

    }

    const options: Option[] = [
        { key: "7", value: t('interval_7_days') },
        { key: "30", value: t('interval_30_days') },
        { key: "90", value: t('interval_90_days') },
        { key: "365", value: t('interval_365_days') },
        { key: "custom", value: t('interval_custom') },
    ]

    return (
        <>
            <p className='subtitle mb-16'>{t('timestamp')}</p>
            <p className='label mb-8'>{t('select_interval')}</p>
            <Dropdown
                id={'interval-selector'}
                label={''}
                hideLabel
                titleText={''}
                onChange={handleStartDateSelection}
                items={options}
                itemToString={(item: Option) => item.value}
                selectedItem={initialItem >= 0 ? options[initialItem] : { key: '', value: t('select_value') }}
            />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '16px' }}>
                <DatePicker
                    dateFormat={'d/m/Y'}
                    datePickerType="single"
                    onChange={handleStartDateInput}
                    value={startDate}
                >
                    <DatePickerInput
                        placeholder="dd/mm/yyyy"
                        labelText={t('start_date')}
                        id="date-picker-single"
                        invalid={invalid}
                        invalidText={t('filters_invalid_input')}
                    />
                </DatePicker>

                <TextInput
                    id={"start-hour"}
                    placeholder={'hh:mm:ss'}
                    labelText={t('start_hour')}
                    value={isChangingStartHour ? tmpStartHour : startHour}
                    onChange={handleStartHourSelection}
                    onBlur={handleStartHourBlur}
                    onFocus={handleStartHourFocus}
                    invalid={(isChangingStartHour ? !validHour(tmpStartHour, true) : !validHour(startHour, true)) || invalid}
                    invalidText={t('filters_invalid_input')}
                />
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '16px', marginBottom: '24px' }}>
                <DatePicker
                    dateFormat={'d/m/Y'}
                    datePickerType="single"
                    value={endDate}
                    onChange={handleEndDateSelection}
                >
                    <DatePickerInput
                        placeholder="dd/mm/yyyy"
                        labelText={t('end_date')}
                        id="date-picker-single"
                        invalid={invalid}
                        invalidText={t('filters_invalid_input')}
                    />
                </DatePicker>

                <TextInput
                    id={"end-hour"}
                    placeholder={'hh:mm:ss'}
                    value={isChangingEndHour ? tmpEndHour : endHour}
                    labelText={t('end_hour')}
                    onChange={handleEndHourSelection}
                    onBlur={handleEndHourBlur}
                    onFocus={handleEndHourFocus}
                    invalid={(isChangingEndHour ? !validHour(tmpEndHour, false) : !validHour(endHour ?? '', false)) || invalid}
                    invalidText={t('filters_invalid_input')}
                />
            </div>
        </>
    );
}

export {
    NewDateTimeSelector
}