import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@carbon/react";
import { useTranslation } from "react-i18next";
import { BreadCrumbComposer } from "../../components/BreadcrumbComposer/BreadcrumbComposer";
import { EventTypeTable } from "../../components/EventDetailTable/EventTypeTable";
import { LocalspaceTable } from "../../components/EventDetailTable/LocalspaceTable";
import { MagnitudeTypeTable } from "../../components/EventDetailTable/MagnitudeTypeTable";
import { OriginTypeTable } from "../../components/EventDetailTable/OriginTypeTable";
import { ProvenanceTable } from "../../components/EventDetailTable/ProvenanceTable";
import { SettingsRouteSelector } from "../../components/SettingsTabs/SettingsRouteSelector";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import './Settings.css';
import CustomModal from "../../components/CustomModal/CustomModal";
import { useEffect } from "react";
import { batch } from "react-redux";
import { closeCta } from "../../redux/reducers/cta.slice";
import { viewMode } from "../../redux/reducers/group.slice";
import { closeStoreModal } from "../../redux/reducers/confirmationModal.slice";
import { resetTable } from "../../redux/reducers/customtable.slice";

const Settings = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const modalSpec = useAppSelector((state) => state.confirmationModal);

    useEffect(() => {
        // forcing store cleanup when entering or exiting the page
        closeAllAndReset(); 
        return () => {
            // the cleanup does not work when the page is reloaded
            closeAllAndReset(); 
        }
    }, [/* only after the initial render */]);

    return (
        <div className="settings-page">
            <BreadCrumbComposer pageId="settings" elementIds={[]} />
            <div className="settings-table-container">
                <Tabs >
                    <TabList contained={false}>
                        <Tab className="bea-tab-header">
                            {t("settings_table_event")}
                        </Tab>
                        <Tab className="bea-tab-header">
                            {t("settings_table_origin")}
                        </Tab>
                        <Tab className="bea-tab-header">
                            {t("settings_table_magnitude")}
                        </Tab>
                        <Tab className="bea-tab-header">
                            {t("settings_table_localspace")}
                        </Tab>
                        <Tab className="bea-tab-header">
                            {t("settings_table_provenance")}
                        </Tab>
                        <Tab className="bea-tab-header">
                            {t("settings_table_route")}
                        </Tab>
                    </TabList>
                    <TabPanels className="settings-container">
                        <TabPanel>
                            <EventTypeTable />
                        </TabPanel>
                        <TabPanel>
                            <OriginTypeTable />
                        </TabPanel>
                        <TabPanel>
                            <MagnitudeTypeTable />
                        </TabPanel>
                        <TabPanel>
                            <LocalspaceTable />
                        </TabPanel>
                        <TabPanel>
                            <ProvenanceTable />
                        </TabPanel>
                        <TabPanel>
                            <SettingsRouteSelector />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
            <CustomModal
                {
                ...modalSpec
                } />
        </div>
    );

    function closeAllAndReset(): void {
        batch(() => {
            dispatch(closeCta());
            dispatch(viewMode());
            dispatch(closeStoreModal());
            dispatch(resetTable());
        })
    }
};

export default Settings;
