import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './CustomExpandableNotification.css'


interface CustomExpandableNotificationProps {
    title: string, 
    more: string,
    content: string,
}

export const CustomExpandableNotification = function({title, more, content}: CustomExpandableNotificationProps) {

    const {t} = useTranslation()
    const [open, setOpen] = useState<boolean>(false)

    return (<>
        <div className='im-notification-container'>
            <div className='text-container'>
                <h5>{title}</h5><h6>{more}</h6>
            </div>
            <div className='ghost'
                onClick={() => setOpen(!open)}
            >
                <div className='inner'>
                    <span>{t(open ? 'im_collapse' : 'im_expand')}</span>
                </div>
            </div>

            <div className={`im-notification-content ${open ? 'open' : 'close'}`}>
                {content}
            </div>
        </div>
    </>)
}