import { Filters, RouteType } from "../redux/reducers/filters.slice";
import { DATETIME_TAG, ID_TAG, MAGNITUDE_TAG, SORT_ASC, SORT_DESC } from "../components/CustomTable/TableUtils";
import { CROWN_GEOFENCE, RECTANGULAR_GEOFENCE, towns_list } from "../components/GeofenceSelector/GeofenceSelector";
import {
    DEFAULT_LIMIT,
    DEFAULT_MAX_DEPTH, DEFAULT_MAX_RADIUS,
    DEFAULT_MIN_DEPTH, DEFAULT_MIN_RADIUS, isValidDepth, isValidLatitude, isValidLongitude,
    isValidMagnitude, isValidRadius,
    MAX_ACCEPTABLE_MAGNITUDE,
    MIN_ACCEPTABLE_MAGNITUDE
} from "./index";
import { PRODUCTS_LIST } from "../components/ProductSelector/ProductSelector";

const createUrlFromFilters = function (filters: Filters): string {
    let queryParams: string[] = [];
    let idSearch: boolean = false;
    for (let entry of Object.entries(filters)) {
        let key = entry[0]
        let value = entry[1]
        if (key === 'eventid' && value) {
            idSearch = true;
        }
        if (key === 'originid' && value) {
            idSearch = true;
        }

        if (key === 'magnitudeid' && value) {
            idSearch = true;
        }
        if (key === 'shape') continue;
        if (key === 'sortDirection') continue;
        if (key === 'sortHeader') continue;
        if (key === 'ref_key') continue;
        if (key === 'ref_region') continue;
        if (key === 'origindirectlinktoevent' || key === 'magnitudedirectlinktoorigin' ||
            key === 'magnitudedirectlinktoevent') {
            value = `${entry[1]}`;
            if (!(value === 'true' || value === 'false')) {
                continue
            }
        }
        if (!value) {
            continue;
        }
        if (value === '') {
            continue;
        }
        if (
            key === 'products' || key === 'wheretypemagnitudenameregexp' ||
            key === 'whereoriginqualityin' || key === 'wheremagnitudemagqualityin' || key === 'wheretypeeventnamein' ||
            key === 'wheretypeeventnamenotin'
        ) {
            let k = key;
            if (key === 'products') {
                k = 'whereflagsin';
            }
            if (!idSearch) {

                queryParams = [...queryParams, `${k}=${encodeURIComponent(value)}`]
                continue;
            }
        }

        if (idSearch) {
            if (key === 'eventid' || key === 'originid' || key === 'magnitudeid' || key === 'route') {
                queryParams = [...queryParams, `${key}=${encodeURIComponent(value)}`]
            }
        } else {
            queryParams = [...queryParams, `${key}=${encodeURIComponent(value)}`]
        }
    }
    if (queryParams.length === 0) return '';
    if (queryParams.findIndex((qp) => qp.startsWith('orderby')) === -1) {
        queryParams = [...queryParams, `orderby=${orderby(filters)}`]
    }
    // console.log('createUrlFromFilters => queryParams', queryParams)

    return queryParams.sort((p1, p2) => p1.localeCompare(p2)).join('&')
}

const orderby = function (filters: Filters) {
    let orderby = ``;
    switch (filters.sortHeader) {
        default:
            orderby = `origin_ot`
            break;
        case ID_TAG:
            orderby = `origin_ot`
            break;
        case MAGNITUDE_TAG:
            orderby = `magnitude_mag`
            break;
    }

    return `${orderby}-${filters.sortDirection === SORT_ASC ? 'asc' : 'desc'}`
}
const sortDirection = function (entry?: string[]) {
    if (!entry) return SORT_DESC
    if (entry[1].endsWith('-desc'))
        return SORT_DESC

    return SORT_ASC
}

const sortHeader = function (entry?: string[]) {
    if (!entry) return DATETIME_TAG
    if (entry[1].startsWith('event_id'))
        return ID_TAG
    if (entry[1].startsWith('origin_ot'))
        return DATETIME_TAG
    if (entry[1].startsWith('magnitude_mag'))
        return MAGNITUDE_TAG

    return DATETIME_TAG
}

const shape = function (queryParams: string[][]): string {
    let indexes = [
        queryParams.findIndex((qp) => qp[0] === 'lat'),
        queryParams.findIndex((qp) => qp[0] === 'lon'),
        queryParams.findIndex((qp) => qp[0] === 'minradiuskm'),
        queryParams.findIndex((qp) => qp[0] === 'maxradiuskm')
    ]
    // we need all the 4 data
    if (indexes.some((i) => i === -1))
        return RECTANGULAR_GEOFENCE // this is the default
    // this is set iff lat, lon, minradius and maxradius are set
    return CROWN_GEOFENCE
}

const refKey = function (queryParams: string[][], shape: string): string {
    if (shape === CROWN_GEOFENCE) return ''
    let indexes = [queryParams.findIndex((qp) => qp[0] === 'minlat'),
    queryParams.findIndex((qp) => qp[0] === 'maxlat'),
    queryParams.findIndex((qp) => qp[0] === 'minlon'),
    queryParams.findIndex((qp) => qp[0] === 'maxlon')
    ]
    // we need all the 4 coordinates
    if (indexes.some((i) => i === -1))
        return ''

    for (let ref of towns_list) {
        if (ref.key === '') continue
        if (
            queryParams[indexes[0]][1] === ref.min_lat &&
            queryParams[indexes[1]][1] === ref.max_lat &&
            queryParams[indexes[2]][1] === ref.min_lon &&
            queryParams[indexes[3]][1] === ref.max_lon
        )
            return ref.key
    }

    return ''
}

const getDataFromParamName = function (key: string, queryParams: string[][]): string | undefined {
    let param = queryParams.filter((qp) => qp[0] === key)
    if (param && param.length === 1) {
        return param[0][1]
    }
}

const limit = function (queryParams: string[][], oldFilters: Filters): number {
    let queryLimit = getDataFromParamName('limit', queryParams)
    let filtersLimit = oldFilters?.limit ?? DEFAULT_LIMIT
    if (queryLimit) {
        let numeric = Number(queryLimit)
        if (!isNaN(numeric))
            switch (numeric) {
                case 10:
                case 20:
                case 30:
                case 40:
                case DEFAULT_LIMIT:
                    return numeric
                default:
                    return DEFAULT_LIMIT
            }
    }

    return filtersLimit
}

const getValueWithDefault = function (
    queryParams: string[][],
    key: string,
    validationCallback: (value: string, canBeEmpty: boolean) => boolean,
    defaultValue: number): string | undefined {
    const value = getDataFromParamName(key, queryParams);
    if (!value) return
    if (validationCallback(value, false)) {
        return value;
    }

    return `${defaultValue}`
}

const maxMag = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(
        queryParams,
        'maxmag',
        isValidMagnitude,
        MAX_ACCEPTABLE_MAGNITUDE
    )
}

const minMag = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'minmag', isValidMagnitude, MIN_ACCEPTABLE_MAGNITUDE)
}

const maxDepth = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'maxdepth', isValidDepth, DEFAULT_MAX_DEPTH)
}

const minDepth = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'mindepth', isValidDepth, DEFAULT_MIN_DEPTH)
}

const minLat = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'minlat', isValidLatitude, -90)
}

const maxLat = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'maxlat', isValidLatitude, 90)
}

const lat = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'lat', isValidLatitude, 0)
}

const minLon = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'minlon', isValidLongitude, -180);
}

const maxLon = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'maxlon', isValidLongitude, 180)
}

const lon = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'lon', isValidLongitude, 0)
}

const minradiuskm = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'minradiuskm', isValidRadius, DEFAULT_MIN_RADIUS)
}

const maxradiuskm = function (queryParams: string[][]): string | undefined {
    return getValueWithDefault(queryParams, 'maxradiuskm', isValidRadius, DEFAULT_MAX_RADIUS)
}

const products = function (queryParams: string[][]): string | undefined {
    const value = getDataFromParamName('whereflagsin', queryParams);
    if (!value) return
    let options = value.split(',').filter(o => o !== '');
    options = options.filter((o) => PRODUCTS_LIST.findIndex((p) => p.key === o) >= 0)
    if (options.length > 0)
        return options.join(',')
}

const route = function (queryParams: string[][], oldFilters: Filters): RouteType {
    const value = getDataFromParamName('route', queryParams);
    if (!value) return oldFilters.route
    return value as RouteType
}

const page = function (queryParams: string[][]): number {
    let p = getDataFromParamName('page', queryParams)
    if (!p) return 1
    return Number(p)
}

const orderbyFromUrl = function (queryParams: string[][]) {
    let orderby = queryParams.find((qp) => qp[0] === 'orderby');
    if (!orderby || orderby.length === 0) return 'origin_ot-desc'
    switch (orderby[1]) {
        case 'origin_ot-desc':
        case 'origin_ot-asc':
        case 'event_id-desc':
        case 'event_id-asc':
        case 'magnitude_mag-desc':
        case 'magnitude_mag-asc':
            return orderby[1]
        default:
            return 'origin_ot-desc'
    }
}

const createFiltersFromUrl = function (queryParamsString: string, oldFilters: Filters): Filters {
    console.log('createFiltersFromUrl =>', queryParamsString)
    let queryParams = queryParamsString.split('&').map((qps) => {
        let tokens = qps.split('=')
        return [tokens[0], decodeURIComponent(tokens[1])]
    })

    let s = shape(queryParams)
    return {
        page: page(queryParams),
        sortDirection: sortDirection(queryParams.find((qp) => qp[0] === 'orderby')),
        sortHeader: sortHeader(queryParams.find((qp) => qp[0] === 'orderby')),
        orderby: orderbyFromUrl(queryParams),
        route: route(queryParams, oldFilters),
        shape: s,
        limit: limit(queryParams, oldFilters),
        ref_key: refKey(queryParams, s),
        ref_region: refKey(queryParams, s),
        minmag: minMag(queryParams),
        maxmag: maxMag(queryParams),
        starttime: getDataFromParamName('starttime', queryParams),
        endtime: getDataFromParamName('endtime', queryParams),
        mindepth: minDepth(queryParams),
        maxdepth: maxDepth(queryParams),
        minlat: minLat(queryParams),
        maxlat: maxLat(queryParams),
        minlon: minLon(queryParams),
        maxlon: maxLon(queryParams),
        lat: lat(queryParams),
        lon: lon(queryParams),
        minradiuskm: minradiuskm(queryParams),
        maxradiuskm: maxradiuskm(queryParams),
        products: products(queryParams),
        mintypeoriginvalue: getDataFromParamName('mintypeoriginvalue', queryParams),
        maxtypeoriginvalue: getDataFromParamName('maxtypeoriginvalue', queryParams),
        wheretypeoriginvaluein: getDataFromParamName('wheretypeoriginvaluein', queryParams),
        whereeventlocalspacenamein: getDataFromParamName('whereeventlocalspacenamein', queryParams),
        whereeventlocalspaceenvironmentin: getDataFromParamName('whereeventlocalspaceenvironmentin', queryParams),
        whereoriginlocalspacenamein: getDataFromParamName('whereoriginlocalspacenamein', queryParams),
        whereoriginlocalspaceenvironmentin: getDataFromParamName('whereoriginlocalspaceenvironmentin', queryParams),
        wheremagnitudelocalspaceenvironmentin: getDataFromParamName('wheremagnitudelocalspaceenvironmentin', queryParams),
        wheremagnitudelocalspacenamein: getDataFromParamName('wheremagnitudelocalspacenamein', queryParams),
        eventid: getDataFromParamName('eventid', queryParams),
        originid: getDataFromParamName('originid', queryParams),
        magnitudeid: getDataFromParamName('magnitudeid', queryParams),
        id_localspace: getDataFromParamName('id_localspace', queryParams),
        wheretypemagnitudenameregexp: getDataFromParamName('wheretypemagnitudenameregexp', queryParams),
        // others
        orpolygon: getDataFromParamName('orpolygon', queryParams),
        notinpolygon: getDataFromParamName('notinpolygon', queryParams),
        wherepolygonnamein: getDataFromParamName('wherepolygonnamein', queryParams),
        origindirectlinktoevent: toBoolean(getDataFromParamName('origindirectlinktoevent', queryParams)),
        magnitudedirectlinktoorigin: toBoolean(getDataFromParamName('magnitudedirectlinktoorigin', queryParams)),
        magnitudedirectlinktoevent: toBoolean(getDataFromParamName('magnitudedirectlinktoevent', queryParams)),
        eventupdatedafter: getDataFromParamName('eventupdatedafter', queryParams),
        originupdatedafter: getDataFromParamName('originupdatedafter', queryParams),
        magnitudeupdatedafter: getDataFromParamName('magnitudeupdatedafter', queryParams),
        updatedafteroperator: getDataFromParamName('updatedafteroperator', queryParams),
        // v3.4.0
        minnphtot: getDataFromParamName('minnphtot', queryParams),
        mindistance: getDataFromParamName('mindistance', queryParams),
        whereoriginqualityin: getDataFromParamName('whereoriginqualityin', queryParams),
        wheremagnitudemagqualityin: getDataFromParamName('wheremagnitudemagqualityin', queryParams),
        // v3.9.0
        wheretypeeventnamein: getDataFromParamName('wheretypeeventnamein', queryParams),
        wheretypeeventnamenotin: getDataFromParamName('wheretypeeventnamenotin', queryParams)
    };

    function toBoolean(value: string | undefined): boolean | undefined {
        if (value === undefined) {
            return value;
        }

        return value === 'true';
    }
}

export {
    createUrlFromFilters,
    createFiltersFromUrl
}