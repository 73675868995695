import {PageId} from "../../components/Breadcrumb/Breadcrumb";

export const quality = [
    "AA",
    "AB",
    "AC",
    "AD",
    "BA",
    "BB",
    "BC",
    "BD",
    "CA",
    "CB",
    "CC",
    "CD",
    "DA",
    "DB",
    "DC",
    "DD",
];

export const pageId = function(pathname: string): PageId {
    if(pathname.includes('create/event'))
        return 'createEvent'
    if(pathname.includes('create/origin'))
        return 'createOrigin'
    if(pathname.includes('create/magnitude'))
        return 'createMagnitude'

    return 'error'
}

export const elementIds = function (pathname: string, eventId?: string, originId?: string): string[] {
    if(pathname.includes('create/origin'))
        return eventId ? [eventId] : []
    if(pathname.includes('create/magnitude')) {
        let ids = eventId ? [eventId] : []
        ids = originId ? [...ids, originId] : ids
        return ids
    }

    return []
}

export const shouldShowEventFields = function (pathname: string): boolean {
    return !(pathname.includes('create/origin') || pathname.includes('create/magnitude'));

}

export const shouldShowOriginFields = function (pathname: string): boolean {
    return !pathname.includes('create/magnitude');
}