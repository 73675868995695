import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { DATETIME_TAG, SORT_DESC } from '../../components/CustomTable/TableUtils'
import { RECTANGULAR_GEOFENCE } from '../../components/GeofenceSelector/GeofenceSelector'
import { DEFAULT_MIN_MAGNITUDE, DEFAULT_ORDER_BY, DEFAULT_LIMIT } from '../../utils'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export type RouteType = 'events-group' | 'events' | 'origins' | 'magnitudes' | 'all' | 'events-catalog'

export interface Filters {
    starttime?: string;
    endtime?: string;
    minlat?: string;
    maxlat?: string;
    minlon?: string;
    maxlon?: string;
    lat?: string;
    lon?: string;
    // radius?: string;
    minradiuskm?: string;
    maxradiuskm?: string;
    minmag?: string;
    maxmag?: string;
    mindepth?: string;
    maxdepth?: string;
    orderby?: 'event_id-asc' | 'event_id-desc' | 'origin_ot-asc' | 'origin_ot-desc' | 'magnitude_mag-asc' | 'magnitude_mag-desc';
    limit?: number;
    shape: string
    ref_key: string;
    ref_region: string;
    products?: string;
    page: number;
    sortDirection: number;
    sortHeader: string;
    whereeventlocalspacenamein?: string;
    whereeventlocalspaceenvironmentin?: string;
    whereoriginlocalspacenamein?: string;
    whereoriginlocalspaceenvironmentin?: string;
    wheremagnitudelocalspacenamein?: string;
    wheremagnitudelocalspaceenvironmentin?: string;
    mintypeoriginvalue?: string;
    maxtypeoriginvalue?: string;
    wheretypeoriginvaluein?: string;
    route: RouteType;
    eventid?: string;
    originid?: string;
    magnitudeid?: string
    id_localspace?: string
    // other
    orpolygon?: string;
    notinpolygon?: string;
    wherepolygonnamein?: string;
    origindirectlinktoevent?: boolean;
    magnitudedirectlinktoorigin?: boolean;
    magnitudedirectlinktoevent?: boolean;
    wheretypemagnitudenameregexp?: string;
    eventupdatedafter?: string;
    originupdatedafter?: string;
    magnitudeupdatedafter?: string;
    updatedafteroperator?: string;
    // 3.4.0
    minnphtot?: string;
    mindistance?: string;
    whereoriginqualityin?: string;
    wheremagnitudemagqualityin?: string;
    // 3.9.0
    wheretypeeventnamein?: string;
    wheretypeeventnamenotin?: string;
}

export const defaultFilters: Filters = ({
    minmag: DEFAULT_MIN_MAGNITUDE,
    orderby: DEFAULT_ORDER_BY,
    limit: DEFAULT_LIMIT,
    starttime: dayjs.utc().subtract(7, 'day').format('YYYY-MM-DD[T00:00:00.000Z]'),
    endtime: undefined,
    shape: RECTANGULAR_GEOFENCE,
    ref_key: '',
    ref_region: '',
    page: 1,
    sortHeader: DATETIME_TAG,
    sortDirection: SORT_DESC,
    route: 'events-group'
})

export const filtersSlice = createSlice({
    name: 'filters',
    initialState: defaultFilters,
    reducers: {
        resetFilters: (state) => {
            return defaultFilters
        },
        setFilters: (state, actionFilters: PayloadAction<Filters>) => {
            return actionFilters.payload
        },
        updateRoute: (state, newRoute: PayloadAction<RouteType>) => {
            return {
                ...state,
                route: newRoute.payload
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { resetFilters, setFilters, updateRoute } = filtersSlice.actions

export default filtersSlice.reducer