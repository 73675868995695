import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'
import { ObjectEvent, ObjectFocalmechanism, ObjectOrigin } from '../../services/network'
import { OriginDetailMlStationMagnitude } from '../CustomTable/flavor/CustomStationMagnitudesTable'
import FocalMechanism from '../FocalMechanism/FocalMechanism'
import { LoaderTranslated } from '../Loader/LoaderTranslated'
import { ReactComponent as LeftArrowIconOn } from './../../assets/chevron-left-on.svg'
import { ReactComponent as LeftArrowIconOff } from './../../assets/chevron-left-off.svg'
import { ReactComponent as RightArrowIconOn } from './../../assets/chevron-right-on.svg'
import { ReactComponent as RightArrowIconOff } from './../../assets/chevron-right-off.svg'
import { ReactComponent as CloseIcon } from './../../assets/fm-close.svg'
import './MagnitudeModal.css'
import { useAppSelector } from '../../hooks/reduxCustomHooks'

export interface FocalMechanismModalProps {
    show: boolean;
    onClose: () => void;
    focalmechanism?: ObjectFocalmechanism;
    focalmechanisms: ObjectFocalmechanism[];
    next?: () => void;
    prev?: () => void;
}

const evaluateAmplitude = (amp1: number, amp2: number) => {
    // from caravel:
    // (abs($amp1 - $amp2) / (2 * pow(10, 3)));    
    return Math.abs(amp1 - amp2) / (2 * Math.pow(10, 3))
}

const evaluatePeriod = (period?: number, time1?: string, time2?: string) => {
    // from caravel:
    /* 
        if($period > 0){ return $period; }
        else { return (strtotime($time1) - strtotime($time2)) * 2; }
    */
    if (period && period > 0) return period
    if (!time1 || !time2)
        return 0
    return ((new Date(time1).getTime() - new Date(time2).getTime()) * 2)
}

interface RenderMagnitudeMlModalProps {
    body: Array<OriginDetailMlStationMagnitude> | undefined
}

function getMag(earthquake?: ObjectEvent, focalmechanism?: ObjectFocalmechanism): string {
    if (focalmechanism) {
        const { momenttensor } = focalmechanism;
        if (momenttensor) {
            let { magnitudeid_out } = momenttensor;
            if (magnitudeid_out) {
                let magnitudes = earthquake
                    ?.origins
                    ?.flatMap(o => o?.magnitudes ?? [])
                    .filter(m => m.id === magnitudeid_out)
                    ?? [];
                if (magnitudes?.length > 0) {
                    return `${magnitudes[0].type_magnitude} ${magnitudes[0].mag}`
                }
            }
        }
    }
    return '--';
}

function getDepth(earthquake?: ObjectEvent, focalmechanism?: ObjectFocalmechanism): string {
    if (focalmechanism && earthquake) {
        let { momenttensor } = focalmechanism;
        if (momenttensor) {
            let { originid_out } = momenttensor;
            if (originid_out) {
                let origins = earthquake?.origins
                    ?.flatMap(o => ({ id: o?.id, depth: o?.depth }))
                    .filter(m => m.id === originid_out)
                    ?? [];
                if (origins?.length > 0) {
                    return `${origins[0].depth} Km`;
                }
            }
        }
    }
    return '--';
}

function banner(earthquake?: ObjectEvent, focalmechanism?: ObjectFocalmechanism): boolean {
    // const [prefOrigin, setPrefOrigin] = useState<ObjectOrigin>()
    // const prefOriginFound = earthquake.origins?.find((origin) => origin.id === prefOriginId);

    // const [prefFocalMechanism, setPrefFocalMechanism] = useState<ObjectFocalmechanism>()
    // const focalmechanisms = earthquake.origins.flatMap((o) => o.focalmechanisms);
    // focalmechanisms.find((focalmechanism) => focalmechanism && focalmechanism.id === prefFocalMechanismId);
    if (!earthquake || !earthquake.origins || !focalmechanism) {
        return true;
    }

    const { preferred_focalmechanism_id, preferred_origin_id } = earthquake;
    if (!preferred_origin_id || !preferred_focalmechanism_id) {
        return true;
    }

    const { origins } = earthquake;
    const prefOrigin: ObjectOrigin | undefined = origins.find((o) => o.id === preferred_origin_id);
    if (!prefOrigin) {
        return true;
    }

    const prefFocalmechanisms: ObjectFocalmechanism[] = origins.flatMap((o) => o.focalmechanisms ? o.focalmechanisms : []);
    const prefFocalmechanism: ObjectFocalmechanism | undefined = prefFocalmechanisms.find((f) => f.id === preferred_focalmechanism_id);
    if (prefFocalmechanisms.length === 0 || !prefFocalmechanism) {
        return true;
    }

    // Ad esempio per l'evento eventid=29883271 si vede che il event.preferred_focalmechanism_id=18381 appartiene 
    // alla origin.id=100817861 che diversa da event.preferred_origin_id=100834921.
    const { focalmechanisms } = prefOrigin;
    let fm: ObjectFocalmechanism | undefined = focalmechanisms?.find((fm) => fm.id === prefFocalmechanism.id)
    return !fm;
}

const FocalMechanismModal = ({ show, onClose, focalmechanism, focalmechanisms, prev, next }: FocalMechanismModalProps) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [body, setBody] = useState<ObjectFocalmechanism | undefined>();
    const earthquake = useAppSelector((state) => state.earthquake);

    useEffect(() => setBody(focalmechanism), [focalmechanism]);
    useEffect(() => setLoading(false), [body]);
    const [index, setIndex] = useState<number>(0);

    function handlePrev(): void {
        if (prev) {
            prev();
        }
    }

    function handleNext(): void {
        if (next) {
            next();
        }
    }

    useEffect(() => {
        if (focalmechanism && focalmechanism.id) {
            if (focalmechanisms) {
                const index: number = focalmechanisms.findIndex((m) => m.id === focalmechanism.id);
                setIndex(index >= 0 ? index : 0);
            }
        }
    }, [focalmechanism, focalmechanisms])

    return (<>
        <ReactModal
            isOpen={show}
            ariaHideApp={false}
            onRequestClose={onClose}
            className={"focalmachanism-modal"}
            overlayClassName="magnitude-overlay"
        >
            <div className='modal-switch'>
                <div className='content-switcher-container'>
                    <div className='content-switcher'>
                        <div className='command' onClick={handlePrev}>
                            {index === 0 ? <LeftArrowIconOff /> : <LeftArrowIconOn />}
                        </div>
                        <div className='command' onClick={handleNext}>
                            {index === (focalmechanisms.length - 1) ? <RightArrowIconOff /> : <RightArrowIconOn />}
                        </div>
                        <span className='header'>
                            {t('focalmechanism_modal_cs', { id: `${focalmechanism?.id}` })}
                        </span>
                    </div>
                </div>
                <div className='close' onClick={onClose}>
                    <CloseIcon className='icon' />
                </div>
            </div>
            <div className='fm-modal-container' id='modal-container'>
                {(loading || !body) && <LoaderTranslated />}
                {(!loading && body) && <FocalMechanism
                    focalMechanism={body}
                    showBanner={false}
                    earthquakeId={`${earthquake.id}`}
                    mag={getMag(earthquake, focalmechanism)}
                    depth={getDepth(earthquake, focalmechanism)}
                />
                }
            </div>
        </ReactModal>
    </>);
}

export default FocalMechanismModal