import '../Common.css';
import {useState} from "react";
import {BeaTextInput} from "../../../BeaTextInput/BeaTextInput";
import {isValidMinMaxTypeOrigin, isValidTypeOrigin, isValidTypeOriginWhereIn, isValidWhereOriginQualityIn} from "../../../../utils";
import {useTranslation} from "react-i18next";
import {typeOriginErrorText} from "../../../DropdownFilters/messages";


export interface IQueryFiltersOriginProps {
    min?: string;
    max?: string;
    wherein?: string;
    quality?: string;
    onUpdate: (key: string, value: string) => void;
}

function QueryFiltersOrigin({quality, min, max, wherein, onUpdate}: IQueryFiltersOriginProps): JSX.Element {

    const {t} = useTranslation();

    function onUpdateQuality(value: string): void {
        onUpdate('whereoriginqualityin', value.replaceAll(' ', '').toLocaleUpperCase());
    }

    function onUpdateIn(value: string): void {
        onUpdate('wheretypeoriginvaluein', value.replaceAll(' ', ''));
    }

    function onUpdateMin(value: string): void {
        onUpdate('mintypeoriginvalue', value);
    }

    function onUpdateMax(value: string): void {
        onUpdate('maxtypeoriginvalue', value);
    }

    return(<div className={'filter-section-container'}>
        <div className={'filter-section-row'}>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    value={quality ?? ''}
                    placeholder={'es. AA,AB'}
                    label={'fs__title__quality'}
                    stateKey={'quality'}
                    invalid={!isValidWhereOriginQualityIn(quality ?? '', true)}
                    onChange={onUpdateQuality}
                    invalidText={t('invalid_format')}
                />
            </div>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    value={wherein ?? ''}
                    placeholder={'es. 3,5,100'}
                    label={'fs__title__version_in'}
                    stateKey={'wheretypeoriginvaluein'}
                    invalid={!isValidTypeOriginWhereIn(wherein ?? '', true)}
                    onChange={onUpdateIn}
                    invalidText={typeOriginErrorText(min ?? '', max ?? '', wherein ?? '', t)}
                />
            </div>
        </div>
        <div className={'filter-section-row'}>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    value={min ?? ''}
                    placeholder={'es. 0'}
                    label={'fs__title__version_min'}
                    stateKey={'min'}
                    invalid={!(isValidTypeOrigin(min ?? '', true) &&
                        isValidMinMaxTypeOrigin(min ?? '', max ?? '', true))
                    }
                    onChange={onUpdateMin}
                    invalidText={typeOriginErrorText(min ?? '', max ?? '', wherein, t)}
                />
            </div>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    value={max ?? ''}
                    placeholder={'es. 1000'}
                    label={'fs__title__version_max'}
                    stateKey={'maxtypeoriginvalue'}
                    invalid={!(isValidTypeOrigin(min ?? '', true) &&
                        isValidMinMaxTypeOrigin(min ?? '', max ?? '', true))
                    }
                    onChange={onUpdateMax}
                    invalidText={typeOriginErrorText(min ?? '', max ?? '', wherein, t)}
                />
            </div>
        </div>
    </div>);
}

export {
    QueryFiltersOrigin
}