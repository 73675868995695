import { useState } from 'react';
import { LocalspaceSelector } from '../../../LocalspaceSelector/LocalspaceSelector';
import './QueryFiltersLocalspace.css';

export interface IQueryFiltersLocalspaceProps {
    localspaceenv?: string;
    filtersEnv: string;
    localspacename?: string;
    filtersName: string;
    onUpdate: (key: string, value: string) => void;
}

function QueryFiltersLocalspace({ localspacename, localspaceenv, onUpdate, filtersEnv, filtersName}: IQueryFiltersLocalspaceProps): JSX.Element {

    function onUpdateName(value: string): void {
        onUpdate(filtersName, value);
    }

    function onUpdateEnv(value: string): void {
        onUpdate(filtersEnv, value);
    }

    return (<div className='localspace-row'>
        <div className='localspace-col'>
            <LocalspaceSelector
                fromEnv={true}
                selected={localspaceenv}
                onUpdate={onUpdateEnv}
                label={'filters__localspace__env'}
                prefix={filtersEnv}
            />
        </div>
        <div className='localspace-col'>
            <LocalspaceSelector
                fromEnv={false}
                selected={localspacename}
                onUpdate={onUpdateName}
                label={'filters__localspace__name'}
                prefix={filtersName}
            />
        </div>
    </div>);
}

export {
    QueryFiltersLocalspace
}