import { useTranslation } from "react-i18next";
import './BeaNavbar.css'
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { ReactComponent as BeaLogo } from '../../assets/bea-logo.svg'
import { useNavigate } from "react-router-dom";
import { LogoutButton } from "../LogoutButton/LogoutButton";
import { useKeycloak } from "@react-keycloak/web";
import { LoginButton } from "../LoginButton/LoginButton";
import { useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import { defaultFilters } from "../../redux/reducers/filters.slice";
import { SettingsButton } from "../SettingsButton/SettingsButton";
import { batch } from "react-redux";
import { closeCta } from "../../redux/reducers/cta.slice";
import { viewMode } from "../../redux/reducers/group.slice";
import { resetTable } from "../../redux/reducers/customtable.slice";
import { resetTimePicker } from "../../redux/reducers/timepicker.slice";
import { createUrlFromFilters } from "../../utils/urlFromFilters";
import { StatusButton } from "../StatusButton/StatusButton";

export function BeaNavbar() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const filters = useAppSelector((state) => state.filters)
    const { keycloak } = useKeycloak()
    const [modal, setModal] = useState(false)
    const dispatch = useAppDispatch()
    const navigation = useNavigate()

    return (
        <>
            <div className={!!keycloak.authenticated ? "navbar-admin" : "navbar"} >
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        batch(() => {
                            dispatch(resetTimePicker())
                            dispatch(closeCta())
                        })

                        navigate(`/earthquakelist?${createUrlFromFilters({
                            ...defaultFilters,
                            route: filters.route,
                        })}`)
                    }}
                >
                    <BeaLogo />
                </div>

                <h1>{t('navbar__title')}</h1>
                <StatusButton callback={() => {
                    dispatch(closeCta())
                    navigation('/info', { replace: false })
                }} />
                {!!keycloak.authenticated && <SettingsButton callback={() => {
                    dispatch(closeCta())
                    navigation('/settings', { replace: false })
                }} />}
                <LanguageSwitcher />
                {!!keycloak.authenticated &&
                    // auth &&
                    <LogoutButton callback={() => {
                        setModal(!modal)
                    }} />
                }
                {!keycloak.authenticated &&
                    // !auth &&
                    <LoginButton callback={() =>
                        keycloak.login()
                    } />
                }
            </div>
            <CustomModal
                show={modal}
                cancel={() => setModal(!modal)}
                title={t('logout_modal_title')}
                text={t('logout_modal_text')}
                confirm={() => {
                    /* inserito per forzare l'utente a ritornare sulla home */
                    /*navigate(`/earthquakelist?${createUrlFromFilters({
                        ...filters,
                        route: 'events-group'
                    })}`)*/
                    batch(() => {
                        dispatch(resetTable())
                        dispatch(viewMode())
                        dispatch(closeCta())
                    })
                    keycloak.logout();
                    setModal(false)
                }}
            />
        </>
    )
}