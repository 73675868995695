import './Filters.css'
import {BeaButton} from "../BeaButton/BeaButton";

export interface IFiltersFooterProps {
    ghostAction: () => void;
    secondaryAction: () => void;
    primaryAction: () => void;
}

function FiltersFooter({primaryAction, secondaryAction, ghostAction}: IFiltersFooterProps) {
    return (<div className={'filters-container-footer'}>
        <BeaButton
            title={'ghost_filters_text'}
            variant={'ghost'}
            onClick={ghostAction}
            disabled={false} />
        <div className={'filters-divider'}></div>
        <div className={'filters-buttons'}>
            <BeaButton
                title={'secondary_filters_text'}
                variant={'secondary'}
                onClick={secondaryAction}
                disabled={false} />
            <BeaButton
                title={'primary_filters_text'}
                variant={'primary'}
                onClick={primaryAction}
                disabled={false} />
        </div>
    </div>);
}

export {
    FiltersFooter
}