/* tslint:disable */
/* eslint-disable */
/**
 * Dante Web Services
 *       # Introduction   Dante is an API Web Service used for iteract with earthquake data stored in database (**quakedb**); the **quakedb** database schema is used at INGV.   Use other schema would require cration of specific `Model` and `Controller` but this is the potential of web services.      Dante provides a set of routes to store message **event**, **origin**, **magnitude**, **arrival**, **...**.      # Input   As input, Dante acept:   - A `json` message (view \'**store**\' spec below)    - An Eartworm `json` message (view \'**earthworm api**\' spec below) produced by **ew2openapi** module      # Output   As output, Dante has a RESTful api foreach database table and implement three specific routes:    - `events-group`: returns the preferred origin and the preferred magnitude from all clusterd events.    - `events`: returns the preferred origin and the preferred magnitude from the same instance.    - `event`: returns the full event (event, origins, magnitudes, arrivals, amplitude, etc...) from an **eventid** or **originid**_/_**originid**.        
 *
 * The version of the OpenAPI document: 3.20.0
 * Contact: valentino.lauciani@ingv.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AddCatalog201Response } from '../model';
// @ts-ignore
import { AddCatalogRequest } from '../model';
// @ts-ignore
import { AddEvent201Response } from '../model';
// @ts-ignore
import { AddFocalmechanism201Response } from '../model';
// @ts-ignore
import { AddFocalmechanismRequest } from '../model';
// @ts-ignore
import { AddLocalspaceRequest } from '../model';
// @ts-ignore
import { AddMagnitude201Response } from '../model';
// @ts-ignore
import { AddMagnitudeRequest } from '../model';
// @ts-ignore
import { AddMomenttensor201Response } from '../model';
// @ts-ignore
import { AddMomenttensorRequest } from '../model';
// @ts-ignore
import { AddOrigin201Response } from '../model';
// @ts-ignore
import { AddOriginFlag201Response } from '../model';
// @ts-ignore
import { AddOriginFlagRequest } from '../model';
// @ts-ignore
import { AddOriginRequest } from '../model';
// @ts-ignore
import { AddProvenanceRequest } from '../model';
// @ts-ignore
import { AddStrongmotion201Response } from '../model';
// @ts-ignore
import { AddStrongmotionRequest } from '../model';
// @ts-ignore
import { AddTypeEventRequest } from '../model';
// @ts-ignore
import { AddTypeMagnitudeRequest } from '../model';
// @ts-ignore
import { AddTypeOriginRequest } from '../model';
// @ts-ignore
import { GetEvent200Response } from '../model';
// @ts-ignore
import { ObjectTableLocalspace } from '../model';
// @ts-ignore
import { ObjectTableProvenance } from '../model';
// @ts-ignore
import { ObjectTableTypeEvent } from '../model';
// @ts-ignore
import { ObjectTableTypeMagnitude } from '../model';
// @ts-ignore
import { ObjectTableTypeOrigin } from '../model';
// @ts-ignore
import { Problem } from '../model';
/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API is used to add a catalog object.
         * @summary Add a new catalog to the DB
         * @param {AddCatalogRequest} addCatalogRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCatalog: async (addCatalogRequest: AddCatalogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addCatalogRequest' is not null or undefined
            assertParamExists('addCatalog', 'addCatalogRequest', addCatalogRequest)
            const localVarPath = `/quakedb/v1/events-catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCatalogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add an event object.
         * @summary Add a new event to the DB
         * @param {GetEvent200Response} getEvent200Response JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEvent: async (getEvent200Response: GetEvent200Response, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getEvent200Response' is not null or undefined
            assertParamExists('addEvent', 'getEvent200Response', getEvent200Response)
            const localVarPath = `/quakedb/v1/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getEvent200Response, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add a focalmechanism object.
         * @summary Add a new focalmechanism(s) to the DB
         * @param {AddFocalmechanismRequest} addFocalmechanismRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFocalmechanism: async (addFocalmechanismRequest: AddFocalmechanismRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addFocalmechanismRequest' is not null or undefined
            assertParamExists('addFocalmechanism', 'addFocalmechanismRequest', addFocalmechanismRequest)
            const localVarPath = `/quakedb/v1/focalmechanism`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFocalmechanismRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add an localspace object.
         * @summary Add a new localspace to the DB
         * @param {AddLocalspaceRequest} addLocalspaceRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLocalspace: async (addLocalspaceRequest: AddLocalspaceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addLocalspaceRequest' is not null or undefined
            assertParamExists('addLocalspace', 'addLocalspaceRequest', addLocalspaceRequest)
            const localVarPath = `/quakedb/table/v1/localspace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLocalspaceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add a magnitude object.
         * @summary Add a new magnitude(s) to the DB
         * @param {AddMagnitudeRequest} addMagnitudeRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMagnitude: async (addMagnitudeRequest: AddMagnitudeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addMagnitudeRequest' is not null or undefined
            assertParamExists('addMagnitude', 'addMagnitudeRequest', addMagnitudeRequest)
            const localVarPath = `/quakedb/v1/magnitude`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMagnitudeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add a momenttensor object.
         * @summary Add a new momenttensor(s) to the DB
         * @param {AddMomenttensorRequest} addMomenttensorRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMomenttensor: async (addMomenttensorRequest: AddMomenttensorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addMomenttensorRequest' is not null or undefined
            assertParamExists('addMomenttensor', 'addMomenttensorRequest', addMomenttensorRequest)
            const localVarPath = `/quakedb/v1/momenttensor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMomenttensorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add an origin object.
         * @summary Add a new origin(s) to the DB
         * @param {AddOriginRequest} addOriginRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrigin: async (addOriginRequest: AddOriginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addOriginRequest' is not null or undefined
            assertParamExists('addOrigin', 'addOriginRequest', addOriginRequest)
            const localVarPath = `/quakedb/v1/origin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOriginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add one or more flags to origin.
         * @summary Add one or more flags to origin
         * @param {AddOriginFlagRequest} addOriginFlagRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOriginFlag: async (addOriginFlagRequest: AddOriginFlagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addOriginFlagRequest' is not null or undefined
            assertParamExists('addOriginFlag', 'addOriginFlagRequest', addOriginFlagRequest)
            const localVarPath = `/quakedb/v1/origin-flag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOriginFlagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add an provenance object.
         * @summary Add a new provenance to the DB
         * @param {AddProvenanceRequest} addProvenanceRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProvenance: async (addProvenanceRequest: AddProvenanceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addProvenanceRequest' is not null or undefined
            assertParamExists('addProvenance', 'addProvenanceRequest', addProvenanceRequest)
            const localVarPath = `/quakedb/table/v1/provenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addProvenanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add a strongmotion object.
         * @summary Add a new strongmotion(s) to the DB
         * @param {AddStrongmotionRequest} addStrongmotionRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStrongmotion: async (addStrongmotionRequest: AddStrongmotionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addStrongmotionRequest' is not null or undefined
            assertParamExists('addStrongmotion', 'addStrongmotionRequest', addStrongmotionRequest)
            const localVarPath = `/quakedb/v1/strongmotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addStrongmotionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add an type_event object.
         * @summary Add a new type_event to the DB
         * @param {AddTypeEventRequest} addTypeEventRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTypeEvent: async (addTypeEventRequest: AddTypeEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addTypeEventRequest' is not null or undefined
            assertParamExists('addTypeEvent', 'addTypeEventRequest', addTypeEventRequest)
            const localVarPath = `/quakedb/table/v1/type-event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTypeEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add an type_magnitude object.
         * @summary Add a new type_magnitude to the DB
         * @param {AddTypeMagnitudeRequest} addTypeMagnitudeRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTypeMagnitude: async (addTypeMagnitudeRequest: AddTypeMagnitudeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addTypeMagnitudeRequest' is not null or undefined
            assertParamExists('addTypeMagnitude', 'addTypeMagnitudeRequest', addTypeMagnitudeRequest)
            const localVarPath = `/quakedb/table/v1/type-magnitude`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTypeMagnitudeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to add an type_origin object.
         * @summary Add a new type_origin to the DB
         * @param {AddTypeOriginRequest} addTypeOriginRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTypeOrigin: async (addTypeOriginRequest: AddTypeOriginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addTypeOriginRequest' is not null or undefined
            assertParamExists('addTypeOrigin', 'addTypeOriginRequest', addTypeOriginRequest)
            const localVarPath = `/quakedb/table/v1/type-origin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTypeOriginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * This API is used to add a catalog object.
         * @summary Add a new catalog to the DB
         * @param {AddCatalogRequest} addCatalogRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCatalog(addCatalogRequest: AddCatalogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCatalog201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCatalog(addCatalogRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addCatalog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add an event object.
         * @summary Add a new event to the DB
         * @param {GetEvent200Response} getEvent200Response JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEvent(getEvent200Response: GetEvent200Response, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddEvent201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEvent(getEvent200Response, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add a focalmechanism object.
         * @summary Add a new focalmechanism(s) to the DB
         * @param {AddFocalmechanismRequest} addFocalmechanismRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFocalmechanism(addFocalmechanismRequest: AddFocalmechanismRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddFocalmechanism201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFocalmechanism(addFocalmechanismRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addFocalmechanism']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add an localspace object.
         * @summary Add a new localspace to the DB
         * @param {AddLocalspaceRequest} addLocalspaceRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLocalspace(addLocalspaceRequest: AddLocalspaceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableLocalspace>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLocalspace(addLocalspaceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addLocalspace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add a magnitude object.
         * @summary Add a new magnitude(s) to the DB
         * @param {AddMagnitudeRequest} addMagnitudeRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMagnitude(addMagnitudeRequest: AddMagnitudeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMagnitude201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMagnitude(addMagnitudeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addMagnitude']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add a momenttensor object.
         * @summary Add a new momenttensor(s) to the DB
         * @param {AddMomenttensorRequest} addMomenttensorRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMomenttensor(addMomenttensorRequest: AddMomenttensorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddMomenttensor201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMomenttensor(addMomenttensorRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addMomenttensor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add an origin object.
         * @summary Add a new origin(s) to the DB
         * @param {AddOriginRequest} addOriginRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrigin(addOriginRequest: AddOriginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrigin201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrigin(addOriginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addOrigin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add one or more flags to origin.
         * @summary Add one or more flags to origin
         * @param {AddOriginFlagRequest} addOriginFlagRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOriginFlag(addOriginFlagRequest: AddOriginFlagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOriginFlag201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOriginFlag(addOriginFlagRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addOriginFlag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add an provenance object.
         * @summary Add a new provenance to the DB
         * @param {AddProvenanceRequest} addProvenanceRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProvenance(addProvenanceRequest: AddProvenanceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableProvenance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProvenance(addProvenanceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addProvenance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add a strongmotion object.
         * @summary Add a new strongmotion(s) to the DB
         * @param {AddStrongmotionRequest} addStrongmotionRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStrongmotion(addStrongmotionRequest: AddStrongmotionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddStrongmotion201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStrongmotion(addStrongmotionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addStrongmotion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add an type_event object.
         * @summary Add a new type_event to the DB
         * @param {AddTypeEventRequest} addTypeEventRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTypeEvent(addTypeEventRequest: AddTypeEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableTypeEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTypeEvent(addTypeEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addTypeEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add an type_magnitude object.
         * @summary Add a new type_magnitude to the DB
         * @param {AddTypeMagnitudeRequest} addTypeMagnitudeRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTypeMagnitude(addTypeMagnitudeRequest: AddTypeMagnitudeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableTypeMagnitude>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTypeMagnitude(addTypeMagnitudeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addTypeMagnitude']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This API is used to add an type_origin object.
         * @summary Add a new type_origin to the DB
         * @param {AddTypeOriginRequest} addTypeOriginRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTypeOrigin(addTypeOriginRequest: AddTypeOriginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableTypeOrigin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTypeOrigin(addTypeOriginRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StoreApi.addTypeOrigin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * This API is used to add a catalog object.
         * @summary Add a new catalog to the DB
         * @param {AddCatalogRequest} addCatalogRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCatalog(addCatalogRequest: AddCatalogRequest, options?: any): AxiosPromise<AddCatalog201Response> {
            return localVarFp.addCatalog(addCatalogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add an event object.
         * @summary Add a new event to the DB
         * @param {GetEvent200Response} getEvent200Response JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEvent(getEvent200Response: GetEvent200Response, options?: any): AxiosPromise<AddEvent201Response> {
            return localVarFp.addEvent(getEvent200Response, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add a focalmechanism object.
         * @summary Add a new focalmechanism(s) to the DB
         * @param {AddFocalmechanismRequest} addFocalmechanismRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFocalmechanism(addFocalmechanismRequest: AddFocalmechanismRequest, options?: any): AxiosPromise<AddFocalmechanism201Response> {
            return localVarFp.addFocalmechanism(addFocalmechanismRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add an localspace object.
         * @summary Add a new localspace to the DB
         * @param {AddLocalspaceRequest} addLocalspaceRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLocalspace(addLocalspaceRequest: AddLocalspaceRequest, options?: any): AxiosPromise<ObjectTableLocalspace> {
            return localVarFp.addLocalspace(addLocalspaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add a magnitude object.
         * @summary Add a new magnitude(s) to the DB
         * @param {AddMagnitudeRequest} addMagnitudeRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMagnitude(addMagnitudeRequest: AddMagnitudeRequest, options?: any): AxiosPromise<AddMagnitude201Response> {
            return localVarFp.addMagnitude(addMagnitudeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add a momenttensor object.
         * @summary Add a new momenttensor(s) to the DB
         * @param {AddMomenttensorRequest} addMomenttensorRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMomenttensor(addMomenttensorRequest: AddMomenttensorRequest, options?: any): AxiosPromise<AddMomenttensor201Response> {
            return localVarFp.addMomenttensor(addMomenttensorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add an origin object.
         * @summary Add a new origin(s) to the DB
         * @param {AddOriginRequest} addOriginRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrigin(addOriginRequest: AddOriginRequest, options?: any): AxiosPromise<AddOrigin201Response> {
            return localVarFp.addOrigin(addOriginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add one or more flags to origin.
         * @summary Add one or more flags to origin
         * @param {AddOriginFlagRequest} addOriginFlagRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOriginFlag(addOriginFlagRequest: AddOriginFlagRequest, options?: any): AxiosPromise<AddOriginFlag201Response> {
            return localVarFp.addOriginFlag(addOriginFlagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add an provenance object.
         * @summary Add a new provenance to the DB
         * @param {AddProvenanceRequest} addProvenanceRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProvenance(addProvenanceRequest: AddProvenanceRequest, options?: any): AxiosPromise<ObjectTableProvenance> {
            return localVarFp.addProvenance(addProvenanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add a strongmotion object.
         * @summary Add a new strongmotion(s) to the DB
         * @param {AddStrongmotionRequest} addStrongmotionRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStrongmotion(addStrongmotionRequest: AddStrongmotionRequest, options?: any): AxiosPromise<AddStrongmotion201Response> {
            return localVarFp.addStrongmotion(addStrongmotionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add an type_event object.
         * @summary Add a new type_event to the DB
         * @param {AddTypeEventRequest} addTypeEventRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTypeEvent(addTypeEventRequest: AddTypeEventRequest, options?: any): AxiosPromise<ObjectTableTypeEvent> {
            return localVarFp.addTypeEvent(addTypeEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add an type_magnitude object.
         * @summary Add a new type_magnitude to the DB
         * @param {AddTypeMagnitudeRequest} addTypeMagnitudeRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTypeMagnitude(addTypeMagnitudeRequest: AddTypeMagnitudeRequest, options?: any): AxiosPromise<ObjectTableTypeMagnitude> {
            return localVarFp.addTypeMagnitude(addTypeMagnitudeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to add an type_origin object.
         * @summary Add a new type_origin to the DB
         * @param {AddTypeOriginRequest} addTypeOriginRequest JSON to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTypeOrigin(addTypeOriginRequest: AddTypeOriginRequest, options?: any): AxiosPromise<ObjectTableTypeOrigin> {
            return localVarFp.addTypeOrigin(addTypeOriginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - interface
 * @export
 * @interface StoreApi
 */
export interface StoreApiInterface {
    /**
     * This API is used to add a catalog object.
     * @summary Add a new catalog to the DB
     * @param {AddCatalogRequest} addCatalogRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addCatalog(addCatalogRequest: AddCatalogRequest, options?: AxiosRequestConfig): AxiosPromise<AddCatalog201Response>;

    /**
     * This API is used to add an event object.
     * @summary Add a new event to the DB
     * @param {GetEvent200Response} getEvent200Response JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addEvent(getEvent200Response: GetEvent200Response, options?: AxiosRequestConfig): AxiosPromise<AddEvent201Response>;

    /**
     * This API is used to add a focalmechanism object.
     * @summary Add a new focalmechanism(s) to the DB
     * @param {AddFocalmechanismRequest} addFocalmechanismRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addFocalmechanism(addFocalmechanismRequest: AddFocalmechanismRequest, options?: AxiosRequestConfig): AxiosPromise<AddFocalmechanism201Response>;

    /**
     * This API is used to add an localspace object.
     * @summary Add a new localspace to the DB
     * @param {AddLocalspaceRequest} addLocalspaceRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addLocalspace(addLocalspaceRequest: AddLocalspaceRequest, options?: AxiosRequestConfig): AxiosPromise<ObjectTableLocalspace>;

    /**
     * This API is used to add a magnitude object.
     * @summary Add a new magnitude(s) to the DB
     * @param {AddMagnitudeRequest} addMagnitudeRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addMagnitude(addMagnitudeRequest: AddMagnitudeRequest, options?: AxiosRequestConfig): AxiosPromise<AddMagnitude201Response>;

    /**
     * This API is used to add a momenttensor object.
     * @summary Add a new momenttensor(s) to the DB
     * @param {AddMomenttensorRequest} addMomenttensorRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addMomenttensor(addMomenttensorRequest: AddMomenttensorRequest, options?: AxiosRequestConfig): AxiosPromise<AddMomenttensor201Response>;

    /**
     * This API is used to add an origin object.
     * @summary Add a new origin(s) to the DB
     * @param {AddOriginRequest} addOriginRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addOrigin(addOriginRequest: AddOriginRequest, options?: AxiosRequestConfig): AxiosPromise<AddOrigin201Response>;

    /**
     * This API is used to add one or more flags to origin.
     * @summary Add one or more flags to origin
     * @param {AddOriginFlagRequest} addOriginFlagRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addOriginFlag(addOriginFlagRequest: AddOriginFlagRequest, options?: AxiosRequestConfig): AxiosPromise<AddOriginFlag201Response>;

    /**
     * This API is used to add an provenance object.
     * @summary Add a new provenance to the DB
     * @param {AddProvenanceRequest} addProvenanceRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addProvenance(addProvenanceRequest: AddProvenanceRequest, options?: AxiosRequestConfig): AxiosPromise<ObjectTableProvenance>;

    /**
     * This API is used to add a strongmotion object.
     * @summary Add a new strongmotion(s) to the DB
     * @param {AddStrongmotionRequest} addStrongmotionRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addStrongmotion(addStrongmotionRequest: AddStrongmotionRequest, options?: AxiosRequestConfig): AxiosPromise<AddStrongmotion201Response>;

    /**
     * This API is used to add an type_event object.
     * @summary Add a new type_event to the DB
     * @param {AddTypeEventRequest} addTypeEventRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addTypeEvent(addTypeEventRequest: AddTypeEventRequest, options?: AxiosRequestConfig): AxiosPromise<ObjectTableTypeEvent>;

    /**
     * This API is used to add an type_magnitude object.
     * @summary Add a new type_magnitude to the DB
     * @param {AddTypeMagnitudeRequest} addTypeMagnitudeRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addTypeMagnitude(addTypeMagnitudeRequest: AddTypeMagnitudeRequest, options?: AxiosRequestConfig): AxiosPromise<ObjectTableTypeMagnitude>;

    /**
     * This API is used to add an type_origin object.
     * @summary Add a new type_origin to the DB
     * @param {AddTypeOriginRequest} addTypeOriginRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApiInterface
     */
    addTypeOrigin(addTypeOriginRequest: AddTypeOriginRequest, options?: AxiosRequestConfig): AxiosPromise<ObjectTableTypeOrigin>;

}

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI implements StoreApiInterface {
    /**
     * This API is used to add a catalog object.
     * @summary Add a new catalog to the DB
     * @param {AddCatalogRequest} addCatalogRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addCatalog(addCatalogRequest: AddCatalogRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addCatalog(addCatalogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add an event object.
     * @summary Add a new event to the DB
     * @param {GetEvent200Response} getEvent200Response JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addEvent(getEvent200Response: GetEvent200Response, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addEvent(getEvent200Response, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add a focalmechanism object.
     * @summary Add a new focalmechanism(s) to the DB
     * @param {AddFocalmechanismRequest} addFocalmechanismRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addFocalmechanism(addFocalmechanismRequest: AddFocalmechanismRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addFocalmechanism(addFocalmechanismRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add an localspace object.
     * @summary Add a new localspace to the DB
     * @param {AddLocalspaceRequest} addLocalspaceRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addLocalspace(addLocalspaceRequest: AddLocalspaceRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addLocalspace(addLocalspaceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add a magnitude object.
     * @summary Add a new magnitude(s) to the DB
     * @param {AddMagnitudeRequest} addMagnitudeRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addMagnitude(addMagnitudeRequest: AddMagnitudeRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addMagnitude(addMagnitudeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add a momenttensor object.
     * @summary Add a new momenttensor(s) to the DB
     * @param {AddMomenttensorRequest} addMomenttensorRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addMomenttensor(addMomenttensorRequest: AddMomenttensorRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addMomenttensor(addMomenttensorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add an origin object.
     * @summary Add a new origin(s) to the DB
     * @param {AddOriginRequest} addOriginRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addOrigin(addOriginRequest: AddOriginRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addOrigin(addOriginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add one or more flags to origin.
     * @summary Add one or more flags to origin
     * @param {AddOriginFlagRequest} addOriginFlagRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addOriginFlag(addOriginFlagRequest: AddOriginFlagRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addOriginFlag(addOriginFlagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add an provenance object.
     * @summary Add a new provenance to the DB
     * @param {AddProvenanceRequest} addProvenanceRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addProvenance(addProvenanceRequest: AddProvenanceRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addProvenance(addProvenanceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add a strongmotion object.
     * @summary Add a new strongmotion(s) to the DB
     * @param {AddStrongmotionRequest} addStrongmotionRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addStrongmotion(addStrongmotionRequest: AddStrongmotionRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addStrongmotion(addStrongmotionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add an type_event object.
     * @summary Add a new type_event to the DB
     * @param {AddTypeEventRequest} addTypeEventRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addTypeEvent(addTypeEventRequest: AddTypeEventRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addTypeEvent(addTypeEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add an type_magnitude object.
     * @summary Add a new type_magnitude to the DB
     * @param {AddTypeMagnitudeRequest} addTypeMagnitudeRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addTypeMagnitude(addTypeMagnitudeRequest: AddTypeMagnitudeRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addTypeMagnitude(addTypeMagnitudeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to add an type_origin object.
     * @summary Add a new type_origin to the DB
     * @param {AddTypeOriginRequest} addTypeOriginRequest JSON to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public addTypeOrigin(addTypeOriginRequest: AddTypeOriginRequest, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).addTypeOrigin(addTypeOriginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

