import { Dropdown } from '@carbon/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidLatitude, isValidLongitude, isValidMinMaxLatitude, isValidMinMaxLongitude, isValidMinMaxRadius, isValidRadius, validNumeric } from '../../utils';
import './GeofenceSelector.css';
import { BeaTextInput } from '../BeaTextInput/BeaTextInput';
import { latitudeErrorText, longitudeErrorText, maxRadiusErrorText, minRadiusErrorText } from '../DropdownFilters/messages';
import { shouldHaveRadius } from '../DropdownFilters/utils';
import { MunicipalityAutocomplete } from './MunicipalityAutocomplete';

interface GeofenceSelectorProps {
    onUpdate: (reference: CoordinateRef, shape: string) => void
    shape: string
    selectedGeofence: CoordinateRef
}

export interface CoordinateRef {
    key: string,
    region: string,
    min_lat?: string,
    max_lat?: string,
    min_lon?: string,
    max_lon?: string,
    lat?: string,
    lon?: string,
    minradius?: string,
    maxradius?: string,
}

export const RECTANGULAR_GEOFENCE = 'rectangular'
export const CIRCLE_GEOFENCE = 'circle'
export const CROWN_GEOFENCE = 'crown'

export const towns_list: Array<CoordinateRef> = [
    { key: '', region: 'select_region', },
    { key: 'world', region: 'world', min_lat: '-90', max_lat: '90', min_lon: '-180', max_lon: '180', lat: '0', lon: '0' },
    { key: 'mediterranean_region', region: 'mediterranean_region', min_lat: '27', max_lat: '48', min_lon: '-7', max_lon: '37.5', lat: '37.5', lon: '15.25' },
    { key: 'italy', region: 'italy', min_lat: '35', max_lat: '49', min_lon: '5', max_lon: '20', lat: '42', lon: '12.5' },
]

interface DropdownItem {
    id: string;
    text: string;
}

interface SelectedItem {
    selectedItem: DropdownItem;
}

const GeofenceSelector = ({ onUpdate, shape, selectedGeofence }: GeofenceSelectorProps) => {

    const { t } = useTranslation()
    const [geofenceShape, setGeofenceShape] = useState(shape)
    const [geofenceRef, setGeofenceRef] = useState<CoordinateRef>(selectedGeofence ?? {
        key: '',
        region: t('select_region'),
    })

    const geographic_area_type = [
        { id: RECTANGULAR_GEOFENCE, text: t('geographic_rectangular') },
        { id: CROWN_GEOFENCE, text: t('geographic_crown') }
    ];

    const geographic_default = [
        { id: 'world', text: t('world') },
        { id: 'mediterranean_region', text: t('mediterranean_region') },
        { id: 'italy', text: t('italy') }
    ];

    useEffect(() => onUpdate(geofenceRef, geofenceShape), [geofenceRef, geofenceShape])

    const update = (type: string, ref: CoordinateRef) => {
        let { key, region, min_lat, max_lat, min_lon, max_lon, lat, lon, } = ref
        if (type === RECTANGULAR_GEOFENCE) {
            setGeofenceRef({ key, region, min_lat, max_lat, min_lon, max_lon })
        } else if (type === CIRCLE_GEOFENCE) {
            setGeofenceRef({ key, region, lat, lon, minradius: '0' })
        } else {
            setGeofenceRef({ key, region, lat, lon, })
        }
    }

    const selectGeofenceShape = (option: SelectedItem) => {
        const shape: string = option.selectedItem.id;
        console.log('selectGeofenceShape =>', shape);
        setGeofenceShape(shape)
        if (shape === RECTANGULAR_GEOFENCE) {
            const ref = towns_list.find(c => t(c.region) === t(geofenceRef.region))
            if (ref) {
                update(shape, ref);
            }
        } else {
            setGeofenceRef({ key: '', region: '', })
        }
    }

    const selectGeofenceRef = (option: SelectedItem) => {
        const key: string = option.selectedItem.id;
        console.log('selectGeofenceRef =>', key);
        let ref = towns_list.find(t => t.key === key);
        if (!ref) {
            console.log('selectGeofenceRef => no result for key:', key)
            return;
        }

        update(geofenceShape, ref)
    }

    const updateFilters = (key: string, value: string, resetRegion: boolean = false) => {
        // it resets the selected region on user input
        let minradius = undefined
        if (shape !== RECTANGULAR_GEOFENCE) {
            if (shape === CIRCLE_GEOFENCE) {
                minradius = '0';
            } else {
                minradius = geofenceRef.minradius;
            }
        }

        setGeofenceRef({
            ...geofenceRef,
            minradius,
            [key]: value,
            key: resetRegion ? '' : geofenceRef.key,
            region: resetRegion ? '' : t(geofenceRef.region),
        })
    }

    const setMinLatitude = (minlat: string) => updateFilters('min_lat', minlat, true);
    const setMaxLatitude = (maxlat: string) => updateFilters('max_lat', maxlat, true);
    const setMinLongitude = (minlon: string) => updateFilters('min_lon', minlon, true);
    const setMaxLongitude = (maxlon: string) => updateFilters('max_lon', maxlon, true);
    const setLatitude = (lat: string) => updateFilters('lat', lat, true);
    const setLongitude = (lon: string) => updateFilters('lon', lon, true);
    const setMinRadius = (minradius: string) => updateFilters('minradius', minradius);
    const setMaxRadius = (maxradius: string) => updateFilters('maxradius', maxradius);

    const buildRectangularGeofence = () => {
        return <>
            <div className='geofence-inputs'>
                <div className='geofence-input-text'>
                    <BeaTextInput
                        value={selectedGeofence.min_lat ?? ''}
                        placeholder={'e.g. -90.00'}
                        label={'latmin'}
                        stateKey={'min_lat'}
                        invalid={!(isValidLatitude(geofenceRef.min_lat ?? '', true) && isValidMinMaxLatitude(geofenceRef.min_lat ?? '', geofenceRef.max_lat ?? '', true))}
                        onChange={setMinLatitude}
                        invalidText={latitudeErrorText(geofenceRef.min_lat ?? '', geofenceRef.max_lat ?? '', geofenceRef.minradius, geofenceRef.maxradius, t)}
                    />
                    <BeaTextInput
                        invalid={!(isValidLatitude(geofenceRef.max_lat ?? '', true) && isValidMinMaxLatitude(geofenceRef.min_lat ?? '', geofenceRef.max_lat ?? '', true))}
                        value={selectedGeofence.max_lat ?? ''}
                        invalidText={latitudeErrorText(geofenceRef.min_lat ?? '', geofenceRef.max_lat ?? '', geofenceRef.minradius, geofenceRef.maxradius, t)}
                        placeholder={'e.g. +90.00'}
                        label={'latmax'}
                        stateKey={'max_lat'}
                        onChange={setMaxLatitude}
                    />
                </div>

                <div className='geofence-input-text'>
                    <BeaTextInput
                        placeholder={'e.g. -180.00'}
                        onChange={setMinLongitude}
                        value={selectedGeofence.min_lon ?? ''}
                        invalid={!(isValidLongitude(geofenceRef.min_lon ?? '') && isValidMinMaxLongitude(geofenceRef.min_lon ?? '', geofenceRef.max_lon ?? ''))}
                        invalidText={longitudeErrorText(geofenceRef.min_lon ?? '', geofenceRef.max_lon ?? '', geofenceRef.minradius, geofenceRef.maxradius, t)}
                        label={'lonmin'}
                        stateKey={'min_lon'}
                    />

                    <BeaTextInput
                        placeholder={'e.g. 180.00'}
                        onChange={setMaxLongitude}
                        value={selectedGeofence.max_lon ?? ''}
                        invalid={!(isValidLongitude(geofenceRef.max_lon ?? '') && isValidMinMaxLongitude(geofenceRef.min_lon ?? '', geofenceRef.max_lon ?? ''))}
                        invalidText={longitudeErrorText(geofenceRef.min_lon ?? '', geofenceRef.max_lon ?? '', geofenceRef.minradius, geofenceRef.maxradius, t)}
                        label={'lonmax'}
                        stateKey={'max_lon'}
                    />
                </div>
            </div>
        </>
    }

    const buildCrownGeofence = () => {
        return <>
            <div className='geofence-inputs'>
                <div className='geofence-input-text'>
                    <BeaTextInput
                        placeholder={'e.g. 90.00'}
                        value={selectedGeofence.lat ?? ''}
                        onChange={setLatitude}
                        invalid={!isValidLatitude(geofenceRef.lat ?? '', !(validNumeric(geofenceRef.maxradius ?? '') || validNumeric(geofenceRef.minradius ?? '')))}
                        invalidText={latitudeErrorText(geofenceRef.lat ?? '', undefined, geofenceRef.minradius, geofenceRef.maxradius, t)}
                        label={'latitude'}
                        stateKey={'lat'}
                    />
                    <BeaTextInput
                        placeholder={'e.g. 180.00'}
                        value={selectedGeofence.lon ?? ''}
                        onChange={setLongitude}
                        invalid={!isValidLongitude(geofenceRef.lon ?? '', !(validNumeric(geofenceRef.maxradius ?? '') || validNumeric(geofenceRef.minradius ?? '')))}
                        invalidText={longitudeErrorText(geofenceRef.lon ?? '', undefined, geofenceRef.minradius, geofenceRef.maxradius, t)}
                        label={'longitude'}
                        stateKey={'lon'}
                    />
                </div>
                <div className='geofence-input-text'>
                    <BeaTextInput
                        placeholder={'e.g. 0.00'}
                        onChange={setMinRadius}
                        value={selectedGeofence.minradius ?? ''}
                        invalid={!(isValidRadius(selectedGeofence.minradius ?? '', !shouldHaveRadius(shape, selectedGeofence.lat, selectedGeofence.lon)) && isValidMinMaxRadius(selectedGeofence.minradius ?? '', selectedGeofence.maxradius ?? '', !shouldHaveRadius(shape, selectedGeofence.lat, selectedGeofence.lon)))}
                        invalidText={minRadiusErrorText(selectedGeofence.minradius ?? '', selectedGeofence.maxradius ?? '', t)}
                        label={'min_radius'}
                        stateKey={'minradius'}
                    />
                    <BeaTextInput
                        placeholder={'e.g. 800.00'}
                        onChange={setMaxRadius}
                        value={selectedGeofence.maxradius ?? ''}
                        invalid={!(isValidRadius(selectedGeofence.maxradius ?? '', !shouldHaveRadius(shape, selectedGeofence.lat, selectedGeofence.lon)))}
                        invalidText={maxRadiusErrorText(selectedGeofence.minradius ?? '', selectedGeofence.maxradius ?? '', t)}
                        label={'max_radius'}
                        stateKey={'maxradius'}
                    />
                </div>
            </div>
        </>
    }

    const buildGeofenceInput = () => {
        if (shape === RECTANGULAR_GEOFENCE) {
            return buildRectangularGeofence();
        }
        if (shape === CROWN_GEOFENCE) {
            return buildCrownGeofence();
        }
    }

    const municipalitySelected = (lat?: number, lon?: number, name?: string) => {
        console.log('GeofenceSelector => MunicipalityAutocomplete => municipalitySelected =>', lat, lon, name);
        setGeofenceRef({
            ...geofenceRef,
            lat: lat?.toString(),
            lon: lon?.toString(),
            key: '',
            region: name ?? '',
        })
    }

    return <>
        <p className='subtitle mb-16'>{t('where')}</p>
        <p className='label mb-8'>{t('where_type_label')}</p>
        <Dropdown
            className={'mb-16'}
            id={'geofence'}
            label={''}
            hideLabel
            titleText={''}
            onChange={selectGeofenceShape}
            items={geographic_area_type}
            itemToString={(item: DropdownItem) => item.text}
            selectedItem={
                shape === ''
                    ? geographic_area_type[0]
                    : geographic_area_type[geographic_area_type.findIndex((g) => g.id === shape)]
            }
        />

        <p className='label mb-8'>{t('coords')}</p>
        {
            shape === RECTANGULAR_GEOFENCE
                ? <Dropdown
                    className={'mb-16'}
                    label={''}
                    hideLabel
                    id='geofence-reference'
                    items={geographic_default}
                    selectedItem={selectedGeofence.key === ''
                        ? geographic_default[0]
                        : geographic_default[geographic_default.findIndex((g) => g.id === selectedGeofence.key)]
                    }
                    onChange={selectGeofenceRef}
                    itemToString={(item: DropdownItem) => item.text}
                />
                : <MunicipalityAutocomplete t={t} onSelection={municipalitySelected} selected={t(selectedGeofence.region)} />
        }

        {
            buildGeofenceInput()
        }
    </>
}

export default GeofenceSelector