import { Accordion, AccordionItem, InlineNotification } from '@carbon/react'
import { useKeycloak } from '@react-keycloak/web'
import { AxiosError, AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom"
import { ReactComponent as IconExpandLess } from '../../assets/expand_less.svg'
import { ReactComponent as CloseIcon } from '../../assets/fm-close.svg'
import { ReactComponent as IconPreferredStar } from '../../assets/preferred-star-big.svg'
import { ReactComponent as IconProvenance } from '../../assets/provenance-white.svg'
import { ReactComponent as IconStar } from '../../assets/star-white.svg'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks'
import { removeCustomTableGroup } from '../../redux/reducers/customtable.slice'
import { fetchEarthquakeEvent } from '../../redux/reducers/earthquake.slice'
import { ApiClient } from '../../services/ApiClient'
import { ObjectFocalmechanism, ObjectProvenance, UpdateEventRequest } from '../../services/network'
import { BeaInlineNotification, defaultNotificationState, onErrorCallbackHandler, onSuccessCallbackHandler } from '../../utils/InlineNotifications'
import BeaDropdown from '../BeaDropdown/BeaDropdown'
import EmptyList from '../EmptyList/EmptyList'
import { DropdownProvenance } from '../EventDetailTable/components/DropdownProvenance'
import TdmtStationsTable from '../TdmtStationsTable/TdmtStationsTable'
import './FocalMechanism.css'
import { evaluateMode, evaluateStatus, getClvd, getDoubleCouple, getIso, getScalarMoment, getVarianceReduction } from './utils'
import { closeStoreModal, showStoreModal } from '../../redux/reducers/confirmationModal.slice'
import { Toast } from '../Toast/Toast'

interface FocalMechanismProps {
    earthquakeId: string,
    showBanner: boolean,
    focalMechanism?: ObjectFocalmechanism
    inModal?: boolean
    mag?: number | string,
    depth?: number | string,
}

const FocalMechanism = ({ earthquakeId, showBanner, focalMechanism, mag, depth, inModal }: FocalMechanismProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const earthquake = useAppSelector((state) => state.earthquake);
    const [psmeca, setPsmeca] = useState('');
    const [ballUrl, setBallUrl] = useState('#');
    const { keycloak } = useKeycloak();
    const [isCatalog, setCatalog] = useState<boolean>(false);
    const [networkRequest, setNetworkRequest] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState<BeaInlineNotification>(defaultNotificationState);

    useEffect(() => {
        if (earthquake) {
            const { localspace } = earthquake;
            if (localspace) {
                setCatalog(localspace.environment === 'catalog');
            }
        }
    }, [earthquake]);

    const getBall = () => {
        if (focalMechanism) {
            let url: string = '#'
            if (focalMechanism.momenttensor) {
                let { mtt, mpp, mrr, mtp, mrt, mrp } = focalMechanism.momenttensor
                const mxx = mtt ? mtt / Math.pow(10, 13) : mtt
                const myy = mpp ? mpp / Math.pow(10, 13) : mpp
                const mzz = mrr ? mrr / Math.pow(10, 13) : mrr
                const mxy = mtp ? (-1 * mtp) / Math.pow(10, 13) : mtp
                const mxz = mrt ? mrt / Math.pow(10, 13) : mrt
                const myz = mrp ? (-1 * mrp) / Math.pow(10, 13) : mrp

                url = `http://webservices.ingv.it/ws/mopad/mopad.php?plot_arg=${mxx},${myy},${mzz},${mxy},${mxz},${myz}&color=109,139,160`
            } else {
                let { strike1, dip1, rake1 } = focalMechanism
                url = `http://webservices.ingv.it/ws/mopad/mopad.php?plot_arg=${strike1},${dip1},${rake1}&color=109,139,160`
            }
            setBallUrl(url);
        }
    }

    const getPsmeca = async function getPsmeca() {
        try {
            let url = `${process.env.REACT_APP_API_BASE_URL?.replace('/api', '')}/fdsnws/query?eventid=${earthquakeId}&format=psmeca`
            let response = await fetch(url, {
                headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                }
            })

            if (response.status === 500) {
                response.json().then((error) => {
                    setPsmeca(`Error ${error.status}: ${error.detail}`)
                })
            } else {
                response.text()
                    .then((t) => setPsmeca(t.replace("\n", "<br />")))
                    .catch((e) => setPsmeca(response.status + ': ' + JSON.stringify(e)))
            }
        } catch (e) {
            console.log('FocalMechanism => psmeca =>', e)
            setPsmeca('Pmeca error: ' + JSON.stringify(e))
        }
    }

    useEffect(() => {
        if (earthquakeId && focalMechanism) {
            if (focalMechanism.momenttensor) {
                getPsmeca();
            }
            getBall();
        }
    }, [earthquakeId, focalMechanism])

    useEffect(() => {
        let p = document.getElementById('psmeca')
        if (p) {
            p.innerHTML = psmeca
        }
    }, [psmeca]);

    function maxWidth(): string {
        let p = document.getElementById('fm-detail');
        if (!p) {
            return '370px'
        }

        return `${p.clientHeight}px`
    }

    return (<>
        <div className='focalmechanism-container' id='focalmechanism-container'>
            {
                showNotification.show &&
                (showNotification.kind === 'success'
                    ? <Toast
                        className='notification-hover'
                        title={t(showNotification.reason)}
                        onClose={() => setShowNotification(defaultNotificationState)}
                    />
                    : <InlineNotification
                        className='notification-hover'
                        kind={showNotification.kind}
                        lowContrast
                        actionbuttonlabel='Action'
                        ariaLabel='closes notification'
                        onClose={() => setShowNotification(defaultNotificationState)}
                        onCloseButtonClick={function noRefCheck() { }}
                        statusIconDescription='notification'
                        subtitle={t(showNotification.reason)}
                        title={showNotification.title}
                    />
                )
            }
            <div className='focalmechanism-container-title'>
                <span className={'title'}>{t('fm__modal__title')}</span>
                <div className='header-buttons'>
                    {
                        isPreferred()
                            ? <BeaDropdown
                                disabled={true}
                                text={(isPreferred() ? 'bc__preferred' : 'bc__make_preferred')}
                                icon={<IconPreferredStar />}
                            />
                            : <BeaDropdown
                                text={'bc__make_preferred'}
                                icon={<IconStar />}
                                callback={() => (!!keycloak.authenticated && !isCatalog) && makePreferredConfirmation()}
                            />
                    }
                </div>
            </div>
            {
                focalMechanism
                    ?
                    <>
                        {showBanner &&
                            <div className='fm-message-container' id='fm-notify'>
                                <div className='fm-yellow-rectangle'></div>
                                <div className='fm-message'>
                                    <span className='warning'>{t('fm_warning')}</span>
                                    <span className='text ellipse'>{t('fm_text')}</span>
                                </div>
                                <CloseIcon className='fm-close' onClick={() => {
                                    let notify = document.getElementById('fm-notify')
                                    if (notify)
                                        notify.style.display = 'none'
                                }}
                                />
                            </div>
                        }
                        <div className='two-cols-container span-2'>
                            <div className='fm-card'>
                                <FocalMechanismDetail />
                            </div>

                            {/* la palla */}
                            <div className='fm-card'>
                                <div className='fm-card-title ellipse'>{t('fm_seismic_source_title')}</div>
                                <div className='fm-card-content' style={{ alignContent: 'center', justifyContent: 'center' }}>
                                    <div className='ball' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={ballUrl} style={{ maxWidth: maxWidth() }} alt='...' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*moment tensor comps*/}
                        {focalMechanism && focalMechanism.momenttensor && <div className={`fm-card span-2`}>
                            <MomentTensorDetail />
                        </div>}

                        {/*psmeca*/}
                        {psmeca.length > 0 &&
                            <div className='double-row-container'>
                                <div className='inner'>
                                    <span className='title'>psmeca</span>
                                    <span id="psmeca" className='subtitle'></span>
                                </div>

                                <div
                                    className='ghost'
                                    onClick={() => window.open(`https://gmt.soest.hawaii.edu/doc/5.4.5/supplements/meca/psmeca.html`, '_blank')?.focus()}
                                >
                                    <div className='inner'>
                                        <span>{t('fm_gmt_documentation')}</span>
                                    </div>
                                </div>
                            </div>
                        }

                        {focalMechanism && focalMechanism.momenttensor && focalMechanism.momenttensor.tdmt &&
                            focalMechanism.momenttensor.tdmt.stations && focalMechanism.momenttensor.tdmt.stations.length > 0 &&
                            <div className='fm-card span-2'>
                                <div className='fm-card-title span-2 pb-10'>
                                    {t('modal__magnitude_mw_detail__tab__details__tdmt_title')}
                                </div>
                                <span className='fm-card-subtitle span-2'>{ }</span>
                                <Accordion>
                                    <AccordionItem className='span-2' title={t('fm_stations_details_title')}>
                                        <TdmtStationsTable focalMechanism={focalMechanism} />
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        }

                        {focalMechanism.momenttensor?.rcmt &&
                            <div className='fm-card span-2'>
                                <div className='fm-card-title span-2 pb-10'>
                                    {t('modal__magnitude_mw_detail__tab__details__rcmt_title')}
                                </div>
                                <div className='span-2'>
                                    <EmptyList title='' verysmall description={t('modal__magnitude_mw_detail__tab__details__rcmt__not_found')} />
                                </div>
                            </div>
                        }
                    </>
                    :
                    <div className='span-2' style={{ display: 'flex', justifyContent: 'center' }}>
                        <EmptyList title={t('fm_not_found_title')} description={''} small />
                    </div>
            }
        </div>
    </>);

    function isPreferred(): boolean {
        if (earthquake && focalMechanism) {
            const { preferred_focalmechanism_id } = earthquake;
            if (preferred_focalmechanism_id) {
                return preferred_focalmechanism_id === focalMechanism.id;
            }
        }

        return false;
    }

    function makePreferredConfirmation(): void {
        dispatch(showStoreModal({
            show: true,
            cancel: () => dispatch(closeStoreModal()),
            confirm: makePreferred,
            title: 'ot__make_preferred__confirmation',
            text: 'ot__make_preferred__confirmation__text'
        }))
    }

    async function makePreferred(): Promise<void> {
        if (isCatalog) {
            dispatch(closeStoreModal());
            return;
        }
        if (!!!keycloak.authenticated) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__not_authorized'), setShowNotification);
            return;
        }
        if (!origin) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__no_origin'), setShowNotification);
            return;
        }
        if (!earthquake || !earthquake.id) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__no_earthquake'), setShowNotification);
            return;
        }

        if (networkRequest) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__operation_in_progress_already'), setShowNotification);
            return;
        }

        if (!focalMechanism || focalMechanism.id === undefined || focalMechanism.id === null) {
            dispatch(closeStoreModal());
            return
        }

        setNetworkRequest(true);

        let request: UpdateEventRequest = {
            data: {
                event: {
                    preferred_focalmechanism_id: +focalMechanism.id
                }
            }
        }

        try {
            let response = await ApiClient.updateApi().updateEvent(+earthquake?.id, request);
            if (response.status === 200) {
                onSuccessCallbackHandler(
                    setShowNotification,
                    t('bc__make_preferred__success'),
                    t('bc__make_preferred__success_text_focalmechanism', { id: `${earthquake.id}`, focalmechanism: +focalMechanism.id })
                );
                resetItemTable();
                getData();
            } else {
                onErrorCallbackHandler(t('bc__make_preferred__failed'), 'Unknown Error ' + response.status, setShowNotification);
            }
        } catch (e) {
            let { response } = (e as AxiosError)
            onErrorCallbackHandler(t('bc__make_preferred__failed'), (response as AxiosResponse)?.data?.detail ?? 'Unknown error', setShowNotification);
        }
        setNetworkRequest(false);
        dispatch(closeStoreModal());
    }

    function resetItemTable(): void {
        const { event_group_id } = earthquake;
        if (event_group_id === undefined || event_group_id === null || event_group_id === 0) {
            dispatch(removeCustomTableGroup(`${earthquake.id}`));
        } else {
            dispatch(removeCustomTableGroup(`${event_group_id}`));
        }
    }

    async function getData(): Promise<void> {
        dispatch(fetchEarthquakeEvent(+earthquakeId));
    }

    function FocalMechanismDetail(): JSX.Element {
        if (!focalMechanism) {
            return <></>;
        }

        const { dip1, strike1, rake1 } = focalMechanism;
        let np1: string = ``;
        if (dip1 || strike1 || rake1) {
            np1 = `${strike1} / ${dip1} / ${rake1}`
        }

        const { dip2, strike2, rake2 } = focalMechanism;
        let np2: string = ``;
        if (dip2 || strike2 || rake2) {
            np2 = `${strike2} / ${dip2} / ${rake2}`
        }

        const { url } = focalMechanism;

        const mode: string = evaluateMode(focalMechanism);
        const status: string = evaluateStatus(focalMechanism);

        return (<>
            <div className='fm-card-title'>
                <div className='ellipse'>{t('fm_details_title')}</div>
                <BeaDropdown
                    text={'bc__show_provenance'}
                    icon={<IconProvenance />}
                    children={getProvenanceDropdown()}
                />
            </div>
            <div className='fm-card-content' id="fm-detail" >
                <>
                    <div className='fm-card-row title'>
                        <div>{t('fm_row_key')}</div>
                        <div>{t('fm_row_value')}</div>
                    </div>
                    {
                        focalMechanism.id !== null && focalMechanism.id !== undefined
                        && <div className='fm-card-row'>
                            <div>ID</div>
                            <div>{focalMechanism.id}</div>
                        </div>
                    }

                    {
                        np1.length > 0 && <div className='fm-card-row'>
                            <div>{t('fm_np1')}</div>
                            <div>{np1}</div>
                        </div>
                    }
                    {
                        np2.length > 0 && <div className='fm-card-row'>
                            <div>{t('fm_np2')}</div>
                            <div>{np2}</div>
                        </div>
                    }
                    {
                        focalMechanism.azim_gap && <div className='fm-card-row'>
                            <div>{t('fm_azim_gap')}</div>
                            <div>{focalMechanism?.azim_gap ?? '--'}</div>
                        </div>
                    }
                    {
                        focalMechanism.nsta_polarity && <div className='fm-card-row'>
                            <div>{t('fm_nsta_polarity')}</div>
                            <div>{focalMechanism?.nsta_polarity ?? '--'}</div>
                        </div>
                    }
                    {
                        focalMechanism.misfit && <div className='fm-card-row'>
                            <div>{t('fm_misfit')}</div>
                            <div>{focalMechanism?.misfit ?? '--'}</div>
                        </div>
                    }
                    {
                        focalMechanism.rms_ang_diff_acc_pref && <div className='fm-card-row'>
                            <div>{t('fm_rms_ang_diff_acc_pref')}</div>
                            <div>{focalMechanism?.rms_ang_diff_acc_pref ?? '--'}</div>
                        </div>
                    }
                    {
                        focalMechanism.frac_acc_30deg_pref && <div className='fm-card-row'>
                            <div>{t('fm_frac_acc_30deg_pref')}</div>
                            <div>{focalMechanism?.frac_acc_30deg_pref ?? '--'}</div>
                        </div>
                    }
                    {focalMechanism.quality && <div className='fm-card-row'>
                        <div>{t('fm_quality')}</div>
                        <div>{focalMechanism?.quality ?? '--'}</div>
                    </div>}
                    {focalMechanism.id_localspace && <div className='fm-card-row'>
                        <div>{t('fm_localspace_id')}</div>
                        <div>{focalMechanism.id_localspace ?? '--'}</div>
                    </div>}
                    <div className='fm-card-row'>
                        <div>{t('fm_localspace')}</div>
                        <div>{focalMechanism?.localspace?.name ?? '--'}</div>
                    </div>
                    {focalMechanism.inserted && <div className='fm-card-row'>
                        <div>{t('fm_inserted')}</div>
                        <div>{focalMechanism.inserted.replace('T', ' ').substring(0, focalMechanism.inserted.indexOf('+'))}</div>
                    </div>}
                    {focalMechanism.modified && <div className='fm-card-row'>
                        <div>{t('fm_modified')}</div>
                        <div>{focalMechanism.modified.replace('T', ' ').substring(0, focalMechanism.modified.indexOf('+'))}</div>
                    </div>
                    }
                    {url && <div className='fm-card-row'>
                        <div>{t('fm_url')}</div>
                        <div>
                            <a href={`${url}`} target="_blank">
                                {t('fm_url_ext')}
                            </a>
                        </div>
                    </div>
                    }
                </>
            </div>
        </>);
    }

    function MomentTensorDetail(): JSX.Element {
        const [open, setOpen] = useState<boolean>(true);

        if (!focalMechanism || !focalMechanism.momenttensor) {
            return <></>;

        }

        const { momenttensor } = focalMechanism;
        const { provenance, localspace, id_localspace, originid_out, inserted, modified, url } = momenttensor;

        return (
            <>
                <div className='fm-card-title'>
                    <div className='ellipse'>{t('fm_mt_component_title')}</div>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {provenance && <BeaDropdown
                            text={'bc__show_provenance'}
                            icon={<IconProvenance />}
                            children={getProvenanceDropdown(provenance)}
                        />}
                        {
                            <div className='ot-cta-container'
                                style={{ width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', transform: open ? 'rotate(0deg)' : 'rotate(180deg)' }}
                                onClick={() => setOpen(!open)}
                            >
                                <IconExpandLess />
                            </div>
                        }
                    </div>
                </div >
                {open &&
                    <div className='fm-card-content'>
                        <div className='fm-card-row title'>
                            <div>{t('fm_row_component')}</div>
                            <div>{t('fm_row_value')}</div>
                        </div>
                        {momenttensor.id && <div className='fm-card-row'>
                            <div>ID</div>
                            <div>{momenttensor.id ?? '--'}</div>
                        </div>}
                        {/* Does this should be removed? */}
                        {(mag && mag !== '--') && <div className='fm-card-row'>
                            <div>{t('magnitude')}</div>
                            <div>{mag ?? '--'}</div>
                        </div>}
                        {momenttensor.scalarmoment &&
                            <div className='fm-card-row'>
                                <div>{t('fm_scalar_moment')}</div>
                                <div>{getScalarMoment(focalMechanism)}</div>
                            </div>
                        }
                        {momenttensor.variance_reduction && <div className='fm-card-row'>
                            <div>{t('fm_variance_reduction')}</div>
                            <div>{getVarianceReduction(focalMechanism)}</div>
                        </div>
                        }
                        {momenttensor.double_couple && <div className='fm-card-row'>
                            <div>{t('fm_double_couple')}</div>
                            <div>{getDoubleCouple(focalMechanism)}</div>
                        </div>}
                        {momenttensor.clvd && <div className='fm-card-row'>
                            <div>{t('fm_clvd')}</div>
                            <div>{getClvd(focalMechanism)}</div>
                        </div>}
                        {(momenttensor.iso !== null && momenttensor.iso !== undefined) && <div className='fm-card-row'>
                            <div>{t('fm_iso')}</div>
                            <div>{getIso(focalMechanism)}</div>
                        </div>}
                        {momenttensor.mrr && <div className='fm-card-row'>
                            <div>Mrr</div>
                            <div>{momenttensor.mrr?.toExponential() ?? '--'}</div>
                        </div>}
                        {momenttensor.mtt && <div className='fm-card-row'>
                            <div>Mtt</div>
                            <div>{momenttensor.mtt?.toExponential() ?? '--'}</div>
                        </div>}
                        {momenttensor.mpp && <div className='fm-card-row'>
                            <div>Mpp</div>
                            <div>{momenttensor.mpp?.toExponential() ?? '--'}</div>
                        </div>}
                        {momenttensor.mrt && <div className='fm-card-row'>
                            <div>Mrt</div>
                            <div>{momenttensor.mrt?.toExponential() ?? '--'}</div>
                        </div>}
                        {momenttensor.mrp && <div className='fm-card-row'>
                            <div>Mrp</div>
                            <div>{momenttensor.mrp?.toExponential() ?? '--'}</div>
                        </div>}
                        {momenttensor.mtp && <div className='fm-card-row'>
                            <div>Mtp</div>
                            <div>{momenttensor.mtp?.toExponential() ?? '--'}</div>
                        </div>
                        }
                        {originid_out !== undefined && <div className='fm-card-row'>
                            <div>Originid Out</div>
                            {
                                originid_out
                                    ? <Link to={`/earthquake/${earthquakeId}/origin/${originid_out}`} reloadDocument={true}>
                                        {originid_out}
                                    </Link>
                                    : <div>--</div>
                            }
                        </div>}
                        {id_localspace &&
                            <div className='fm-card-row'>
                                <div>{t('fm_localspace_id')}</div>
                                <div>{id_localspace ?? '--'}</div>
                            </div>
                        }
                        {localspace && localspace.name && <div className='fm-card-row'>
                            <div>{t('fm_localspace')}</div>
                            <div>{focalMechanism?.momenttensor?.localspace?.name ?? '--'}</div>
                        </div>
                        }
                        {inserted && <div className='fm-card-row'>
                            <div>{t('fm_inserted')}</div>
                            <div>{inserted.replace('T', ' ').substring(0, inserted.indexOf('+'))}</div>
                        </div>
                        }
                        {modified && <div className='fm-card-row'>
                            <div>{t('fm_modified')}</div>
                            <div>{modified.replace('T', ' ').substring(0, modified.indexOf('+'))}</div>
                        </div>
                        }
                        {
                            url && <div className='fm-card-row'>
                                <div>{t('fm_url')}</div>
                                <div>
                                    <a href={`${url}`} target="_blank">
                                        {t('fm_url_ext')}
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                }
            </>);
    }

    function getProvenanceDropdown(provenance?: ObjectProvenance): JSX.Element | undefined {
        if (!provenance) {
            if (focalMechanism) {
                if (focalMechanism.provenance) {
                    return <DropdownProvenance provenance={focalMechanism.provenance} />
                }
            }
        } else {
            return <DropdownProvenance provenance={provenance} />
        }

        return undefined;
    }
}

export default FocalMechanism