import './SettingsButton.css'
import { IconButton } from '@carbon/react'
import { ReactComponent as IconSettings } from '../../assets/settings.svg'
import { ReactComponent as IconActiveSettings } from '../../assets/settings-active.svg'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

type SettingsButtonProps = {
    callback: object
}

const SettingsButton = ({callback}: SettingsButtonProps) => {

    const location = useLocation()
    const [active, setActive] = useState(false)
    

    useEffect(() => {
        console.log('SettingsButton => pathname =>', location.pathname)
        console.log('SettingsButton => isActive =>', location.pathname === '/settings')
        setActive(location.pathname === '/settings')
    }, [location])

    return <div className={active ? 'settings-btn-container active' : 'settings-btn-container'}>
            <IconButton className={active ? 'settings-btn active' : 'settings-btn'} label='settings' onClick={callback}>
                { active ? <IconActiveSettings /> : <IconSettings /> }
            </IconButton>
        </div>
}

export { SettingsButton }