import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ObjectArrival } from "../../services/network";
import { EventDetailArrival } from "../CustomTable/flavor/CustomArrivalTable";
import EmptyList from "../EmptyList/EmptyList";
import { GenericTable, IHeader, Row, SORT_ASC, SortDirection, ot, singleRow } from "../EventDetailTable/GenericTable";
import { azAsc, azDesc, distanceAsc, distanceDesc, evaluationAsc, evaluationDesc, phaseAsc, phaseDesc, residualAsc, residualDesc, restAngleAsc, restAngleDesc, scnlAsc, scnlDesc, uncertaintyAsc, uncertaintyDesc, weightAsc, weightDesc, polarityAsc, polarityDesc } from "../EventDetailTable/sortutils";
import { LoaderTranslated } from "../Loader/LoaderTranslated";
import './ArrivalTable.css';
import { evaluateEvMode, evaluatePolarity, evaluateUncertainty } from "./utils";

interface ArrivalTableParams {
    arrivals: Array<ObjectArrival> | undefined
}

function ArrivalTable({ arrivals }: ArrivalTableParams) {
    const { t } = useTranslation()
    const [arrivalTableArrivals, setArrivalTableArrivals] = useState<Array<EventDetailArrival> | undefined>(undefined)
    const [rows, setRows] = useState<Row[] | undefined>(undefined);

    useEffect(() => {
        const tableArrivals: Array<EventDetailArrival> = arrivals?.map((arrival, index) => {
            const { pick } = arrival;
            return {
                id: `${index}`,
                scnl: `${pick?.net}.${pick?.sta}.${pick?.cha}.${pick?.loc}`,
                datetime: `${pick?.arrival_time}`,
                uncertainty: evaluateUncertainty(pick?.upper_uncertainty, pick?.lower_uncertainty),
                l_unc: pick?.lower_uncertainty ?? -1,
                u_unc: pick?.upper_uncertainty ?? -1,
                polarity: evaluatePolarity(pick?.firstmotion, t),
                evaluation: evaluateEvMode(undefined), // TODO: Ask INGV about this!
                phase: `${arrival?.isc_code ?? '--'}`,
                az: `${arrival.azimut ?? '--'}`,
                a: arrival.azimut ?? -1,
                distance: `${arrival.ep_distance_km ?? '--'}`,
                d: arrival.ep_distance_km ?? -1,
                rest_angle: `${arrival.take_off ?? '--'}`,
                ra: arrival.take_off ?? -1,
                residual: `${arrival.residual ?? '--'}`,
                r: arrival.residual ?? -1,
                weight: `${arrival.weight ?? '--'}`,
                w: arrival.weight ?? -1
            }
        }) ?? [];

        tableArrivals.sort(weightAsc);
        setArrivalTableArrivals(tableArrivals);
    }, [arrivals]);

    useEffect(() => {
        if (!arrivalTableArrivals) {
            return;
        }

        const temp: Row[] = arrivalTableArrivals.map((a, index) => {
            const link: string = '#';
            return [
                {
                    tag: 'id_tag',
                    id: `${a.id}`,
                    element: <></>
                },
                {
                    tag: 'scnl_tag',
                    id: `${a.id}`,
                    element: singleRow(link, a.scnl, a.scnl)

                },
                {
                    tag: 'ot_tag',
                    id: `${a.id}`,
                    element: ot(link, a.datetime)
                },
                {
                    tag: 'uncertainty_tag',
                    id: `${a.id}`,
                    element: singleRow(link, a.uncertainty, a.uncertainty)
                },
                {
                    tag: 'polarity_tag',
                    id: `${a.id}`,
                    element: singleRow(link, a.polarity, a.polarity)
                },
                {
                    tag: 'eval_tag',
                    id: `${a.id}`,
                    element: singleRow(link, a.evaluation, a.evaluation)
                },
                {
                    tag: 'phase_tag',
                    id: `${a.id}`,
                    element: singleRow(link, a.phase, a.phase)
                },
                {
                    tag: 'az_tag',
                    id: `${a.id}`,
                    element: singleRow(link, a.az, a.az)
                },
                {
                    tag: 'distance_tag',
                    id: `${a.id}`,
                    element: singleRow(link, a.distance, a.distance)
                },
                {
                    tag: 'angle_tag',
                    id: `${a.id}`,
                    element: singleRow(link, a.rest_angle, a.rest_angle)
                },
                {
                    tag: 'residual_tag',
                    id: `${a.id}`,
                    element: singleRow(link, a.residual, a.residual)
                },
                {
                    tag: 'weight_tag',
                    id: `${a.id}`,
                    element: singleRow(link, a.weight, a.weight)
                }
            ];
        });
        setRows(temp);
    }, [arrivalTableArrivals]);

    function sortByScnl(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? scnlAsc : scnlDesc);
    }

    function sortByWeight(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? weightAsc : weightDesc);
    }

    function sortByResidual(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? residualAsc : residualDesc);
    }

    function sortByRestAngle(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? restAngleAsc : restAngleDesc);
    }

    function sortByDistance(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? distanceAsc : distanceDesc);
    }

    function sortByAz(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? azAsc : azDesc);
    }

    function sortByPhase(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? phaseAsc : phaseDesc)
    }

    function sortByEval(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? evaluationAsc : evaluationDesc);
    }

    function sortByDatetime(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? distanceAsc : distanceDesc);
    }

    function sortByUncertainty(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? uncertaintyAsc : uncertaintyDesc);
    }

    function sortByPolarity(direction: SortDirection): void {
        sortArrivals(direction === SORT_ASC ? polarityAsc : polarityDesc);
    }

    function sortArrivals(sortingProcedure: (m1: EventDetailArrival, m2: EventDetailArrival) => number): void {
        if (!arrivalTableArrivals) {
            return;
        }
        const copyArrivals: EventDetailArrival[] = [...arrivalTableArrivals];
        copyArrivals.sort(sortingProcedure);
        setArrivalTableArrivals(copyArrivals);
    }

    const tableHeaders: IHeader[] = [
        { tag: 'scnl_tag', label: 'ot__scnl__label', sortable: true, sortFunction: sortByScnl },
        { tag: 'ot_tag', label: 'ot__ot__label', sortable: true, sortFunction: sortByDatetime },
        { tag: 'uncertainty_tag', label: 'ot__uncertainty__label', sortable: true, sortFunction: sortByUncertainty },
        { tag: 'polarity_tag', label: 'ot__polarity__label', sortable: true, sortFunction: sortByPolarity },
        { tag: 'eval_tag', label: 'ot__eval__label', sortable: true, sortFunction: sortByEval },
        { tag: 'phase_tag', label: 'ot__phase__label', sortable: true, sortFunction: sortByPhase },
        { tag: 'az_tag', label: 'ot__az__label', sortable: true, sortFunction: sortByAz },
        { tag: 'distance_tag', label: 'ot__distance__label', sortable: true, sortFunction: sortByDistance },
        { tag: 'angle_tag', label: 'ot__angle__label', sortable: true, sortFunction: sortByRestAngle },
        { tag: 'residual_tag', label: 'ot__residual__label', sortable: true, sortFunction: sortByResidual },
        { tag: 'weight_tag', label: 'ot__weight__label', sortable: true, sortFunction: sortByWeight }
    ]

    return (
        <div className={'origin-table-container'}>
            {/* empty div*/}
            <div></div>
            {
                (arrivals && arrivalTableArrivals && rows)
                    ? (
                        arrivalTableArrivals.length > 0 && rows.length > 0
                            ? (<GenericTable
                                rows={rows}
                                headers={tableHeaders}
                                gridDefinition="od-at-grid"
                                defaultSorting={{
                                    direction: SORT_ASC,
                                    tag: 'weight_tag'
                                }}
                            />)
                            : (<EmptyList title="od__arrivals_table__empty" />)
                    )
                    : <LoaderTranslated />
            }
        </div>
    )
}

export { ArrivalTable };

