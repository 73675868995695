import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import session from 'redux-persist/lib/storage/session'

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

//import auth from './store/authentication' // TODO
import earthquake from './reducers/earthquake.slice'
import filters from './reducers/filters.slice'
import timepicker from './reducers/timepicker.slice'
import cta from './reducers/cta.slice'
import group from './reducers/group.slice'
import maps from './reducers/maps.slice'
import table from './reducers/customtable.slice'
import timezone from './reducers/timezone.slice'
import auth from './reducers/auth.slice'
import settings from './reducers/settings.slice'
import layout from './reducers/layout.slice'
import filtersmodal from './reducers/filtersmodal.slice'
import stations from './reducers/stations.slice'
import stationEndpointsError from './reducers/stationEndpointsError.slice'
import confirmationModal from './reducers/confirmationModal.slice'

/*
 * Here we will be adding reducers
 * */
const reducer = combineReducers({
  settings,
  earthquake,
  filters,
  timepicker,
  cta,
  group,
  maps,
  table,
  timezone,
  layout,
  filtersmodal,
  stations,
  stationEndpointsError,
  confirmationModal,
  auth, // fake to be removed when keycloak works for ingv
})

const persistConfig = {
  key: 'root',
  storage: session,
  blacklist: []
};

const persistedReducer = persistReducer(persistConfig, reducer);

/**
 * configureStore accepts a single object rather that multiple function arguments. 
 * It's because under the hood, the store has been configured to allow using the Redux DevTools Extension and has had some Redux middleware included by default.
 */
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch