import './Filters.css'
import { NewDateTimeSelector } from '../DateTimeSelector/NewDateTimeSelector';
import GeofenceSelector, { CoordinateRef } from '../GeofenceSelector/GeofenceSelector';
import { useEffect, useState } from 'react';
import { Filters } from '../../redux/reducers/filters.slice';
import { QueryFiltersOrigin } from './components/QueryFiltersOrigin/QueryFiltersOrigin';
import { QueryFiltersDepth } from './components/QueryFiltersDepth/QueryFiltersDepth';
import { QueryFiltersMagnitude } from './components/QueryFiltersMagnitude/QueryFiltersMagnitude';
import ProductSelector from '../ProductSelector/ProductSelector';
import { useTranslation } from 'react-i18next';
import { QueryFiltersLocalspace } from './components/QueryFiltersLocalspace/QueryFiltersLocalspace';
import { QueryFiltersOther, fromFiltersToOthers } from './components/QueryFiltersOther/QueryFiltersOther';
import { BeaTextInput } from "../BeaTextInput/BeaTextInput";
import { validNumericText } from '../DropdownFilters/messages';

export interface IQueryFiltersProps {
    onFiltersUpdate: (newFilters: Filters) => void;
    filters: Filters;
}

function QueryFilters({ onFiltersUpdate, filters }: IQueryFiltersProps) {

    const { t } = useTranslation();
    const [localFilters, setLocalFilters] = useState<Filters>({ ...filters })
    useEffect(() => setLocalFilters({ ...filters }), [filters]) // this is not invoked until the Apply button is clicked

    useEffect(() => {
        if (JSON.stringify(localFilters) !== JSON.stringify(filters)) {
            onFiltersUpdate(localFilters);
        }
    }, [localFilters])

    return (
        <div className={'filters-parts'}>
            <div className={'filters-first-part'}>
                <NewDateTimeSelector />
                <GeofenceSelector
                    shape={localFilters.shape}
                    selectedGeofence={{
                        key: localFilters.ref_key,
                        region: localFilters.ref_region,
                        max_lat: localFilters.maxlat,
                        min_lat: localFilters.minlat,
                        max_lon: localFilters.maxlon,
                        min_lon: localFilters.minlon,
                        lat: localFilters.lat,
                        lon: localFilters.lon,
                        minradius: localFilters.minradiuskm,
                        maxradius: localFilters.maxradiuskm,
                    }}
                    onUpdate={(reference: CoordinateRef, shape: string) => {
                        setLocalFilters({
                            ...localFilters,
                            minlat: reference.min_lat,
                            minlon: reference.min_lon,
                            maxlat: reference.max_lat,
                            maxlon: reference.max_lon,
                            lat: reference.lat,
                            lon: reference.lon,
                            minradiuskm: reference.minradius,
                            maxradiuskm: reference.maxradius,
                            shape,
                            ref_key: reference.key,
                            ref_region: reference.region,
                        })
                    }}
                />
            </div>
            <div className={'filters-second-part'}>
                <div className={'full-no-bottom-24px'}>
                    <p className={'filters-section-title'}>
                        {t('fs__title__origin')}
                    </p>
                    <QueryFiltersOrigin
                        quality={localFilters.whereoriginqualityin}
                        min={localFilters.mintypeoriginvalue}
                        max={localFilters.maxtypeoriginvalue}
                        wherein={localFilters.wheretypeoriginvaluein}
                        onUpdate={updateValueCallback}
                    />
                </div>
                <div className={'full-no-bottom-24px'}>
                    <p className={'filters-section-title'}>
                        {t('fs__title__depth')}
                    </p>
                    <QueryFiltersDepth
                        min={localFilters.mindepth}
                        max={localFilters.maxdepth}
                        onUpdate={updateValueCallback}
                    />
                </div>
                <div className={'full-no-bottom-24px'}>
                    <p className={'filters-section-title'}>{t('magnitude')}</p>
                    <QueryFiltersMagnitude
                        quality={localFilters.wheremagnitudemagqualityin}
                        magType={localFilters.wheretypemagnitudenameregexp}
                        min={localFilters.minmag}
                        max={localFilters.maxmag}
                        onUpdate={updateValueCallback}
                    />
                </div>
                <div className={'full-no-bottom-24px'}>
                    <p className='filters-section-title'>{t('products')}</p>
                    <ProductSelector
                        selected={localFilters.products ?? ''}
                        onUpdate={(products) => setLocalFilters({ ...localFilters, products: products === '' ? undefined : products })}
                    />
                </div>
                <div className={'full-no-bottom-24px'}>
                    <p className={'filters-section-title'}>Localspace</p>
                    <div className={'filters-localspace-selectors'}>
                        <div className={'filters-single-localspace-selector'}>
                            <BeaTextInput
                                value={localFilters.id_localspace ?? ''}
                                placeholder={'eg. 1234567890'}
                                label={'fs__title__id_localspace'}
                                stateKey={'id_localspace'}
                                invalid={localFilters.id_localspace !== undefined && localFilters.id_localspace != '' && isNaN(Number(localFilters.id_localspace))}
                                onChange={(id: string) => updateValueCallback('id_localspace', id)}
                                invalidText={t(validNumericText(localFilters.id_localspace ?? '', 0, Number.MAX_SAFE_INTEGER, true))}
                            />
                        </div>
                        <div className={'filters-single-localspace-selector'}>
                            <span>{t('fs__title__event_localspace')}</span>
                            <QueryFiltersLocalspace
                                localspaceenv={localFilters.whereeventlocalspaceenvironmentin}
                                filtersEnv={'whereeventlocalspaceenvironmentin'}
                                localspacename={localFilters.whereeventlocalspacenamein}
                                filtersName={'whereeventlocalspacenamein'}
                                onUpdate={updateValueCallback}
                            />
                        </div>
                        <div className={'filters-single-localspace-selector'}>
                            <span>{t('fs__title__origin_localspace')}</span>
                            <QueryFiltersLocalspace
                                localspaceenv={localFilters.whereoriginlocalspaceenvironmentin}
                                filtersEnv={'whereoriginlocalspaceenvironmentin'}
                                localspacename={localFilters.whereoriginlocalspacenamein}
                                filtersName={'whereoriginlocalspacenamein'}
                                onUpdate={updateValueCallback} />
                        </div>
                        <div className={'filters-single-localspace-selector'}>
                            <span>{t('fs__title__magnitude_localspace')}</span>
                            <QueryFiltersLocalspace
                                localspaceenv={localFilters.wheremagnitudelocalspaceenvironmentin}
                                filtersEnv={'wheremagnitudelocalspaceenvironmentin'}
                                localspacename={localFilters.wheremagnitudelocalspacenamein}
                                filtersName={'wheremagnitudelocalspacenamein'}
                                onUpdate={updateValueCallback} />
                        </div>
                    </div>
                </div>
                <div className={'full-24px'}>
                    <p className={'filters-section-title'}>{t('fs__title__other')}</p>
                    <QueryFiltersOther
                        filterOther={localFilters}
                        onUpdate={updateOthers} />
                </div>
            </div>
        </div >
    );

    function updateValueCallback(key: string, value: string): void {
        setLocalFilters({
            ...localFilters,
            eventid: undefined, // preventing situation where query parameters and ids got mixed
            originid: undefined, // preventing situation where query parameters and ids got mixed
            magnitudeid: undefined, // preventing situation where query parameters and ids got mixed
            [key]: value
        });
    }

    function updateOthers(newFilters: Filters): void {
        setLocalFilters({
            ...localFilters,
            eventid: undefined, // preventing situation where query parameters and ids got mixed
            originid: undefined, // preventing situation where query parameters and ids got mixed
            magnitudeid: undefined, // preventing situation where query parameters and ids got mixed
            orpolygon: newFilters.orpolygon,
            notinpolygon: newFilters.notinpolygon,
            wherepolygonnamein: newFilters.wherepolygonnamein,
            origindirectlinktoevent: newFilters.origindirectlinktoevent,
            magnitudedirectlinktoorigin: newFilters.magnitudedirectlinktoorigin,
            magnitudedirectlinktoevent: newFilters.magnitudedirectlinktoevent,
            eventupdatedafter: newFilters.eventupdatedafter,
            originupdatedafter: newFilters.originupdatedafter,
            magnitudeupdatedafter: newFilters.magnitudeupdatedafter,
            updatedafteroperator: newFilters.updatedafteroperator,
            // v3.4.0
            mindistance: newFilters.mindistance,
            minnphtot: newFilters.minnphtot,
            // v3.9.0
            wheretypeeventnamein: newFilters.wheretypeeventnamein,
            wheretypeeventnamenotin: newFilters.wheretypeeventnamenotin
        });
    }
}

export {
    QueryFilters
}