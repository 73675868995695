import {Dropdown} from "@carbon/react";
import {Option} from "../CustomSelector/CustomSelector";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/reduxCustomHooks";
import {setBoth, setList, setMap} from "../../redux/reducers/layout.slice";

interface SelectedItem {
    selectedItem: Option
}

const LayoutSelector = () => {

    const {t} = useTranslation();
    const [stateLayout, setLayout] = useState('both');
    const dispatch = useAppDispatch();
    const layout = useAppSelector((state) => state.layout);

    const options: Option[] = [
        { key: 'both', value: t('map_and_list_layout') },
        { key: 'map', value: t('map_layout') },
        { key: 'list', value: t('list_layout') }
    ];

    useEffect(() => {
        setLayout(layout);
    }, [layout])

    const changeLayout = (selected: SelectedItem) => {
        switch (selected.selectedItem.key) {
            case 'map': dispatch(setMap()); break;
            case 'list': dispatch(setList()); break;
            default: dispatch(setBoth()); break;
        }
    }

    return (<div style={{maxHeight: '40px', width: '180px'}}>
        <Dropdown
            id={'layout-selector'}
            label={''}
            hideLabel
            titleText={''}
            onChange={changeLayout}
            items={options}
            itemToString={(item: Option) => item.value}
            selectedItem={options[options.findIndex((o) => o.key === stateLayout)]}
        />
    </div>);
}

export default LayoutSelector