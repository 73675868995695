import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import log from 'loglevel'
import { ApiClient } from '../../services/ApiClient'
import { ObjectEvent } from '../../services/network'

const initialEarthquakeState: ObjectEvent = {
    id: 0,
    type_event: ''
}

export const earthquakeSlice = createSlice({
  name: 'earthquake',
  initialState: initialEarthquakeState,
  reducers: {
    resetEvent: (state) => {
      state = initialEarthquakeState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEarthquakeEvent.fulfilled, (state, action) => {
      return action.payload
    })
    builder.addCase(fetchEarthquakeEventByOrigin.fulfilled, (state, action) => {
      return action.payload
    })
  },
})

export const fetchEarthquakeEvent = createAsyncThunk(
    'earthquake/fetchById',
    async (eventId: number, thunkAPI) => {
          try{
              let resp = await ApiClient.getApi().getEvent(undefined, eventId)
              log.debug("fetchEarthquakeEvent resp => ", resp)
              return resp.data.data?.event ?? initialEarthquakeState
          } catch(error){
              log.error(`fetchEarthquakeEvent error catch => ${error}`)
              return thunkAPI.rejectWithValue(error)
          }
    }
)

export const fetchEarthquakeEventByOrigin = createAsyncThunk(
  'earthquake/fetchByOriginId',
  async (originId: number, thunkAPI) => {
        try {
            let resp = await ApiClient.getApi().getEvent(originId, undefined)
            log.debug("OriginDetail => fetchEarthquakeEvent resp => ", resp.data.data?.event)
            return resp.data.data?.event ?? initialEarthquakeState
        } catch(error){
            log.error(`fetchEarthquakeEvent error catch => ${error}`)
            return thunkAPI.rejectWithValue(error)
        }
 }
)

// Action creators are generated for each case reducer function
export const { resetEvent } = earthquakeSlice.actions

export default earthquakeSlice.reducer