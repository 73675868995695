import {ID_TAG, SORT_ASC, TableHeader} from "../TableUtils";

export const MUNICIPALITY_TAG = 'municipality'
export const REGION_TAG = 'region'
export const INHABITANTS_TAG = 'inhabitants'
export const CUMULATIVE_TAG = 'cumulative'
export const DISTANCE_TAG_EDC = 'distance_edc'

export interface EventDetailCity {
    [ID_TAG]: string, 
    [MUNICIPALITY_TAG]: string, 
    [REGION_TAG]: string, 
    [DISTANCE_TAG_EDC]: string, 
    [INHABITANTS_TAG]: string, 
    [CUMULATIVE_TAG]: string
}

export const eventdetail_citytable_headers: Array<TableHeader> = [
    {
        tag: MUNICIPALITY_TAG,
        header: 'eventdetail_citytable_municipality',
        sortable: true,
    },
    {
        tag: REGION_TAG,
        header: 'eventdetail_citytable_region',
        sortable: true,
    },
    {
        tag: DISTANCE_TAG_EDC,
        header: 'eventdetail_citytable_distance',
        sortable: true,
    },
    {
        tag: INHABITANTS_TAG,
        header: 'eventdetail_citytable_inhabitants',
        sortable: true,
    },
    {
        tag: CUMULATIVE_TAG,
        header: 'eventdetail_citytable_cumulative',
        sortable: true,
    }
]

export const castedEventDetailCitiesSorting =  (tag: string, elements: Array<EventDetailCity>, direction: number) => {
    let copy = [...elements]
    
    if(tag === MUNICIPALITY_TAG || tag === REGION_TAG) {                
        return copy.sort((e1, e2) => {
            return direction === SORT_ASC
                ? e1[tag].localeCompare(e2[tag])
                : -(e1[tag].localeCompare(e2[tag]))
        })
    }

    if(tag === DISTANCE_TAG_EDC || tag === INHABITANTS_TAG || tag === CUMULATIVE_TAG) {       
        return copy.sort((e1, e2) => {
            let v1:number = Number(e1[tag].replaceAll(/[\D]/g, ""))
            let v2:number = Number(e2[tag].replaceAll(/[\D]/g, ""))
            return direction === SORT_ASC
                ? v1 - v2
                : -(v1 - v2)
        })
    }

    return elements
}

export const isCityTableTag = (tag: string) => tag === REGION_TAG || tag === MUNICIPALITY_TAG ||
    tag === DISTANCE_TAG_EDC || tag === INHABITANTS_TAG || tag === CUMULATIVE_TAG