import { createSlice } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
    name: 'filtersmodal',
    initialState: false,
    reducers: {
        open: () => true,
        close: () => false
    }
});

export const { open, close } = layoutSlice.actions
export default layoutSlice.reducer