import { AbsoluteRange, NewIntervalType } from "./NewDateTimeSelector";
import moment, { unitOfTime } from "moment/moment";
import { Moment } from "moment";

const fromStringToMoment = function (date?: string): Moment | null {
    if (!date) {
        console.log('fromStringToMoment => no date')
        return null;
    }

    // assuming format YYYY-MM-DDThh:mm:ss
    if (date.includes('-'))
        return moment(date)
    // assuming format DD/MM/YYYY
    if (date.includes('/'))
        return moment(date.split('/').reverse().join('-'))
    return null
}

/**
 * A valid relative interval is an interval that starts in a precise moment in time till now.
 * So we need a starting date and a starting hour
 * @param startDate
 */
const validRelativeInterval = function (startDate: Moment): boolean {
    if (startDate.isValid())
        return !isFutureFromToday(startDate);
    return false
}

/**
 * A valid absolute interval has a startDate and an endDate.
 * StartDate param is valid if it is before endDate param.
 * EndDate param is valid if it is before the date of today at 23:59:59
 * @param startDate
 * @param endDate
 */
const validAbsoluteInterval = function (startDate: Moment, endDate: Moment): boolean {
    if (isFutureFromToday(startDate)) return false
    if (isFutureFromToday(endDate, 'days')) return false
    return !isFuture(startDate, endDate);

}

/**
 * Check if the date provided is before `now` according to a certain granularity (default is `seconds`)
 * So if date is 12/01/2023 15:01:02 and now is 12/01/2023 15:01:01, we have the following cases
 *  - granularity === 'seconds', the function returns false
 *  - granularity === 'days', the function returns true
 * @param date
 * @param granularity
 */
const isFutureFromToday = function (date: Moment, granularity: unitOfTime.StartOf = 'seconds'): boolean {
    return isFuture(date, moment(), granularity)
}

const isFuture = function (date: Moment, reference: Moment, granularity: unitOfTime.StartOf = 'seconds'): boolean {
    if (date.isAfter(reference, granularity)) {
        console.log('validInterval => isFuture => isAfter => date in the future')
        return true
    }
    console.log('validInterval => isFuture => isAfter => date is in the past')
    return false
}

const checkIfRelative = function (interval: NewIntervalType): boolean {
    let { startDate, endDate, startHour, endHour } = interval as AbsoluteRange
    if (startDate && startDate !== '') {
        if (startHour && startHour !== '') { // startDate AND startHour are set
            if (!endDate || endDate === '') {
                if (!endHour || endHour === '') // endDate AND endHour are NOT set
                    return true
            }
            return false
        }
    }

    throw new Error('Not a valid interval')
}

const validInterval = function (interval: NewIntervalType): boolean {
    if ('days' in interval) {
        console.log('validInterval => days => valid interval')
        return true;
    }

    let _startDate = moment(`${interval.startDate.split('/').reverse().join('-')}T${interval.startHour}`)
    try {
        if (checkIfRelative(interval)) {
            console.log('validInterval => check relative interval')
            if (validRelativeInterval(_startDate)) {
                console.log('validInterval => relative interval is valid')
                return true
            }
        } else {
            let _endDate = moment(`${(interval?.endDate ?? '').split('/').reverse().join('-')}T${interval.endHour}`)
            console.log('validInterval => check absolute interval')
            if (validAbsoluteInterval(_startDate, _endDate)) {
                console.log('validInterval => absolute interval is valid')
                return true
            }
        }
    } catch (e) {

    }
    return false
}

export {
    validInterval,
    fromStringToMoment,
    isFutureFromToday
}