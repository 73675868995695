import { ObjectOrigin, ObjectOriginForMagnitudesOriginsEventsAndEventsGroup as Origin } from "../../services/network"
import {fromStringToMoment, isFutureFromToday} from "../../components/DateTimeSelector/utils";

export const evaluateMarkerClass = (mag:number) => {
    if(mag < 2.5) return 'circular-marker-small'
    if(mag < 4) return 'circular-marker-medium'
    if(mag < 5.5) return 'circular-marker-big'
    return 'circular-marker-verybig'
}

export const evaluateMarkerText = (mag:number) => {
    if(mag < 4) return ''
    return mag.toFixed(1)
}

export const evaluateVersion = function(o: Origin|ObjectOrigin|undefined): string {
    if(!o)
        return '--'
    let {type_origin} = o
    if(!type_origin)
        return '--'
    let {version_name, version_value} = type_origin
    return `${version_value ?? '--'}#${version_name ?? '--'}`
}

export const getEndtimeIfNotInFuture = function(endtime?: string): string | undefined {
    if(!endtime) return
    let _endtimeMoment = fromStringToMoment(endtime)
    if(_endtimeMoment)
        if(_endtimeMoment.isValid())
            if(!isFutureFromToday(_endtimeMoment))
                return endtime
}