import { ObjectFocalmechanism } from "../../services/network"

const evaluateStatus = (focalMechanism?: ObjectFocalmechanism) => {
    if(focalMechanism) {
        if(focalMechanism.provenance) {
            if(focalMechanism.provenance.username) {
                let {username} = focalMechanism.provenance
                switch(username) {
                    case 'tdmt_historical': return 'final'
                    case 'tdmt_reviewer': return 'reviewed'
                    case 'tdmt_auto': return 'preliminary'
                }
            }
        }
    }
    return '--'
}

const evaluateMode = (focalMechanism?: ObjectFocalmechanism) => {
    if(focalMechanism) {
        if(focalMechanism.provenance) {
            if(focalMechanism.provenance.username) {
                let {username} = focalMechanism.provenance
                switch(username) {
                    case 'tdmt_historical': return 'manual'
                    case 'tdmt_reviewer': return 'manual'
                    case 'tdmt_auto': return 'automatic'
                }
            }
        }
    }
    return '--'
}

const getScalarMoment = (focalMechanism?: ObjectFocalmechanism) => {
    return getMomentTensorParams('scalar_moment', focalMechanism)
}

const getIso = (focalMechanism?: ObjectFocalmechanism) => {
    return getMomentTensorParams('iso', focalMechanism)
}

const getClvd = (focalMechanism?: ObjectFocalmechanism) => {
    return getMomentTensorParams('clvd', focalMechanism)
}

const getDoubleCouple = (focalMechanism?: ObjectFocalmechanism) => {
    return getMomentTensorParams('double_couple', focalMechanism)
}

const getVarianceReduction = (focalMechanism?: ObjectFocalmechanism) => {
    return getMomentTensorParams('variance_reduction', focalMechanism)
}

const getModel =(focalMechanism?: ObjectFocalmechanism) => {
    if(focalMechanism) {
        return 'TO BE DEFINED'
    }
    return '--'
}

const getMomentTensorParams = (param: string, focalMechanism?: ObjectFocalmechanism) => {
    if(focalMechanism) {
        if(focalMechanism.momenttensor) {
            if(param === 'iso') {
                if(focalMechanism.momenttensor.iso !== undefined && focalMechanism.momenttensor.iso !== null)
                    return `${focalMechanism.momenttensor.iso * 100}%`
            }
            if(param === 'clvd') {
                if(focalMechanism.momenttensor.clvd)
                    return `${focalMechanism.momenttensor.clvd * 100}%`
            }
            if(param === 'double_couple') {
                if(focalMechanism.momenttensor.double_couple)
                    return `${focalMechanism.momenttensor.double_couple * 100}%`
            }
            if(param === 'variance_reduction') {
                if(focalMechanism.momenttensor.variance_reduction)
                    return `${focalMechanism.momenttensor.variance_reduction}%`
            }
            if(param === 'scalar_moment') {
                if(focalMechanism.momenttensor.scalarmoment) {
                    return `${focalMechanism.momenttensor.scalarmoment.toExponential()} N-m`
                }
            }
        }
    }
    return '--' 
}

export {evaluateStatus, evaluateMode, getModel, getScalarMoment, getClvd, getIso, getVarianceReduction, getDoubleCouple}