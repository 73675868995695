import './BeaTextInput.css'
import {TextInput} from "@carbon/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export interface IBeaTextInputProps {
    value: string;
    placeholder: string;
    label: string;
    stateKey: string;
    invalid: boolean;
    onChange: (value: string) => void;
    invalidText: string;
}

function BeaTextInput({
    value, placeholder, label, stateKey, invalid, onChange: onChanged, invalidText
}: IBeaTextInputProps): JSX.Element {

    const {t} = useTranslation();
    const inputChanged = (e: React.FormEvent<HTMLInputElement>) => {
        onChanged(e.currentTarget.value)
    }

    return(
        <div className={'filter-section-inputbox'}>
            <p className={'filters-id-inputtext-label'}>{t(label)}</p>
            <TextInput
                labelText=''
                className='input'
                id='filter-id-input'
                hideLabel
                placeholder={placeholder}
                value={value}
                invalid={invalid}
                onChange={inputChanged}
                invalidText={invalidText}
            />
        </div>
    );
}

export {
    BeaTextInput
}