import { DATETIME_TAG, DISTANCE_TAG, SCNL_TAG, SORT_ASC, TableHeader } from "../TableUtils"

///////// ARRIVALS TABLE
export const UNCERTAINTY_TAG = 'uncertainty'
export const POLARITY_TAG = 'polarity'
export const EVALUATION_TAG = 'evaluation'
export const PHASE_TAG = 'phase'
export const AZ_TAG = 'az'
export const REST_ANGLE_TAG = 'rest_angle'
export const RESIDUAL_TAG = 'residual'
export const WEIGHT_TAG = 'weight'

export interface EventDetailArrival { 
    id: string,  
    [SCNL_TAG]: string, 
    [DATETIME_TAG]: string, 
    [UNCERTAINTY_TAG]: string, 
    l_unc: number, 
    u_unc: number, 
    [POLARITY_TAG]: string, 
    [EVALUATION_TAG]: string, 
    [PHASE_TAG]: string, 
    [AZ_TAG]: string, 
    a: number, 
    [DISTANCE_TAG]: string, 
    d: number, 
    [REST_ANGLE_TAG]: string, 
    ra: number, 
    [RESIDUAL_TAG]: string, 
    r: number, 
    [WEIGHT_TAG]: string, 
    w: number}

export const origindetail_arrivals_headers: Array<TableHeader> = [
    {
        tag: SCNL_TAG,
        header: 'origindetail_arrivals_scnl',
        sortable: true,
    },
    {
        tag: DATETIME_TAG,
        header: 'origindetail_arrivals_datetime',
        sortable: true,
    },
    {
        tag: UNCERTAINTY_TAG,
        header: 'origindetail_arrivals_uncertainty',
        sortable: true,        
    },
    {
        tag: POLARITY_TAG,
        header: 'origindetail_arrivals_polarity',
        sortable: true,        
    },
    {
        tag: EVALUATION_TAG,
        header: 'origindetail_arrivals_evaluation',
        sortable: true,        
    },
    {
        tag: PHASE_TAG,
        header: 'origindetail_arrivals_phase',
        sortable: true,        
    },
    {
        tag: AZ_TAG,
        header: 'origindetail_arrivals_az',
        sortable: true,        
    },
    {
        tag: DISTANCE_TAG,
        header: 'origindetail_arrivals_distance',
        sortable: true,        
    },
    {
        tag: REST_ANGLE_TAG,
        header: 'origindetail_arrivals_restangle',
        sortable: true,        
    },
    {
        tag: RESIDUAL_TAG,
        header: 'origindetail_arrivals_residual',
        sortable: true,        
    },
    {
        tag: WEIGHT_TAG,
        header: 'origindetail_arrivals_weight',
        sortable: true,        
    }
]

export const castedEventDetailArrivalSorting = (tag: string, elements: Array<EventDetailArrival>, direction: number) => {
    

    let copy = [...elements]
    if(tag === SCNL_TAG || tag === PHASE_TAG || tag === DATETIME_TAG || tag === UNCERTAINTY_TAG || tag === POLARITY_TAG || tag === EVALUATION_TAG) {                
        return copy.sort((e1, e2) => {
            return direction === SORT_ASC
                ? e1[tag].localeCompare(e2[tag])
                : -(e1[tag].localeCompare(e2[tag]))
        })
    }

    if(tag === AZ_TAG || tag === REST_ANGLE_TAG || tag === DISTANCE_TAG || tag === RESIDUAL_TAG || tag === WEIGHT_TAG) {                
        return copy.sort((e1, e2) => {
            let v1:number = Number(e1[tag].replaceAll(/[^.\-\+\d+]/g, ""))
            let v2:number = Number(e2[tag].replaceAll(/[^.\-\+\d+]/g, ""))
            return direction === SORT_ASC 
                ? v1 - v2
                : -(v1 - v2)
        })
    }

    return elements
}

export const isArrivalsTableTag = (tag: string) => tag === SCNL_TAG || tag === DATETIME_TAG || tag === PHASE_TAG || tag === UNCERTAINTY_TAG || tag === POLARITY_TAG || tag === EVALUATION_TAG || tag === AZ_TAG || tag === REST_ANGLE_TAG || tag === DISTANCE_TAG || tag === RESIDUAL_TAG || tag === WEIGHT_TAG