import './Status.css'
import {ApiClient} from "../../services/ApiClient";
import {useEffect, useState} from "react";
import {ObjectStatus} from "../../services/network";
import {BreadCrumbComposer} from "../../components/BreadcrumbComposer/BreadcrumbComposer";
import {Link} from "react-router-dom";
import infoPageBackground from '../../assets/bea-status-header.png'
import {useTranslation} from "react-i18next";
const Status = function() {

    const [status, setStatus] = useState<undefined|ObjectStatus>()
    const {t} = useTranslation()

    const _getStatus = async function () {
        try {
            let response = await ApiClient.getApi().getStatus()
            setStatus(response?.data);
        } catch (e) {
            setStatus(undefined)
        }
    }

    useEffect(() => {
        _getStatus()
    }, [])

    return(<>
        <div className='info-page-container'>
            <div className='info-page-header'
                 style={{
                     backgroundImage: `url(${infoPageBackground})`,
                     display: 'flex',
                     alignItems: 'stretch'
            }}
            >
                <BreadCrumbComposer pageId="info" elementIds={[]} />
            </div>
            <div className='info-page-sidebar'>
                <Link to={{pathname: '/info', hash: '#contributors'}}><span>Contributors</span></Link>
                <Link to={{pathname: '/info', hash: '#sw-version'}}>Software version</Link>
                <Link to={{pathname: '/info', hash: '#be-wa-version'}}>BE & Webapp version</Link>
                <Link to={{pathname: '/info', hash: '#further-info'}}>Further information</Link>
            </div>
            <div className='info-page-body'>
                <div className='info-page-body-item'>
                    <div className='info-page-body-item-title' id='contributors'>Contributors</div>
                    <div className='info-page-body-item-content'>
                        {t('page__info__contributors')}
                    </div>
                </div>
                <div className='info-page-body-item'>
                    <div className='info-page-body-item-title' id='sw-version'>Software version</div>
                    <div className='info-page-body-item-content'>
                        {t('page__info__software_version')}
                    </div>
                </div>
                <div className='info-page-body-item'>
                    <div className='info-page-body-item-title' id='be-wa-version'>BE & Webapp version</div>
                    <div className='info-page-body-item-content'>
                        <span>{t('page__info__be_and_webapp_version')}</span>
                        <h6><strong>Bea Version</strong>: {process.env.REACT_APP_BEA_PROVENANCE_VERSION}</h6>
                        {
                            status
                                ? <>
                                    <h6><strong>api-version</strong>: {status.version ?? '--'}</h6>
                                    <h6><strong>db-port</strong>: {status["db-port"] ?? '--'}</h6>
                                    <h6><strong>db-host.reader</strong>: {status["db-host"]?.read ? status['db-host']?.read?.join(', '): '--'}</h6>
                                    <h6><strong>db-host.writer</strong>: {status["db-host"]?.write ? status['db-host']?.write?.join(', '): '--'}</h6>
                                    <h6><strong>db-name</strong>: {status["db-name"] ?? '--'}</h6>
                                </>
                                : <h6><strong>No data</strong></h6>
                        }
                    </div>
                </div>
                <div className='info-page-body-item'>
                    <div className='info-page-body-item-title' id='further-info'>Further information</div>
                    <div className='info-page-body-item-content'>
                        <span>
                            {t('page__info__further_information')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </>)
    /*return(<>
        <div className="status-page">
            <BreadCrumbComposer pageId="info" elementIds={[]} />
            <div className={'status-container'}>
                <h5>Front End</h5>
                <h6><strong>Bea Version</strong>: {process.env.REACT_APP_BEA_PROVENANCE_VERSION}</h6>
                <br />
                <h5>Back End</h5>
                {
                    status
                        ? <>
                            <h6><strong>api-version</strong>: {status.version ?? '--'}</h6>
                            <h6><strong>db-port</strong>: {status["db-port"] ?? '--'}</h6>
                            <h6><strong>db-host.reader</strong>: {status["db-host"]?.read ? status['db-host']?.read?.join(', '): '--'}</h6>
                            <h6><strong>db-host.writer</strong>: {status["db-host"]?.write ? status['db-host']?.write?.join(', '): '--'}</h6>
                            <h6><strong>db-name</strong>: {status["db-name"] ?? '--'}</h6>
                        </>
                        : <h6><strong>No data</strong></h6>
                }
            </div>
        </div>
    </>)*/
}

export {
    Status
}