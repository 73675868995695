export const exportData = (url: string, exportType: string, callback: (reason: string) => void) => {

    let mimetype = 'text/plain'
    let extension = '.txt'

    switch(exportType) {
        case 'kml': 
            mimetype = 'application/vnd.google-earth.kml+xml'; 
            extension = '.kml'
            break;
        case 'geojson':
        case 'geogsonmobile':
            mimetype = 'application/json';
            extension = '.json'
            break;
        case 'full-xml':
        case 'xml':
            mimetype = 'application/xml';
            extension = '.xml'
            break;
        case 'atom':
            mimetype = 'application/atom+xml';
            extension = '.atom'
            break;
        case 'phs':
            extension = '.phshypo71'
            break;
        case 'nll':
            extension = '.phsnll'
            break;
        case 'nll':
            extension = '.psmeca'
            break;
    }

    fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': mimetype,
        },
      })
      .then((response) => {
            console.log('ExportData => response =>', response)
            if(response.status === 200) {                
                return response.blob()
            } if(response.status === 204 || response.status === 404) {
                console.log('ExportData => 204 || 404')
                callback('dropdown__download__reason_error')
                //alert('Nessun dato trovato. Impossibile scaricare i dati')
            } else {
                console.log('ExportData => 500')
                callback('dropdown__download__reason_no_data')
                // alert('Si è verificato un errore. Impossibile scaricare i dati')
            }
        }
      )
      .then((blob) => {
        // Create blob link to download
        if(!blob) {
            return
        }

        console.log('ExportData => blob =>', blob.type)
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `FileName${extension}`,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      });
}