import {EARTHQUAKE_LIST_TABLE} from "../CustomTable/TableComposer";

const changeDisplay = function (container: HTMLElement, button: HTMLElement, show: boolean, type: string) {
    if(show) {
        console.log('changeDisplay =>', type);

        const containerHeight: number = Math.ceil(container.getBoundingClientRect().height);

        const header = document.getElementById( `${type}-header`);
        const headerTop = header?.getBoundingClientRect().bottom ?? 200;

        const content = document.getElementById( `${type}-content`);
        const contentBottom = Math.ceil(content?.getBoundingClientRect().bottom ?? 600)

        const { top, y } = button.getBoundingClientRect();
        // should the menu be visible?
        container.style.display = top < headerTop || (top > window.innerHeight - 24)? 'none' : 'block';

        // where to put the menu on the right side of the screen
        if(type === EARTHQUAKE_LIST_TABLE) {
            container.style.right = `8px`;
        } else {
            const buttonRight: number = Math.floor(button.getBoundingClientRect().right);
            container.style.left = `${buttonRight}px`;
        }

        // where to put the button wrt the button
        const buttonHeight: number = Math.ceil(button.clientHeight);
        // position for the Menu to be under the cta button
        const containerVerticalBottomPosition: number = Math.ceil(y) + buttonHeight + 10;
        // position for the Menu to be upon the cta button
        const containerVerticalTopPosition: number = Math.ceil(y) - containerHeight - 10;

        if(type === EARTHQUAKE_LIST_TABLE) {
            if (containerVerticalBottomPosition + containerHeight <= contentBottom) {
                container.style.top = `${containerVerticalBottomPosition}px`;
            } else {
                container.style.top = `${containerVerticalTopPosition}px`;
            }
        } else {
            const buttonTop: number = Math.floor(button.getBoundingClientRect().top);
            container.style.top = `${buttonTop}px`;
        }

        container.style.zIndex = '23';
    } else {
        container.style.display = 'none'
    }

}

export {
    changeDisplay
}