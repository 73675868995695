import { AxiosError } from "axios";
import { TFunction } from "i18next";
import { SETTINGS_VIEW_MODE } from "../../redux/reducers/settings.slice";
import { ApiClient } from "../../services/ApiClient";
import { RequiredError } from "../../services/network/base";
import { buildError } from "../../utils";
import {
    SETTINGS_EVENT_TABLE,
    SETTINGS_LOCALSPACE_TABLE,
    SETTINGS_MAGNITUDE_TABLE,
    SETTINGS_ORIGIN_TABLE, SETTINGS_PROVENANCE_TABLE
} from "../CustomTable/TableComposer";
import { AllowedSettingsType } from "./SettingsGenericTable";

export const fabTitle = function(tableType: string, t: TFunction): string {
    switch (tableType) {
        case SETTINGS_EVENT_TABLE:
            return t('settings_table_event_new')
        case SETTINGS_ORIGIN_TABLE:
            return t('settings_table_origin_new')
        case SETTINGS_MAGNITUDE_TABLE:
            return t('settings_table_magnitude_new')
        case SETTINGS_LOCALSPACE_TABLE:
            return t('settings_table_localspace_new')
        case SETTINGS_PROVENANCE_TABLE:
            return t('settings_table_provenance_new')
        default:
            return t('settings_table_nope_new_modal')
    }
}

export const modalTitle = function(tableType: string, mode: string): string {
    let action = mode === SETTINGS_VIEW_MODE ? 'new' : 'edit'
    let table = 'nope'
    switch (tableType) {
        case SETTINGS_EVENT_TABLE: table = 'event'; break
        case SETTINGS_ORIGIN_TABLE: table = 'origin'; break
        case SETTINGS_MAGNITUDE_TABLE: table = 'magnitude'; break
        case SETTINGS_LOCALSPACE_TABLE: table = 'localspace'; break
        case SETTINGS_PROVENANCE_TABLE: table = 'provenance'; break
        default:
            return 'settings_table_nope_new_modal'
    }
    return `settings_table_${table}_${action}_modal`
}

export const confirmModalTitle = function(tableType: string, mode: string): string {
    let action = mode === SETTINGS_VIEW_MODE ? 'new' : 'edit'
    let table = 'nope'
    switch (tableType) {
        case SETTINGS_EVENT_TABLE: table = 'event'; break
        case SETTINGS_ORIGIN_TABLE: table = 'origin'; break
        case SETTINGS_MAGNITUDE_TABLE: table = 'magnitude'; break
        case SETTINGS_LOCALSPACE_TABLE: table = 'localspace'; break
        case SETTINGS_PROVENANCE_TABLE: table = 'provenance'; break
        default:
            return 'settings_table_nope_new_confirm_modal'
    }
    return `settings_table_${table}_${action}_confirm_modal`
}

export const modalText = function(tableType: string, mode: string): string {
    return `${modalTitle(tableType, mode)}_text`
}

export const confirmModalText = function(tableType: string, mode: string): string {
    return `${confirmModalTitle(tableType, mode)}_text`
}

export const successTitle = function(tableType: string, mode: string): string {
    let action = mode === SETTINGS_VIEW_MODE ? 'new' : 'edit'
    let table = 'nope'
    switch (tableType) {
        case SETTINGS_EVENT_TABLE: table = 'event'; break
        case SETTINGS_ORIGIN_TABLE: table = 'origin'; break
        case SETTINGS_MAGNITUDE_TABLE: table = 'magnitude'; break
        case SETTINGS_LOCALSPACE_TABLE: table = 'localspace'; break
        case SETTINGS_PROVENANCE_TABLE: table = 'provenance'; break
        default:
            return 'settings_table_nope_new_modal'
    }
    return `settings_table_${table}_${action}_success`
}

export const addSuccessText = function(tableType: string, mode: string): string {
    return `${successTitle(tableType, mode)}_text`
}

export const getData = async function (
    tableType: string,
    successCallback: (data: AllowedSettingsType[]) => void,
    errorCallback: (data: string) => void,
    errorSubCallback: (data: number) => void,
    loadingCallback: (data: boolean) => void
) {
    try {
        let response;
        switch(tableType) {
            case SETTINGS_EVENT_TABLE:
                response = await ApiClient.getApi().getTypeEvent()
                break;
            case SETTINGS_ORIGIN_TABLE:
                response = await ApiClient.getApi().getTypeOrigin()
                break;
            case SETTINGS_MAGNITUDE_TABLE:
                response = await ApiClient.getApi().getTypeMagnitude()
                break;
            case SETTINGS_LOCALSPACE_TABLE:
                response = await ApiClient.getApi().getLocalspace()
                break;
            case SETTINGS_PROVENANCE_TABLE:
                response = await ApiClient.getApi().getProvenance()
                break;
            default:
                console.log('SettingsGenericTable => not implemented')
                return
        }

        let data = response?.data?.data;
        if(data) {
            successCallback(data)
        }
    } catch(e) {
        errorCallback(buildError(e as AxiosError<RequiredError>, (code:number) => errorSubCallback(code)))
    }

    loadingCallback(false)
}

export const getSuffixFromTableType = function(tableType: string): string {
    switch(tableType) {
        case SETTINGS_EVENT_TABLE:
            return 'event'
        case SETTINGS_ORIGIN_TABLE:
            return 'origin'
        case SETTINGS_MAGNITUDE_TABLE:
            return 'magnitude'
        case SETTINGS_LOCALSPACE_TABLE:
            return 'localspace'
        case SETTINGS_PROVENANCE_TABLE:
            return 'provenance'
        default:
            return ''
    }
}