import { PayloadAction } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { batch } from 'react-redux'
import { useAppSelector, useAppDispatch } from '../../../hooks/reduxCustomHooks'
import { closeCta } from '../../../redux/reducers/cta.slice'
import { joinMode, splitMode } from '../../../redux/reducers/group.slice'
import './CtaGroupOwner.css'
import { EARTHQUAKE_LIST_TABLE } from "../../CustomTable/TableComposer";
import { changeDisplay } from "../utils";

interface CtaEqListProps {

}

// this is the dropdown menu with the join/split options
// if we see this, the group.open should be equal to the id part of the cta.callerId variable.
// callerId has the following format `cta-{event.id}`
const CtaGroupOwner = ({ }: CtaEqListProps) => {

    const { t } = useTranslation();
    const cta = useAppSelector((state) => state.cta);
    const group = useAppSelector((state) => state.group);
    const dispatch = useAppDispatch();

    const updatePosition = () => {
        if(cta.callerId === '') {
            return;
        }
        let container = document.getElementById('cta-container')
        let button = document.getElementById(cta.callerId)
        if (container && button) {
            changeDisplay(container, button, cta.show, EARTHQUAKE_LIST_TABLE)
        }
    }

    window.onscroll = null
    window.onscroll = function (e: Event) {
        updatePosition()
    }

    useEffect(() => {
        updatePosition();
        let table = document.getElementById(`${EARTHQUAKE_LIST_TABLE}-content`)
        if (table) {
            table.onscroll = null
            if (cta.show) {
                table.onscroll = updatePosition
            }
        }
    }, [cta])

    const batchCallback = (cb: PayloadAction<any>) => {
        batch(() => {
            dispatch(cb);
            dispatch(closeCta());
        })
    }
    const splitCallback = () => batchCallback(splitMode({ ...group, owner: cta.callerId.replaceAll('cta-', ''), src: cta.callerId.replaceAll('cta-', '') }))
    const joinCallback = () => batchCallback(joinMode({ ...group, owner: cta.callerId.replaceAll('cta-', ''), src: cta.callerId.replaceAll('cta-', '') }))

    return (<>
        <div className='group-options-container'>
            <h4>{t('group_options')}</h4>
            <div className='divider-container'>
                <div className='divider'></div>
            </div>
            <div
                className='item'
                onClick={joinCallback}
            >
                <p>{t('join_groups')}</p>
            </div>
            <div
                className='item'
                onClick={splitCallback}
            >
                <p>{t('split_group')}</p>
            </div>
        </div>
    </>);
}

export default CtaGroupOwner