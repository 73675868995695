import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StationCoordinates } from '../../services/StationsApiClient';


const defaultStationsState: StationCoordinates[] = [];

export const stationsSlice = createSlice({
    name: 'stations',
    initialState: defaultStationsState,
    reducers: {
        addStations: (state, stationsAction: PayloadAction<StationCoordinates[]>) => {
            return [...state, ...stationsAction.payload];
        },
        resetStations: () => {
            return defaultStationsState;
        }
    }
});

export const { addStations, resetStations } = stationsSlice.actions;
export default stationsSlice.reducer;