import { InlineNotification } from "@carbon/react";
import { useKeycloak } from '@react-keycloak/web';
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import { closeStoreModal, showStoreModal } from "../../redux/reducers/confirmationModal.slice";
import { SETTINGS_EDIT_MODE, edit, view } from '../../redux/reducers/settings.slice';
import { ApiClient } from "../../services/ApiClient";
import { GetLocalspace200Response, ObjectTableLocalspace } from "../../services/network";
import { BeaInlineNotification, defaultNotificationState, onErrorCallbackHandler, onSuccessCallbackHandler } from '../../utils/InlineNotifications';
import { BeaButton } from "../BeaButton/BeaButton";
import CustomModal from '../CustomModal/CustomModal';
import { SETTINGS_LOCALSPACE_TABLE } from '../CustomTable/TableComposer';
import EmptyList from "../EmptyList/EmptyList";
import { LoaderTranslated } from "../Loader/LoaderTranslated";
import { SettingsGenericCreateForm } from '../SettingsTabs/SettingsGenericCreateForm';
import { addSuccessText, confirmModalText, confirmModalTitle, modalText, modalTitle, successTitle } from '../SettingsTabs/utils';
import { GenericTable, IHeader, Row, SORT_ASC, SortDirection, singleRow } from "./GenericTable";
import './GenericTable.css';
import { idAsc, idDesc, nameAsc, nameDesc, priorityAsc, priorityDesc } from "./sortutils";
import { Toast } from "../Toast/Toast";

interface LocalspaceTableProps {

}

function LocalspaceTable({ }: LocalspaceTableProps) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const [localspaces, setLocalspaces] = useState<ObjectTableLocalspace[] | undefined>();
    const [rows, setRows] = useState<Row[] | undefined>(undefined);
    const [showNotification, setShowNotification] = useState<BeaInlineNotification>(defaultNotificationState);

    const dispatch = useAppDispatch();
    const settings = useAppSelector((state) => state.settings);
    const [modal, setModal] = useState<boolean>(false);
    const [element, setElement] = useState<ObjectTableLocalspace>({
        name: ''
    });
    const [disabled, setDisabled] = useState<boolean>(true);

    function sortById(direction: SortDirection): void {
        sortLocalspace(direction === SORT_ASC ? idAsc : idDesc);
    }

    function sortByName(direction: SortDirection): void {
        sortLocalspace(direction === SORT_ASC ? nameAsc : nameDesc);
    }

    function sortByPriority(direction: SortDirection): void {
        sortLocalspace(direction === SORT_ASC ? priorityAsc : priorityDesc);
    }

    const tableHeaders: IHeader[] = [
        { tag: 'id_tag', label: 'ot__id__label', sortable: true, sortFunction: sortById },
        { tag: 'name_tag', label: 'ot__name__label', sortable: true, sortFunction: sortByName },
        { tag: 'description_tag', label: 'ot__description__label', sortable: false },
        { tag: 'priority_tag', label: 'ot__priority__label', sortable: true, sortFunction: sortByPriority }
    ];

    function sortLocalspace(sortingProcedure: (m1: ObjectTableLocalspace, m2: ObjectTableLocalspace) => number): void {
        if (localspaces) {
            setLocalspaces([...localspaces].sort(sortingProcedure));
        }
    }

    useEffect(() => {
        download().then(() => console.log('ok'));
    }, []);

    useEffect(() => {
        if (element) {
            if (element.name) {
                if (element.name.length > 0) {
                    setDisabled(false);
                    return;
                }
            }
        }
        setDisabled(true);
    }, [element, disabled]);

    useEffect(() => {
        if (localspaces) {
            const temp: Row[] = localspaces.map((ls) => {
                const link: string = `#`;
                return [
                    {
                        tag: 'id_tag',
                        id: `${ls.id}`,
                        element: id(link, ls)
                    },
                    {
                        tag: 'name_tag',
                        id: `${ls.id}`,
                        element: name(link, ls)
                    },
                    {
                        tag: 'description_tag',
                        id: `${ls.id}`,
                        element: description(link, ls)
                    },
                    {
                        tag: 'priority_tag',
                        id: `${ls.id}`,
                        element: priority(link, ls)
                    }
                ]
            });
            setRows(temp);
        }
    }, [localspaces, keycloak]);
    return (
        <>
            <div className={'origin-table-container'}>
                <div className={'origin-button-container'}>
                    <div>
                        {
                            !!keycloak.authenticated &&
                            <BeaButton
                                onClick={() => {
                                    dispatch(view());
                                    setModal(true);
                                }}
                                disabled={false}
                                variant={'primary'}
                                height="48px"
                                title={'create_new_localspace'} />
                        }
                    </div>
                </div>
                {
                    (localspaces && rows)
                        ? (
                            rows.length > 0
                                ? (
                                    <GenericTable
                                        headers={tableHeaders}
                                        rows={rows}
                                        gridDefinition={'sp-ls-grid'}
                                        onClickCallback={editMode}
                                    />
                                )
                                : (
                                    <EmptyList title={'No type magnitude found'} />
                                )
                        )
                        : (
                            <LoaderTranslated />
                        )
                }

                {modal &&
                    <CustomModal
                        disable={disabled}
                        cancel={() => {
                            dispatch(view());
                            setElement({
                                name: ''
                            });
                            setModal(false);
                        }}
                        lockUi={true}
                        confirm={() => {
                            dispatch(showStoreModal({
                                title: t(confirmModalTitle(SETTINGS_LOCALSPACE_TABLE, settings.mode)),
                                text: t(confirmModalText(SETTINGS_LOCALSPACE_TABLE, settings.mode)),
                                show: true,
                                cancel: () => dispatch(closeStoreModal()),
                                confirm: () => updateLocalspace().then(() => {
                                    setDisabled(true);
                                    dispatch(closeStoreModal());
                                })
                            }));
                        }}
                        show={modal}
                        title={t(modalTitle(SETTINGS_LOCALSPACE_TABLE, settings.mode))}
                        text={t(modalText(SETTINGS_LOCALSPACE_TABLE, settings.mode))}
                        children={getForm()}
                    />
                }
            </div>
            {
                showNotification.show &&
                (showNotification.kind === 'success'
                    ? <Toast
                        className='settings-notification'
                        title={t(showNotification.reason)}
                        onClose={() => setShowNotification(defaultNotificationState)}
                    />
                    : <InlineNotification
                        className="settings-notification"
                        kind={showNotification.kind}
                        lowContrast
                        actionbuttonlabel="Action"
                        ariaLabel="closes notification"
                        onClose={() => setShowNotification(defaultNotificationState)}
                        onCloseButtonClick={function noRefCheck() { }}
                        statusIconDescription="notification"
                        subtitle={t(showNotification.reason)}
                        title={t(showNotification.title)}
                    />)
            }
        </>
    )

    function id(link: string, o: ObjectTableLocalspace): JSX.Element {
        return singleRow(link, o.id ? `${o.id}` : '--', o.id ? `${o.id}` : '--');
    }

    function name(link: string, o: ObjectTableLocalspace): JSX.Element {
        return singleRow(link, o.name ? `${o.name}` : '--', o.name ? `${o.name}` : '--');
    }

    function description(link: string, o: ObjectTableLocalspace): JSX.Element {
        return singleRow(link, o.description ? `${o.description}` : '--', o.description ? `${o.description}` : '--');
    }

    function priority(link: string, o: ObjectTableLocalspace): JSX.Element {
        return singleRow(link, o.priority !== undefined ? `${o.priority}` : '--', o.priority ? `${o.priority}` : '--');
    }

    async function download() {
        try {
            const response: AxiosResponse<GetLocalspace200Response> = await ApiClient.getApi().getLocalspace();
            if (response && response.data && response.data.data) {
                setLocalspaces(response.data.data)
            }
        } catch (e) {
            setLocalspaces([]);
        }
    }

    function editMode(id: string) {
        if (id.length === 0) {
            return;
        }
        if (!localspaces) {
            return;
        }

        const e: ObjectTableLocalspace | undefined = localspaces.find((et) => `${et.id}` === id);
        if (!e) {
            return;
        }

        setElement(e);
        dispatch(edit({
            mode: SETTINGS_EDIT_MODE,
            id: id,
            type: SETTINGS_LOCALSPACE_TABLE
        }));
        setModal(true);
    }

    function getForm(): JSX.Element {

        const name: string = element ? (element.name ?? '') : '';
        const description: string = element ? (element.description ?? '') : '';

        return <SettingsGenericCreateForm
            elementName={name}
            elementDescription={description}
            onUpdateNameCallback={(name: string) => setElement({ ...element, name })}
            onUpdateDescriptionCallback={(description: string) => setElement({ ...element, description })}
        />
    }

    async function updateLocalspace(): Promise<void> {

        if (!element || !localspaces || localspaces?.length == 0) {
            return;
        }

        if (disabled) {
            return;
        }

        setDisabled(true);
        try {
            let response;
            if (settings.mode === SETTINGS_EDIT_MODE) {
                const { id } = element;
                const copyElement: ObjectTableLocalspace | undefined = localspaces.find((et) => `${et.id}` === `${id}`);
                if (!copyElement) {
                    return;
                }

                if (element.description === copyElement.description) {
                    delete element.description;
                }
                response = await ApiClient.updateApi().updateLocalspace(+settings.id, element);
            } else {
                response = await ApiClient.storeApi().addLocalspace(element);
            }

            const { status } = response;
            if (status === 200 || status === 201) {
                if (settings.mode === SETTINGS_EDIT_MODE) {
                    const { data } = (response as AxiosResponse<ObjectTableLocalspace>);
                    const index = localspaces.findIndex((d) => d.id === data.id);
                    if (index !== -1) {
                        localspaces[index] = data;
                        setLocalspaces([...localspaces]);
                    }
                } else {
                    setLocalspaces([...localspaces, response.data]);
                }

                onSuccessCallbackHandler(
                    setShowNotification, successTitle(SETTINGS_LOCALSPACE_TABLE, settings.mode), addSuccessText(SETTINGS_LOCALSPACE_TABLE, settings.mode)
                );
            } else {
                dispatch(view());
                onErrorCallbackHandler(`Error ${status}`, response.statusText, setShowNotification);
            }
        } catch (e) {
            let { response } = (e as AxiosError);
            onErrorCallbackHandler(
                response?.statusText ?? 'Timeout error',
                (response as AxiosResponse)?.data?.detail ?? 'Request was interrupted',
                setShowNotification
            );
        }
        setElement({
            name: ''
        });
        setModal(false);
        dispatch(view());

        setDisabled(false);
    }
}

export { LocalspaceTable };
