import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import { Option } from '../CustomSelector/CustomSelector'
import './PaginationFooter.css'
import { ReactComponent as DownArrowIcon } from '../../assets/down-arrow.svg'
import { ReactComponent as UpArrowIcon } from '../../assets/up-arrow.svg'
import { ReactComponent as PaginationPreviousIcon } from '../../assets/pagination-arrow-left.svg'
import { ReactComponent as PaginationNextIcon } from '../../assets/pagination-arrow-right.svg'
import {createUrlFromFilters} from "../../utils/urlFromFilters";
import {useNavigate} from "react-router-dom";
import {DEFAULT_LIMIT} from "../../utils";

export interface Pagination {
    total: number,
    last_page: number,
    from: number,
    to: number
}

interface PaginationFooterProps {
    pagination: Pagination
}

export const PaginationFooter = function({pagination}: PaginationFooterProps) {

    const options: Option[] = [
        {key: "50", value: "50"},
        {key: "100", value: "100"},
        {key: "250", value: "250"},
        {key: "500", value: "500"},
        {key: "1000", value: "1000"},
        {key: "1500", value: "1500"},
        {key: "2500", value: "2500"},
        {key: `${DEFAULT_LIMIT}`, value: `${DEFAULT_LIMIT}`},
    ]

    const defaultPages: Option[] = [
        {key: "1", value: "1"},
    ]

    const filters = useAppSelector(state => state.filters)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [pageCountOpen, setPageCountOpen] = useState(false)
    const [pageSelectionOpen, setPageSelectionOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(`${DEFAULT_LIMIT}`)
    const [pages, setPages] = useState<Option[]>(defaultPages)
    
    useEffect(() => {
        setSelectedOption(`${filters.limit ?? DEFAULT_LIMIT}`)
    }, [filters])

    useEffect(() => {
        if(pagination.last_page > 1) {
            let x: Option[] = []
            for(let i = 1; i <= pagination.last_page; i++) {
                x = [...x, { key: `${i}`, value: `${i}`}]
            }
            setPages(x)
        }
    }, [pagination]);

    const setBottom = (id: string) => {
        let pdc = document.getElementById(id);
        if(pdc) {
            pdc.style.bottom=`${16}px`;
        }
    }

    const openPageCountDropDown = () => {
        setBottom('pd-content');
        setPageCountOpen(!pageCountOpen);
    }

    const openPageSelectionDropDown = () => {
        setBottom('ps-content');
        setPageSelectionOpen(!pageSelectionOpen);
    }

    const selectPageCountOption = (key: string) => {
        navigate(`/earthquakelist?${createUrlFromFilters({
            ...filters,
            limit: Number(key),
            page: 1,
        })}`)
        setPageCountOpen(false)
    }

    const selectPageSelectionOption = (key: string) => {
        console.log('selectPageSelectionOption =>', key)
        navigate(`/earthquakelist?${createUrlFromFilters({
            ...filters,
            page: Number(key)
        })}`)
        setPageSelectionOpen(false)
    }

    const previous = () => {
        if(filters.page === 1)
            return
        navigate(`/earthquakelist?${createUrlFromFilters({
            ...filters,
            page: filters.page - 1
        })}`)
    }

    const next = () => {
        if(filters.page+1 > pagination.last_page)
            return
        navigate(`/earthquakelist?${createUrlFromFilters({
            ...filters,
            page: filters.page + 1
        })}`)
    }


    return (<>
        <div className='pagination-footer'>
            <div className='pages-count-selection'>
                <span className='pagination-label'>{t('page__list_earthquakes__pagination__events_for_page')}</span>
                <div className='pagination-dropdown' onClick={openPageCountDropDown}>
                    <div className='pages-count'>
                        {selectedOption}                        
                    </div>
                    {
                            pageCountOpen 
                                ? <UpArrowIcon className="arrow"/> 
                                : <DownArrowIcon className="arrow"/>
                        }
                    <div id="pd-content" className={`pagination-dropdown-content ${pageCountOpen && 'show'}`}>
                        {
                            options.map((o) => <div key={o.key} onClick={() => selectPageCountOption(o.key)}>{o.value}</div>)
                        }
                    </div>
                </div>      
            </div>
            <div className='vertical-divider'></div>
            <span className='pagination-label' style={{width: '100%'}}>{t('page__list_earthquakes__pagination__recap', {start: pagination.from, end: pagination.to, total: pagination.total})}</span>
            <div className='vertical-divider'></div>
            <div className='pages-count-selection'>
                <div className='pagination-dropdown' onClick={openPageSelectionDropDown}>
                    <div className='pages-count'>
                        {filters.page ?? 1}                        
                    </div>
                    {
                            pageCountOpen 
                                ? <UpArrowIcon className="arrow"/> 
                                : <DownArrowIcon className="arrow"/>
                        }
                    <div id="ps-content" className={`pagination-dropdown-content ${pageSelectionOpen && 'show'}`}>
                        {
                            pages.map((o) => <div key={o.key} onClick={() => selectPageSelectionOption(o.key)}>{o.value}</div>)
                        }
                    </div>
                </div> 
                <div className='ml-8 mr-16 pagination-label'>
                    di {pagination.last_page} pagine
                </div>
                <div className="page-swipe-button" onClick={previous}>
                    <PaginationPreviousIcon />
                </div>
                <div className='vertical-divider-8'></div>
                <div className="page-swipe-button mr-16" onClick={next}>
                    <PaginationNextIcon />
                </div>
            </div>
        </div>
    </>)
}