export const SORT_ASC = 0;
export const SORT_DESC = 1;

export interface TableHeader { header: string, tag: string, sortable: boolean, cols?: number }

export const ROW_MODE_ADD = 'add'
export const ROW_MODE_NORMAL = ''

///////// COMMON LIST
export const PRODUCTS_TAG = 'products'
export const MAGNITUDE_TAG = 'magnitude'    // EarthquakeList, StationMagnitude
export const SCNL_TAG = 'scnl'              // StationMagnitudes, ArrivalsTable
export const DATETIME_TAG = 'datetime'      // EarthquakeList, ArrivalsTable, OriginTable
export const TYPOLOGY_TAG = 'typology'      // OriginTable, StationMagnitudes
export const COORDINATES_TAG = 'coordinates'
export const LOCALSPACE_TAG = 'localspace'
export const VERSION_TAG = 'version'
export const EVENT_TYPE_TAG = 'event_type'
export const DISTANCE_TAG = 'distance'


///////// EARTHQUAKE LIST 
export const ZONE_TAG = 'zone'
export const LAT_TAG = 'lat'
export const LON_TAG = 'lon'
export const DEPTH_TAG = 'depth' // used for quality
export const ID_TAG = 'id'
export const CTA_TAG = 'cta'
export const EXPAND_TAG = 'expand'
export const OPEN_GROUP_TAG = 'opengroup'

export interface EarthquakeTableEvent {
    htmlId: string,
    id: string, datetime: string, magnitude: string, zone: string, depth: string, lat: string, lon: string, mag: number, elementGroupId: number, [PRODUCTS_TAG]?:string|null, [COORDINATES_TAG]: string, [LOCALSPACE_TAG]: string, [VERSION_TAG]: string
}

export const earthquake_headers: Array<TableHeader> = [
    {
        header: 'page__list_earthquakes__table__datetime',
        sortable: true,
        tag: DATETIME_TAG,
    },
    {
        header: 'page__list_earthquakes__table__magnitude',
        sortable: true,
        tag: MAGNITUDE_TAG,
    },
    {
        header: 'page__list_earthquakes__table__zone',
        sortable: false,
        tag: ZONE_TAG,
    },
    {
        header: 'page__list_earthquakes__table__lat',
        sortable: false,
        tag: COORDINATES_TAG,
    },
    {
        header: 'page__list_earthquakes__table__depth',
        sortable: false,
        tag: DEPTH_TAG,
    },
    {
        header: 'page__list_earthquakes__table__products',
        sortable: false,
        tag: PRODUCTS_TAG,
    },
    {
        header: 'page__list_earthquakes__table__localspace',
        sortable: false,
        tag: LOCALSPACE_TAG,
    },
    {
        header: 'page__list_earthquakes__table__version',
        sortable: false,
        tag: VERSION_TAG,
    },
    {
        header: 'empty',
        sortable: false,
        tag: EVENT_TYPE_TAG,
    },
]

export const auth_earthquake_headers: Array<TableHeader> = [
    {
        tag: OPEN_GROUP_TAG,
        header: 'empty',
        sortable: false,
    },
    {
        header: 'page__list_earthquakes__table__id',
        sortable: true,
        tag: ID_TAG,
    },
    ...earthquake_headers,
    {
        header: 'empty',
        sortable: false,
        tag: CTA_TAG,
    },
]

export const auth_earthquake_magnitudes_headers: Array<TableHeader> = [
    {
        header: 'page__list_earthquakes__table__id',
        sortable: true,
        tag: ID_TAG,
    },
    ...earthquake_headers,
]

export const evaluateRowColor = (e: EarthquakeTableEvent): string => {
    const magnitude = e.mag
    if (magnitude < 2.5) return ''
    if (magnitude >= 2.5 && magnitude < 4) return 'earthquakelist-medium-eq'
    if (magnitude >= 4 && magnitude < 5.5) return 'earthquakelist-big-eq'
    return 'earthquakelist-verybig-eq'
}

///////// TDMTSTATION LIST 
export const STATION_TAG = 'station'
export const EP_DISTANCE_TAG = 'ep_distance'
export const EP_AZ_TAG = 'ep_az'
export const VARIANCE_TAG = 'variance_reduction'
export const ZCOR_TAG = 'zcor'

export interface OriginDetailMwTdmtStation { [DATETIME_TAG]: string, [STATION_TAG]: string, [EP_DISTANCE_TAG]: string, d: number, [EP_AZ_TAG]: string, a: number, [VARIANCE_TAG]: string, v: number, [ZCOR_TAG]: string, z: number }

export const origindetail_mw_tdmtstation_headers: Array<TableHeader> = [
    {
        tag: STATION_TAG,
        header: 'origindetail_station',
        sortable: true,
    },
    {
        tag: EP_DISTANCE_TAG,
        header: 'origindetail_distance',
        sortable: true,
    },    
    {
        tag: EP_AZ_TAG,
        header: 'origindetail_ep_az',
        sortable: true,
    },    
    {
        tag: VARIANCE_TAG,
        header: 'origindetail_variance',
        sortable: true,
    },    
    {
        tag: ZCOR_TAG,
        header: 'origindetail_zcor',
        sortable: true,
    },
]