import { InlineNotification } from '@carbon/react'
import { useKeycloak } from '@react-keycloak/web'
import { AxiosError, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactModal from 'react-modal'
import { ReactComponent as IconPreferredStar } from '../../assets/preferred-star-big.svg'
import { ReactComponent as IconStar } from '../../assets/star-white.svg'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks'
import { closeStoreModal, showStoreModal } from '../../redux/reducers/confirmationModal.slice'
import { removeCustomTableGroup } from '../../redux/reducers/customtable.slice'
import { fetchEarthquakeEvent } from '../../redux/reducers/earthquake.slice'
import { ApiClient } from '../../services/ApiClient'
import { ObjectAmplitude, ObjectEvent, ObjectMagnitude, ObjectStationmagnitude, UpdateEventRequest } from '../../services/network'
import { BeaInlineNotification, defaultNotificationState, onErrorCallbackHandler, onSuccessCallbackHandler } from '../../utils/InlineNotifications'
import BeaDropdown from '../BeaDropdown/BeaDropdown'
import { ORIGINDETAIL_ML_STATIONMAGNITUDE, buildTable } from '../CustomTable/TableComposer'
import { OriginDetailMlStationMagnitude } from '../CustomTable/flavor/CustomStationMagnitudesTable'
import { DoubleRowInfo } from '../DoubleRowInfo/DoubleRowInfo'
import EmptyList from '../EmptyList/EmptyList'
import { LoaderTranslated } from '../Loader/LoaderTranslated'
import { ReactComponent as LeftArrowIconOff } from './../../assets/chevron-left-off.svg'
import { ReactComponent as LeftArrowIconOn } from './../../assets/chevron-left-on.svg'
import { ReactComponent as RightArrowIconOff } from './../../assets/chevron-right-off.svg'
import { ReactComponent as RightArrowIconOn } from './../../assets/chevron-right-on.svg'
import { ReactComponent as CloseIcon } from './../../assets/fm-close.svg'
import './MagnitudeModal.css'
import { Toast } from '../Toast/Toast'


export interface MagnitudeModalProps {
    show: boolean;
    onClose: () => void;
    magnitude?: ObjectMagnitude;
    mode: string;
    earthquake?: ObjectEvent;
    magnitudes: ObjectMagnitude[];
    next?: () => void;
    prev?: () => void;
}

const evaluateAmplitude = (amp1: number, amp2: number) => {
    // from caravel:
    // (abs($amp1 - $amp2) / (2 * pow(10, 3)));    
    return Math.abs(amp1 - amp2) / (2 * Math.pow(10, 3))
}

const evaluatePeriod = (period?: number, time1?: string, time2?: string) => {
    // from caravel:
    /* 
        if($period > 0){ return $period; }
        else { return (strtotime($time1) - strtotime($time2)) * 2; }
    */
    if (period && period > 0) return period
    if (!time1 || !time2)
        return 0
    return ((new Date(time1).getTime() - new Date(time2).getTime()) * 2)
}

interface RenderMagnitudeMlModalProps {
    body: Array<OriginDetailMlStationMagnitude> | undefined
}

const RenderMagnitudeMlModal = ({ body }: RenderMagnitudeMlModalProps) => {
    return (<>
        {
            body
                ? buildTable(ORIGINDETAIL_ML_STATIONMAGNITUDE, false, body)
                : <LoaderTranslated />
        }
    </>)
}

const MagnitudeModal = ({ show, mode, onClose, magnitude, magnitudes, prev, next }: MagnitudeModalProps) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [body, setBody] = useState<Array<OriginDetailMlStationMagnitude> | undefined>();
    const [index, setIndex] = useState<number>(0);
    const { keycloak } = useKeycloak();
    const [isCatalog, setCatalog] = useState<boolean>(false);
    const [networkRequest, setNetworkRequest] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState<BeaInlineNotification>(defaultNotificationState)
    const earthquake = useAppSelector((state) => state.earthquake);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (earthquake) {
            const { localspace } = earthquake;
            if (localspace) {
                setCatalog(localspace.environment === 'catalog');
            }
        }
    }, [earthquake]);


    function typology(amplitude?: ObjectAmplitude): string {
        if (!amplitude || !amplitude.type_amplitude) {
            return '--';
        }

        const { type_amplitude } = amplitude;
        const name: string = type_amplitude.name;
        const unit: string | undefined | null = type_amplitude.unit;
        return unit ? `${name} (${unit})` : name;
    }

    useEffect(() => {
        if (magnitude && magnitude.id) {
            if (magnitudes) {
                const index: number = magnitudes.findIndex((m) => m.id === magnitude.id);
                setIndex(index >= 0 ? index : 0);
            }
        }
    }, [magnitude, magnitudes])

    useEffect(() => {
        if (mode === 'ML') {
            console.log('MagnitudeModal => useEffect[magnitude] =>', magnitude?.stationmagnitudes)
            let stationmagnitudes: Array<ObjectStationmagnitude> = magnitude?.stationmagnitudes ?? []
            let odml: Array<OriginDetailMlStationMagnitude> = stationmagnitudes.map(sm => ({
                datetime: sm.inserted ?? '--',
                scnl: `${sm.amplitude?.net}.${sm.amplitude?.sta}.${sm.amplitude?.cha}.${sm.amplitude?.loc}` ?? '--',
                mag: sm.mag ?? -1,
                magnitude: `${sm.type_magnitude} ${sm.mag}`,
                period: `${evaluatePeriod(sm.amplitude?.period, sm.amplitude?.time1, sm.amplitude?.time2)}`,
                per: evaluatePeriod(sm.amplitude?.period, sm.amplitude?.time1, sm.amplitude?.time2),
                category: sm.amplitude?.type_amplitude?.category ?? '--',
                unit: sm.amplitude?.type_amplitude?.unit ?? '--',
                timeref_window: sm.amplitude?.timewindow_reference ?? '--',
                amp: evaluateAmplitude(sm.amplitude?.amp1 ?? Number.MIN_VALUE, sm.amplitude?.amp2 ?? Number.MIN_VALUE),
                amplitude: `${evaluateAmplitude(sm.amplitude?.amp1 ?? Number.MIN_VALUE, sm.amplitude?.amp2 ?? Number.MIN_VALUE)}` ?? '--',
                typology: typology(sm.amplitude),
                is_used: sm.is_used ? sm.is_used : false
            }))
            setBody(odml)
        }
    }, [magnitude])

    useEffect(() => {
        setLoading(false);
    }, [body])

    function handlePrev(): void {
        if (prev) {
            prev();
        }
    }

    function handleNext(): void {
        if (next) {
            next();
        }
    }

    return (<>
        <ReactModal
            isOpen={show}
            ariaHideApp={false}
            onRequestClose={onClose}
            className={"magnitude-modal"}
            overlayClassName="magnitude-overlay"
        >
            {
                showNotification.show &&
                
                (showNotification.kind === 'success'
                    ? <Toast
                        className='notification-hover'
                        title={t(showNotification.reason)}
                        onClose={() => setShowNotification(defaultNotificationState)}
                    />
                    : <InlineNotification
                    className='notification-hover'
                    kind={showNotification.kind}
                    lowContrast
                    actionbuttonlabel='Action'
                    ariaLabel='closes notification'
                    onClose={() => setShowNotification(defaultNotificationState)}
                    onCloseButtonClick={function noRefCheck() { }}
                    statusIconDescription='notification'
                    subtitle={t(showNotification.reason)}
                    title={showNotification.title}
                />)
            }
            <div className='modal-switch'>
                <div className='content-switcher-container'>
                    <div className='content-switcher'>
                        <div className='command' onClick={handlePrev}>
                            {index === 0 ? <LeftArrowIconOff /> : <LeftArrowIconOn />}
                        </div>
                        <div className='command' onClick={handleNext}>
                            {index === magnitudes?.length - 1 ? <RightArrowIconOff /> : <RightArrowIconOn />}
                        </div>
                        <span className='header'>
                            {t('magnitude_modal_cs', { id: `${magnitude?.id}`, mag: `${magnitude?.type_magnitude} ${magnitude?.mag?.toFixed(1)}` })}
                            {` -/+ [${magnitude?.lower_uncertainty ?? '--'}, ${magnitude?.upper_uncertainty ?? '--'}]`}
                        </span>
                    </div>
                </div>
                <div className='close' onClick={onClose}>
                    <CloseIcon className='icon' />
                </div>
            </div>
            <div className='modal-resume'>
                <DoubleRowInfo title={t('page__origin_detail__author')} content={`${magnitude?.localspace?.name ?? '--'}`} stationMagnitudes />
                <DoubleRowInfo title={t('page__origin_detail__author_id')} content={`${magnitude?.id_localspace ?? '--'}`} stationMagnitudes />
                <DoubleRowInfo title={t('page__origin_detail__date')} content={`${dayjs(magnitude?.inserted).format('DD MMM YYYY, HH:mm:ss (UTC)') ?? '--'}`} stationMagnitudes />
                <DoubleRowInfo title={t('Qty INGV / Qty')} content={`${magnitude?.mag_quality ?? '--'} / ${magnitude?.quality ?? '--'}`} stationMagnitudes />
                <DoubleRowInfo title={t('Nsta / Ncha')} content={`${magnitude?.nsta ?? '--'} / ${magnitude?.ncha ?? '--'}`} stationMagnitudes />
                {
                    isPreferred()
                        ? <BeaDropdown
                            disabled={true}
                            text={(isPreferred() ? 'bc__preferred' : 'bc__make_preferred')}
                            icon={<IconPreferredStar />}
                        />
                        : <BeaDropdown
                            text={'bc__make_preferred'}
                            icon={<IconStar />}
                            callback={() => (!!keycloak.authenticated && !isCatalog) && makePreferredConfirmation()}
                        />
                }
            </div>
            <div className={'station-magnitudes-header-container'}>
                <span className={'station-magnitudes-header'}>Station Magnitude</span>
            </div>
            <div className='modal-container' id='modal-container'>
                {(loading || !body) && <LoaderTranslated />}
                {(!loading && mode === 'ML' && body && body.length > 0) && <RenderMagnitudeMlModal body={body} />}
                {(!loading && mode === 'ML' && (body && body.length === 0)) && <EmptyList title={t('modal__magnitude_ml_detail__stationmagnitudes__not_found')} description='' />}
            </div>
        </ReactModal>
    </>);

    function isPreferred(): boolean {
        if (earthquake && magnitude) {
            const { preferred_magnitude_id } = earthquake;
            if (preferred_magnitude_id) {
                return preferred_magnitude_id === magnitude.id;
            }
        }

        return false;
    }

    function makePreferredConfirmation(): void {
        dispatch(showStoreModal({
            show: true,
            cancel: () => dispatch(closeStoreModal()),
            confirm: makePreferred,
            title: 'ot__make_preferred__confirmation',
            text: 'ot__make_preferred__confirmation__text'
        }))
    }

    async function makePreferred(): Promise<void> {
        if (isCatalog) {
            dispatch(closeStoreModal());
            return;
        }
        if (!!!keycloak.authenticated) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__not_authorized'), setShowNotification);
            return;
        }
        if (!origin) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__no_origin'), setShowNotification);
            return;
        }
        if (!earthquake || !earthquake.id) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__no_earthquake'), setShowNotification);
            return;
        }

        if (networkRequest) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__operation_in_progress_already'), setShowNotification);
            return;
        }

        if (!magnitude) {
            dispatch(closeStoreModal());
            return;
        }

        if (magnitude.id === undefined || magnitude.id === null) {
            dispatch(closeStoreModal());
            return
        }

        setNetworkRequest(true);

        let request: UpdateEventRequest = {
            data: {
                event: {
                    preferred_magnitude_id: +magnitude.id
                }
            }
        }

        try {
            let response = await ApiClient.updateApi().updateEvent(+earthquake?.id, request);
            if (response.status === 200) {
                //  function(callback: (data: BeaInlineNotification) => void, title?: string, reason?: string)
                onSuccessCallbackHandler(
                    setShowNotification,
                    t('bc__make_preferred__success'),
                    t('bc__make_preferred__success_text_magnitude', { id: `${earthquake.id}`, magnitude: magnitude.id })
                );

                // setTimeout(() => setShowNotification(defaultNotificationState), 10000);
                resetItemTable();
                getData();
            } else {
                onErrorCallbackHandler(t('bc__make_preferred__failed'), 'Unknown Error ' + response.status, setShowNotification);
            }
        } catch (e) {
            let { response } = (e as AxiosError)
            onErrorCallbackHandler(t('bc__make_preferred__failed'), (response as AxiosResponse)?.data?.detail ?? 'Unknown error', setShowNotification);
        }
        setNetworkRequest(false);
        dispatch(closeStoreModal());
    }

    function resetItemTable(): void {
        const { event_group_id } = earthquake;
        if (event_group_id === undefined || event_group_id === null || event_group_id === 0) {
            dispatch(removeCustomTableGroup(`${earthquake.id}`));
        } else {
            dispatch(removeCustomTableGroup(`${event_group_id}`));
        }
    }

    async function getData(): Promise<void> {
        if (earthquake && earthquake.id) {
            dispatch(fetchEarthquakeEvent(earthquake.id));
        }
    }
}

export default MagnitudeModal