import { TableHeader, TYPOLOGY_TAG, DATETIME_TAG, LAT_TAG, LON_TAG, DEPTH_TAG, MAGNITUDE_TAG, VERSION_TAG, COORDINATES_TAG, CTA_TAG, PRODUCTS_TAG } from "../TableUtils"

export const PUBLISHED_TAG = 'published'

export interface EventDetailOriginsTable {earthquakeId:string, preferred: boolean, id: string, inserted: string, [VERSION_TAG]: string, [TYPOLOGY_TAG]: string, [DATETIME_TAG]: string, [LAT_TAG]: string, [LON_TAG]: string, [PRODUCTS_TAG]?: string, [DEPTH_TAG]: string, [MAGNITUDE_TAG]: JSX.Element, [PUBLISHED_TAG]: string, mag: string }

export const eventdetail_origintable_headers: Array<TableHeader> = [
    {
        tag: DATETIME_TAG,
        header: 'eventdetail_origintable_datetime',
        sortable: false,
    }, 
    {
        tag: MAGNITUDE_TAG,
        header: 'eventdetail_origintable_magnitude',
        sortable: false,        
    },
    {
        tag: COORDINATES_TAG,
        header: 'eventdetail_origintable_coords',
        sortable: false,        
    },
    {
        tag: DEPTH_TAG,
        header: 'eventdetail_origintable_depth',
        sortable: false,        
    },
    {
        tag: TYPOLOGY_TAG,
        header: 'eventdetail_origintable_typology',
        sortable: false,
    },
    {
        tag: PUBLISHED_TAG,
        header: 'eventdetail_origintable_published',
        sortable: false,        
    },
    {
        tag: VERSION_TAG,
        header: 'eventdetail_origintable_version',
        sortable: false,
    },
    {
        tag: PRODUCTS_TAG,
        header: 'eventdetail_origintable_products',
        sortable: false,        
    },
]

export const auth_eventdetail_origintable_headers: Array<TableHeader> = [
    ...eventdetail_origintable_headers,
    {
        tag: CTA_TAG,
        header: '',
        sortable: false,
    }

] 