import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ObjectFocalmechanism } from '../../services/network'
import { buildOriginDetailMwTdmtStation } from '../CustomTable/TableComposer'
import { DATETIME_TAG, EP_AZ_TAG, EP_DISTANCE_TAG, OriginDetailMwTdmtStation, STATION_TAG, VARIANCE_TAG, ZCOR_TAG } from '../CustomTable/TableUtils'
import EmptyList from '../EmptyList/EmptyList'
import './TdmtStationsTable.css'

interface TdmtStationTableProps {
    focalMechanism?: ObjectFocalmechanism,
}

const TdmtStationsTable = ({focalMechanism}:TdmtStationTableProps) => {

    const [tdmtStations, seTtdmtStations] = useState<Array<OriginDetailMwTdmtStation>>([])
    const {t} = useTranslation()

    useEffect(() => {       
        if(focalMechanism){ 
            let {momenttensor} = focalMechanism
            if(momenttensor) {
                console.log('TdmtStationsTable => momenttensor')
                let {tdmt} = momenttensor
                if(tdmt) {
                    console.log('TdmtStationsTable => tdmt')
                    let {stations} = tdmt
                    if(stations){
                        seTtdmtStations(stations.map((s, index) => ({
                            [DATETIME_TAG]: `0${index}`,
                            [STATION_TAG]: s.sta ?? '--',
                            [EP_DISTANCE_TAG]: `${s.r}` ?? '--',
                            d: s.r ?? '-1',
                            [EP_AZ_TAG]: `${s.azi}` ?? '--',
                            a: s.azi ?? -1,
                            [VARIANCE_TAG]: `${s.vr}` ?? '--',
                            v: s.vr ?? -1,
                            [ZCOR_TAG]: `${s.zcor}` ?? '--',
                            z: s.zcor ?? -1
                        } as OriginDetailMwTdmtStation)))
                    }
                }
            }
        }
        
    }, [focalMechanism])

    useEffect(() => { 
        console.log('TdmtStationsTable => tdmtStations =>', tdmtStations)
    }, [tdmtStations])

    return (<>
        {
            tdmtStations.length > 0
                ? buildOriginDetailMwTdmtStation(tdmtStations)
                : <EmptyList title={t('origindetail_tdmt_not_found')} small/>
        }
    </>)
}

export default TdmtStationsTable