import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks/reduxCustomHooks";
import { Filters, defaultFilters } from "../../../../redux/reducers/filters.slice";
import { close } from "../../../../redux/reducers/filtersmodal.slice";
import { createUrlFromFilters } from "../../../../utils/urlFromFilters";
import { BeaTextInput } from "../../../BeaTextInput/BeaTextInput";
import { DATETIME_TAG, SORT_DESC } from "../../../CustomTable/TableUtils";

export interface IQueryFiltersOtherProps {
    filterOther: Filters;
    onUpdate: (newFilters: Filters) => void;
}

// origindirectlinktoevent=true&magnitudedirectlinktoorigin=false&magnitudedirectlinktoevent=true&wheretypeeventnamein=nuclear explosion,avalanche&wheretypeeventnamenotin=not existing&orpolygon=12,42,13,42,13,43,12,43,12,42|12.3,42.3,13.3,42.3,13.3,43.3,12.3,43.3,12.3,42.3&notinpolygon=12.5,42,12.5,43,13,48,12.5,42|12.2,41.8,13.2,41.8,13.2,42.8,12.2,42.8,12.2,41.8&wherepolygonnamein=area_confine_italia,area_vulcanica_italia&minnphtot=2&mindistance=5&eventupdatedafter=2024-02-29T00:00:00.000Z&originupdatedafter=2024-02-29T00:00:00.000Z&magnitudeupdatedafter=&2024-02-29T00:00:00.000Z&updatedafteroperator=or
// origindirectlinktoevent=true&magnitudedirectlinktoorigin=false&magnitudedirectlinktoevent=true
// wheretypeeventnamein=nuclear explosion,avalanche&wheretypeeventnamenotin=not existing
// orpolygon=12,42,13,42,13,43,12,43,12,42|12.3,42.3,13.3,42.3,13.3,43.3,12.3,43.3,12.3,42.3&notinpolygon=12.5,42,12.5,43,13,48,12.5,42|12.2,41.8,13.2,41.8,13.2,42.8,12.2,42.8,12.2,41.8&wherepolygonnamein=area_confine_italia,area_vulcanica_italia
// minnphtot=2&mindistance=5
// eventupdatedafter=2024-02-29T00:00:00.000Z&originupdatedafter=2024-02-29T00:00:00.000Z&magnitudeupdatedafter=&2024-02-29T00:00:00.000Z&updatedafteroperator=or
export const SUPPORTED_KEYS: string[] = [
    'orpolygon',
    'notinpolygon',
    'wherepolygonnamein',
    'origindirectlinktoevent', // booelan
    'magnitudedirectlinktoorigin', // booelan
    'magnitudedirectlinktoevent', // booelan
    'eventupdatedafter',
    'originupdatedafter',
    'magnitudeupdatedafter',
    'updatedafteroperator',
    // v3.4.0
    'minnphtot',
    'mindistance',
    // v3.9.0
    'wheretypeeventnamein',
    'wheretypeeventnamenotin'
];

export function fromFiltersToOthers(filters: Filters): string {
    console.log('fromFiltersToOthers', filters);
    let tokens: string[] = [];
    if (filters.orpolygon) { tokens = [...tokens, `orpolygon=${filters.orpolygon}`]; }
    if (filters.notinpolygon) { tokens = [...tokens, `notinpolygon=${filters.notinpolygon}`]; }
    if (filters.wherepolygonnamein) { tokens = [...tokens, `wherepolygonnamein=${filters.wherepolygonnamein}`]; }
    if (filters.origindirectlinktoevent !== undefined) {
        tokens = [...tokens, `origindirectlinktoevent=${filters.origindirectlinktoevent}`];
    }
    if (filters.magnitudedirectlinktoorigin !== undefined) {
        tokens = [...tokens, `magnitudedirectlinktoorigin=${filters.magnitudedirectlinktoorigin}`];
    }
    if (filters.magnitudedirectlinktoevent !== undefined) {
        tokens = [...tokens, `magnitudedirectlinktoevent=${filters.magnitudedirectlinktoevent}`];
    }
    if (filters.eventupdatedafter) { tokens = [...tokens, `eventupdatedafter=${filters.eventupdatedafter}`]; }
    if (filters.originupdatedafter) { tokens = [...tokens, `originupdatedafter=${filters.originupdatedafter}`]; }
    if (filters.magnitudeupdatedafter) { tokens = [...tokens, `magnitudeupdatedafter=${filters.magnitudeupdatedafter}`]; }
    if (filters.updatedafteroperator) { tokens = [...tokens, `updatedafteroperator=${filters.updatedafteroperator}`]; }
    // v3.4.0
    if (filters.minnphtot) { tokens = [...tokens, `minnphtot=${filters.minnphtot}`] }
    if (filters.mindistance) { tokens = [...tokens, `mindistance=${filters.mindistance}`] }
    // v3.9.0
    if (filters.wheretypeeventnamein) { tokens = [...tokens, `wheretypeeventnamein=${filters.wheretypeeventnamein}`] }
    if (filters.wheretypeeventnamenotin) { tokens = [...tokens, `wheretypeeventnamenotin=${filters.wheretypeeventnamenotin}`] }
    console.log('fromFiltersToOthers =>', tokens.join('&'));
    return tokens.join('&');
}

function QueryFiltersOther({ filterOther, onUpdate }: IQueryFiltersOtherProps): JSX.Element {

    const [others, setOthers] = useState<string | undefined>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    
    useEffect(() => {
        setOthers(fromFiltersToOthers(filterOther));
    }, [filterOther]);

    useEffect(() => {
        const x = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                primaryAction(filterOther, others);
            }
        }

        document.addEventListener('keydown', x);

        console.log('primaryAction => useffect update =>', filterOther.minmag);

        return () => {
            document.removeEventListener('keydown', x);
        }
    }, [filterOther, others]);

    function update(filtersString: string): void {
        setOthers(filtersString);
    }

    function getValue(data: string[], key: string): string | boolean | undefined {
        const filtered: string[] = data.filter((d) => d.startsWith(key));
        if (filtered.length === 0) {
            return undefined;
        }

        if (key === 'origindirectlinktoevent' || key === 'magnitudedirectlinktoorigin' ||
            key === 'magnitudedirectlinktoevent'
        ) {
            if (filtered[0].split('=')[1] === 'true') {
                return true;
            }
            if (filtered[0].split('=')[1] === 'false') {
                return false;
            }
            return undefined;
        }
        return filtered[0].split('=')[1];
    }

    function triggerUpdate(): void {
        if (!others) {
            return;
        }
        let newFilters: Filters = filterOther;
        const tokens: string[] = others.split('&')
            .filter((t) => t.split('=').length === 2)
            .filter((t) => SUPPORTED_KEYS.some((sk) => t.startsWith(`${sk}=`)));
        newFilters = {
            ...newFilters,
            orpolygon: getValue(tokens, 'orpolygon') as string | undefined,
            notinpolygon: getValue(tokens, 'notinpolygon') as string | undefined,
            wherepolygonnamein: getValue(tokens, 'wherepolygonnamein') as string | undefined,
            origindirectlinktoevent: getValue(tokens, 'origindirectlinktoevent') as boolean | undefined,
            magnitudedirectlinktoorigin: getValue(tokens, 'magnitudedirectlinktoorigin') as boolean | undefined,
            magnitudedirectlinktoevent: getValue(tokens, 'magnitudedirectlinktoevent') as boolean | undefined,
            eventupdatedafter: getValue(tokens, 'eventupdatedafter') as string | undefined,
            originupdatedafter: getValue(tokens, 'originupdatedafter') as string | undefined,
            magnitudeupdatedafter: getValue(tokens, 'magnitudeupdatedafter') as string | undefined,
            updatedafteroperator: getValue(tokens, 'updatedafteroperator') as string | undefined,
            // v3.4.0
            minnphtot: getValue(tokens, 'minnphtot') as string | undefined,
            mindistance: getValue(tokens, 'mindistance') as string | undefined,
            // v3.9.0
            wheretypeeventnamein: getValue(tokens, 'wheretypeeventnamein') as string | undefined,
            wheretypeeventnamenotin: getValue(tokens, 'wheretypeeventnamenotin') as string | undefined
        }

        setOthers(fromFiltersToOthers(newFilters));
        onUpdate(newFilters);
    }

    return (<div className={'filter-section-container'}
        onBlur={() => triggerUpdate()}
    >
        <div className={'filter-section-row'}>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    value={others ?? ''}
                    placeholder={'es. param1=0123&param2=abcd'}
                    label={'fs__label__other'}
                    stateKey={'other'}
                    invalid={false}
                    onChange={update}
                    invalidText={''}
                />
            </div>
        </div>
    </div>);

    function onCloseCallback(): void {
        dispatch(close());
    }

    function primaryAction(localFilters: Filters, others?: string): void {
        console.log('primaryAction => localFilters =>', localFilters);
        let newFilters: Filters = defaultFilters;
        const tokens: string[] = (others ?? '').split('&')
            .filter((t) => t.split('=').length === 2)
            .filter((t) => SUPPORTED_KEYS.some((sk) => t.startsWith(`${sk}=`)));
        newFilters = {
            ...localFilters,
            orpolygon: getValue(tokens, 'orpolygon') as string | undefined,
            notinpolygon: getValue(tokens, 'notinpolygon') as string | undefined,
            wherepolygonnamein: getValue(tokens, 'wherepolygonnamein') as string | undefined,
            origindirectlinktoevent: getValue(tokens, 'origindirectlinktoevent') as boolean | undefined,
            magnitudedirectlinktoorigin: getValue(tokens, 'magnitudedirectlinktoorigin') as boolean | undefined,
            magnitudedirectlinktoevent: getValue(tokens, 'magnitudedirectlinktoevent') as boolean | undefined,
            eventupdatedafter: getValue(tokens, 'eventupdatedafter') as string | undefined,
            originupdatedafter: getValue(tokens, 'originupdatedafter') as string | undefined,
            magnitudeupdatedafter: getValue(tokens, 'magnitudeupdatedafter') as string | undefined,
            updatedafteroperator: getValue(tokens, 'updatedafteroperator') as string | undefined,
            // v3.4.0
            minnphtot: getValue(tokens, 'minnphtot') as string | undefined,
            mindistance: getValue(tokens, 'mindistance') as string | undefined,
            // v3.9.0
            wheretypeeventnamein: getValue(tokens, 'wheretypeeventnamein') as string | undefined,
            wheretypeeventnamenotin: getValue(tokens, 'wheretypeeventnamenotin') as string | undefined
        }


        navigate(`/earthquakelist?${createUrlFromFilters({ ...newFilters, page: 1, sortDirection: SORT_DESC, sortHeader: DATETIME_TAG })}`);
        onCloseCallback();
    }
}

export {
    QueryFiltersOther
};
