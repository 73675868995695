import { createSlice,  } from '@reduxjs/toolkit'

const defaultTimezoneState = 'utc'

export const timezoneSlice = createSlice({
    name: 'timezone',
    initialState: defaultTimezoneState,
    reducers: {
        utc: (state) => {
            return defaultTimezoneState
        },
        italy: (state) => {
            return 'italy'
        }
    },
})

// Action creators are generated for each case reducer function
export const { utc, italy } = timezoneSlice.actions

export default timezoneSlice.reducer