import './DoubleRowInfo.css'
import CustomTooltip from "../CustomTooltip/CustomTooltip";

interface DoubleRowInfoParams {
    title: string,
    content: string | undefined
    className?: string
    stationMagnitudes?: boolean
    tooltip?: string | boolean
}

function DoubleRowInfo({ title, content, className, stationMagnitudes, tooltip }: DoubleRowInfoParams) {

    const getClassContainer = () => {
        return 'doublerow-container'
    }

    const getClassTitle = () => {
        if (stationMagnitudes) return 'stationmagnitudes-title'
        return ''
    }

    const getClassContent = () => {
        if (stationMagnitudes) return 'stationmagnitudes-content'
        return ''
    }

    return (
        tooltip
            ? (
                <div className={`${getClassContainer()} ${className ?? ''}`}>
                    <span className={`doublerow-title ${getClassTitle()}`}>{title}</span>
                    <CustomTooltip placement="top" title={tooltip} arrow>
                        <span className={`doublerow-content ${getClassContent()}`}>{content ?? '--'}</span>
                    </CustomTooltip>
                </div>
            )
            : (
                <div className={`${getClassContainer()} ${className ?? ''}`}>
                    <span className={`doublerow-title ${getClassTitle()}`}>{title}</span>
                    <span className={`doublerow-content ${getClassContent()}`}>{content ?? '--'}</span>
                </div>
            )
    )
}

export { DoubleRowInfo }