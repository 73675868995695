import { ObjectProvenance } from "../../../services/network"
import '../GenericTable.css'

export interface IDropdownProvenanceProps {
    provenance: ObjectProvenance
}

function DropdownProvenance({ provenance }: IDropdownProvenanceProps) {
    return (<div className="dropdown-provenance" >
        <div className={'ot-options-container'}>
            <h4>Provenance</h4>
            <div className='divider-container'>
                <div className='divider' />
            </div>
            {
                Object.entries(provenance).map((e, index) => {
                    return (<div
                        key={`${index}`}
                        className='item-no-click'
                    >
                        <p>
                            <strong style={{ textTransform: 'capitalize' }}>{e[0]}</strong>: {e[1]}
                        </p>
                    </div>)
                })
            }
        </div>
    </div>);
}

export {
    DropdownProvenance
}