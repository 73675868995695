import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const SETTINGS_VIEW_MODE = 'SETTINGS_VIEW_MODE'
export const SETTINGS_EDIT_MODE = 'SETTINGS_EDIT_MODE'

export interface EditSettingsMode {
    mode: string,
    id: string,
    type: string,
}

const defaultSettingsMode = {
    mode: SETTINGS_VIEW_MODE,
    id: '',
    type: '',
}

export const settingSlice = createSlice({
    name: 'editSettings',
    initialState: defaultSettingsMode,
    reducers: {
        edit: (state, editAction:PayloadAction<EditSettingsMode>) => {
            return {
                mode: SETTINGS_EDIT_MODE,
                id: editAction.payload.id,
                type: editAction.payload.type
            }
        },
        view: () => {
            return defaultSettingsMode
        }
    }
})

export const {edit, view} = settingSlice.actions
export default settingSlice.reducer