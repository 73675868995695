import { useTranslation } from "react-i18next"
import {useNavigate} from "react-router-dom";

export function NotAuthorized(){
    const {t} = useTranslation()
    const navigate = useNavigate()

    return (<div style={{padding: '16px'}}>
        <h1>{ t('page__not_authorized__message') }</h1>
        <div>
            <div
                className={'button bea-btn ms-4 cds--btn cds--btn--primary'}
                onClick={() => navigate('/earthquakelist')}
            >
                HOME
            </div>
        </div>
    </div>)
}