import { Option } from "../CustomSelector/CustomSelector"
import './MultiSelectItem.css'
import { ReactComponent as CheckedIcon } from '../../assets/delete-mi.svg'
import React from "react"

interface MultiSelectItemProps {
    option: Option
    deleteOption: (key:string) => void
}

const MultiSelectItem = ({option, deleteOption}: MultiSelectItemProps) => {
    return (
        <div className="container" onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault();
                e.stopPropagation();
                deleteOption(option.key)
            }}>
            <p className="text">{option.value}</p>
            <CheckedIcon />
        </div>
    )
}

export default MultiSelectItem