import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ApiClient } from "../../services/ApiClient"
import CustomSelector, { Option } from "../CustomSelector/CustomSelector"
import { LOCALSPACE_ENVIRONMENT } from "../../utils/process";

interface LocalspaceSelectorProps {
    selected?: string;
    onUpdate: (data: string) => void;
    label?: string;
    fromEnv: boolean;
    prefix: string;
}

const LocalspaceSelector = function ({ selected, onUpdate, label, fromEnv, prefix }: LocalspaceSelectorProps) {

    const { t } = useTranslation();

    const [options, setOptions] = useState<Option[] | undefined>(undefined);

    const getLocalspace = async function () {
        if (!options) {
            if (fromEnv) {
                console.log('fromEnv => LOCALSPACE_ENVIRONMENT =>   ', LOCALSPACE_ENVIRONMENT);
                setOptions(LOCALSPACE_ENVIRONMENT.map(l => ({ key: l, value: l } as Option)));
            } else {
                try {
                    let response = await ApiClient.getApi().getLocalspace()
                    let localspaces = response?.data?.data ?? []
                    setOptions(localspaces.map(l => ({ key: l.name, value: l.name } as Option)));
                } catch (e) {
                    console.log('LocalspaceSelector => error =>', JSON.stringify(e))
                }
            }
        }
    }

    useEffect(() => {
        if (!options){ getLocalspace(); }
    }, [])

    return <div className={'localspace-input'}>
        <p className='label'>{t(label ?? 'filters__localspace__text')}</p>
        <CustomSelector
            isMultiSelect={true}
            prefix={prefix}
            options={options ?? []}
            selected={selected}
            onChange={(key) => {
                let tokens = selected?.split(',') ?? []
                let index = tokens.findIndex(t => t === key)
                if (index === -1) {
                    tokens = [...tokens, key]
                } else {
                    tokens = tokens.filter(t => t !== key)
                }
                let joined: string = tokens.join(',').trim();
                if(joined.indexOf(',') === 0){
                    joined = joined.substring(1);
                }
                onUpdate(joined);
            }}
        />
    </div>
}

export {
    LocalspaceSelector
}