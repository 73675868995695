// this slice is meant to handle the situation when a group is open
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const VIEW_MODE = 'VIEW_MODE'
export const SPLIT_MODE = 'SPLIT_MODE'
export const JOIN_MODE = 'JOIN_MODE'
export const MOVE_MODE = 'MOVE_MODE'

// by the definition of the new API, which we're still waiting for, we should have the following information stored:
// the destination of the operation and the array ids of the involved elements
interface Group {
    open: string[],
    owner: string,
    members: string[],
    mode: string,
    join: string[],
    src?: string,
    destination?: string[]
}

export const initialGroupState = {
    open: [], // this is the actually open event group
    owner: '0', // this is the destination of the join group action
    members: [],
    mode: VIEW_MODE,
    join: [],
} as Group

export const groupSlice = createSlice({
    name: 'group',
    initialState: initialGroupState,
    reducers: {
        closeGroup: (state, groupAction:PayloadAction<string>) => {
            return {
                ...state,
                open: [...state.open.filter(g => g !== groupAction.payload)]
            }
        },
        openGroup: (state, groupAction:PayloadAction<string>) => {
            return {
                ...state,
                open: [...state.open, groupAction.payload]
            }
        },
        viewMode: (state) => {
            return {
                ...initialGroupState,
            }
        },
        splitMode: (state, splitAction: PayloadAction<Group>) => {
            return {
                ...state,
                mode: SPLIT_MODE,
                owner: splitAction.payload.owner,
                src: splitAction.payload.src,
                destination: []
            }
        },
        joinMode: (state, joinAction: PayloadAction<Group>) => {
            return {
                ...state,
                mode: JOIN_MODE,
                owner: joinAction.payload.owner,
                join: [joinAction.payload.owner],
                src: joinAction.payload.src,
                destination: []
            }
        },
        addGroupJoin: (state, addAction:PayloadAction<string>) => {
            return {
                ...state,
                join: [...state.join, addAction.payload].filter(a => a !== '0')
            }
        },
        removeGroupJoin: (state, removeAction:PayloadAction<string>) => {
            return {
                ...state,
                join: [...state.join.filter(g => g !== removeAction.payload && g !== '0')]
            }
        },
        moveMode: (state, moveAction:PayloadAction<string[]>) => {
            return {
                ...state,
                mode: MOVE_MODE,
                src: moveAction.payload[0],
                destination: [moveAction.payload[1]]
            }
        },
        joinModeAdd: (state, joinModeAddAction:PayloadAction<string[]>) => {
            return {
                ...state,
                destination: joinModeAddAction.payload
            }
        }
    }
})

export const {closeGroup, openGroup, viewMode, splitMode, joinMode, addGroupJoin, removeGroupJoin, moveMode, joinModeAdd} = groupSlice.actions
export default groupSlice.reducer