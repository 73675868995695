import { InlineNotification, Tab, TabList, TabPanel, TabPanels, Tabs, Toggle } from '@carbon/react'
import Leaflet, { LatLngTuple, Map as LeafletMap } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { createRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MapContainer, Marker, Polyline, TileLayer, Tooltip, ZoomControl } from "react-leaflet"
import { batch } from 'react-redux'
import { useParams } from "react-router-dom"
import { BreadCrumbComposer } from "../../components/BreadcrumbComposer/BreadcrumbComposer"
import { CityTable } from "../../components/CityTable/CityTable"
import CustomModal from '../../components/CustomModal/CustomModal'
import { EarthquakeDetailHeader } from "../../components/EarthquakeDetailHeader/EarthquakeDetailHeader"
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent"
import { FaultMechanismTable } from "../../components/EventDetailTable/FaultMechanismTable"
import { MagnitudeTable } from "../../components/EventDetailTable/MagnitudeTable"
import { OriginTable } from "../../components/EventDetailTable/OriginTable"
import { LoaderTranslated } from "../../components/Loader/LoaderTranslated"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks"
import { closeStoreModal } from '../../redux/reducers/confirmationModal.slice'
import { closeCta } from "../../redux/reducers/cta.slice"
import { removeCustomTableGroup } from '../../redux/reducers/customtable.slice'
import { fetchEarthquakeEvent, resetEvent } from "../../redux/reducers/earthquake.slice"
import { viewMode } from '../../redux/reducers/group.slice'
import { addStationEndpointError, removeStationEndpointError, resetStationEndpointsError } from '../../redux/reducers/stationEndpointsError.slice'
import { addStations } from '../../redux/reducers/stations.slice'
import { StationCoordinates, StationsApiClient } from "../../services/StationsApiClient"
import { ObjectOrigin } from "../../services/network"
import { buildError, starIcon, stationArrival, stationArrivalAndMagnitude, stationMagnitude } from '../../utils'
import { BeaInlineNotification, getErrorNotification, onErrorTimeoutCallbackHandler } from '../../utils/InlineNotifications'
import { STATIONS_BASE_URLS } from '../../utils/process'
import './EarthquakeDetail.css'

interface EarthquakeDetailProps {
    earthquakeId: string
}

interface EarthquakeStation {
    stationName: string,
    isArrival: boolean,
    isMagnitude: boolean,
    lat: number,
    lon: number
}

const TAG: string = `EarthquakeDetail =>`;

function EarthquakeDetail() {
    const { t } = useTranslation()
    const { earthquakeId } = useParams<keyof EarthquakeDetailProps>() as EarthquakeDetailProps
    const earthquake = useAppSelector((state) => state.earthquake);
    const stationEndpointsError = useAppSelector((state) => state.stationEndpointsError);
    const modalSpec = useAppSelector((state) => state.confirmationModal);

    const dispatch = useAppDispatch()
    const map = createRef<LeafletMap>()
    const [loading, setLoading] = useState(true)
    const [prefOrigin, setPrefOrigin] = useState<ObjectOrigin>();
    const [selectedOrigin, setSelectedOrigin] = useState<ObjectOrigin>();
    const mapBounds: LatLngTuple[] = [[90, -180], [-90, 180]]

    const [downloading, isDownloading] = useState<boolean>(false);
    const [showStations, setShowStations] = useState<boolean>(true);
    const [showNotification, setShowNotification] = useState<BeaInlineNotification[]>([]);

    const stateStations = useAppSelector((state) => state.stations);
    const [renderableStations, setRenderableStations] = useState<Array<EarthquakeStation>>([]);
    const [zoomLock, setZoomLock] = useState<boolean>(false);

    useEffect(() => {
        closeAllAndReset();
        return () => {
            closeAllAndReset();
        }
    }, [/* only after the initial render */]);


    useEffect(() => {
        setShowNotification(stationEndpointsError.map((ser) => {
            const tokens: string[] = ser.split('#');
            const title: string = `${t('dropdown__download__failed')}${tokens[1] !== '0' ? ` (${tokens[1]})` : ''}`
            return {
                ...getErrorNotification(),
                show: true,
                title: title,
                reason: tokens[0]
            }
        }));
    }, [stationEndpointsError])

    useEffect(() => {
        if (!earthquake) {
            return;
        }
        if (`${earthquake.id}` !== earthquakeId) {
            return;
        }

        if (downloading) {
            return;
        }
        isDownloading(true);
        let staToBeFound: Array<string> = [];

        if (earthquake) {
            if (`${earthquake.id}` !== earthquakeId) {
                return;
            }

            if (earthquake.origins && earthquake.origins.length > 0) {
                const { origins } = earthquake;
                origins.forEach((origin) => {
                    // Get all the arrivals stations
                    origin.arrivals?.forEach((arrival) => {
                        if (arrival.pick) {
                            const { sta, net } = arrival.pick;
                            if (sta) {
                                // if not in store
                                if (stateStations.findIndex((stateStation) => stateStation.stationNetwork === net && stateStation.stationName === sta) === -1) {
                                    // and if not already in the required ids
                                    if (staToBeFound.findIndex((id) => id === sta) === -1) {
                                        staToBeFound.push(sta);
                                    }
                                }
                            }
                        }
                    });

                    // Get all the magnitude stations
                    origin.magnitudes?.forEach((magnitude) => {
                        magnitude.stationmagnitudes?.forEach((stationMagnitude) => {
                            if (stationMagnitude) {
                                if (stationMagnitude.amplitude) {
                                    if (stationMagnitude.amplitude.sta) {
                                        const { sta, net } = stationMagnitude.amplitude;
                                        // if not in store
                                        if (stateStations.findIndex((stateStation) => stateStation.stationNetwork === net && stateStation.stationName === sta) === -1) {
                                            // and if not already in the required ids
                                            if (staToBeFound.findIndex((id) => id === sta) === -1) {
                                                staToBeFound.push(sta);
                                            }
                                        }
                                    }
                                }
                            }
                        })
                    });
                });

                if (!downloading) {
                    getStations(staToBeFound);
                }
            }
        }
    }, [earthquake, downloading]);

    async function getStations(stationIds: string[]): Promise<void> {

        if (stationIds.length === 0) {
            return;
        }

        // this should be used to avoid duplicates?
        const stationsCoordinatesMap = new Map<string, StationCoordinates>();

        for (const url of STATIONS_BASE_URLS) {
            const index = STATIONS_BASE_URLS.findIndex((u) => u === url);

            StationsApiClient.getStationsCoordinates(index, stationIds)
                .then((stationsCoordinatesFromEndpoint: StationCoordinates[]) => {
                    let found: StationCoordinates[] = [];

                    for (const c of stationsCoordinatesFromEndpoint) {
                        if (c.stationName === "" || c.stationName === undefined || c.stationName === null) {
                            continue;
                        }
                        if (c.stationNetwork === "" || c.stationNetwork === undefined || c.stationNetwork === null) {
                            continue;
                        }
                        const key: string = `${c.stationNetwork}.${c.stationName}`;
                        if (!stationsCoordinatesMap.get(key)) {
                            stationsCoordinatesMap.set(key, c);
                            if (stateStations.findIndex((stateStation) => `${stateStation.stationNetwork}.${stateStation.stationName}` === key) === -1) {
                                found = [...found, c];
                            }
                        }
                    }

                    if (found.length > 0) {
                        dispatch(addStations(found));
                    }
                }, (e) => {
                    let reason: string = url;
                    if (e.response) {
                        if (e.response.status > 0) {
                            console.log('getStations => getStationsCoordinates => e', 'status > 0');
                            // status:
                            // the network request is actually blocked before it is completed and therefore there is no response associated and thus no http code.
                            // If the response is present we append the status to the message
                            reason = `${reason}#${e.response.status}`
                        } else {
                            reason = `${reason}#${0}`
                        }
                    } else {
                        reason = `${reason}#${0}`
                    }

                    onErrorTimeoutCallbackHandler('title', reason, (n: BeaInlineNotification) => {
                        if (n.show) {
                            dispatch(addStationEndpointError(reason))
                        } else {
                            dispatch(removeStationEndpointError(reason))
                        }
                    });
                });
        }
    }

    useEffect(() => {
        if (selectedOrigin) {
            if (showStations) {
                populateStations([selectedOrigin]);
            }
        }
    }, [showStations, selectedOrigin, stateStations]);

    /* questo metodo, partendo dalle origin, crea una mapping fra le stazioni nelle origin e le coordinate scaricate */
    const populateStations = (origins: Array<ObjectOrigin>) => {

        if (origins.length === 0) {
            console.log(TAG, 'populateStations => no origins');
            return;
        }

        // qui la chiave dovrebbe essere net.sta perché sta potrebbe essere duplicato
        const stationsToBeRendered = new Map<string, EarthquakeStation>()
        let stationIds: Array<string> = [];

        origins.forEach((origin) => {
            // Get all the arrivals stations
            origin.arrivals?.forEach((arrival) => {
                if (arrival.pick) {
                    const { sta, net } = arrival.pick
                    const key: string = `${net}.${sta}`;
                    if (sta) {
                        const stationToBeRendered = stationsToBeRendered.get(key);
                        if (stationToBeRendered) {
                            // station `${net}.${sta}` already exists
                            stationToBeRendered.isArrival = true
                        } else {
                            // station `${net}.${sta}` doesn't exist
                            stationsToBeRendered.set(key, {
                                isArrival: true,
                                isMagnitude: false,
                                stationName: `${net}.${sta} / ARRIVAL`,
                                lat: 91,
                                lon: 181
                            })
                            stationIds.push(key)
                        }
                    }
                }
            })

            // Get all the magnitude stations
            origin.magnitudes?.forEach((magnitude) => {
                magnitude.stationmagnitudes?.forEach((stationMagnitude) => {
                    if (stationMagnitude) {
                        if (stationMagnitude.amplitude) {
                            const sta = stationMagnitude.amplitude.sta ?? '--';
                            const net = stationMagnitude.amplitude.net ?? '--';
                            const key: string = `${net}.${sta}`;
                            const stationToBeRendered = stationsToBeRendered.get(key);
                            if (stationToBeRendered) {
                                // station `${net}.${sta}` already exists
                                stationToBeRendered.isMagnitude = true
                                stationToBeRendered.stationName = `${net}.${sta} / MAGNITUDE+ARRIVAL`
                            } else {
                                // station `${net}.${sta}` doesn't exist
                                stationsToBeRendered.set(key, {
                                    isArrival: false,
                                    isMagnitude: true,
                                    stationName: `${net}.${sta} / MAGNITUDE`,
                                    lat: 91,
                                    lon: 181
                                })
                                stationIds.push(key)
                            }
                        }
                    }
                })
            })
        })

        let stationsArray: Array<EarthquakeStation> = [];
        console.log('stateStations', stateStations);
        stateStations.forEach(c => {
            const key: string = `${c.stationNetwork}.${c.stationName}`;
            const station = stationsToBeRendered.get(key);
            if (station) {
                station.lat = c.lat;
                station.lon = c.lon;
            }
        });

        stationsToBeRendered.forEach((station) => stationsArray.push(station));

        stationsArray = stationsArray.filter(s => s.lat <= 90 && s.lon <= 180);

        setRenderableStations(stationsArray);
    }

    const [error, setError] = useState<boolean | string>(false);
    const [httpCode, setHttpCode] = useState(200);

    const getData = function () {
        if (!earthquakeId) {
            return;
        }
        if (earthquake) {
            if (earthquakeId === `${earthquake.id}`) {
                // if we remove the following command the earthquake is not update when it is repoened
                // return;
            }
        }
        try {
            dispatch(fetchEarthquakeEvent(+earthquakeId))
                .unwrap()
                .catch((e) => {
                    let { name } = e
                    if (name === 'AxiosError') {
                        setError(buildError(e, (code: number) => setHttpCode(code)));
                        dispatch(resetEvent());
                    }
                    setLoading(false);
                })
        } catch (e) {
            console.log('fetchEarthquakeEvent => error =>', e)
        }
    }

    useEffect(() => {
        if (!earthquakeId) {
            setError('Terromoto non trovato');
            setLoading(false);
            return;
        }

        getData();
        return () => {
            console.log('resetting event');
            dispatch(resetEvent());
            setShowNotification([]);
            dispatch(resetStationEndpointsError());
        }
    }, [earthquakeId]);

    useEffect(() => {
        if (!earthquake) {
            console.log('no earthquake 2');
            return
        }
        if (earthquakeId !== `${earthquake.id}`) {
            console.log('no earthquake id');
            return;
        }

        const prefOriginId = earthquake.preferred_origin_id;
        const prefOriginFound = earthquake.origins?.find((origin) => origin.id === prefOriginId);
        if (prefOriginFound) {
            setPrefOrigin(prefOriginFound);
            setSelectedOrigin(prefOriginFound);
        }
    }, [earthquake]);

    useEffect(() => {
        return () => {
            setError(false);
            setLoading(true);
            dispatch(closeCta());
        }
    }, [])

    useEffect(() => {
        if (earthquakeId !== `${earthquake.id}`) {
            return;
        }

        let x: Array<LatLngTuple> = showStations ? Object.values(renderableStations).map(s => [s?.lat, s?.lon] as LatLngTuple) : [];

        if (prefOrigin && prefOrigin.lat && prefOrigin.lon) {
            x = [...x, [prefOrigin?.lat, prefOrigin?.lon] as LatLngTuple];
        }
        if (!zoomLock) {
            if (x.length > 1) {
                map.current?.fitBounds(new Leaflet.LatLngBounds(x));
            } else {
                if (prefOrigin && prefOrigin.lat && prefOrigin.lon) {
                    map.current?.flyTo([prefOrigin?.lat, prefOrigin?.lon] as LatLngTuple, 4)
                }
            }
        }
        setLoading(false);
    }, [renderableStations]);

    function resetItemTable(): void {
        const { event_group_id } = earthquake;
        if (event_group_id === undefined || event_group_id === null || event_group_id === 0) {
            dispatch(removeCustomTableGroup(`${earthquake.id}`));
        } else {
            dispatch(removeCustomTableGroup(`${event_group_id}`));
        }
    }

    return (
        <>
            {
                showNotification.filter((n) => n.show).length > 0 &&
                <div style={{
                    position: 'absolute', bottom: '0', right: '0', width: '27%', zIndex: 99, maxHeight: '50vh', overflowY: 'auto',
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '16px', gap: '16px'
                }}>
                    {
                        showNotification.filter((n) => n.show).length > 0 &&
                        showNotification.filter((n) => n.show).map((n, index) =>
                            <InlineNotification
                                key={n.id}
                                kind={n.kind}
                                lowContrast
                                className='endpoint-notification'
                                actionbuttonlabel='Action'
                                ariaLabel='closes notification'
                                onClose={() => dispatch(removeStationEndpointError(n.reason))}
                                onCloseButtonClick={function noRefCheck() { }}
                                statusIconDescription='notification'
                                subtitle={n.reason}
                                title={`${n.title}`}
                            />
                        )
                    }
                </div>
            }
            <div className="earthquake-detail-container">
                <div className="earthquake-detail">
                    <BreadCrumbComposer pageId='earthquakeDetail' elementIds={[earthquakeId]} />

                    {
                        loading && !prefOrigin
                            ? <div style={{ gridRow: '2 / span 2' }}><LoaderTranslated /></div>
                            : (
                                error === false
                                    ? (<>
                                        <EarthquakeDetailHeader
                                            dateStr={`${prefOrigin?.ot ?? ''}`}
                                            eventType={earthquake.type_event}
                                            localspaceName={earthquake.localspace?.name ?? '--'}
                                            id_localspace={`${earthquake.id_localspace ?? '--'}`}
                                            environment={`${earthquake?.localspace?.environment ?? '--'}`}
                                        />
                                        <div className={'earthquake-detail-table-container'}>
                                            <Tabs>
                                                <TabList contained={false}>
                                                    <Tab className="bea-tab-header">{t('page__earthquake_detail__tab__origin__title')}</Tab>
                                                    <Tab className="bea-tab-header">{t('page__earthquake_detail__tab__cities__title')}</Tab>
                                                    <Tab className="bea-tab-header">{t('page__earthquake_detail__tab__magnitudes__title')}</Tab>
                                                    <Tab className="bea-tab-header">{t('page__earthquake_detail__tab__mechanism__title')}</Tab>
                                                </TabList>
                                                <TabPanels>
                                                    <TabPanel>
                                                        <OriginTable
                                                            selectedOrigin={selectedOrigin}
                                                            onHoverCallback={(id) => {
                                                                if (earthquake && earthquake.origins && earthquake.origins.length > 0) {
                                                                    const { origins } = earthquake;
                                                                    const selected: ObjectOrigin | undefined = origins.find((o) => `${o.id}` === id);
                                                                    if (selected) {
                                                                        if (!selectedOrigin) {
                                                                            setSelectedOrigin(selected);
                                                                        } else {
                                                                            if (selectedOrigin.id !== selected.id) {
                                                                                setSelectedOrigin(selected);
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                            updateCallback={() => {
                                                                resetItemTable();
                                                                getData()
                                                            }}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel><CityTable /></TabPanel>
                                                    <TabPanel>
                                                        <MagnitudeTable updateCallback={() => {
                                                            resetItemTable();
                                                            getData()
                                                        }} />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <FaultMechanismTable updateCallback={() => {
                                                            resetItemTable();
                                                            getData()
                                                        }} />
                                                    </TabPanel>
                                                </TabPanels>
                                            </Tabs>
                                        </div>

                                    </>)
                                    : <div style={{ gridRow: '2 / span 2' }}>
                                        <ErrorComponent title={`${t('page__earthquake_detail__error__title')} ${httpCode}`} description={error as string} />
                                    </div>
                            )
                    }
                </div>
                <div className="originlist-map">
                    <div className='box-toggle'>
                        <div className='box-toggle-horizontal'>
                            <div className='label'>{t('ed__map__show_stations')}</div>
                            <Toggle
                                labelText=""
                                hideLabel
                                labelA=""
                                labelB=""
                                defaultToggled
                                id="toggle-1"
                                onToggle={(toggled: boolean) => setShowStations(toggled)}
                            />
                        </div>

                        <div className='box-toggle-horizontal'>
                            <div className='label'>{t('ed__map__zoom_lock')}</div>
                            <Toggle
                                labelText=""
                                hideLabel
                                labelA=""
                                labelB=""
                                id="toggle-zoom"
                                onToggle={(toggled: boolean) => setZoomLock(toggled)}
                            />
                        </div>
                    </div>
                    <MapContainer
                        bounds={mapBounds}
                        zoomControl={false}
                        scrollWheelZoom={true}
                        minZoom={2}
                        style={{ height: 'calc(100vh - 56px)' } /* 56px is the size of the navbar */}
                        ref={map}
                    >
                        <ZoomControl position='bottomright' />
                        <TileLayer
                            url={`${process.env.REACT_APP_BEA_TILE_LAYER}`}
                            attribution={process.env.REACT_APP_BEA_TILE_LAYER_DESCRIPTION}
                        />
                        {/*reference layer*/}
                        {/* removed as done on EE Front End */}
                        {/*<TileLayer
                            url={`${process.env.REACT_APP_BEA_REFERENCE_TILE_LAYER}`}
                        />*/}
                        {(earthquake && earthquake.origins && earthquake.origins.length > 0) &&
                            [...earthquake.origins].sort((o1, o2) => {
                                if (o1.id === prefOrigin?.id) { return 1; }
                                if (o2.id === prefOrigin?.id) { return -1; }
                                return 0;
                            }).map((o) => <Marker
                                key={`${o.id}`}
                                position={[+o.lat, +o.lon]}
                                riseOnHover={true}
                                zIndexOffset={((o.id === selectedOrigin?.id)) ? 1000 : 0}
                                eventHandlers={{
                                    mouseover: (e: Leaflet.LeafletMouseEvent) => setSelectedOrigin(o)
                                }}
                                icon={new Leaflet.DivIcon({
                                    html: `<div class='origin-marker${o.id === selectedOrigin?.id ? '-hover' : ''}'>
                                        ${o.id === prefOrigin?.id
                                            ? `<div class='origin-marker-star'>${starIcon}<div/>`
                                            : `<div class='origin-marker-circle'> 
                                            </div>`}
                                        </div>`,
                                    className: 'no-show'
                                })} />)
                        }

                        {/* Render all the stations as markers with the appropriate icon */}
                        {showStations && renderableStations.map((station, index) => {
                            let icon
                            if (station.isArrival && station.isMagnitude) {
                                icon = stationArrivalAndMagnitude
                            } else if (station.isArrival) {
                                icon = stationArrival
                            } else { // station.isMagnitude
                                icon = stationMagnitude
                            }

                            return (<>
                                {icon === stationArrival &&
                                    <Polyline
                                        key={`arrival-${index}`}
                                        pathOptions={{ color: '#EE5396', weight: 1 }}
                                        positions={[[+station.lat, +station.lon], [+(selectedOrigin?.lat ?? 0), +(selectedOrigin?.lon ?? 0)]]} />
                                }

                                {icon === stationArrivalAndMagnitude &&
                                    <Polyline
                                        key={`arrival-and-magnitude-${index}`}
                                        pathOptions={{ color: '#4589FF', weight: 1 }}
                                        positions={[[+station.lat, +station.lon], [+(selectedOrigin?.lat ?? 0), +(selectedOrigin?.lon ?? 0)]]} />
                                }

                                <Marker
                                    key={station.stationName}
                                    position={[station.lat, station.lon]}
                                    riseOnHover={true}
                                    icon={new Leaflet.DivIcon({
                                        html:
                                            `${icon}`,
                                        className: 'no-show'
                                    })}>
                                    <Tooltip
                                        key={`tooltip-${station.stationName}-${index}`}
                                        className="map-tooltip"
                                    >
                                        {
                                            (station.isArrival && station.isMagnitude)
                                                ? <>
                                                    <div key={`map-tooltip-left-arrival-${index}`} className="map-tooltip-left-arrival"></div>
                                                    <div key={`map-tooltip-right-magnitude-${index}`} className="map-tooltip-right-magnitude"></div>
                                                </ >
                                                : <div
                                                    key={`${station.isMagnitude ? "map-tooltip-right-magnitude" : "map-tooltip-arrival"}-${index}`}
                                                    className={station.isMagnitude ? "map-tooltip-right-magnitude" : "map-tooltip-arrival"}></div>
                                        }
                                        {station.stationName}
                                    </Tooltip>
                                </Marker>
                            </>)
                        })}
                    </MapContainer>
                </div>
            </div>
            <CustomModal
                {
                ...modalSpec
                } />
        </>
    );

    function closeAllAndReset(): void {
        batch(() => {
            dispatch(closeCta());
            dispatch(viewMode());
            dispatch(closeStoreModal());
            dispatch(resetStationEndpointsError());
            dispatch(resetEvent());
        })
    }
}

export { EarthquakeDetail }
