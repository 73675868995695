import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/reduxCustomHooks";
import { ObjectEvent } from "../../services/network";
import { BreadCrumb, Crumbs, PageId } from "../Breadcrumb/Breadcrumb";
import "./BreadcrumbComposer.css";
import {createUrlFromFilters} from "../../utils/urlFromFilters";

interface BreadcrumbComposerProps {
    pageId: PageId;
    elementIds: Array<string>;
}

function BreadCrumbComposer({ pageId, elementIds }: BreadcrumbComposerProps) {
    const { t } = useTranslation();
    const earthquake = useAppSelector((state) => state.earthquake);
    const filters = useAppSelector((state) => state.filters)

    const evaluateEarthquakeName = (earthquake: ObjectEvent): string => {
        const prefOrigin = earthquake.origins?.find(
            (origin) => origin.id === earthquake.preferred_origin_id
        );
        const prefMag = earthquake.origins
            ?.flatMap((o) => o.magnitudes ?? [])
            .find(
                (magnitude) =>
                    magnitude.id === earthquake.preferred_magnitude_id
            );
        let name = t("component__breadcrumb__unnamed_earthquake")

        if (prefOrigin) {
            name = `${
                prefOrigin.region ??
                t("component__breadcrumb__unnamed_earthquake")
            }`;
        }

        if(prefMag) {
            name = `${name} - ${prefMag.type_magnitude} ${prefMag.mag.toFixed(1)}`
        }

        return name;
    };

    const evaluateOriginName = (
        earthquake: ObjectEvent,
        originId: string
    ): string => {
        const selectedOrigin = earthquake.origins?.find(
            (origin) => origin.id === +originId
        );
        if (selectedOrigin) {
            return `${selectedOrigin.type_origin?.version_name} (${selectedOrigin.type_origin?.version_value})`;
        } else {
            return t("component__breadcrumb__unnamed_origin");
        }
    };

    const evaluateCrumbs = (
        pageId: PageId,
        elementIds: Array<string>,
        earthquake?: ObjectEvent
    ): Array<Crumbs> => {
        switch (pageId) {
            case "createEvent":
                return [
                    {
                        href: `/earthquakelist?${createUrlFromFilters(filters)}`,
                        title: t("page__list_earthquakes__title"),
                    },
                    {
                        href: "",
                        title: t("page__list_earthquakes__new_event"),
                    },
                ];
            case "createOrigin":
                return [
                    {
                        href: `/earthquakelist?${createUrlFromFilters(filters)}`,
                        title: t("page__list_earthquakes__title"),
                    },
                    {
                        href: "/earthquake/" + elementIds[0],
                        title:
                            earthquake && `${earthquake.id}` === elementIds[0]
                                ? evaluateEarthquakeName(earthquake)
                                : t(
                                    "component__breadcrumb__unnamed_earthquake"
                                ),
                    },
                    {
                        href: "",
                        title: t("page__list_earthquakes__new_origin"),
                    },
                ];
            case "createMagnitude":
                return [
                    {
                        href: `/earthquakelist?${createUrlFromFilters(filters)}`,
                        title: t("page__list_earthquakes__title"),
                    },
                    {
                        href: "/earthquake/" + elementIds[0],
                        title:
                            earthquake && `${earthquake.id}` === elementIds[0]
                                ? evaluateEarthquakeName(earthquake)
                                : t(
                                    "component__breadcrumb__unnamed_earthquake"
                                ),
                    },
                    {
                        href: "/earthquake/" + elementIds[0]+"/origin/"+elementIds[1],
                        title:
                            earthquake && `${earthquake.id}` === elementIds[0]
                                ? evaluateOriginName(earthquake, elementIds[1])
                                : t("component__breadcrumb__unnamed_origin"),
                        origin: elementIds[1],
                    },
                    {
                        href: "",
                        title: t("page__list_earthquakes__new_magnitude"),
                    },
                ];
            case "settings":
                return [{ href: "", title: t("settings_breadcrumb") }];
                // return [];
            case "info":
                return [{ href: "", title: 'Info' }];
                // return [];
            case "earthquakeList":
                return [
                    {
                        href: "",
                        title: t("page__list_earthquakes__title"),
                    },
                ];
            case "earthquakeDetail":
                return [
                    {
                        href: `/earthquakelist?${createUrlFromFilters(filters)}`,
                        title: t("page__list_earthquakes__title"),
                    },
                    {
                        href: "",
                        title:
                            earthquake && `${earthquake.id}` === elementIds[0]
                                ? evaluateEarthquakeName(earthquake)
                                : t(
                                      "component__breadcrumb__unnamed_earthquake"
                                  ),
                    },
                ];
            case "originDetail":
                return [
                    {
                        href: `/earthquakelist?${createUrlFromFilters(filters)}`,
                        title: t("page__list_earthquakes__title"),
                    },
                    {
                        href: "/earthquake/" + elementIds[0],
                        title:
                            earthquake && `${earthquake.id}` === elementIds[0]
                                ? evaluateEarthquakeName(earthquake)
                                : t(
                                      "component__breadcrumb__unnamed_earthquake"
                                  ),
                    },
                    {
                        href: "",
                        title:
                            earthquake && `${earthquake.id}` === elementIds[0]
                                ? evaluateOriginName(earthquake, elementIds[1])
                                : t("component__breadcrumb__unnamed_origin"),
                        origin: elementIds[1],
                    },
                ];
            default:
                return []
        }
    };

    const crumbs = evaluateCrumbs(pageId, elementIds, earthquake);

    const evaluateOrigin = function (
        elementIds: string[]
    ): number | string | undefined {
        if (elementIds) {
            if (elementIds.length === 2) return elementIds[1];
        }
        return;
    };

    return (
        <BreadCrumb
            crumbs={crumbs}
            pageId={pageId}
            origin={evaluateOrigin(elementIds)}
        />
    );
}

export { BreadCrumbComposer };
