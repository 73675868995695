import { useTranslation } from 'react-i18next'
import './EmptyList.css'

interface EmptyListProps {
    title: string,
    description?: string,
    small?: boolean
    verysmall?: boolean
}

const EmptyList = ({title, description, small, verysmall}: EmptyListProps) => {
    const {t} = useTranslation();
    return <>
    <div className={small ? 'empty-list-container-small' : (verysmall ? 'empty-list-container-verysmall' : 'empty-list-container')}>                    
        <h1 className='empty-list-title'>{t(title)}</h1>
        {description && <p className='empty-list-description'>{description}</p>}
    </div>
</>
}

export default EmptyList