import {TableHeader, TYPOLOGY_TAG} from "../TableUtils";
import {DESCRIPTION_TAG} from "./CustomSettingsTable";

export interface OriginDetailProducts {
    [TYPOLOGY_TAG]: string,
    [DESCRIPTION_TAG]: string
}

export const origindetail_productstable_headers: Array<TableHeader> = [
    {
        tag: TYPOLOGY_TAG,
        header: 'origindetail_productstable_typology',
        sortable: false,
    },
    {
        tag: DESCRIPTION_TAG,
        header: 'origindetail_productstable_description',
        sortable: false,
    },
]