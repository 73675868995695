import { DATETIME_TAG, MAGNITUDE_TAG, SCNL_TAG, SORT_ASC, TableHeader, TYPOLOGY_TAG } from "../TableUtils"

///////// STATION MAGNITUDES LIST 
export const AMPLITUDE_TAG = 'amplitude'
export const PERIOD_TAG = 'period'
export const CATEGORY_TAG = 'category'
export const UNIT_TAG = 'unit'
export const TIMEREF_WINDOW_TAG = 'timeref_window'
export const IS_USED_TAG = 'is_used'

export interface OriginDetailMlStationMagnitude { 
    scnl: string, 
    [DATETIME_TAG]: string, 
    [MAGNITUDE_TAG]: string, 
    mag: number, 
    [AMPLITUDE_TAG]: string,
    amp: number, 
    [PERIOD_TAG]: string, 
    per: number,
    [TYPOLOGY_TAG]: string, 
    [CATEGORY_TAG]: string, 
    [UNIT_TAG]: string, 
    [TIMEREF_WINDOW_TAG]: string 
    [IS_USED_TAG]: boolean
}

export const origindetail_ml_stationmagnitude_headers: Array<TableHeader> = [
    {
        tag: SCNL_TAG,
        header: 'origindetail_scnl',
        sortable: true,
        cols: 1,
    },
    {
        tag: MAGNITUDE_TAG,
        header: 'origindetail_magnitude',
        sortable: true,
    },    
    {
        tag: AMPLITUDE_TAG,
        header: 'origindetail_amplitude',
        sortable: true,
        cols: 1,
    },    
    {
        tag: PERIOD_TAG,
        header: 'origindetail_period',
        sortable: true,
    },    
    {
        tag: TYPOLOGY_TAG,
        header: 'origindetail_typology',
        sortable: true,        
    },    
    /*{
        tag: CATEGORY_TAG,
        header: 'origindetail_category',
        sortable: true,
    },    
    {
        tag: UNIT_TAG,
        header: 'origindetail_unit',
        sortable: true,
    },    */
    {
        tag: TIMEREF_WINDOW_TAG,
        header: 'origindetail_timeref_window',
        sortable: true,
    },    
    {
        tag: IS_USED_TAG,
        header: 'origindetail_is_used',
        sortable: false,
    }
]

export const castedOriginDetailMlStationMagnitudeSorting = (tag: string, elements: Array<OriginDetailMlStationMagnitude>, direction: number) => {

    let copy = [...elements]
    if(tag === TIMEREF_WINDOW_TAG) {                
        return copy.sort((e1, e2) => {
            return direction === SORT_ASC 
                ? e1[tag].localeCompare(e2[tag])
                : -(e1[tag].localeCompare(e2[tag]))
        })
    }

    if(tag === AMPLITUDE_TAG || tag === PERIOD_TAG) {                
        return copy.sort((e1, e2) => {
            let v1:number = Number(e1[tag].replaceAll(/[^.\-\+\d+]/g, ""))
            let v2:number = Number(e2[tag].replaceAll(/[^.\-\+\d+]/g, ""))
            return direction === SORT_ASC 
                ? v1 - v2
                : -(v1 - v2)
        })
    }

    return elements
}