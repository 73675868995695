import { InlineNotification } from "@carbon/react";
import { useKeycloak } from '@react-keycloak/web';
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import { closeStoreModal, showStoreModal } from "../../redux/reducers/confirmationModal.slice";
import { SETTINGS_EDIT_MODE, edit, view } from '../../redux/reducers/settings.slice';
import { ApiClient } from "../../services/ApiClient";
import { GetTypeEvent200Response, ObjectTableTypeEvent } from "../../services/network";
import { BeaInlineNotification, defaultNotificationState, onErrorCallbackHandler, onSuccessCallbackHandler } from '../../utils/InlineNotifications';
import { BeaButton } from "../BeaButton/BeaButton";
import CustomModal from '../CustomModal/CustomModal';
import { SETTINGS_EVENT_TABLE } from '../CustomTable/TableComposer';
import EmptyList from "../EmptyList/EmptyList";
import { LoaderTranslated } from "../Loader/LoaderTranslated";
import { SettingsGenericCreateForm } from '../SettingsTabs/SettingsGenericCreateForm';
import { addSuccessText, confirmModalText, confirmModalTitle, modalText, modalTitle, successTitle } from '../SettingsTabs/utils';
import { GenericTable, IHeader, Row, SORT_ASC, SortDirection, singleRow } from "./GenericTable";
import './GenericTable.css';
import { idAsc, idDesc, nameAsc, nameDesc } from "./sortutils";
import { Toast } from "../Toast/Toast";

interface EventTypeTableProps {

}

function EventTypeTable({ }: EventTypeTableProps) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const [eventTypes, setEventTypes] = useState<ObjectTableTypeEvent[] | undefined>(undefined);
    const [rows, setRows] = useState<Row[] | undefined>(undefined);
    const [showNotification, setShowNotification] = useState<BeaInlineNotification>(defaultNotificationState);

    const dispatch = useAppDispatch();
    const settings = useAppSelector((state) => state.settings);
    const [modal, setModal] = useState<boolean>(false);
    const [element, setElement] = useState<ObjectTableTypeEvent>();
    const [disabled, setDisabled] = useState<boolean>(true);

    function sortById(direction: SortDirection): void {
        sortEventTypes(direction === SORT_ASC ? idAsc : idDesc);
    }

    function sortByName(direction: SortDirection): void {
        sortEventTypes(direction === SORT_ASC ? nameAsc : nameDesc);
    }

    const tableHeaders: IHeader[] = [
        { tag: 'id_tag', label: 'ot__id__label', sortable: true, sortFunction: sortById },
        { tag: 'name_tag', label: 'ot__name__label', sortable: true, sortFunction: sortByName },
        { tag: 'description_tag', label: 'ot__description__label', sortable: false }
    ];

    function sortEventTypes(sortingProcedure: (m1: ObjectTableTypeEvent, m2: ObjectTableTypeEvent) => number): void {
        if (eventTypes) {
            setEventTypes([...eventTypes].sort(sortingProcedure));
        }
    }

    useEffect(() => {
        download().then(() => console.log('ok'));
    }, []);

    useEffect(() => {
        if (element) {
            if (element.name) {
                if (element.name.length > 0) {
                    setDisabled(false);
                    return;
                }
            }
        }
        setDisabled(true);
    }, [element, disabled]);

    useEffect(() => {
        if (eventTypes) {
            const temp: Row[] = eventTypes.map((et, index) => {
                const link: string = `#`;
                return [
                    {
                        tag: 'id_tag',
                        id: `${et.id}`,
                        element: id(link, et)
                    },
                    {
                        tag: 'name_tag',
                        id: `${et.id}`,
                        element: name(link, et)
                    },
                    {
                        tag: 'description_tag',
                        id: `${et.id}`,
                        element: description(link, et)
                    }

                ]
            });
            setRows(temp);
        }
    }, [eventTypes, keycloak]);

    return (
        <>
            <div className={'origin-table-container'}>
                <div className={'origin-button-container'}>
                    <div>
                        {
                            !!keycloak.authenticated &&
                            <BeaButton
                                onClick={() => {
                                    dispatch(view());
                                    setModal(true);
                                }}
                                disabled={false}
                                variant={'primary'}
                                height="48px"
                                title={'create_new_type_event'} />
                        }
                    </div>
                </div>
                {
                    (eventTypes && rows)
                        ? (
                            rows.length > 0
                                ? (
                                    <GenericTable
                                        headers={tableHeaders}
                                        rows={rows}
                                        gridDefinition={'sp-et-grid'}
                                        defaultSorting={{
                                            direction: SORT_ASC,
                                            tag: 'id_tag'
                                        }}
                                        onClickCallback={editMode}
                                    />
                                )
                                : (
                                    <EmptyList title={'No type event found'} />
                                )
                        )
                        : (
                            <LoaderTranslated />
                        )
                }
            </div>
            {
                showNotification.show &&

                (showNotification.kind === 'success'
                    ? <Toast
                        className='settings-notification'
                        title={t(showNotification.reason)}
                        onClose={() => setShowNotification(defaultNotificationState)}
                    />
                    : <InlineNotification
                        className="settings-notification"
                        kind={showNotification.kind}
                        lowContrast
                        actionbuttonlabel="Action"
                        ariaLabel="closes notification"
                        onClose={() => setShowNotification(defaultNotificationState)}
                        onCloseButtonClick={function noRefCheck() { }}
                        statusIconDescription="notification"
                        subtitle={t(showNotification.reason)}
                        title={t(showNotification.title)}
                    />)
            }
            {modal &&
                <CustomModal
                    disable={disabled}
                    cancel={() => {
                        dispatch(view());
                        setElement(undefined);
                        setModal(false);
                    }}
                    lockUi={true}
                    confirm={() => {
                        dispatch(showStoreModal({
                            title: t(confirmModalTitle(SETTINGS_EVENT_TABLE, settings.mode)),
                            text: t(confirmModalText(SETTINGS_EVENT_TABLE, settings.mode)),
                            show: true,
                            cancel: () => dispatch(closeStoreModal()),
                            confirm: updateTypeEvent
                        }));
                    }}
                    show={modal}
                    title={t(modalTitle(SETTINGS_EVENT_TABLE, settings.mode))}
                    text={t(modalText(SETTINGS_EVENT_TABLE, settings.mode))}
                    children={getForm()}
                />
            }
        </>
    )

    function id(link: string, o: ObjectTableTypeEvent): JSX.Element {
        return singleRow(link, o.id ? `${o.id}` : '--', o.id ? `${o.id}` : '--', true);
    }

    function name(link: string, o: ObjectTableTypeEvent): JSX.Element {
        return singleRow(link, o.name ? `${o.name}` : '--', o.name ? `${o.name}` : '--', true);
    }

    function description(link: string, o: ObjectTableTypeEvent): JSX.Element {
        return singleRow(link, o.description ? `${o.description}` : '--', o.description ? `${o.description}` : '--', true);
    }

    async function download() {
        try {
            const response: AxiosResponse<GetTypeEvent200Response> = await ApiClient.getApi().getTypeEvent();
            if (response && response.data && response.data.data) {
                setEventTypes(response.data.data.sort(idAsc));
            }
        } catch (e) {
            setEventTypes([]);
        }
    }

    function editMode(id: string) {
        if (id.length === 0) {
            return;
        }
        if (!eventTypes) {
            return;
        }

        const e: ObjectTableTypeEvent | undefined = eventTypes.find((et) => `${et.id}` === id);
        if (!e) {
            return;
        }

        setElement(e);
        dispatch(edit({
            mode: SETTINGS_EDIT_MODE,
            id: id,
            type: SETTINGS_EVENT_TABLE
        }));
        setModal(true);
    }

    function getForm(): JSX.Element {

        const name: string = element ? (element.name ?? '') : '';
        const description: string = element ? (element.description ?? '') : '';

        return <SettingsGenericCreateForm
            elementName={name}
            elementDescription={description}
            onUpdateNameCallback={(name: string) => setElement({ ...element, name })}
            onUpdateDescriptionCallback={(description: string) => setElement({ ...element, description })}
        />
    }

    async function updateTypeEvent(): Promise<void> {
        if (!element || !eventTypes || eventTypes?.length == 0) {
            return;
        }

        if (disabled) {
            return;
        }

        setDisabled(true);

        try {
            let response;
            if (settings.mode === SETTINGS_EDIT_MODE) {
                const { id } = element;
                const copyElement: ObjectTableTypeEvent | undefined = eventTypes.find((et) => `${et.id}` === `${id}`);
                if (!copyElement) {
                    return;
                }
                if (element.name === copyElement.name) {
                    delete element.name;
                }
                if (element.description === copyElement.description) {
                    delete element.description;
                }
                response = await ApiClient.updateApi().updateTypeEvent(+settings.id, element);
            } else {
                response = await ApiClient.storeApi().addTypeEvent(element);
            }

            const { status } = response;
            if (status === 200 || status === 201) {
                if (settings.mode === SETTINGS_EDIT_MODE) {
                    const { data } = (response as AxiosResponse<ObjectTableTypeEvent>);
                    const index = eventTypes.findIndex((d) => d.id === data.id);
                    if (index !== -1) {
                        eventTypes[index] = data;
                        setEventTypes([...eventTypes]);
                    }
                } else {
                    setEventTypes([...eventTypes, response.data]);
                }

                onSuccessCallbackHandler(
                    setShowNotification, successTitle(SETTINGS_EVENT_TABLE, settings.mode), addSuccessText(SETTINGS_EVENT_TABLE, settings.mode)
                );
            } else {
                dispatch(view());
                onErrorCallbackHandler(`Error ${status}`, response.statusText, setShowNotification);
            }
        } catch (e) {
            let { response } = (e as AxiosError);
            onErrorCallbackHandler(
                response?.statusText ?? 'Timeout error',
                (response as AxiosResponse)?.data?.detail ?? 'Request was interrupted',
                setShowNotification
            );
        }
        setElement({});
        setModal(false);
        setDisabled(false);
        batch(() => {
            dispatch(closeStoreModal());
            dispatch(view());
        })
    }
}

export { EventTypeTable };
