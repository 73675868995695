import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import './BeaDropdown.css'

export interface IBeaDropdownProps {
    text: string;
    icon: React.ReactNode;
    callback?: () => void;
    children?: React.ReactNode;
    disabled?: boolean;
}

const BeaDropdown = ({ text, icon, callback, children, disabled }: IBeaDropdownProps) => {

    const [show, setShow] = useState<boolean>(false);
    const { t } = useTranslation();

    function handleClick(): void {
        if (children) {
            setShow(!show);
            return;
        }

        if (callback) {
            callback();
        }
    }

    function handleBlur(): void {
        setTimeout(() => setShow(false), 375);
    }

    return <>

        <div className='dd-container dropdown' tabIndex={-1} onBlur={handleBlur}>
            <CustomTooltip placement='bottom' title={t(text)} arrow>
                <div className={disabled ? 'bc-button-disabled' : (show ? 'bc-button-active' : 'bc-button')} onClick={handleClick}>
                    {icon}
                </div>
            </CustomTooltip>
            <div style={{ display: show ? 'block' : 'none' }}>
                {children}
            </div>
        </div>
    </>
}

export default BeaDropdown;