export const PROVENANCE_NAME = process.env.REACT_APP_BEA_PROVENANCE_NAME ?? "undefiend";
export const PROVENANCE_SOFTWARENAME = process.env.REACT_APP_BEA_PROVENANCE_SOFTWARENAME ?? "undefined";
export const PROVENANCE_VERSION = process.env.REACT_APP_BEA_PROVENANCE_VERSION ?? "undefined";
export const PROVENANCE_DESCRIPTION = process.env.REACT_APP_BEA_PROVENANCE_DESCRIPTION ?? "undefined";
export const TYPE_ORIGIN_LIST_STRING = process.env.REACT_APP_BEA_TYPE_ORIGIN ?? "";
export const TYPE_ORIGIN_LIST = TYPE_ORIGIN_LIST_STRING.split("|").map((to) => {
    let tokens = to.split('#')
    if(tokens.length !== 2) return to
    return `${tokens[0]} (${tokens[1]})`
});
export const REACT_APP_BEA_LOCALSPACE = process.env.REACT_APP_BEA_LOCALSPACE ?? "";
export const LOCALSPACE_LIST = REACT_APP_BEA_LOCALSPACE.split("|");
export const REACT_APP_BEA_LOCALSPACE_ENVIRONMENT_STRING = process.env.REACT_APP_BEA_LOCALSPACE_ENVIRONMENT ?? "";
export const LOCALSPACE_ENVIRONMENT = REACT_APP_BEA_LOCALSPACE_ENVIRONMENT_STRING.split("|");
export const STATIONS_BASE_URLS = process.env.REACT_APP_STATIONS_BASE_API?.split("|") ?? ["https://webservices.ingv.it/fdsnws"]