import './CustomModal.css'
import { Button, Modal } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { InlineLoading } from '@carbon/react'
import { useEffect, useState } from "react";

export interface CustomModalProps {
    title?: string;
    text?: string;
    cancel: () => void;
    confirm?: () => void;
    show: boolean;
    children?: JSX.Element;
    disable?: boolean;
    lockUi?: boolean;
}

const CustomModal = ({
    title, text, show, cancel, confirm, children, disable, lockUi
}: CustomModalProps) => {

    const { t } = useTranslation();
    const [onRequest, setOnRequest] = useState(false);

    useEffect(() => {
        if (!show) {
            setOnRequest(false);
        }
    }, [show])

    const confirmHandler = () => {
        if (confirm) {
            confirm();
            if(lockUi){
                return
            }
            setOnRequest(true);
        }
    }

    function cancelHandler(): void {
        if (!onRequest) {
            cancel();
        }
    }

    return (
        <Modal
            onRequestClose={cancelHandler}
            size={'xs'}
            passiveModal={true}
            open={show}
        >
            <div className="bea-modal-container">
                <div className="bea-modal-text-container">
                    <span className='title'>{t(title ?? '')}</span>
                    <span className='text'>{t(text ?? '')}</span>
                </div>
                {
                    children
                }
                <div className='bea-modal-button-container'>
                    <Button className="button bea-btn-black me-4" onClick={cancelHandler} disabled={onRequest}>
                        {t('cancel')}
                    </Button>
                    {
                        onRequest && <InlineLoading className={'modal-loader-loading-circle'} />
                    }
                    {!onRequest &&
                        <Button className="button bea-btn ms-4" onClick={confirmHandler} disabled={disable}>
                            {t('confirm')}
                        </Button>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default CustomModal