import Keycloak from "keycloak-js";

const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL ?? 'http://localhost:8080/auth'
const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM ?? 'ingv-bea'
const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'bea'

const keycloak = new Keycloak({
 url: KEYCLOAK_URL,
 realm: KEYCLOAK_REALM,
 clientId: KEYCLOAK_CLIENT_ID,
});

export { keycloak }