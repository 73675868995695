import {MagnitudeCreationForm, OriginCreationForm} from "./CreateEvent";
import * as utility from "../../utils";
import {LOCALSPACE_LIST, TYPE_ORIGIN_LIST} from "../../utils/process";

export const validateOriginCreationForm = function (originForm: OriginCreationForm, originEnabled: boolean) {
    if(!originEnabled) return true
    let {lat, err_lat, lon, err_lon} = originForm
    let {err_h, err_ot, rms, azim_gap} = originForm
    return utility.isValidLatitude(lat ?? "", false) &&
        utility.isValidLongitude(lon ?? "", false) &&
        utility.isValidDepth(originForm.depth ?? "", false)  &&
        utility.isValid(err_lat ?? "", Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, true) &&
        utility.isValid(err_lon ?? "", Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, true) &&
        utility.isValid(err_h ?? "", Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, true) &&
        utility.isValid(err_ot ?? "", Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, true) &&
        utility.isValid(rms ?? "", Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, true) &&
        utility.isValid(azim_gap ?? "", Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, true)
}

export const validateMagnitudeCreationForm = function (magnitudeForm: MagnitudeCreationForm): boolean {
    let {mag, upper_uncertainty, lower_uncertainty, type_magnitude} = magnitudeForm
    return type_magnitude !== undefined &&
        utility.isValid(mag ?? "", -1, 10, false) &&
    utility.isValid(upper_uncertainty ?? "", Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, true) &&
    utility.isValid(lower_uncertainty ?? "", Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, true)
}

/**
 * It returns true if the localspace is properly set; false otherwise
 * @param localspace
 */
export const isValidLocalspace = function (localspace?: string): boolean {
    if(!localspace)
        return false
    // we check that the given localspace is in those in the .env list
    return LOCALSPACE_LIST.findIndex((l) => l === localspace) !== -1
}

/**
 * It returns true if the type_origin is properly set; false otherwise
 * @param name
 * @param version_name
 * @param version_value
 */
export const isValidTypeOrigin = function(name?: string, version_name?: string, version_value?: number): boolean {
    // step 1: version_name, version_value are required;  name must be hypocenter
    if(!version_name)
        return false

    if(!version_value)
        return false

    if(name !== 'hypocenter')
        return false

    // we check that the given type origin is in those in the .env list
    return TYPE_ORIGIN_LIST.findIndex((to) => to === `${version_name} (${version_value})`) !== -1
}