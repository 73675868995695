import './SettingsCreateForm.css'
import {useTranslation} from "react-i18next";
import { TextInput, Dropdown } from '@carbon/react';
import React, {useEffect, useState} from "react";
import { ObjectTableTypeOrigin, TypeOriginName } from '../../services/network';

interface SettingsCreateFormProps {
    element: ObjectTableTypeOrigin
    onUpdateElement: (element: ObjectTableTypeOrigin) => void
}

const SettingsOriginCreateForm = ({
    element, onUpdateElement
}: SettingsCreateFormProps) => {

    const {t} = useTranslation()
    const [localElement, setLocalElement] = useState<ObjectTableTypeOrigin>(element)

    interface SelectedDropDown {
        selectedItem: string;
    }

    const updateName = (e: SelectedDropDown) => setLocalElement({
        ...localElement,
        name: e.selectedItem as TypeOriginName
    })
    const updateVersionName = (e: React.FormEvent<HTMLInputElement>) => setLocalElement({
        ...localElement,
        version_name: e.currentTarget.value
    })
    const updateVersionValue = (e: React.FormEvent<HTMLInputElement>) => setLocalElement({
        ...localElement,
        version_value: +e.currentTarget.value
    })
    const updateDescription = (e: React.FormEvent<HTMLInputElement>) => setLocalElement({
        ...localElement,
        description: e.currentTarget.value
    })

    useEffect(() => {
        onUpdateElement(localElement)
    }, [localElement])

    const originTypeName: string[] = [
        'hypocenter', 'centroid', 'amplitude', 'macroseismic', 'rupture start', 'rupture end'
    ]

    return(<>
        <div className={'settings-create-form'}>
            <Dropdown
                titleText={t('settings_table_form_name')}
                onChange={updateName}
                items={originTypeName.sort((o1, o2) => o1.localeCompare(o2))}
                selectedItem={element.name}
                initialSelectedItem={t('create_event_choose_value')}
            />
            <span className={'label mb-8 mt-24'}>{t('settings_table_form_versionname')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-name"
                hideLabel
                value={localElement.version_name}
                onChange={updateVersionName}
            />
            <span className={'label mb-8 mt-24'}>{t('settings_table_form_versionvalue')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-name"
                hideLabel
                value={localElement.version_value}
                onChange={updateVersionValue}
            />
            <span className={'label mb-8 mt-24'}>{t('settings_table_form_description')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-description"
                hideLabel
                value={localElement.description}
                onChange={updateDescription}
            />
        </div>
    </>)
}

export {
    SettingsOriginCreateForm
}