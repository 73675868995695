import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as SortAscIcon } from '../../assets/sort-asc.svg';
import { ReactComponent as SortDescIcon } from '../../assets/sort-desc.svg';
import { ReactComponent as SortNormalIcon } from '../../assets/sort-normal.svg';
import { ObjectLocalspace, ObjectProvenance } from "../../services/network";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import './GenericTable.css';
import { Provenance } from "./components/Provenance";

interface GenericTableProps {
    headers: IHeader[];
    rows: Row[];
    gridDefinition: string;
    defaultSorting?: Sorting;
    selectedRow?: string;
    onClickCallback?: (id: string) => void;
    onHoverCallback?: (id: string) => void;
}

export interface IHeader {
    tag: string;
    label: string;
    sortable: boolean;
    sortFunction?: (direction: SortDirection) => void;
}

export interface ICell {
    tag: string;
    element: JSX.Element;
    id: string;
}

export type Row = ICell[];

export const SORT_ASC = 1;
export const SORT_DESC = -1;
export const SORT_NORM = 0;

export type SortDirection = 1 | -1 | 0

export interface Sorting {
    direction: SortDirection;
    tag: string;
}

function emptyCell(link: string): JSX.Element {
    return (<Link to={link}>
        <span>--</span>
    </Link>);
}

function singleRow(link: string, row: string, tooltip: string, removeSecondRow?: boolean): JSX.Element {
    return twoRows(link, row, '', tooltip, removeSecondRow);
}

function twoRows(link: string, firstRow: string, secondRow: string, tooltip: string, removeSecondRow?: boolean): JSX.Element {
    if (firstRow === '--') {
        return emptyCell(link);
    }
    return (<CustomTooltip placement="top" title={tooltip} arrow>
        <Link to={link}>
            <span>{firstRow}</span>
            {
                !removeSecondRow &&
                <div className={'subtitle'}>
                    <span>{secondRow}</span>

                </div>
            }
        </Link>
    </CustomTooltip>);
}

function ot(link: string, ot: string): JSX.Element {
    if (!ot) {
        return <span>--</span>;
    }
    const tokens: string[] = ot.split('T');
    if (tokens.length === 2) {
        return twoRows(link, tokens[0], tokens[1].replace('+00:00', ''), ot);
    }

    return <span>Invalid format</span>;
}

function localspace(link: string, localspace?: ObjectLocalspace): JSX.Element {
    return twoRows(link, localspace?.name ?? '--', localspace?.description ?? '--', `${localspace?.name ?? '--'} (${localspace?.description ?? '--'})`);
}

export interface IProvenanceCellProps {
    link: string;
    p?: ObjectProvenance;
    reverse: boolean;
}
function ProvenanceCell({ link, p, reverse }: IProvenanceCellProps): JSX.Element {
    const { t } = useTranslation();
    if (p) {
        return (<CustomTooltip placement="top" title={t('ot__show_provenance')} arrow>
            <Link to={link}>
                <Provenance provenance={p} label="provenance" reverse={reverse}/>
            </Link>
        </CustomTooltip>);

    }
    return (<></>);
}

function GenericTable({
    headers, rows: body, gridDefinition, defaultSorting, selectedRow, onClickCallback, onHoverCallback
}: GenericTableProps) {
    const { t } = useTranslation();
    const [sorting, setSorting] = useState<Sorting>(defaultSorting ?? {
        direction: SORT_NORM,
        tag: ''
    });

    function sortableIcon(header: IHeader): JSX.Element {
        const { direction, tag } = sorting;

        if (!header.sortable) {
            return <></>
        }
        if (header.tag !== tag) {
            return <SortNormalIcon />
        }

        return direction === SORT_ASC
            ? <SortAscIcon />
            : <SortDescIcon />
    }

    function sortElements(header: IHeader): void {
        console.log('sortElements', header);
        if (header.sortable) {
            const newSorting: Sorting = updateSorting(header.tag);
            if (header.sortFunction) {
                // update sort direction and header
                header.sortFunction(newSorting.direction);
            }
            setSorting(newSorting);
        }
    }

    function updateSorting(tag: string): Sorting {
        return {
            tag,
            direction: tag === sorting.tag ? (sorting.direction === SORT_ASC ? SORT_DESC : SORT_ASC) : SORT_ASC
        };
    }

    function handleClick(id: string): void {
        if (onClickCallback) {
            onClickCallback(id);
        }
    }

    function handleHover(id: string): void {
        if (onHoverCallback) {
            onHoverCallback(id);
        }
    }

    function optionalThStyle(tag: string): string {
        switch (tag) {
            case `products_tag`: return `ot-th-p-08`;
            case `provenance_tag`: return `ot-th-p-08`;
            case `cta_tag`: return `ot-th-p-08`;
            case `qty_tag`: return `ot-th-p-08`;
        }
        return ``;
    }

    function optionalTdStyle(tag: string): string {
        switch (tag) {
            case `products_tag`: return `ot-td-p-08`;
            case `provenance_tag`: return `ot-td-p-08`;
            case `cta_tag`: return `ot-td-p-08`;
            case `qty_tag`: return `ot-td-p-08`;
        }
        return ``;
    }

    // check if headers and body have the same tas
    return (
        <div id='origin-table' className='origin-table'>
            <div className={'ot-table'}>
                <div className={`${gridDefinition} ot-row ot-header`}>
                    {
                        headers.map((h) => (
                            <div className={`ot-th ${optionalThStyle(h.tag)}`} key={h.tag} onClick={() => sortElements(h)}>
                                <CustomTooltip key={h.tag} title={t(`${h.label}_ext`)} placement="top" arrow>
                                    <span>{t(h.label)}</span>
                                </CustomTooltip>
                                {
                                    h.sortable && <div className={'icon'}>
                                        {sortableIcon(h)}
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>

                {
                    body.map((row) => {
                        const id: string = row.filter((cell) => cell.tag === 'id_tag')[0].id;
                        return (<div
                        className={`${gridDefinition} ot-row ot-body ${selectedRow === id ? 'selected' : ''}`}
                        onMouseEnter={() => handleHover(id)}
                        onClick={() => handleClick(id)}>
                            {
                                headers.map((h) => <div className={`ot-td ${optionalTdStyle(h.tag)}`}>{
                                    row.filter((cell) => cell.tag === h.tag)[0].element}
                                </div>)
                            }
                        </div>);
                    })
                }
            </div>
        </div >
    )
}

export { GenericTable, ProvenanceCell, localspace, ot, singleRow, twoRows };

