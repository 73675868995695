import {useState} from 'react';
import { useTranslation } from "react-i18next";
import './LanguageSwitcher.css'

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.resolvedLanguage)

  const updateLanguage = () => {
    i18n.changeLanguage(language === 'it' ? 'en' : 'it')
    setLanguage(language === 'it' ? 'en' : 'it')
  }

  return (
      <div className="dropdown switcher" onClick={updateLanguage}>
        <div className="spacer-56 centered">
          <p className="selected-language">{language}</p>
        </div>
    </div>
  );
}
export default LanguageSwitcher;