import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as RadioOptionSelected } from '../../assets/cs-selected-dark.svg';
import { ReactComponent as RadioOptionUnselected } from '../../assets/cs-unselected.svg';
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import { setFilters } from "../../redux/reducers/filters.slice";
import { GetApiAxiosParamCreator } from "../../services/network";

const SettingsRouteSelector = function () {

    const { t } = useTranslation();
    const filters = useAppSelector((state) => state.filters);
    const dispatch = useAppDispatch();

    const [url, setUrl] = useState<[string, string][]>([])

    useEffect(() => {
        Promise.all([
            GetApiAxiosParamCreator().getEventsGroup(),
            GetApiAxiosParamCreator().getEvents(),
            GetApiAxiosParamCreator().getOrigins(),
            GetApiAxiosParamCreator().getMagnitudes(),
            GetApiAxiosParamCreator().getAll(),
            GetApiAxiosParamCreator().getEventsCatalog()
        ]).then(([
            a1, a2, a3, a4, a5, a6
        ]) => {
            console.log(a1.url, a2.url, a3.url, a4.url, a5.url, a6.url)
            setUrl([
                ['events-group', a1.url],
                ['events', a2.url],
                ['origins', a3.url],
                ['magnitudes', a4.url],
                ['all', a5.url],
                ['events-catalog', a6.url]
            ])
        });

    }, [filters])

    return (<>
        <div className="route-container">
            <div className="route-body">
                <div className="title">
                    <span>{t('routes__title')}</span>
                </div>
                <div className="option">
                    <div className="routetext">
                        {filters.route === 'events-group'
                            ? <RadioOptionSelected />
                            : <RadioOptionUnselected onClick={() => dispatch(setFilters({ ...filters, route: 'events-group' }))} />
                        }
                        <span>{url.length > 0 ? url[0][0] : ''}</span>
                    </div>
                    <div style={{ paddingLeft: '28px' }}>
                        <span>{t('routes__downloaded_from_default', { url: url.length > 0 ? url[0][1] : '' })}</span>
                    </div>
                </div>

                <div className="option">
                    <div className="routetext">
                        {filters.route === 'events'
                            ? <RadioOptionSelected />
                            : <RadioOptionUnselected onClick={() => dispatch(setFilters({ ...filters, route: 'events' }))} />
                        }
                        <span>{url.length > 0 ? url[1][0] : ''}</span>
                    </div>
                    <div style={{ paddingLeft: '28px' }}>
                        <span>{t('routes__downloaded_from', { url: url.length > 0 ? url[1][1] : '' })}</span>
                    </div>
                </div>

                <div className="option">
                    <div className="routetext">
                        {filters.route === 'origins'
                            ? <RadioOptionSelected />
                            : <RadioOptionUnselected onClick={() => dispatch(setFilters({ ...filters, route: 'origins' }))} />
                        }
                        <span>{url.length > 0 ? url[2][0] : ''}</span>
                    </div>
                    <div style={{ paddingLeft: '28px' }}>
                        <span>{t('routes__downloaded_from', { url: url.length > 0 ? url[2][1] : '' })}</span>
                    </div>
                </div>

                <div className="option">
                    <div className="routetext">
                        {filters.route === 'magnitudes'
                            ? <RadioOptionSelected />
                            : <RadioOptionUnselected onClick={() => dispatch(setFilters({ ...filters, route: 'magnitudes' }))} />
                        }
                        <span>{url.length > 0 ? url[3][0] : ''}
                        </span>
                    </div>
                    <div style={{ paddingLeft: '28px' }}>
                        <span>{t('routes__downloaded_from', { url: url.length > 0 ? url[3][1] : '' })}</span>
                    </div>
                </div>

                <div className="option">
                    <div className="routetext">
                        {filters.route === 'all'
                            ? <RadioOptionSelected />
                            : <RadioOptionUnselected onClick={() => dispatch(setFilters({ ...filters, route: 'all' }))} />
                        }
                        <span>{url.length > 0 ? url[4][0] : ''}</span>
                    </div>
                    <div style={{ paddingLeft: '28px' }}>
                        <span>{t('routes__downloaded_from', { url: url.length > 0 ? url[4][1] : '' })}</span>
                    </div>
                </div>

                <div className="divider"></div>

                <div className="option">
                    <div className="routetext">
                        {filters.route === 'events-catalog'
                            ? <RadioOptionSelected />
                            : <RadioOptionUnselected onClick={() => dispatch(setFilters({ ...filters, route: 'events-catalog' }))} />
                        }
                        <span>{url.length > 0 ? url[5][0] : ''}</span>
                    </div>
                    <div style={{ paddingLeft: '28px' }}>
                        <span>{t('routes__downloaded_from', { url: url.length > 0 ? url[5][1] : '' })}</span>
                    </div>
                </div>

            </div>
        </div>
    </>)
}

export {
    SettingsRouteSelector
};
