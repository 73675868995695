import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks"
import { italy, utc } from "../../redux/reducers/timezone.slice"
import './TimezoneSelector.css'
import {Dropdown} from '@carbon/react'
import {Option} from "../CustomSelector/CustomSelector";

interface SelectedItem {
    selectedItem: Option
}

const NewTimezoneSelector = () => {

    const tz = useAppSelector((state) => state.timezone)
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const changeTimezone = (selected: SelectedItem) => {
        console.log('changeTimezone => selected =>', selected)
        let {selectedItem} = selected
        let {key} = selectedItem
        if(key === 'utc')
            dispatch(utc())
        else
            dispatch(italy())
    }

    const options: Option[] = [
        {
            key: 'utc',
            value: t('timezone_selector_utc')
        },
        {
            key: 'italy',
            value: t('timezone_selector_italy')
        }
    ]

    return (
        <div style={{maxHeight: '40px', width: '180px'}}>
            <Dropdown
                id={'new-timezone-selector'}
                label={''}
                hideLabel
                titleText={''}
                onChange={changeTimezone}
                items={options}
                itemToString={(item: Option) => item.value}
                selectedItem={options[tz === 'utc' ? 0 : 1]}
            />
        </div>)
}

export default NewTimezoneSelector