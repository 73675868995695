import './BeaButton.css';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

export interface IBeaButtonProps {
    title: string;
    variant: 'primary' | 'secondary' | 'ghost';
    onClick: () => void;
    disabled: boolean;
    height?: string
}

function BeaButton({title, variant, onClick, disabled, height}: IBeaButtonProps): JSX.Element {

    const {t} = useTranslation();

    const [classes, setClasses] = useState<string>('bea-button');

    useEffect(() => {
        let c: string = `bea-button`;

        switch (variant) {
            case 'ghost':
            case 'secondary':
            case 'primary': c = `${c} bea-${variant}${disabled ? '-disabled' : ''}`; break;
            default: c = `${c} bea-primary${disabled ? '-disabled' : ''}`; break;
        }

        setClasses(c);
    }, [variant, disabled])


    function onClickHandler(): void {
        if(disabled) {
            return;
        }
        onClick();
    }

    function getStyle() {
        if(height) {
            return {height}
        }
        return {}
    }

    return(<div role={'button'} className={classes} onClick={onClickHandler} style={getStyle()}>
        {t(title)}
    </div>);    
}

export {
    BeaButton
}