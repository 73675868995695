import 'dayjs/locale/it'
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import './DoubleRowRegion.css'

interface DoubleRowRegionParams {
    firstRow: string,
    secondRow: string,
    className?: string,
}

function DoubleRowGeneric({ firstRow: title, secondRow: content, className }: DoubleRowRegionParams) {

    return (
        <div className={`doublerowregion-container ${className}`}>
            <CustomTooltip title={`${title} - ${content}`} placement="top" arrow>
                <span className={`cell-main-text`}>{title}</span>
            </CustomTooltip>
            <CustomTooltip title={`${title} - ${content}`} placement="top" arrow>
                <span className='cell-sub-text'>{content}</span>
            </CustomTooltip>
        </div>
    )
}

export { DoubleRowGeneric }
