import './Loader.css'
import {InlineLoading} from '@carbon/react'
import { useTranslation } from "react-i18next";

interface LoaderTranslatedProps {
    small?: boolean;
    white?: boolean;
}

export function LoaderTranslated({small, white}: LoaderTranslatedProps){

    const {t} = useTranslation()

    function getClass(): string {
        let className: string = 'loader-container';
        if(small) {
            className = `${className}-small`;
        }

        if(white) {
            className = `${className} white`;
        }

        return className;
    }

    return <>
            <div className={getClass()}>
                <h1 className='loader-loading'>{t('component__cities_table__loading')}</h1>
                <div><InlineLoading className="loader-loading-circle" /></div>
            </div>
        </>
}