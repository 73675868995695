import { useState } from "react"
import { useTranslation } from "react-i18next"
import { batch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks"
import { closeCta } from "../../redux/reducers/cta.slice"
import { resetTable } from "../../redux/reducers/customtable.slice"
import { Filters, defaultFilters } from "../../redux/reducers/filters.slice"
import { viewMode } from "../../redux/reducers/group.slice"
import { resetTimePicker } from "../../redux/reducers/timepicker.slice"
import { keycloak } from "../../services/auth/Keycloak"
import { createUrlFromFilters } from "../../utils/urlFromFilters"
import CustomTooltip from "../CustomTooltip/CustomTooltip"
import './FiltersBox.css'
import './Other.css'
import { chipText, evaluateDatetimeChip, evaluateDepthChip, evaluateGeofenceChip, evaluateMagnitudeChip } from "./filtersBoxUtils"
import { ReactComponent as IconExpandLess } from '../../assets/other-chips.svg'

export interface ChipProps {
    valueKey: string;
    value: string;
    tag: string;
}

function Chip({ tag, valueKey, value }: ChipProps): JSX.Element {
    const { t } = useTranslation();
    return (<div id={tag} className={'filterbox-chip'}>
        <span className="fb-light-text">{valueKey}: </span>
        <CustomTooltip title={chipText(value, t)} arrow>
            <span className="fb-bold-text">{chipText(value, t)}</span>
        </CustomTooltip>
    </div>);
}

const FiltersBox = () => {

    const { t } = useTranslation()
    const filters = useAppSelector((state) => state.filters);
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [expanded, isExpanded] = useState<boolean>(false);

    const hasFilters = () => {
        const f: string = Object.entries(filters)
            .filter((e) => e[1] !== undefined)
            .sort((e1, e2) => e1[0].localeCompare(e2[0]))
            .map((e) => `${e[0]}=${e[1]}`)
            .join(',');
        const d: string = Object.entries({ ...defaultFilters, route: filters.route })
            .filter((e) => e[1] !== undefined)
            .sort((e1, e2) => e1[0].localeCompare(e2[0]))
            .map((e) => `${e[0]}=${e[1]}`)
            .join(',');
        return f !== d;
    }

    const hasChips = () => {
        const container: HTMLElement | null = document.getElementById('filterbox-container');
        if (container) {
            if (container.clientHeight != 24) {
                return true;
            }
            if (container.clientHeight < container.scrollHeight) {
                return true;
            }
        }

        return false;
    };

    const evaluateVersion = function (filters: Filters): string | undefined {
        let { mintypeoriginvalue, maxtypeoriginvalue, wheretypeoriginvaluein } = filters
        if (!mintypeoriginvalue && !maxtypeoriginvalue && !wheretypeoriginvaluein)
            return

        let version = ''
        if (mintypeoriginvalue) {
            version = `${mintypeoriginvalue}`
        }
        if (maxtypeoriginvalue) {
            if (version === '') {
                version = `${maxtypeoriginvalue}`
            } else {
                version = `${version}-${maxtypeoriginvalue}`
            }
        }

        if (wheretypeoriginvaluein) {
            if (version === '') {
                return wheretypeoriginvaluein
            } else {
                version = `${version},${wheretypeoriginvaluein}`
            }
        }

        if (version !== '')
            return version
    }

    function removeKeys(e: [key: string, value: any]): boolean {
        return ![
            'orderby', 'route', 'ref_key', 'ref_region', 'page', 'sortHeader', 'sortDirection', 'limit', 'shape',
            'starttime', 'endtime', 'lat', 'lon', 'minradiuskm', 'maxradiuskm',
            // origin section
            'whereoriginqualityin', 'mintypeoriginvalue', 'maxtypeoriginvalue', 'wheretypeoriginvaluein',
            // depth section
            'mindepth', 'maxdepth',
            // magnitude section
            'wheretypemagnitudenameregexp', 'wheremagnitudemagqualityin', 'minmag', 'maxmag',
            // product section
            'products',
            // localspace section
            'id_localspace',
            'whereeventlocalspaceenvironmentin', 'whereeventlocalspacenamein',
            'whereoriginlocalspaceenvironmentin', 'whereoriginlocalspacenamein',
            'wheremagnitudelocalspaceenvironmentin', 'wheremagnitudelocalspacenamein',
            // search
            'eventid', 'originid', 'magnitudeid',
            // others
            'wheretypeeventnamein', 'wheretypeeventnamenotin',
            'origindirectlinktoevent', 'magnitudedirectlinktoorigin', 'magnitudedirectlinktoevent',
            'orpolygon', 'notinpolygon', 'wherepolygonnamein',
            'minnphtot', 'mindistance',
            'eventupdatedafter', 'originupdatedafter', 'magnitudeupdatedafter', 'updatedafteroperator',
        ]
            .some((k) => k === e[0]);
    }

    function dateTime(filters: Filters): JSX.Element {
        const chip: string[] | undefined = evaluateDatetimeChip(filters);
        if (chip) {
            return (<Chip {...{ valueKey: t(chip[0]), tag: 'datetime', value: chip[1] }} />);
        }
        return <></>
    }

    function magnitude(filters: Filters): JSX.Element {
        const mag: string | undefined = evaluateMagnitudeChip(filters);
        const { wheretypemagnitudenameregexp, wheremagnitudemagqualityin } = filters;
        return <>
            {wheretypemagnitudenameregexp && <Chip {...{ valueKey: t('wheretypemagnitudenameregexp'), tag: 'wheretypemagnitudenameregexp', value: wheretypemagnitudenameregexp }} />}
            {wheremagnitudemagqualityin && <Chip {...{ valueKey: t('wheremagnitudemagqualityin'), tag: 'wheremagnitudemagqualityin', value: wheremagnitudemagqualityin }} />}
            {mag && <Chip {...{ valueKey: t('magnitude'), tag: 'magnitude', value: mag }} />}
        </>
    }

    function geofence(filters: Filters): JSX.Element {
        const chip: string | undefined = evaluateGeofenceChip(filters);
        if (chip) {
            return <Chip {...{ valueKey: t('geofence'), tag: 'geofence', value: chipText(chip, t) }} />
        }
        return <></>
    }

    function origin(filters: Filters): JSX.Element {
        const { whereoriginqualityin } = filters;
        const version: string | undefined = evaluateVersion(filters);
        return (<>
            {whereoriginqualityin && <Chip {...{ tag: 'whereoriginqualityin', valueKey: t('whereoriginqualityin'), value: whereoriginqualityin }} />}
            {version && <Chip {...{ tag: 'version', valueKey: t('version'), value: version }} />}
        </>);
    }

    function depth(filters: Filters): JSX.Element {
        const chip: string | undefined = evaluateDepthChip(filters);
        if (chip) {

            return <Chip {...{ valueKey: t('depth'), tag: 'depth', value: chip }} />
        }
        return <></>
    }

    function products(filters: Filters): JSX.Element {
        if (filters.products) {
            const { products } = filters;
            return <Chip {...{ valueKey: t('products'), tag: 'products', value: products.replaceAll(',', ', ') }} />
        }
        return <></>
    }

    function localspace(filters: Filters): JSX.Element {
        const { id_localspace } = filters;
        const { whereeventlocalspaceenvironmentin, whereeventlocalspacenamein } = filters;
        const { whereoriginlocalspaceenvironmentin, whereoriginlocalspacenamein } = filters;
        const { wheremagnitudelocalspaceenvironmentin, wheremagnitudelocalspacenamein } = filters;
        return (<>
            {id_localspace && <Chip {...{ tag: 'id_localspace', valueKey: t('id_localspace'), value: id_localspace }} />}
            {whereeventlocalspaceenvironmentin && <Chip {...{ tag: 'whereeventlocalspaceenvironmentin', valueKey: t('eventLocalspaceEnv'), value: whereeventlocalspaceenvironmentin.replaceAll(',', ', ') }} />}
            {whereeventlocalspacenamein && <Chip {...{ tag: 'whereeventlocalspacenamein', valueKey: t('eventLocalspaceName'), value: whereeventlocalspacenamein.replaceAll(',', ', ') }} />}
            {whereoriginlocalspaceenvironmentin && <Chip {...{ tag: 'whereoriginlocalspaceenvironmentin', valueKey: t('originLocalspaceEnv'), value: whereoriginlocalspaceenvironmentin.replaceAll(',', ', ') }} />}
            {whereoriginlocalspacenamein && <Chip {...{ tag: 'whereoriginlocalspacenamein', valueKey: t('originLocalspaceName'), value: whereoriginlocalspacenamein.replaceAll(',', ', ') }} />}
            {wheremagnitudelocalspaceenvironmentin && <Chip {...{ tag: 'wheremagnitudelocalspaceenvironmentin', valueKey: t('magnitudeLocalspaceEnv'), value: wheremagnitudelocalspaceenvironmentin.replaceAll(',', ', ') }} />}
            {wheremagnitudelocalspacenamein && <Chip {...{ tag: 'wheremagnitudelocalspacenamein', valueKey: t('magnitudeLocalspaceName'), value: wheremagnitudelocalspacenamein.replaceAll(',', ', ') }} />}
        </>);
    }

    function mins(filters: Filters): JSX.Element {
        const { minnphtot, mindistance } = filters;
        // minnphtot=2&mindistance=5
        return (<>
            {minnphtot && <Chip {...{ tag: 'minnphtot', valueKey: t('minnphtot'), value: minnphtot }} />}
            {mindistance && <Chip {...{ tag: 'mindistance', valueKey: t('mindistance'), value: mindistance }} />}
        </>);
    }


    function search(filters: Filters): JSX.Element {
        const { eventid, originid, magnitudeid } = filters;
        return (<>
            {eventid && <Chip {...{ valueKey: t('eventid'), tag: 'eventid', value: eventid }} />}
            {originid && <Chip {...{ valueKey: t('originid'), tag: 'originid', value: originid }} />}
            {magnitudeid && <Chip {...{ valueKey: t('magnitudeid'), tag: 'magnitudeid', value: magnitudeid }} />}
        </>);
    }

    function directlink(filters: Filters): JSX.Element {
        // origindirectlinktoevent=true&magnitudedirectlinktoorigin=false&magnitudedirectlinktoevent=true
        const { origindirectlinktoevent, magnitudedirectlinktoorigin, magnitudedirectlinktoevent } = filters;
        return (<>
            {origindirectlinktoevent !== undefined && <Chip {...{ valueKey: t('origindirectlinktoevent'), tag: 'origindirectlinktoevent', value: JSON.stringify(origindirectlinktoevent) }} />}
            {magnitudedirectlinktoorigin !== undefined && <Chip {...{ valueKey: t('magnitudedirectlinktoorigin'), tag: 'magnitudedirectlinktoorigin', value: JSON.stringify(magnitudedirectlinktoorigin) }} />}
            {magnitudedirectlinktoevent !== undefined && <Chip {...{ valueKey: t('magnitudedirectlinktoevent'), tag: 'magnitudedirectlinktoevent', value: JSON.stringify(magnitudedirectlinktoevent) }} />}
        </>);
    }

    function typeeventname(filters: Filters): JSX.Element {
        // wheretypeeventnamein=nuclear explosion,avalanche&wheretypeeventnamenotin=not existing
        const { wheretypeeventnamein, wheretypeeventnamenotin } = filters;
        return (<>
            {wheretypeeventnamein && <Chip {...{ valueKey: t('wheretypeeventnamein'), tag: 'wheretypeeventnamein', value: wheretypeeventnamein.replaceAll(',', ', ') }} />}
            {wheretypeeventnamenotin && <Chip {...{ valueKey: t('wheretypeeventnamenotin'), tag: 'wheretypeeventnamenotin', value: wheretypeeventnamenotin.replaceAll(',', ', ') }} />}
        </>);
    }

    function polygon(filters: Filters): JSX.Element {
        // orpolygon=12,42,13,42,13,43,12,43,12,42|12.3,42.3,13.3,42.3,13.3,43.3,12.3,43.3,12.3,42.3&notinpolygon=12.5,42,12.5,43,13,48,12.5,42|12.2,41.8,13.2,41.8,13.2,42.8,12.2,42.8,12.2,41.8&wherepolygonnamein=area_confine_italia,area_vulcanica_italia
        const { orpolygon, notinpolygon, wherepolygonnamein } = filters;
        return (<>
            {orpolygon && <Chip {...{ valueKey: t('orpolygon'), tag: 'orpolygon', value: orpolygon }} />}
            {notinpolygon && <Chip {...{ valueKey: t('notinpolygon'), tag: 'notinpolygon', value: notinpolygon }} />}
            {wherepolygonnamein && <Chip {...{ valueKey: t('wherepolygonnamein'), tag: 'wherepolygonnamein', value: wherepolygonnamein.replaceAll(',', ', ') }} />}
        </>);
    }

    function updateafter(filters: Filters): JSX.Element {
        const { eventupdatedafter, originupdatedafter, magnitudeupdatedafter, updatedafteroperator } = filters
        return (<>
            {eventupdatedafter && <Chip {...{ valueKey: t('eventupdatedafter'), tag: 'eventupdatedafter', value: eventupdatedafter }} />}
            {originupdatedafter && <Chip {...{ valueKey: t('originupdatedafter'), tag: 'originupdatedafter', value: originupdatedafter }} />}
            {magnitudeupdatedafter && <Chip {...{ valueKey: t('magnitudeupdatedafter'), tag: 'magnitudeupdatedafter', value: magnitudeupdatedafter }} />}
            {updatedafteroperator && <Chip {...{ valueKey: t('updatedafteroperator'), tag: 'updatedafteroperator', value: updatedafteroperator }} />}
        </>);
    }

    return (
        <div className="filterbox">
            <div style={{ margin: "8px 24px", height: '24px' }}></div>
            <div id="filterbox-container" className="filterbox-container" style={expanded
                ? { minHeight: '24px', height: 'auto', maxHeight: '400px' }
                : { minHeight: '24px', height: '24px' }
            }>
                {hasChips() &&
                    <div className='other-chips' style={{ position: 'inherit', top: '0', right: '0' }} onClick={() => isExpanded(!expanded)}>
                        <span className="label">{t('other__filters__chip')}</span>
                        <div className='icon'>
                            <IconExpandLess className={expanded ? 'rotate' : 'normal'} />
                        </div>
                    </div>}
                {
                    filters.route !== 'events-group' && !!keycloak.authenticated &&
                    <div key={'fp-route'} className={'filterbox-chip-transparent'}>
                        <span className="fb-light-text">{t('route')}: </span>
                        <span className="fb-bold-text">{chipText(filters.route, t)}</span>
                        {Object.entries(filters).length > 0 && <div className={'fb-divider'}>-</div>}
                    </div>
                }
                {dateTime(filters)}
                {geofence(filters)}
                {origin(filters)}
                {depth(filters)}
                {magnitude(filters)}
                {products(filters)}
                {localspace(filters)}
                {typeeventname(filters)}
                {polygon(filters)}
                {directlink(filters)}
                {mins(filters)}
                {updateafter(filters)}
                {
                    Object.entries(filters)
                        .filter(removeKeys)
                        .filter((e) => e[1] !== undefined)
                        .map(
                            (e, index) =>
                                <div key={index} className={'filterbox-chip'}>
                                    <span className="fb-light-text">{e[0]}: </span>
                                    <span className="fb-bold-text">{e[1]}</span>
                                </div>
                        )
                }
                {search(filters)}
            </div>
            {/*check if there are default filters or not*/}
            {
                (hasFilters()) &&
                <div
                    className="fb-remove-filter"
                    onClick={() => {
                        batch(() => {
                            dispatch(resetTimePicker())
                            dispatch(resetTable())
                            dispatch(closeCta())
                            dispatch(viewMode())
                        })
                        navigate(`/earthquakelist?${createUrlFromFilters({
                            ...defaultFilters,
                            route: filters.route,
                        })}`)
                    }}
                >
                    <span className={'fb-remove-filter-label'}>
                        {t('remove_filters')}
                    </span>
                    <span className={'fb-remove-filter-close'}>
                        X
                    </span>
                </div>
            }
        </div>
    )
}

export default FiltersBox