import {
    AddMagnitudeRequest,
    AddOriginRequest,
    GetEvent200Response,
    ObjectLocalspace,
    ObjectMagnitude, ObjectOrigin,
    ObjectProvenance
} from "../../services/network";
import {
    PROVENANCE_DESCRIPTION,
    PROVENANCE_NAME,
    PROVENANCE_SOFTWARENAME,
    PROVENANCE_VERSION
} from "../../utils/process";
import {EventCreationForm, MagnitudeCreationForm, OriginCreationForm, TypeOriginCreationForm} from "./CreateEvent";
import Keycloak from "keycloak-js";


const buildLocalspace = function(eventForm: EventCreationForm): ObjectLocalspace | undefined{
    return eventForm.localspace
            ? { name: eventForm.localspace }
            : undefined
}

const buildProvenance = function(keycloak: Keycloak): ObjectProvenance | undefined {
    return {
        name: PROVENANCE_NAME,
        softwarename: PROVENANCE_SOFTWARENAME,
        version: PROVENANCE_VERSION,
        description: PROVENANCE_DESCRIPTION,
        username: keycloak.idTokenParsed?.preferred_username,
        hostname: window.location.hostname,
    }
}

const buildMagnitude = function (
    eventForm: EventCreationForm, magnitudeForm: MagnitudeCreationForm, keycloak: Keycloak
): ObjectMagnitude {
    return {
        mag: +magnitudeForm.mag!,
        type_magnitude: magnitudeForm.type_magnitude!,
        upper_uncertainty: magnitudeForm.upper_uncertainty
        ? +magnitudeForm.upper_uncertainty
        : undefined,
        lower_uncertainty: magnitudeForm.lower_uncertainty
        ? +magnitudeForm.lower_uncertainty
        : undefined,
        mag_quality: magnitudeForm.mag_quality
        ? magnitudeForm.mag_quality
        : undefined,
        provenance: buildProvenance(keycloak),
        localspace: buildLocalspace(eventForm),
    }
}

const buildOrigin = function (
    eventForm: EventCreationForm, originForm: OriginCreationForm, magnitudeForm: MagnitudeCreationForm,
    typeOriginForm: TypeOriginCreationForm, keycloak: Keycloak
): ObjectOrigin {
    // let rfc_string=moment(originForm.ot!,'DD/MM/YYYY HH:mm:ss').format();
    return {
        depth: +originForm.depth!,
            lat: +originForm.lat!,
        lon: +originForm.lon!,
        ot: originForm.ot ?? '',
        err_ot: originForm.err_ot ? +originForm.err_ot : undefined,
        err_h: originForm.err_h ? +originForm.err_h : undefined,
        quality: originForm.origin_quality
        ? originForm.origin_quality
        : undefined,
        rms: originForm.rms ? +originForm.rms : undefined,
        azim_gap: originForm.azim_gap
        ? +originForm.azim_gap
        : undefined,
        err_lat: originForm.err_lat
        ? +originForm.err_lat
        : undefined,
        err_lon: originForm.err_lon
        ? +originForm.err_lon
        : undefined,
        provenance: buildProvenance(keycloak),
        localspace: buildLocalspace(eventForm),
        type_origin:{
        name: typeOriginForm.name!,
            version_name: typeOriginForm.version_name!,
            version_value: typeOriginForm.version_value!,
    },
        magnitudes: [buildMagnitude(eventForm, magnitudeForm, keycloak)],
    }
}

export const buildMagnitudeRequestObject = function (
    originId: string, eventForm: EventCreationForm, originForm: OriginCreationForm, magnitudeForm: MagnitudeCreationForm,
    typeOriginForm: TypeOriginCreationForm, keycloak: Keycloak
): AddMagnitudeRequest {

    return {
        data: {
            originid: +originId,
            magnitudes: [ buildMagnitude(eventForm, magnitudeForm, keycloak)],
        }
    }
}

export const buildOriginRequestObject = function (
    eventId: string, eventForm: EventCreationForm, originForm: OriginCreationForm, magnitudeForm: MagnitudeCreationForm,
    typeOriginForm: TypeOriginCreationForm, keycloak: Keycloak
): AddOriginRequest {

    return {
        data: {
            eventid: +eventId,
            origins: [ buildOrigin(eventForm, originForm, magnitudeForm, typeOriginForm, keycloak)],
        }
    }
}

export const buildEventRequestObject = (
    eventForm: EventCreationForm, originForm: OriginCreationForm, magnitudeForm: MagnitudeCreationForm,
    typeOriginForm: TypeOriginCreationForm, keycloak: Keycloak
) => {
    let event200: GetEvent200Response = {};
    event200.data = {};
    event200.data.event = {
        type_event: eventForm.eventype!,
        provenance: buildProvenance(keycloak),
        localspace: buildLocalspace(eventForm),
        origins: [ buildOrigin(eventForm, originForm, magnitudeForm, typeOriginForm, keycloak)],
    };
    console.log("Object builded:", event200);
    return event200;
};