import { EventDetailOriginsTable } from "./flavor/CustomOriginTable"
import { EARTHQUAKE_LIST_TABLE, EVENTDETAIL_ORIGINS_TABLE, GenericEvent } from "./TableComposer"
import {CTA_TAG, EarthquakeTableEvent} from "./TableUtils"
import {getSuffixFromTableType} from "../SettingsTabs/utils";
import {RouteType} from "../../redux/reducers/filters.slice";
import {Link} from "react-router-dom";
import {evaluateMarkerClass} from "../../pages/EarthquakeList/utils";

export const evaluateHref = function(type: string, logged: boolean, route: RouteType, event: GenericEvent): string {

    if(type === EARTHQUAKE_LIST_TABLE && !logged)
        return `/earthquake/${(event as EarthquakeTableEvent).id}`

    if(type === EARTHQUAKE_LIST_TABLE && logged && route !== 'events-group')
        return `/earthquake/${(event as EarthquakeTableEvent).id}`

    if(type === EVENTDETAIL_ORIGINS_TABLE) {
        let {earthquakeId, id} = event as EventDetailOriginsTable
        return `/earthquake/${earthquakeId}/origin/${id}`
    }

    return '#'
}

const unhoverMarker = (className: string) => `${className.replace('-hover', '')}`;

const hoverOnMarker = (className: string) => className.includes('-hover') ? className : `${className}-hover`;

const updateNode = (elementId: string, hover: boolean) => {
    const element = document.getElementById(elementId);
    if(element) {
        element.className = hover ? hoverOnMarker(element.className) : unhoverMarker(element.className);
    }
}

const updateNodeParent = (elementId: string, hover: boolean) => {
    const element = document.getElementById(elementId);
    if(element && element.parentElement) {
        const zIndex: number = hover
            ? Number(element.parentElement.style.zIndex) + 100000
            : Number(element.parentElement.style.zIndex) - 100000;
        element.parentElement.style.zIndex = `${zIndex}`;
    }
}

/*
 * this function is used to distinguish the cases when a row in a table should be an anchor tag or a div tag
 */
export const evaluateRow = (
    key: string, type: string, logged: boolean, className: string, onClick: () => void,
    children: JSX.Element[],
    elementId: string,
    route: RouteType,
    href?: string
) => {
    if(type === EARTHQUAKE_LIST_TABLE) {
        return (<Link
            onMouseOver={() => {
                updateNode(elementId, true);
                updateNode(`${elementId}-text`, true);
                updateNodeParent(elementId, true);
            }}
            onMouseOut={() => {
                updateNode(elementId, false);
                updateNode(`${elementId}-text`, false);
                updateNodeParent(elementId, false);
            }}
            key={key}
            onClick={(e) => {
                if(route !== 'events-group'){
                    return
                }
                if(logged) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                onClick();
            }}
            to={href ?? '#'}
            className={className}>
            {
                children
            }
        </Link>)
    }

    if(type === EVENTDETAIL_ORIGINS_TABLE) {
        return (<Link
            key={key}
            onClick={onClick}
            to={href ?? '#'}
            className={className}>
            {
                children
            }
        </Link>)
    }

    return (<div
        onMouseOver={() => showCtaIcon('block', elementId, type)}
        onMouseOut={() => showCtaIcon('none', elementId, type)}
        key={key}
        onClick={onClick}
        className={className}>
        {
            children
        }
    </div>)
}

const showCtaIcon = function(display: string, id: string, type: string) {
    if(id === '') return
    if(id === 'undefined') return;
    if(id === 'null') return;
    if(!type.startsWith('SETTINGS_'))
        return

    let ctaId = `${id}-${getSuffixFromTableType(type)}-${CTA_TAG}`
    let cta = document.getElementById(ctaId)
    if(cta) {
        cta.style.display = display
    }
}