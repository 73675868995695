import {ApiClient} from "../../services/ApiClient";
import {UpdateEventsGroupRequest} from "../../services/network";
import {buildError} from "../../utils";
import {AxiosError} from "axios";
import {RequiredError} from "../../services/network/base";


const handleError = function(e: AxiosError<RequiredError>, onError: (title: string, reason: string) => void) {
    let code = 500;
    let error = buildError(
        e as AxiosError<RequiredError>,
        (httpCode: number) => code = httpCode
    )
    error = error.replaceAll('<br />', ', ')
    onError(`Error ${code}`, error)
}


export const joinGroups = async function (
    request: UpdateEventsGroupRequest,
    onSuccess: (id: number|undefined) => void,
    onError: (title: string, reason: string) => void
) {
    try {
        let response = await ApiClient.updateApi().updateEventsGroup(request)
        // todo: it succeds but it does not return an ObjectTableTypeEvent as decsribed in the swagger
        console.log('Join groups => success =>', response?.data)
        onSuccess(request.data?.event_group_id)
        //ObjectTableTypeEvent
    } catch (e) {
        handleError(e as AxiosError<RequiredError>, onError)
        console.log('Join groups => error =>', e)
    }
}

export const splitGroups =  async function (
    request: UpdateEventsGroupRequest,
    onSuccess: (id: number|undefined) => void,
    onError: (title: string, reason: string) => void
) {
    try {
        let response = await ApiClient.updateApi().updateEventsGroup(request)
        // todo: it succeds but apparently the results are not complete
        console.log('Split groups => success =>', response?.data)
        onSuccess(request.data?.event_group_id)
        //ObjectTableTypeEvent
    } catch (e) {
        handleError(e as AxiosError<RequiredError>, onError)
        console.log('Split groups => error =>', e)
    }
}

export const moveEvent = async function (
    request: UpdateEventsGroupRequest,
    onSuccess: (id: number|undefined) => void,
    onError: (title: string, reason: string) => void
) {
    try {
        let response = await ApiClient.updateApi().updateEventsGroup(request)
        // todo: it succeds but it does not return an ObjectTableTypeEvent as decsribed in the swagger
        console.log('Move event => success =>', response?.data)
        onSuccess(request.data?.event_group_id)
        //ObjectTableTypeEvent
    } catch (e) {
        handleError(e as AxiosError<RequiredError>, onError)
        console.log('Move event => error =>', e)
    }
}