import { CSSProperties, useState } from 'react';
import { ReactComponent as ProductsIcon } from '../../../assets/products.svg'
import '../GenericTable.css'
import { useTranslation } from 'react-i18next';
import CustomTooltip from '../../CustomTooltip/CustomTooltip';
import { Link } from 'react-router-dom';

export interface ICtaProps {
    label: string;
    products: string;
    width?: string;
    reverse: boolean;
    link: string;
}

function Products({ label, products, width, reverse, link }: ICtaProps): JSX.Element {

    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();
    const open = () => setOpen(true);
    const close = () => setOpen(false);

    function onBlurCallback(): void {
        if (isOpen) {
            close();
        }
    }

    function onClickCallback(e: React.MouseEvent<HTMLDivElement>): void {
        e.preventDefault();
        e.stopPropagation();
        return isOpen ? close() : open();
    }

    function getStyle(): CSSProperties | undefined {
        if (width) {
            return {
                width: `${width}px !important`,
            }
        }
        return undefined
    }

    function getTop(options: number): CSSProperties {
        if (reverse) {
            // return up
            const optionHeight: number = 36;
            return {
                top: `${-50-(optionHeight * options)}px`
            } 
        } else {
            // return down
            return {
                top: '44px'
            };
        }
    }


    return (
        <CustomTooltip title={t(label)} placement="top" arrow>
            <Link to={link}>
                <div className={isOpen ? 'ot-cta-container-active' : 'ot-cta-container'} tabIndex={0} onClick={onClickCallback} onBlur={onBlurCallback}>
                    <ProductsIcon />
                    {isOpen && <div className={reverse ? `ot-cta-menu` : `ot-cta-menu`} style={getTop(products.split(',').length)}>
                        <div className={'ot-options-container'} style={getStyle()}>
                            <h4>{t(label)}</h4>
                            <div className='divider-container'>
                                <div className='divider' />
                            </div>
                            {
                                products.split(',').map((item, index) => {
                                    return (<div
                                        key={`${index}`}
                                        className='item-no-click'
                                        onMouseEnter={(e) => { e.preventDefault(); e.stopPropagation(); }}
                                    >
                                        <p style={{ textTransform: 'capitalize' }}>{item}</p>
                                    </div>)
                                })
                            }
                        </div>
                    </div>}
                </div>
            </Link>
        </CustomTooltip>);
}

export {
    Products
}