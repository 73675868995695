import './App.css'
import './fonts/IBMPlexSans-Bold.ttf'
import './fonts/IBMPlexSans-BoldItalic.ttf'
import './fonts/IBMPlexSans-ExtraLight.ttf'
import './fonts/IBMPlexSans-ExtraLightItalic.ttf'
import './fonts/IBMPlexSans-Italic.ttf'
import './fonts/IBMPlexSans-Light.ttf'
import './fonts/IBMPlexSans-LightItalic.ttf'
import './fonts/IBMPlexSans-Medium.ttf'
import './fonts/IBMPlexSans-MediumItalic.ttf'
import './fonts/IBMPlexSans-Regular.ttf'
import './fonts/IBMPlexSans-SemiBold.ttf'
import './fonts/IBMPlexSans-SemiBoldItalic.ttf'
import './fonts/IBMPlexSans-Thin.ttf'
import './fonts/IBMPlexSans-ThinItalic.ttf'
import {Navigate, Route, Routes} from 'react-router-dom'
import {Loader} from './components/Loader/Loader'
import {BeaNavbar} from './components/BeaNavbar/BeaNavbar'
import {EarthquakeList} from './pages/EarthquakeList/EarthquakeList'
import {NotFound} from './components/NotFound/NotFound'
import {EarthquakeDetail} from './pages/EarthquakeDetail/EarthquakeDetail'
import {OriginDetail} from './pages/OriginDetail/OriginDetail'
import VersionComponent from "./components/SrcVersionComponent/VersionComponent"
import CreateEvent from './pages/CreateEvent/CreateEvent'
import Settings from './pages/Settings/Settings'
import {Status} from "./pages/Status/Status";
import {PrivateComponent} from "./components/PrivateComponent/PrivateComponent";
import {createUrlFromFilters} from "./utils/urlFromFilters";
import {useAppSelector} from "./hooks/reduxCustomHooks";

function App() {

    const filters = useAppSelector((state) => state.filters);
    // First the navbar
    // Then all the other routes
    return (
        <div className='main-container'>
            <BeaNavbar/>
            <main className='main-content'>
                <Routes>
                    <Route path='/' element={<Navigate to={`/earthquakelist?${createUrlFromFilters({
                        ...filters,
                        route: filters.route,
                    })}`} replace/>}/>
                    <Route path='version' element={<VersionComponent/>}/>
                    <Route path='earthquakelist' element={<EarthquakeList/>}/>
                    <Route path='earthquake/:earthquakeId' element={<EarthquakeDetail/>}/>
                    <Route path='earthquake/:earthquakeId/origin/:originId' element={<OriginDetail/>}/>
                    <Route path='load' element={<Loader/>}/>
                    <Route path='create/event' element={<PrivateComponent child={<CreateEvent />}/>}/>
                    <Route path='create/origin/:eventId' element={<PrivateComponent child={<CreateEvent />}/>}/>
                    <Route path='create/origin/:eventId/:originId' element={<PrivateComponent child={<CreateEvent />}/>}/>
                    <Route path='create/magnitude/:eventId/:originId' element={<PrivateComponent child={<CreateEvent />}/>}/>
                    <Route path='settings' element={<PrivateComponent child={<Settings />}/>}/>
                    <Route path='info' element={<Status />}/>
                    <Route path='*' element={<NotFound/>}/>
                </Routes>
            </main>
        </div>
    );
}

export default App;
