import './Filters.css'
import { useTranslation } from 'react-i18next';
import { TextInput } from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { ReactComponent as IconRadioUnselected } from '../../assets/bc-checkbox-unselected.svg'
import { ReactComponent as IconRadioSelected } from '../../assets/bc-checkbox-selected.svg'
import { ReactComponent as IconRadioDisabled } from '../../assets/bc-checkbox-unselected-disabled.svg'
import { ReactComponent as IconCancel } from '../../assets/cancel.svg'
import { validNumeric } from "../../utils";
import { defaultFilters, Filters } from "../../redux/reducers/filters.slice";

export interface IQueryIdProps {
    onFiltersUpdate: (newFilters: Filters) => void;
    filters: Filters;
}
function QueryId({ onFiltersUpdate, filters }: IQueryIdProps) {

    const { t } = useTranslation();
    const [id, setId] = useState('');
    const [radioOption, setRadioOption] = useState('');
    const [hasError, setError] = useState(false);

    useEffect(() => {
        if (filters.eventid) {
            setRadioOption('eventid');
            setId(filters.eventid);
        } else if (filters.originid) {
            setRadioOption('originid');
            setId(filters.originid);
        } else if (filters.magnitudeid) {
            setRadioOption('magnitudeid');
            setId(filters.magnitudeid);
        } else {
            reset();
        }
    }, [filters]);

    useEffect(() => {
        setError(id !== '' && !validNumeric(id));
        switch (radioOption) {
            case 'eventid':
            case 'originid':
            case 'magnitudeid':
                onFiltersUpdate({
                    ...defaultFilters,
                    minmag: undefined,
                    [radioOption]: id
                } as Filters)
                break;
            default:
                console.log('unknown option', radioOption);
                break;
        }
    }, [id]);

    useEffect(() => {
        if (radioOption === '') {
            onFiltersUpdate({
                ...defaultFilters,
                minmag: undefined
            } as Filters)
        } else {
            switch (radioOption) {
                case 'eventid':
                case 'originid':
                case 'magnitudeid':
                    onFiltersUpdate({
                        ...defaultFilters,
                        minmag: undefined,
                        [radioOption]: id
                    } as Filters)
                    break;
                default:
                    console.log('unknown option', radioOption);
                    break;
            }
        }
    }, [radioOption]);

    const onIdUpdate = function (e: React.FormEvent<HTMLInputElement>): void {
        setId(e.currentTarget.value);
    }

    function onRadioSelect(option: string): void {
        if (id !== '') {
            switch (option) {
                case 'eventid':
                case 'originid':
                case 'magnitudeid':
                    setRadioOption(option);
                    break;
                default:
                    console.log('unknown option', option);
                    break;
            }
        }
    }

    function reset(): void {
        setId('');
        setRadioOption('');
    }

    function RadioOption(radioOption: string, option: string): JSX.Element {
        return id === ''
            ? <IconRadioDisabled />
            : radioOption === option ? <IconRadioSelected /> : <IconRadioUnselected />;
    }

    return (<div className={'filters-parts'}>
        <div className={'filters-full'}>
            <div className={'filters-id-container'}>
                <div className={'filters-id-title-container'}>
                    <p className={'filters-id-title'}>{t('filtersbox__search_id_title')}</p>
                </div>
                <div className={'filters-id-searchbar'}>
                    <div className={'filters-id-inputtext'}>
                        <p className={'filters-id-inputtext-label'}>{t('filtersbox__search_id_subtitle')}</p>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <TextInput
                                labelText=''
                                className='input'
                                id='filter-id-input'
                                hideLabel
                                placeholder={'e.g. 1234567890'}
                                onChange={onIdUpdate}
                                value={id}
                                invalid={hasError}
                                invalidText={t('bc_numeric_value')}
                            />
                            {id !== '' &&
                                <IconCancel onClick={reset} className={hasError ? 'bc-cancel-error' : 'bc-cancel'} />}
                        </div>
                    </div>
                    <div className={'filters-id-radio'}>
                        <div className={'filters-id-radiooption'} onClick={() => onRadioSelect('eventid')}>
                            {RadioOption('eventid', radioOption)}
                            <span className={id === '' ? 'bc-disabled' : ''}>{t('bc_event_id')}</span>
                        </div>
                        <div className={'filters-id-radiooption'} onClick={() => onRadioSelect('originid')}>
                            {RadioOption('originid', radioOption)}
                            <span className={id === '' ? 'bc-disabled' : ''}>{t('bc_origin_id')}</span>
                        </div>
                        <div className={'filters-id-radiooption'} onClick={() => onRadioSelect('magnitudeid')}>
                            {RadioOption('magnitudeid', radioOption)}
                            <span className={id === '' ? 'bc-disabled' : ''}>{t('bc_magnitude_id')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export {
    QueryId
}