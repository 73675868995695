import { Configuration, GetApi, StoreApi, UpdateApi, UserApi } from "./network"
import { keycloak } from "./auth/Keycloak"
import log from "loglevel"


const getConfigurationWithToken = (): Configuration | undefined => {
    let configuration: Configuration | undefined = undefined

    log.debug('Is keycloak authenticated? => ', keycloak.authenticated)
    
    if (!!keycloak.authenticated) {
        configuration = new Configuration()
        configuration.accessToken = keycloak.token
        log.debug('Keycloak token => ', keycloak.token)
    }
    
    log.debug('Configuration token => ', configuration?.accessToken)
    
    return configuration
}
/**
 * Utility function to get an active instance of the GetApi
 * 
 * @returns An active instance of the GetApi
 */
const getApi = (): GetApi => {
    return new GetApi(getConfigurationWithToken(), process.env.REACT_APP_API_BASE_URL)
}

/**
 * Utility function to get an active instance of the StoreApi
 * 
 * @returns An active instance of the StoreApi
 */
const storeApi = (): StoreApi => {
    return new StoreApi(getConfigurationWithToken(), process.env.REACT_APP_API_BASE_URL)
}

/**
 * Utility function to get an active instance of the UpdateApi
 * 
 * @returns An active instance of the UpdateApi
 */
const updateApi = (): UpdateApi => {
    return new UpdateApi(getConfigurationWithToken(), process.env.REACT_APP_API_BASE_URL)
}

/**
 * Utility function to get an active instance of the UserApi
 * 
 * @returns An active instance of the UserApi
 */
const userApi = (): UserApi => {
    return new UserApi(getConfigurationWithToken(), process.env.REACT_APP_API_BASE_URL)
}

export const ApiClient = {
    getApi,
    storeApi,
    updateApi,
    userApi
}