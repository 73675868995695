import { ReactComponent as RadioOptionSelected } from '../../assets/cs-selected.svg'
import { ReactComponent as RadioOptionUnselected } from '../../assets/cs-unselected.svg'
import {useTranslation} from "react-i18next";

export interface IContentSwitcherProps {
    selectedContent: 'ID' | 'FILTERS';
    onContentSwitch: (newContent: 'ID' | 'FILTERS') => void;
}
function ContentSwitcher({selectedContent, onContentSwitch}: IContentSwitcherProps): JSX.Element {

    const {t} = useTranslation();

    function switchContent(content: 'ID' | 'FILTERS'): void {
        onContentSwitch(content);
    }

    function toIds(): void {
        switchContent('ID');
    }

    function toFilters(): void {
        switchContent('FILTERS');
    }

    return(<div className={'filters-selector'}>
        <div className={'content-switcher'}>
            <div className={`option left ${selectedContent === 'FILTERS' ? 'selected-content' : ''}`} onClick={toFilters}>
                {selectedContent === 'FILTERS' ? <RadioOptionSelected /> : <RadioOptionUnselected />} {t('cs_filters_option')}
            </div>
            <div className={`option right ${selectedContent === 'ID' ? 'selected-content' : ''}`} onClick={toIds}>
                {selectedContent === 'ID' ? <RadioOptionSelected /> : <RadioOptionUnselected />}   {t('cs_id_option')}
            </div>
        </div>
    </div>)
}

export {
    ContentSwitcher
}