import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@carbon/react'
import dayjs from "dayjs"
import 'dayjs/locale/it'
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { batch } from 'react-redux'
import { useParams } from "react-router-dom"
import { ArrivalTable } from "../../components/ArrivalTable/ArrivalTable"
import { BreadCrumbComposer } from "../../components/BreadcrumbComposer/BreadcrumbComposer"
import CustomModal from '../../components/CustomModal/CustomModal'
import { DoubleRowInfo } from "../../components/DoubleRowInfo/DoubleRowInfo"
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent"
import { FaultMechanismTable } from "../../components/EventDetailTable/FaultMechanismTable"
import { MagnitudeTable } from "../../components/EventDetailTable/MagnitudeTable"
import { ProductTable } from '../../components/EventDetailTable/ProductTable'
import { LoaderTranslated } from "../../components/Loader/LoaderTranslated"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks"
import { closeStoreModal } from '../../redux/reducers/confirmationModal.slice'
import { closeCta } from '../../redux/reducers/cta.slice'
import { fetchEarthquakeEvent } from "../../redux/reducers/earthquake.slice"
import { viewMode } from '../../redux/reducers/group.slice'
import { ObjectOrigin } from "../../services/network"
import { buildError } from "../../utils"
import './OriginDetail.css'

interface OriginDetailParams {
    earthquakeId: string,
    originId: string
}

function OriginDetail() {
    const { i18n } = useTranslation();
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.locale(i18n.language)

    const { t } = useTranslation()
    const { earthquakeId, originId } = useParams<keyof OriginDetailParams>() as OriginDetailParams;
    const earthquake = useAppSelector((state) => state.earthquake);
    const dispatch = useAppDispatch();
    const [origin, setOrigin] = useState<ObjectOrigin>();
    const [loading, setLoading] = useState(true)
    const [httpCode, setHttpCode] = useState(200)
    const [error, setError] = useState<boolean | string>(false)
    const modalSpec = useAppSelector((state) => state.confirmationModal);

    useEffect(() => {
        closeAllAndReset();
        return () => {
            closeAllAndReset();
        }
    }, []);

    const getData = function () {
        if (!earthquakeId || !originId) return
        dispatch(fetchEarthquakeEvent(+earthquakeId))
            .unwrap()
            .catch((e) => {
                let { name } = e
                if (name === 'AxiosError') {
                    setError(buildError(e, (code: number) => setHttpCode(code)))
                    setLoading(false)
                }
            })
    }

    useEffect(() => {
        if (!earthquake || (`${earthquake.id}` !== earthquakeId)) {
            getData();
        }
    }, [earthquake, earthquakeId])

    useEffect(() => {
        console.log('OriginDetail => earthquake update')
        if (!earthquake || earthquake.id === 0) {
            console.log('OriginDetail => no earthquake')
            return
        }
        console.log('OriginDetail => earthquake found =>', earthquake)
        console.log('OriginDetail => searching for origin', originId)
        console.log('OriginDetail => searching for origin in', earthquake.origins)
        const originFound = earthquake.origins?.find((origin) => origin.id === +originId)
        console.log('OriginDetail => origin found', originFound)
        if (!originFound) {
            setLoading(false)
        } else {
            setOrigin(originFound)
        }

    }, [earthquake]);

    const time = (ot?: string) => {
        if (!origin || !ot) return '--'
        return ot?.replaceAll(/[T]/g, ' ').replaceAll(/\+\d+:\d+/g, '')
    }

    const italyTime = function (ot?: string): string {
        if (!origin || !ot) return '--'
        return dayjs(ot).tz('Europe/Rome').format('YYYY-MM-DD HH:mm:ss.SSS [(UTC ]Z[)] [ora italiana]')
    }

    const formatDepthWithError = function (origin?: ObjectOrigin): string {
        if (!origin) return '--'
        if (!origin.depth) return '--'
        if (!origin.err_depth && !origin.err_z) return `${origin.depth}`

        // at this point we have depth and at least one between err_depth and err_z
        // err_depth has higher priority
        if (origin.err_depth) return `${origin.depth} +/- ${origin.err_depth}`

        return `${origin.depth} +/- ${origin.err_z}`
    }

    const formatCoordinatesWithError = function (latOrLon: boolean, origin?: ObjectOrigin): string {
        if (!origin) return '--'

        if (latOrLon) {
            let lat: string = `${origin.lat.toFixed(4) ?? '--'}`
            if ('err_lat_deg' in origin) {
                if (origin['err_lat_deg']) {
                    return `${lat} +/- ${origin['err_lat_deg']}`
                }
            }

            return lat
        } else {
            let lon: string = `${origin.lon.toFixed(4) ?? '--'}`
            if ('err_lon_deg' in origin) {
                if (origin['err_lon_deg']) {
                    return `${lon} +/- ${origin['err_lon_deg']}`
                }
            }

            return lon
        }
    }

    return (
        <>

            <div className='origin-detail-container'>
                <div className='origin-detail'>
                    <BreadCrumbComposer pageId='originDetail' elementIds={[earthquakeId, originId]} />
                    <div className='origin-detail-header'>
                        <DoubleRowInfo title={t('page__earthquake_detail__type')} content={`${origin?.type_origin?.name ?? '--'}`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__version')} content={`${origin?.type_origin?.version_name ?? '--'} (${origin?.type_origin?.version_value ?? '--'})`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__datetime')} content={time(origin?.ot)} className="" tooltip={italyTime(origin?.ot)} />
                        <DoubleRowInfo title={t('page__earthquake_detail__published_datetime')} content={time(origin?.inserted)} className="" />
                    </div>
                    <div className='origin-detail-header'>
                        <DoubleRowInfo title={t('page__earthquake_detail__published_by')} content={`${origin?.localspace?.name ?? '--'} (${origin?.localspace?.description ?? '--'})`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__published_by_id')} content={`${origin?.id_localspace ?? '--'}`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__lat')} content={`${formatCoordinatesWithError(true, origin)}`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__lon')} content={`${formatCoordinatesWithError(false, origin)}`} className="" />
                    </div>
                    <div className='origin-detail-header'>
                        <DoubleRowInfo title={t('page__earthquake_detail__depth')} content={`${formatDepthWithError(origin)}`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__gap')} content={`${origin?.azim_gap ?? '--'}`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__rms')} content={`${origin?.rms ?? '--'}`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__quality')} content={`${origin?.quality ?? '--'}`} className="" />
                    </div>
                    <div className='origin-detail-header'>
                        <DoubleRowInfo title={t('page__earthquake_detail__min_dist')} content={`${origin?.min_distance ?? '--'}`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__nph')} content={`${origin?.nph ?? '--'}`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__nph_s')} content={`${origin?.nph_s ?? '--'}`} className="" />
                        <DoubleRowInfo title={t('page__earthquake_detail__nph_tot')} content={`${origin?.nph_tot ?? '--'}`} className="" />
                    </div>
                    <div className='origin-detail-tabs'>
                        <Tabs>
                            <TabList contained={false} id="origindetail-info-row">
                                <Tab className="bea-tab-header">{t('page__earthquake_detail__tab__magnitudes__title')}</Tab>
                                <Tab className="bea-tab-header">{t('page__earthquake_detail__tab__arrivals__title')}</Tab>
                                <Tab className="bea-tab-header">{t('page__earthquake_detail__tab__fm__title')}</Tab>
                                <Tab className="bea-tab-header">{t('page__earthquake_detail__tab__products__title')}</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <MagnitudeTable updateCallback={getData} originId={originId} />
                                </TabPanel>
                                <TabPanel><ArrivalTable arrivals={origin?.arrivals} /></TabPanel>
                                <TabPanel>
                                    <FaultMechanismTable updateCallback={getData} originId={originId} />
                                </TabPanel>
                                <TabPanel>
                                    <ProductTable />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                </div>

                {
                    loading && <div className='origindetail-content' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <LoaderTranslated />
                    </div>
                }
                {
                    !loading && error !== false && <div className='origindetail-content' style={{ display: 'flex', justifyContent: 'center' }}>
                        <ErrorComponent title={`Errore ${httpCode}`} description={error as string} asHtml />
                    </div>
                }
            </div>

            <CustomModal
                {
                ...modalSpec
                } />
        </>
    );

    function closeAllAndReset(): void {
        batch(() => {
            dispatch(closeCta());
            dispatch(viewMode());
            dispatch(closeStoreModal());
        })
    }
}

export { OriginDetail }
