import './CustomTooltip.css'
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: '400px',
      backgroundColor: '#383838',
      color: '#FFFFFFDE',
      fontFamily: 'IBM Plex Sans',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      borderRadius: '2px',
      letterSpacing: '0.32px',
      fontWeight: 400,
      padding: '8px'
  },
  [`& .${tooltipClasses.arrow}`]: {
      color: '#383838',
  },
  }));

  export default CustomTooltip