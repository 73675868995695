import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isValidMagnitude, isValidMinMaxMagnitude, isValidWhereMagnitudeQualityIn } from "../../../../utils";
import { BeaTextInput } from "../../../BeaTextInput/BeaTextInput";
import { maxMagErrorText, minMagErrorText } from "../../../DropdownFilters/messages";
import '../Common.css';

export interface IQueryFiltersMagnitudeProps {
    quality?: string;
    min?: string;
    max?: string;
    magType?: string;
    onUpdate: (key: string, value: string) => void;
}

function QueryFiltersMagnitude({
    quality, min, max, magType, onUpdate
}: IQueryFiltersMagnitudeProps): JSX.Element {

    const { t } = useTranslation();

    function onUpdateMagType(value: string): void {
        onUpdate('wheretypemagnitudenameregexp', value);
    }

    function onUpdateQuality(value: string): void {
        onUpdate('wheremagnitudemagqualityin', value.replaceAll(' ', '').toLocaleUpperCase());
    }

    function onUpdateMin(value: string): void {
        onUpdate('minmag', value);
    }

    function onUpdateMax(value: string): void {
        onUpdate('maxmag', value);
    }

    return (<div className={'filter-section-container'}>
        <div className={'filter-section-row'}>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    value={magType ?? ''}
                    placeholder={'es. ML'}
                    label={'fs__title__mag_type'}
                    stateKey={'wheretypemagnitudenameregexp'}
                    invalid={false}
                    onChange={onUpdateMagType}
                    invalidText={''}
                />
            </div>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    value={quality ?? ''}
                    placeholder={'es. A,B,AA,AB'}
                    label={'fs__title__quality'}
                    stateKey={'wheremagnitudemagqualityin'}
                    invalid={!isValidWhereMagnitudeQualityIn(quality ?? '', true)}
                    onChange={onUpdateQuality}
                    invalidText={t('invalid_format')}
                />
            </div>
        </div><div className={'filter-section-row'}>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    invalidText={minMagErrorText(min ?? '', max ?? '', t)}
                    value={min ?? ''}
                    placeholder={'es. 0.00'}
                    label={'fs__title__magnitude_min'}
                    stateKey={'minmag'}
                    invalid={!(isValidMagnitude(min ?? '', true) &&
                        isValidMinMaxMagnitude(min ?? '', max ?? '', true))
                    }
                    onChange={onUpdateMin}
                />
            </div>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    invalidText={maxMagErrorText(min ?? '', max ?? '', t)}
                    value={max ?? ''}
                    placeholder={'es. 10.00'}
                    label={'fs__title__magnitude_max'}
                    stateKey={'maxmag'}
                    invalid={!(isValidMagnitude(max ?? '', true) &&
                        isValidMinMaxMagnitude(min ?? '', max ?? '', true))
                    }
                    onChange={onUpdateMax}
                />
            </div>
        </div>
    </div>);
}

export {
    QueryFiltersMagnitude
};
