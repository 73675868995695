import {
    InlineNotification
} from '@carbon/react';
import { useKeycloak } from '@react-keycloak/web';
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as StarIcon } from '../../assets/preferred-star.svg';
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import { closeStoreModal, showStoreModal } from '../../redux/reducers/confirmationModal.slice';
import { ApiClient } from "../../services/ApiClient";
import { ObjectEvent, ObjectMagnitude, ObjectOrigin, UpdateEventRequest } from "../../services/network";
import { BeaInlineNotification, defaultNotificationState, onErrorCallbackHandler, onSuccessCallbackHandler } from '../../utils/InlineNotifications';
import { BeaButton } from "../BeaButton/BeaButton";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import EmptyList from "../EmptyList/EmptyList";
import { LoaderTranslated } from "../Loader/LoaderTranslated";
import { Toast } from '../Toast/Toast';
import { GenericTable, IHeader, ProvenanceCell, Row, SORT_ASC, SortDirection, localspace, ot, singleRow, twoRows } from "./GenericTable";
import './GenericTable.css';
import { Cta } from "./components/Cta";
import { Products } from "./components/Products";
import { idAsc, idDesc, mindistAsc, mindistDesc, modifiedDesc, otAsc, otDesc, qtyAsc, qtyDesc } from "./sortutils";

interface OriginTableProps {
    updateCallback: () => void;
    onHoverCallback: (id: string) => void;
    selectedOrigin?: ObjectOrigin
}

function OriginTable({ updateCallback, onHoverCallback, selectedOrigin }: OriginTableProps) {
    const { t } = useTranslation();
    const earthquake = useAppSelector((state) => state.earthquake);
    const [origins, setOrigins] = useState<ObjectOrigin[] | undefined>(undefined);
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();

    const [rows, setRows] = useState<Row[] | undefined>(undefined);
    const [showNotification, setShowNotification] = useState<BeaInlineNotification>(defaultNotificationState);
    const [isCatalog, setCatalog] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<string>(``);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const copyOrigins: ObjectOrigin[] = earthquake && earthquake.origins ? [...earthquake.origins] : [];
        copyOrigins.sort(modifiedDesc);
        setOrigins(copyOrigins);
    }, [earthquake]);

    useEffect(() => {
        if (selectedOrigin) {
            if (selectedOrigin.id) {
                setSelectedRow(`${selectedOrigin.id}`)
            }
        }
    }, [selectedOrigin])

    useEffect(() => {
        if (earthquake) {
            if (earthquake.localspace) {
                if (earthquake.localspace.environment) {
                    setCatalog(earthquake.localspace.environment === 'catalog');
                }
            }
        }
    }, [earthquake]);

    function sortById(direction: SortDirection): void {
        sortOrigins(direction === SORT_ASC ? idAsc : idDesc);
    }

    function sortByOt(direction: SortDirection): void {
        sortOrigins(direction === SORT_ASC ? otAsc : otDesc);
    }

    function sortByMindist(direction: SortDirection): void {
        sortOrigins(direction === SORT_ASC ? mindistAsc : mindistDesc);
    }

    function sortByQty(direction: SortDirection): void {
        sortOrigins(direction === SORT_ASC ? qtyAsc : qtyDesc);
    }

    const tableHeaders: IHeader[] = [
        { tag: 'id_tag', label: 'ot__id__label', sortable: false, sortFunction: sortById },
        { tag: 'ot_tag', label: 'ot__ot__label', sortable: false, sortFunction: sortByOt },
        { tag: 'mag_tag', label: 'ot__mag__label', sortable: false },
        { tag: 'coords_tag', label: 'ot__coords__label', sortable: false },
        { tag: 'qty_tag', label: 'ot__qty__label', sortable: false, sortFunction: sortByQty },
        { tag: 'gap_rms_tag', label: 'ot__gap_rms__label', sortable: false },
        { tag: 'min_dist_tag', label: 'ot__min_dist__label', sortable: false, sortFunction: sortByMindist },
        { tag: 'localspace_tag', label: 'ot__localspace__label', sortable: false },
        { tag: 'version_tag', label: 'ot__version__label', sortable: false },
        { tag: 'products_tag', label: 'ot__products__label', sortable: false },
        { tag: 'provenance_tag', label: 'ot__provenance__label', sortable: false },
        { tag: 'cta_tag', label: 'ot__cta__label', sortable: false },
    ];

    function sortOrigins(sortingProcedure: (m1: ObjectOrigin, m2: ObjectOrigin) => number): void {
        if (!origins) {
            return;
        }
        const copyOrigins: ObjectOrigin[] = [...origins];
        copyOrigins.sort(sortingProcedure);
        setOrigins(copyOrigins);
    }

    useEffect(() => {
        if (origins) {
            const temp: Row[] = origins.map((o, index) => {
                const link: string = `/earthquake/${earthquake.id}/origin/${o.id}`;
                return [
                    {
                        tag: 'id_tag',
                        id: `${o.id}`,
                        element: <CustomTooltip placement="top" title={`${o?.id ?? '--'} (${o?.type_origin?.name ?? '--'})`} arrow>
                            <Link to={link}>
                                {id(isPreferredOrigin(earthquake, o), o.id, o?.type_origin?.name)}
                            </Link>
                        </CustomTooltip>
                    },
                    {
                        tag: 'ot_tag',
                        id: `${o.id}`,
                        element: ot(link, o.ot)
                    },
                    {
                        tag: 'mag_tag',
                        id: `${o.id}`,
                        element: magnitudeCell(link, o.magnitudes, earthquake.preferred_magnitude_id)
                    },
                    {
                        tag: 'coords_tag',
                        id: `${o.id}`,
                        element: coords(link, o.lat, o.lon, o.depth)
                    },
                    {
                        tag: 'qty_tag',
                        id: `${o.id}`,
                        element: twoRows(
                            link,
                            o.quality ?? '--',
                            `${o.quality_numeric ?? '--'}`,
                            o.quality
                                ? `${o.quality} (${o.quality_numeric ?? '--'})`
                                : ''
                        )
                    },
                    {
                        tag: 'gap_rms_tag',
                        id: `${o.id}`,
                        element: gaprms(link, o.azim_gap, o.rms)
                    },
                    {
                        tag: 'min_dist_tag',
                        id: `${o.id}`,
                        element: mindist(link, o.min_distance)
                    },
                    {
                        tag: 'localspace_tag',
                        id: `${o.id}`,
                        element: localspace(link, o.localspace)
                    },
                    {
                        tag: 'version_tag',
                        id: `${o.id}`,
                        element: twoRows(
                            link,
                            o?.type_origin?.version_name ?? '--',
                            `(${o?.type_origin?.version_value ?? '--'})`,
                            `${o?.type_origin?.version_name ?? '--'} (${o?.type_origin?.version_value ?? '--'})`
                        )
                    },
                    {
                        tag: 'products_tag',
                        id: `${o.id}`,
                        element: products(link, index, o.flags)
                    },
                    {
                        tag: 'provenance_tag',
                        id: `${o.id}`,
                        element: <ProvenanceCell link={link} p={o.provenance} reverse={reverse(index, o.provenance ? Object.entries(o.provenance).length : 0)} />
                    },
                    {
                        tag: 'cta_tag',
                        id: `${o.id}`,
                        element: !!keycloak.authenticated
                            ? <Cta
                                classWidth='ot-origin'
                                key={`${o.id}-cta`}
                                id={`${o.id}`}
                                reverse={reverse(index, isPreferredOrigin(earthquake, o) ? 1 : 2)}
                                label={'cta__origin__title'}
                                menu={[
                                    { tag: '', label: 'cta__origin__new_origin', callback: () => newOriginFromThis(o.id) },
                                    { tag: '', label: 'cta__origin__preferred', callback: () => updateEventConfirmation(o.id) }
                                ].filter((item) => {
                                    if (item.label === 'cta__origin__new_origin') {
                                        return true;
                                    } else {
                                        return !isPreferredOrigin(earthquake, o)
                                    }
                                })}
                            />
                            : <></>
                    }
                ]
            });
            setRows(temp);
        }
    }, [origins, keycloak]);

    useEffect(() => {
        if (earthquake) {
            if (origins) {
                const index: number = origins.findIndex((o) => o.id === earthquake.preferred_origin_id);
                if (index >= 0) {
                    setSelectedRow(origins[index].id?.toString() ?? '');
                }
            }
        }
    }, [origins]);

    function reverse(index: number, options: number): boolean {
        if (!origins || origins.length === 0) {
            return false;
        }

        const rowHeight: number = 68;
        const optionHeight: number = 36;
        const baseDropdownHeight: number = 50;
        const dropdownHeight = baseDropdownHeight + (options * optionHeight);
        const ot: HTMLElement | null = document.getElementById('origin-table');
        if (!ot) {
            return false;
        }

        if (index <= (origins.length - Math.ceil(dropdownHeight / rowHeight) - 1)) {
            return false;
        }

        if (ot.scrollHeight === ot.clientHeight) {
            return false;
        }

        return ot.scrollHeight > (ot.clientHeight - dropdownHeight);
    }

    return (
        <>
            <div className={'origin-table-container'}>
                <div className={'origin-button-container'}>
                    <div className={'preferred-label-container'}>
                        <StarIcon />
                        <span className={'label'}>{t('preferred_value')}</span>
                    </div>
                    <div>
                        {
                            (!!keycloak.authenticated && !isCatalog) &&
                            <BeaButton
                                onClick={() => earthquake && navigate(`/create/origin/${earthquake.id}`)}
                                disabled={false}
                                variant={'primary'}
                                height="48px"
                                title={'create_new_origin'} />
                        }
                    </div>
                </div>
                {
                    (earthquake && origins && rows)
                        ? (
                            rows.length > 0
                                ? (
                                    <GenericTable
                                        headers={headers()}
                                        rows={rows}
                                        selectedRow={selectedRow}
                                        gridDefinition={(!!keycloak.authenticated && !isCatalog) ? 'ed-ot-grid' : 'ed-ot-pub-grid'}
                                        onClickCallback={(originId: string) => navigate(`/earthquake/${earthquake.id}/origin/${originId}`)}
                                        onHoverCallback={(originId: string) => {
                                            setSelectedRow(originId);
                                            onHoverCallback(originId);
                                        }}
                                    />
                                )
                                : (
                                    <EmptyList title={'No origin found'} />
                                )
                        )
                        : (
                            <LoaderTranslated />
                        )
                }
            </div>

            {showNotification.show &&
                (showNotification.kind === 'success'
                    ? <Toast
                        className='notification-hover'
                        title={t(showNotification.reason)}
                        onClose={() => setShowNotification(defaultNotificationState)}
                    />
                    : <InlineNotification
                        className='notification-hover'
                        kind={showNotification.kind}
                        lowContrast
                        actionbuttonlabel='Action'
                        ariaLabel='closes notification'
                        onClose={() => setShowNotification(defaultNotificationState)}
                        onCloseButtonClick={function noRefCheck() { }}
                        statusIconDescription='notification'
                        subtitle={t(showNotification.reason)}
                        title={showNotification.title}
                    />)}
        </>
    )

    function headers(): IHeader[] {
        if (keycloak) {
            if (keycloak.authenticated) {
                if (!isCatalog) {
                    return tableHeaders;
                }
            }
        }
        return tableHeaders.filter((h) => h.tag !== 'cta_tag');
    }

    function coords(link: string, lat?: number, lon?: number, depth?: number): JSX.Element {
        let firstRow: string = '--';
        let secondRow: string = '--';
        let tooltip: string = '';

        if (lat !== undefined && lon !== undefined && depth !== undefined) {
            firstRow = `${lat.toFixed(2)}, ${lon.toFixed(2)}`
            secondRow = `${depth.toFixed(2)} Km`;
            tooltip = `${lat}, ${lon} - ${depth} Km`
        }

        return twoRows(link, firstRow, secondRow, tooltip);
    }

    function gaprms(link: string, gap?: number, rms?: number): JSX.Element {
        let firstRow: string = '--';
        let secondRow: string = '--';
        let tooltip: string = '';

        if (gap !== undefined && gap !== null) {
            firstRow = `${gap.toFixed(2)}°`
            tooltip = `${gap}°`
        }

        if (rms !== undefined && rms !== null) {
            secondRow = `${rms.toFixed(2)} sec`;
            if (tooltip !== '') {
                tooltip = `${tooltip}, `
            } else {
                tooltip = `--, `
            }
            tooltip = `${tooltip}${rms} sec`
        }

        return twoRows(link, firstRow, secondRow, tooltip);
    }

    function mindist(link: string, mindist?: number): JSX.Element {
        let row: string = `--`;
        let tooltip: string = '';
        if (mindist !== undefined) {
            row = `${mindist ? `${mindist.toFixed(2)} Km` : '--'}`
            tooltip = `${mindist} Km`;
        }
        return singleRow(link, row, tooltip);
    }

    function products(link: string, index: number, p?: string): JSX.Element {
        if (p) {
            return <Products link={link} label='ot__show_products' products={p} reverse={reverse(index, p.split(',').length)} />
        }
        return <></>;
    }

    function id(isPreferred: boolean, id?: number, type?: string): JSX.Element {
        return <>
            <span>{id ?? '--'}</span>
            <div className={'subtitle'}>
                {isPreferred && <StarIcon />}
                <span>{id ? (type ?? '--') : ''}</span>
            </div>
        </>
    }

    function magnitudeCell(link: string, magnitudes?: ObjectMagnitude[], prefMagnitudeId?: number | null | undefined): JSX.Element {
        if (!magnitudes || magnitudes.length === 0) {
            return <Link to={link}><span>--</span></Link>
        }

        const i: number = prefMagnitudeId ? magnitudes.findIndex((m) => m.id === prefMagnitudeId) : -1;
        let tokens: string[] = [];
        if (i >= 0) {
            const m: ObjectMagnitude = magnitudes[i];
            tokens = [`✩ ${m.type_magnitude} ${m.mag}`];
        }

        const t1: string[] = magnitudes.map((m, index) => i !== index ? `${m.type_magnitude} ${m.mag}` : '').filter((t) => t !== '');
        tokens = [...tokens, ...t1];

        return <CustomTooltip placement="top" title={tokens.join(', ')} arrow>
            <Link to={link}>
                {magnitude(magnitudes, earthquake.preferred_magnitude_id)}
            </Link>
        </CustomTooltip>
    }

    function isPreferredOrigin(e: ObjectEvent, o: ObjectOrigin): boolean {
        const prefOriginId: number = e?.preferred_origin_id ?? -1;
        const orignId: number = o?.id ?? -2;

        return prefOriginId === orignId;
    }

    function magnitude(magnitudes?: ObjectMagnitude[], preferredMagnitude?: number | null | undefined): JSX.Element {
        if (!magnitudes || magnitudes.length === 0) {
            return <><span>--</span></>
        }

        const hasPreferred: boolean = !!(preferredMagnitude && magnitudes.some((m) => m.id === preferredMagnitude));
        let main: ObjectMagnitude = magnitudes[0];

        if (hasPreferred) {
            main = magnitudes.filter((m) => m.id === preferredMagnitude)[0];
        }

        return <div className={'magnitude'}>
            <div style={{ width: '100%' }}>
                {hasPreferred && <StarIcon />}
                <span>{`${main.type_magnitude} ${main.mag.toFixed(2)}`}</span>
            </div>
            <div className={'subtitle'}>
                <span>{(magnitudes.length - 1 > 0) ? `+ ${magnitudes.length - 1}` : ''}</span>
            </div>
        </div>
    }

    function newOriginFromThis(originId?: number): void {
        if (!originId) {
            return;
        }
        if (earthquake) {
            navigate(`/create/origin/${earthquake.id}/${originId}`)
        }
    }

    function updateEventConfirmation(originId?: number) {
        if (originId === null || originId === undefined) {
            return;
        }
        dispatch(showStoreModal({
            show: true,
            cancel: close,
            confirm: () => updateEvent(originId),
            title: 'ot__make_preferred__confirmation',
            text: 'ot__make_preferred__confirmation__text'
        }));
    }

    function close(): void {
        dispatch(closeStoreModal());
    }

    async function updateEvent(originId?: number): Promise<void> {
        if (!originId) {
            dispatch(closeStoreModal());
            return;
        }
        if (!earthquake || !earthquake.id) {
            dispatch(closeStoreModal());
            return;
        }

        let request: UpdateEventRequest = {
            data: {
                event: {
                    preferred_origin_id: originId
                }
            }
        }

        try {
            let response = await ApiClient.updateApi().updateEvent(earthquake.id, request)
            console.log('OriginTable => updateEvent => response =>', response);
            if (response.status === 200) {
                onSuccessCallbackHandler(
                    setShowNotification,
                    t('cta__origin_table__preferred_success_title'),
                    t('cta__origin_table__preferred_success', { origin: `${originId}`, event: `${earthquake.id}` })
                );

                updateCallback();
            } else {
                const title: string = `${t('cta__origin_table__preferred_error_title')} (${response.status})`;
                const m: string = t('cta__origin_table__preferred_error', { origin: `${originId}`, error: (response as AxiosResponse)?.data?.detail ?? 'Unknown error' });
                onErrorCallbackHandler(title, m, setShowNotification);
            }
        } catch (e) {
            let { response } = (e as AxiosError);
            console.log('OriginTable => updateEvent => error =>', response);
            const title: string = `${t('cta__origin_table__preferred_error_title')} (${response?.status ?? 500})`;
            const m: string = t('cta__origin_table__preferred_error', { origin: `${originId}`, error: (response as AxiosResponse)?.data?.detail ?? 'Unknown error' });
            onErrorCallbackHandler(title, m, setShowNotification);
        }
        dispatch(closeStoreModal());
    }
}

export { OriginTable };
