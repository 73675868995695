import '../Common.css';
import {BeaTextInput} from '../../../BeaTextInput/BeaTextInput';
import {isValidDepth, isValidMinMaxDepth} from "../../../../utils";
import {maxDepthErrorText, minDepthErrorText} from "../../../DropdownFilters/messages";
import {useTranslation} from "react-i18next";

export interface IQueryFiltersDepthProps {
    min?: string;
    max?: string;
    onUpdate: (key: string, value: string) => void;
}

function QueryFiltersDepth({min, max, onUpdate}: IQueryFiltersDepthProps): JSX.Element {

    const {t} = useTranslation();

    function onUpdateMin(value: string): void {
        onUpdate('mindepth', value);
    }

    function onUpdateMax(value: string): void {
        onUpdate('maxdepth', value);
    }
    
    return(<div className={'filter-section-container'}>
        <div className={'filter-section-row'}>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    invalidText={minDepthErrorText(min ?? '', max ?? '', t)}
                    value={min ?? ''}
                    placeholder={'es. 0.00'}
                    label={'fs__title__depth_min'}
                    stateKey={'mindepth'}
                    invalid={!(isValidDepth(min ?? '') &&
                        isValidMinMaxDepth(min ?? '', max ?? ''))
                    }
                    onChange={onUpdateMin}
                />
            </div>
            <div className={'filter-section-col'}>
                <BeaTextInput
                    invalidText={maxDepthErrorText(min ?? '', max ?? '', t)}
                    value={max ?? ''}
                    placeholder={'es. 1000.00'}
                    label={'fs__title__depth_max'}
                    stateKey={'maxdepth'}
                    invalid={!(isValidDepth(max ?? '') &&
                        isValidMinMaxDepth(min ?? '', max ?? ''))
                    }
                    onChange={onUpdateMax}
                />
            </div>
        </div>
    </div>);
}

export {
    QueryFiltersDepth
}