import { CSSProperties, useState } from 'react';
import { ReactComponent as CtaIcon } from '../../../assets/cta.svg'
import '../GenericTable.css'
import { useTranslation } from 'react-i18next';

export interface ICtaMenuItem {
    tag: string;
    label: string;
    callback: () => void;
}

export interface ICtaProps {
    label: string;
    id: string;
    menu: ICtaMenuItem[];
    width?: string;
    reverse?: boolean;
    classWidth: string;
}

function Cta({ id, label, menu, width, reverse, classWidth }: ICtaProps): JSX.Element {

    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();
    const open = () => setOpen(true);
    const close = () => setOpen(false);

    function onBlurCallback(): void {
        if (isOpen) {
            close();
        }
    }

    function onClickCallback(e: React.MouseEvent<HTMLDivElement>): void {
        e.preventDefault();
        e.stopPropagation();
        return isOpen ? close() : open();
    }

    function getStyle(): CSSProperties | undefined {
        if (width) {
            return {
                width: `${width}px !important`,
            }
        } 
        
        return undefined
    }

    function getTop(): CSSProperties {
        if (reverse) {
            // return up
            const optionHeight: number = 36;
            return {
                top: `${-50-(optionHeight * menu.length)}px`
            } 
        } else {
            // return down
            return {
                top: '44px'
            };
        }
    }

    return (<div className={isOpen ? 'ot-cta-container-active' : 'ot-cta-container'} tabIndex={0} onClick={onClickCallback} onBlur={onBlurCallback}>
        <CtaIcon />
        {isOpen && <div className={reverse ? `ot-cta-menu ${classWidth}` : `ot-cta-menu ${classWidth}`} style={getTop()}>
            <div className={'ot-options-container'} style={getStyle()}>
                <h4>{t(label)}: {id}</h4>
                <div className='divider-container'>
                    <div className='divider' />
                </div>
                {
                    menu.map((item, index) => {
                        return (<div
                            key={`${id}-${index}`}
                            className='item'
                            onClick={item.callback}
                        >
                            <p>{t(item.label)}</p>
                        </div>)
                    })
                }
            </div>
        </div>}
    </div>);
}

export {
    Cta
}