import './SettingsCreateForm.css'
import { useTranslation } from "react-i18next";
import { TextInput } from '@carbon/react';
import React, { useEffect, useState } from "react";

interface SettingsCreateFormProps {
    elementName?: string | null | undefined,
    elementDescription?: string | null | undefined,
    onUpdateNameCallback: (name: string) => void,
    onUpdateDescriptionCallback: (description: string) => void,
}

const SettingsGenericCreateForm = ({
    elementName, elementDescription, onUpdateNameCallback, onUpdateDescriptionCallback
}: SettingsCreateFormProps) => {

    const { t } = useTranslation()
    const [name, setName] = useState(elementName ?? '')
    const [description, setDescription] = useState(elementDescription ?? '')

    const updateName = (e: React.FormEvent<HTMLInputElement>) => setName(e.currentTarget.value)
    const updateDescription = (e: React.FormEvent<HTMLInputElement>) => setDescription(e.currentTarget.value)

    useEffect(() => {
        onUpdateNameCallback(name)
    }, [name])

    useEffect(() => {
        onUpdateDescriptionCallback(description)
    }, [description])

    return (<>
        <div className={'settings-create-form'}>
            <span className={'label mb-8'}>{t('settings_table_form_name')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-name"
                hideLabel
                value={name}
                onChange={updateName}
            />
            <span className={'label mb-8 mt-24'}>{t('settings_table_form_description')}</span>
            <TextInput
                labelText=''
                className="input"
                id="settings-description"
                hideLabel
                value={description}
                onChange={updateDescription}
            />
        </div>
    </>)
}

export {
    SettingsGenericCreateForm
}