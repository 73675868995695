import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/reduxCustomHooks";
import EmptyList from "../EmptyList/EmptyList";
import { LoaderTranslated } from "../Loader/LoaderTranslated";
import { GenericTable, IHeader, Row, SORT_ASC, SortDirection, singleRow } from "./GenericTable";
import './GenericTable.css';

interface ProductTableProps {

}

function ProductTable({ }: ProductTableProps) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const [products, setProducts] = useState<string[]>([]);
    const [rows, setRows] = useState<Row[] | undefined>(undefined);
    const earthquake = useAppSelector((state) => state.earthquake);

    useEffect(() => {
        if (earthquake) {
            if (earthquake.flags) {
                setProducts(earthquake.flags.split(','));
                return;
            }
        }

        setProducts([]);
    }, [earthquake]);

    function sortByName(direction: SortDirection): void {
        sortProvenances(
            direction === SORT_ASC
                ? (p1: string, p2: string) => p1.localeCompare(p2)
                : (p1: string, p2: string) => -p1.localeCompare(p2)
        );
    }

    const tableHeaders: IHeader[] = [
        { tag: 'id_tag', label: 'ot__name__label', sortable: true, sortFunction: sortByName },
        { tag: 'description_tag', label: 'ot__description__label', sortable: false }
    ];

    function sortProvenances(sortingProcedure: (p1: string, p2: string) => number): void {
        if (products) {
            setProducts([...products].sort(sortingProcedure));
        }
    }

    useEffect(() => {
        if (products) {
            const temp: Row[] = products.map((prd) => {
                const link: string = `#`;
                return [
                    {
                        tag: 'id_tag',
                        id: `${prd}`,
                        element: name(link, prd)
                    },
                    {
                        tag: 'description_tag',
                        id: `${prd}`,
                        element: description(link, `${prd}_description`)
                    }
                ]
            });
            setRows(temp);
        }
    }, [products]);

    return (
        <>
            <div className={'origin-table-container'}>
                <div className={'origin-button-container'}>

                </div>
                {
                    (products && rows)
                        ? (
                            rows.length > 0
                                ? (
                                    <GenericTable
                                        headers={tableHeaders}
                                        rows={rows}
                                        gridDefinition={'od-pr-grid'}
                                        defaultSorting={{
                                            direction: SORT_ASC,
                                            tag: 'id_tag'
                                        }}
                                    />
                                )
                                : (
                                    <EmptyList title={'od__products_table__empty'} />
                                )
                        )
                        : (
                            <LoaderTranslated />
                        )
                }
            </div>
        </>
    )

    function name(link: string, o: string): JSX.Element {
        return singleRow(link, o ? `${o}` : '--', o ? `${o}` : '--');
    }

    function description(link: string, o: string): JSX.Element {
        return singleRow(link, o ? `${o.toLocaleLowerCase()}` : '--', o ? `${o}` : '--');
    }
}

export { ProductTable };
