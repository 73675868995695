import { createSlice } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
    name: 'layout',
    initialState: 'both',
    reducers: {
        setBoth: () => 'both',
        setList: () => 'list',
        setMap: () => 'map'
    }
});

export const {setBoth, setList, setMap} = layoutSlice.actions
export default layoutSlice.reducer