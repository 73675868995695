import './VersionComponent.css'
import { useEffect, useState } from 'react'
import CustomTooltip from '../CustomTooltip/CustomTooltip'

interface Props {
    versionStr: string
}

const VersionComponent = ({versionStr}:Props) => {

    // ${origin.type_origin?.version_value}#${origin.type_origin?.version_name}
    const [vv, setVV] = useState('--') // origin.type_origin?.version_value
    const [vn, setVN] = useState('--') // origin.type_origin?.version_name

    useEffect(() => {
        let tokens = versionStr.split('#');
        if(tokens.length === 2) {
            setVV(tokens[0])
            setVN(tokens[1])
        } else {
            setVV('err')
            setVN('err')
        }
    }, [versionStr])

    return <div className='doublerow-version-container'>
                <CustomTooltip placement="top" title={`${vn} (${vv})`} arrow>
                    <span className='cell-main-text'>{vn}</span>
                </CustomTooltip> 
                    <span className='cell-sub-text'>({vv})</span>
            </div>
}

export default VersionComponent