import { InlineNotification } from "@carbon/react"
import { useKeycloak } from '@react-keycloak/web'
import { AxiosError, AxiosResponse } from 'axios'
import dayjs from "dayjs"
import 'dayjs/locale/it'
import Leaflet, { LatLngTuple, Map } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import log from "loglevel"
import { createRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MapContainer, Marker, TileLayer, ZoomControl } from "react-leaflet"
import { batch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as ConfirmIcon } from '../../assets/confirm.svg'
import { BreadCrumbComposer } from '../../components/BreadcrumbComposer/BreadcrumbComposer'
import CtaGroupEvent from '../../components/CTA/CtaGroupEvent/CtaGroupEvent'
import CtaGroupOwner from '../../components/CTA/CtaGroupOwner/CtaGroupOwner'
import CustomModal from '../../components/CustomModal/CustomModal'
import { buildEarthquakeTable } from '../../components/CustomTable/TableComposer'
import { COORDINATES_TAG, EVENT_TYPE_TAG, EarthquakeTableEvent, LOCALSPACE_TAG, PRODUCTS_TAG, VERSION_TAG } from '../../components/CustomTable/TableUtils'
import EmptyList from '../../components/EmptyList/EmptyList'
import ErrorComponent from '../../components/ErrorComponent/ErrorComponent'
import { QueryFiltersBox } from '../../components/Filters/QueryFiltersBox'
import FiltersBox from '../../components/FiltersBox/FiltersBox'
import { LoaderTranslated } from '../../components/Loader/LoaderTranslated'
import { Pagination, PaginationFooter } from '../../components/PaginationFooter/PaginationFooter'
import { PAGINATION_DEFAULT, paginationUpdateOrDefault } from "../../components/PaginationFooter/utils"
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks'
import { closeStoreModal, showStoreModal } from '../../redux/reducers/confirmationModal.slice'
import { closeCta } from '../../redux/reducers/cta.slice'
import { resetTable } from "../../redux/reducers/customtable.slice"
import { Filters, defaultFilters, setFilters } from '../../redux/reducers/filters.slice'
import {
    MOVE_MODE,
    SPLIT_MODE,
    VIEW_MODE,
    openGroup,
    viewMode
} from '../../redux/reducers/group.slice'
import { ApiClient } from "../../services/ApiClient"
import {
    ObjectMagnitudeForMagnitudesOriginsEventsAndEventsGroup,
    ObjectMagnitudesOriginsEventsAndEventsGroup,
    UpdateEventsGroupRequest
} from '../../services/network'
import { RequiredError } from '../../services/network/base'
import { buildError, convertTime } from '../../utils'
import {
    BeaInlineNotification,
    defaultNotificationState, onErrorCallbackFullHandler,
    onErrorCallbackHandler, onSuccessCallbackFullHandler,
    onSuccessCallbackHandler
} from "../../utils/InlineNotifications"
import { createFiltersFromUrl, createUrlFromFilters } from "../../utils/urlFromFilters"
import './EarthquakeList.css'
import { joinGroups, moveEvent, splitGroups } from "./groups"
import { evaluateMarkerClass, evaluateMarkerText, evaluateVersion, getEndtimeIfNotInFuture } from './utils'
import { Toast } from "../../components/Toast/Toast"

function EarthquakeList() {
    const { t } = useTranslation()
    const { keycloak } = useKeycloak()
    const navigate = useNavigate()
    const [earthquakes, setEarthquakes] = useState<Array<ObjectMagnitudesOriginsEventsAndEventsGroup> | undefined>()
    const [earthquakeTableEvents, setEarthquakeTableEvents] = useState<Array<EarthquakeTableEvent> | undefined>(undefined)
    const [eartquakeMarkerEvents, setEarthquakeMarkerEvents] = useState<Array<EarthquakeTableEvent>>([])
    const mapBounds: LatLngTuple[] = [[35, 5], [49, 20]]
    const map = createRef<Map>()
    const filters = useAppSelector((state) => state.filters)
    const group = useAppSelector((state) => state.group)
    const cta = useAppSelector((state) => state.cta)
    const table = useAppSelector((state) => state.table)
    const timezone = useAppSelector((state) => state.timezone)
    const layout = useAppSelector((state) => state.layout)
    const filtersmodal = useAppSelector((state) => state.filtersmodal);
    const location = useLocation();

    const dispatch = useAppDispatch();
    const modalSpec = useAppSelector((state) => state.confirmationModal)

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<boolean | string>(false);

    const [showNotification, setNotification] = useState<BeaInlineNotification>(defaultNotificationState);
    const [pagination, setPagination] = useState<Pagination>(PAGINATION_DEFAULT);

    const [httpCode, setHttpCode] = useState<number | string>(200);

    const viewModeFooter = () => {
        if (!earthquakes) return <span>No earthquakes error</span>
        if (!group.src || !group.destination || group.destination.length !== 1) {
            return <span>Impossibile continuare!</span>
        }

        let dst = earthquakes.find((e) => e.id === Number(group.destination && group.destination[0]))
        if (!dst) {
            return <span>Missing info for event: {group.destination[0]}</span>
        }

        let srcArray = table.find((e) => e.parent === cta.ownerId)
        if (!srcArray || !srcArray.children) {
            return <span>Missing info for event: {group.src} [1] {cta.ownerId}</span>
        }

        if (srcArray.children.length === 0) {
            return <span>Missing info for event: {group.src} [2]</span>
        }

        let src = srcArray.children.find((e) => e.id === Number(group.src))
        log.debug('EarthquakeList => viewModeFooter =>', srcArray)
        if (!src) {
            return <span>Missing info for event: {group.src} [3]</span>
        }

        return `Id ${group.src} - ${convertTime(timezone, src.origin?.ot)} - ${src.origin?.region} - ${src.magnitude?.type_magnitude} ${src.magnitude?.mag?.toFixed(1)} => 
        Id ${group.destination[0]} - ${convertTime(timezone, dst.origin?.ot)} - ${dst.origin?.region} - ${dst.magnitude?.type_magnitude} ${dst.magnitude?.mag?.toFixed(1)}`
    }

    const float = function (value: string | undefined): number | undefined {
        return value ? parseFloat(value) : undefined
    }

    const wheretypeoriginvaluein = function (value?: string): string | undefined {
        return value ? value.replaceAll(' ', '').replaceAll('--', '.').replaceAll(',,', ',') : undefined
    }

    function filtersStartTime(filters: Filters): string | undefined {
        if (filters.eventid || filters.magnitudeid || filters.originid) {
            return undefined;
        }
        return filters.starttime;
    }

    function filtersEndTime(filters: Filters): string | undefined {
        if (filters.eventid || filters.magnitudeid || filters.originid) {
            return undefined;
        }
        return getEndtimeIfNotInFuture(filters.endtime);
    }

    function filtersOrderBy(filters: Filters): string | undefined {
        if (filters.eventid || filters.magnitudeid || filters.originid) {
            return undefined;
        }
        return filters.orderby;
    }

    useEffect(() => {
        closeAll();
        return () => {
            closeAll();
        }
    }, [/* only after the initial render */]);

    const getEarthquakes = async (filters: Filters) => {
        setLoading(true);
        try {

            const p = filters?.products ?? undefined;
            const minradiuskm = (filters.minradiuskm ?? undefined);
            const maxradiuskm = (filters.maxradiuskm ?? undefined);

            const qParams: any[] = [
                filtersStartTime(filters), filtersEndTime(filters),
                float(filters.minlat), float(filters.maxlat),
                float(filters.minlon), float(filters.maxlon),
                filters.orpolygon, filters.notinpolygon, // orpolygon?: string, notinpolygon?: string
                filters.wherepolygonnamein
                    ? filters.wherepolygonnamein.split(',')
                    : undefined, // wherepolygonnamein?: Array<'settore_1_pol' | 'settore_2_pol' | 'settore_cat_pol' | 'aree_vulcaniche_italia_2020' | 'ov_aree_vulcani_mari' | 'area_vulcanica_italia' | 'area_confine_italia'>
                float(filters.lat), float(filters.lon),
                undefined, undefined,
                float(minradiuskm), float(maxradiuskm),
                float(filters.minmag), float(filters.maxmag),
                float(filters.mindepth), float(filters.maxdepth),
                float(filters.minnphtot), float(filters.mindistance),
                filters.wheretypeeventnamein ? filters.wheretypeeventnamein.split(',') : undefined,
                filters.wheretypeeventnamenotin ? filters.wheretypeeventnamenotin.split(',') : undefined,
                filters.whereoriginqualityin ? filters.whereoriginqualityin.split(',') : undefined,
                filters.wheremagnitudemagqualityin ? filters.wheremagnitudemagqualityin.split(',') : undefined,
                float(filters.mintypeoriginvalue), float(filters.maxtypeoriginvalue), // mintypeoriginvalue, maxtypeoriginvalue,
                filters.origindirectlinktoevent, // origindirectlinktoevent?: boolean
                filters.magnitudedirectlinktoorigin, // magnitudedirectlinktoorigin?: boolean
                filters.magnitudedirectlinktoevent, // magnitudedirectlinktoevent?: boolean
                wheretypeoriginvaluein(filters.wheretypeoriginvaluein),
                filters.wheretypemagnitudenameregexp, // wheretypemagnitudenameregexp?: string
                filters.whereeventlocalspaceenvironmentin // whereeventlocalspaceenvironmentin?: Array<'development' | 'testing' | 'staging' | 'production' | 'external' | 'catalog' | 'generic'>
                    ? filters.whereeventlocalspaceenvironmentin.split(',')
                    : undefined,
                filters.whereoriginlocalspaceenvironmentin // whereoriginlocalspaceenvironmentin?: Array<'development' | 'testing' | 'staging' | 'production' | 'external' | 'catalog' | 'generic'>
                    ? filters.whereoriginlocalspaceenvironmentin.split(',')
                    : undefined,
                filters.wheremagnitudelocalspaceenvironmentin // wheremagnitudelocalspaceenvironmentin?: Array<'development' | 'testing' | 'staging' | 'production' | 'external' | 'catalog' | 'generic'>
                    ? filters.wheremagnitudelocalspaceenvironmentin.split(',')
                    : undefined,
                filters.whereeventlocalspacenamein, //whereeventlocalspacenamein?: 'event' | 'origin' | 'magnitude'
                filters.whereoriginlocalspacenamein, // whereoriginlocalspacenamein?: string,
                filters.wheremagnitudelocalspacenamein, // wheremagnitudelocalspacenamein?: string
                filters.eventupdatedafter, filters.originupdatedafter, // eventupdatedafter?: string, originupdatedafter?: string
                filters.magnitudeupdatedafter, filters.updatedafteroperator, // magnitudeupdatedafter?: string, updatedafteroperator?: 'and' | 'or'
                p, filtersOrderBy(filters), // whereflagsin?: string, orderby?: string
                undefined, filters.id_localspace, // eventGroupId?: number, idLocalspace?: number
                filters.limit, filters.page, // limit?: number, page?: number,
                filters.eventid,
                filters.originid,
                filters.magnitudeid
            ];

            let response;
            if (!!keycloak.authenticated) {
                switch (filters.route) {
                    case 'events': response = await ApiClient.getApi().getEvents(...qParams); break;
                    case 'origins': response = await ApiClient.getApi().getOrigins(...qParams); break;
                    case 'magnitudes': response = await ApiClient.getApi().getMagnitudes(...qParams); break;
                    case 'all': response = await ApiClient.getApi().getAll(...qParams); break;
                    case 'events-catalog': response = await ApiClient.getApi().getEventsCatalog(...qParams); break;
                    default: response = await ApiClient.getApi().getEventsGroup(...qParams); break;
                }
            } else {
                response = await ApiClient.getApi().getEventsGroup(...qParams);
            }

            setPagination(paginationUpdateOrDefault(response.data));
            setEarthquakes(response.data.data ?? []);
            localStorage.setItem('last_update', dayjs().format('DD/MM/YYYY HH:mm:ss.SSS'));
        } catch (e) {
            setEarthquakes([]);
            let response: AxiosResponse = ((e as AxiosError<RequiredError>).response as AxiosResponse);
            if (response) {
                switch (response.status) {
                    case 422: setHttpCode('el_page_data_no_results'); break;
                    case 404: setHttpCode('el_page_data_not_found'); break;
                    case 409: setHttpCode('el_page_data_not_found'); break;
                    case 400: setHttpCode('el_page_wrong_request'); break;
                    case 429: setHttpCode('el_page_too_many_requests'); break;
                    case 503: setHttpCode('el_page_service_unavailable'); break;
                    default: setHttpCode('el_page_internal_server_error'); break;
                }
            } else {
                setHttpCode('el_page_internal_server_error')
            }

            setError(buildError(e as AxiosError<RequiredError>, (code: number) => console.log(code)));
            setPagination(PAGINATION_DEFAULT);
        }
    }

    useEffect(() => {
        let newFilters: Filters = { ...defaultFilters };
        if (!keycloak.authenticated) {
            newFilters.route = 'events-group'
        }

        log.debug('EarthquakeList => useEffect for filters creation');
        if (location.search === '') {
            log.debug(`EarthquakeList => no query params => redirect to ${createUrlFromFilters(newFilters)}`);
            navigate(`/earthquakelist?${createUrlFromFilters(newFilters)}`);
        }

        if (searchEqualsFilters(location.search, filters)) {
            console.log('EarthquakeList => location => filters and url match => no store update');
            return;
        }

        // insert a condition to avoid double navigation
        let queryParamsString = location.search.replace('?', '')
            .split('&').sort((p1, p2) => p1.localeCompare(p2)).join('&');
        newFilters = createFiltersFromUrl(queryParamsString, filters);

        batch(() => {
            dispatch(setFilters(newFilters));
            dispatch(closeCta());
        });
    }, [location]);

    function searchEqualsFilters(search: string, filters: Filters): boolean {
        const q1: string = search.replace('?', '').split('&')
            .sort((p1, p2) => p1.localeCompare(p2))
            .join('&');
        const q2: string = createUrlFromFilters(filters);
        console.log('searchEqualsFilters', q1, q2);
        return q1 === q2;
    }

    useEffect(() => {
        if (location.search === '') {
            log.debug('EarthquakeList => filters => no download');
            return;
        }

        log.debug('EarthquakeList => useEffect for earthquake download');
        if (!searchEqualsFilters(location.search, filters)) {
            console.log('EarthquakeList => filters => filters and url don\'t match => no download');
            if (!location.search.endsWith('&refresh=true')) {
                return;
            }
        }

        refreshEarthquakes();
        return () => {
            setLoading(true);
            setError(false);
            setEarthquakes(undefined);
            setEarthquakeTableEvents(undefined);
            setEarthquakeMarkerEvents([]);
        }
    }, [filters]); /* after the initial render and after re-renders with changed dependencies */

    const magnitudeStr = (magnitude?: ObjectMagnitudeForMagnitudesOriginsEventsAndEventsGroup) => {
        if (!magnitude || !magnitude.type_magnitude || !magnitude.mag) return '--';
        return `${magnitude?.type_magnitude} ${magnitude?.mag?.toFixed(1)}`
    }

    useEffect(() => {
        if (!earthquakes) { return; }

        const tableEvents: Array<EarthquakeTableEvent> = earthquakes.map((earthquake, index) => ({
            htmlId: `${earthquake.id}-${index}`,
            id: `${earthquake.id}`,
            datetime: earthquake.origin?.ot ?? '',
            magnitude: magnitudeStr(earthquake.magnitude),
            zone: earthquake.origin?.region ?? '',
            depth: `${(earthquake.origin?.quality ?? '--')}`,
            lat: (+(earthquake.origin?.lat ?? 0)).toFixed(4),
            lon: (+(earthquake.origin?.lon ?? 0)).toFixed(4),
            mag: earthquake.magnitude?.mag ?? 0,
            elementGroupId: earthquake?.event_group_id ?? 0,
            [PRODUCTS_TAG]: earthquake?.flags,
            [COORDINATES_TAG]: `${(+(earthquake.origin?.lat ?? 0)).toFixed(2)}, ${(+(earthquake.origin?.lon ?? 0)).toFixed(2)}#${(earthquake.origin?.depth ?? 0).toFixed(2)} Km`,
            [VERSION_TAG]: evaluateVersion(earthquake.origin),
            [LOCALSPACE_TAG]: earthquake.localspace?.name ?? '--',
            [EVENT_TYPE_TAG]: earthquake?.type_event ?? '--'
        }))
        setEarthquakeTableEvents(tableEvents)
        return () => {
            setLoading(true)
        }
    }, [earthquakes])

    useEffect(() => {
        if (map) {
            const x = document.getElementById('earthquakelist-content');
            if (x) {
                switch (layout) {
                    case 'list': { x.style.gridTemplateRows = '0% 100%'; break; }
                    case 'map': { x.style.gridTemplateRows = '100% 0%'; break; }
                    default: { x.style.gridTemplateRows = '50% 50%'; break; }
                }
                map.current?.invalidateSize(true);
            }
        }
    }, [layout])

    useEffect(() => {
        if (!earthquakes) return
        let arrayBounds: Array<LatLngTuple> = earthquakes
            .filter(e => e.origin !== undefined && e.origin !== null)
            .map(e => e.origin)
            .filter(o => o && o.lat && o.lon)
            .map(o => [o?.lat, o?.lon] as LatLngTuple)

        if (arrayBounds.length !== 0) { // to avoid crash on empty bounds
            map.current?.invalidateSize(true);
            if (arrayBounds.length === 1) {
                map.current?.flyTo(arrayBounds[0], 12);
            } else {
                map.current?.fitBounds(new Leaflet.LatLngBounds(arrayBounds));
            }
        } else {
            log.debug('mapBounds => arrayBounds => not applied');
        }
    }, [earthquakes, layout])

    useEffect(() => {
        if (!earthquakeTableEvents) return
        setEarthquakeMarkerEvents([
            ...earthquakeTableEvents.filter((quake) => quake.mag >= 5)
                .sort((a, b) => a.datetime.localeCompare(b.datetime))
                .reverse(),
            ...earthquakeTableEvents.filter((quake) => quake.mag < 5)
                .sort((a, b) => a.datetime.localeCompare(b.datetime))
                .reverse()
        ]);
        setLoading(false);
    }, [earthquakeTableEvents])

    useEffect(() => {
        if (group.mode === SPLIT_MODE) {
            let tableElement = table.find(e => e.parent === group.src);
            if (tableElement) {
                log.debug('EarthquakeList => SPLIT_MODE => element =>', tableElement);
                if (
                    tableElement.children.length === 0 ||
                    (tableElement.children.length === 1 && `${tableElement.children[0].id}` === group.src)
                ) {
                    // setModal(false);
                    dispatch(closeStoreModal());
                    batch(() => {
                        dispatch(viewMode());
                        dispatch(openGroup(group.src ?? '0'));
                    });
                    onErrorCallbackHandler(
                        'split_groups_error',
                        'split_groups_error_text',
                        setNotification
                    );
                    return;
                }
            }

            dispatch(showStoreModal({
                show: true,
                cancel: closeAll,
                confirm: applySplit,
                title: t('modal_confirm_split_title'),
                text: t('modal_confirm_split_text')
            }));
        } else {
            dispatch(closeStoreModal());
        }
    }, [group])

    const applyMoveEvents = function () {
        if (group.src && group.destination && group.destination.length === 1) {
            let request: UpdateEventsGroupRequest = {
                data: {
                    event_group_id: Number(group.destination[0]),
                    eventids: [Number(group.src)],
                    event_group_ids: []
                }
            }
            moveEvent(
                request,
                (id: number | undefined) => {
                    onSuccessCallbackFullHandler(
                        setNotification,
                        'cta_eventgroup_move_success',
                        'cta_eventgroup_move_success_text',
                        `${id}`
                    );
                    refreshEarthquakes();
                },
                (title: string, reason: string) => {
                    onErrorCallbackHandler(title, reason, setNotification);
                    dispatch(viewMode());
                }
            )
        } else {
            onErrorCallbackHandler('unknown_error_title', 'unknown_error_move', setNotification);
            dispatch(viewMode());
        }

        dispatch(closeStoreModal());
    }

    const applyJoinGroups = function () {
        if (group.src && group.destination) {
            log.debug('EarthquakeList => debug => JOIN_MODE => new-api =>', {
                event_group_id: Number(group.src),
                eventids: [],
                event_group_ids: group.destination.map(d => Number(d))
            });

            let request: UpdateEventsGroupRequest = {
                data: {
                    event_group_id: Number(group.src),
                    eventids: [],
                    event_group_ids: group.destination.map(d => Number(d))
                }
            };

            joinGroups(
                request,
                (id: number | undefined) => {
                    console.log('join success => id =>', id)
                    onSuccessCallbackFullHandler(
                        setNotification,
                        'cta_eventgroup_join_success',
                        'cta_eventgroup_join_success_text',
                        `${id}`
                    )

                    refreshEarthquakes();
                },
                (title: string, reason: string) => {
                    onErrorCallbackHandler(title, reason, setNotification);
                    dispatch(viewMode());
                }
            )
                .then(() => console.log('JoinGroups => Ok'))
                .catch((e) => console.log('JoinGroups => error =>', e));
        } else {
            onErrorCallbackHandler('unknown_error_title', 'unknown_error_join', setNotification);
            dispatch(viewMode());
        }
    }

    /* this methos split the group */
    const applySplit = async () => {
        if (!group.src) {
            onErrorCallbackHandler(
                'split_groups_error', 'split_groups_error_generic_text', setNotification
            );
            dispatch(viewMode());

            return;
        }

        let idsToSplit = [group.src];

        await splitGroups(
            {
                data: {
                    event_group_id: 0,
                    event_group_ids: idsToSplit.map(id => Number(id)),
                    eventids: []
                }
            },
            (id: number | undefined) => {
                onSuccessCallbackHandler(
                    setNotification, 'split_groups_success', 'split_groups_success_text'
                );
                refreshEarthquakes();
            },
            (title: string, reason: string) => {
                onErrorCallbackHandler(title, reason, setNotification);
                closeAll();
            }
        );

        closeAll();
    }

    function closeAll(): void {
        batch(() => {
            dispatch(closeCta());
            dispatch(viewMode());
            dispatch(closeStoreModal());
        })
    }

    function closeAllAndReset(): void {
        batch(() => {
            dispatch(closeCta());
            dispatch(viewMode());
            dispatch(closeStoreModal());
            dispatch(resetTable());
        })
    }

    const refreshEarthquakes = function () {
        closeAllAndReset();

        setLoading(true);
        setEarthquakes(undefined);
        setEarthquakeTableEvents(undefined);
        setEarthquakeMarkerEvents([]);

        getEarthquakes(filters).then(() => log.debug('getEarthquakes => ok'));
    }

    useEffect(() => {
        const x = (e: KeyboardEvent) => {
            if (group.mode !== VIEW_MODE) {
                if (e.code === "KeyX") {
                    closeAll();
                    return;
                }
                if (e.key === "V" || e.code === "KeyV") {
                    dispatch(showStoreModal({
                        show: true,
                        cancel: () => dispatch(closeStoreModal()),
                        confirm: group.mode === MOVE_MODE ? applyMoveEvents : applyJoinGroups,
                        title: group.mode === MOVE_MODE ? t('modal_confirm_move_title') : t('modal_confirm_join_title'),
                        text: group.mode === MOVE_MODE ? t('modal_confirm_move_text') : t('modal_confirm_join_text')
                    }));
                }
            }
        }

        document.addEventListener('keydown', x);

        return () => {
            document.removeEventListener('keydown', x);
        }
    }, [group]);

    return (
        <>
            {filtersmodal && <QueryFiltersBox />}
            <div className="earthquakelist-container">
                <BreadCrumbComposer pageId='earthquakeList' elementIds={[]} />
                <div style={{ gridColumn: '1 / span 16', marginLeft: '8px' }}>
                    <h3 className='query-result-header'>
                        <span>{pagination.total}</span> {t('page__list_earthquakes__number_earthquakes')}
                        &nbsp; - {t('page__list_earthquakes__last_update', { time: localStorage.getItem('last_update') })}
                    </h3>
                </div>
                <FiltersBox />
                <div id="earthquakelist-content" className="earthquakelist-content">
                    {
                        showNotification.show
                        && (showNotification.kind === 'success'
                            ? <Toast
                                className='notification-eqlist'
                                title={t(showNotification.reason)}
                                onClose={() => setNotification(defaultNotificationState)}
                            />
                            : <InlineNotification
                                className="notification-eqlist"
                                kind={showNotification.kind}
                                lowContrast
                                actionbuttonlabel="Action"
                                ariaLabel="closes notification"
                                onClose={() => setNotification(defaultNotificationState)}
                                onCloseButtonClick={function noRefCheck() { }}
                                statusIconDescription="notification"
                                subtitle={`${t(showNotification.reason)} ${showNotification.additionalData ?? ''}`}
                                title={t(showNotification.title)}
                            />
                        )

                    }
                    <div id="earthquakelist-map" className="earthquakelist-map">
                        <div
                            className='group-footer-hidden'
                            style={{ display: (group.join.length > 0 || group.mode === MOVE_MODE) ? 'block' : 'none' }}
                        >
                        </div>
                        <MapContainer
                            zoomControl={false}
                            scrollWheelZoom={true}
                            bounds={mapBounds}
                            doubleClickZoom={true}
                            attributionControl={true}
                            style={{ height: '100%', minHeight: '100%', zIndex: '1' }}
                            ref={map}
                            worldCopyJump={true}
                        >
                            <ZoomControl position='bottomright' />

                            <TileLayer
                                url={`${process.env.REACT_APP_BEA_TILE_LAYER}`}
                                attribution={process.env.REACT_APP_BEA_TILE_LAYER_DESCRIPTION}
                            />
                            {/*reference layer*/}
                            {/* removed as done on EE Front End */}
                            {/*<TileLayer
                            url={`${process.env.REACT_APP_BEA_REFERENCE_TILE_LAYER}`}
                        />*/}
                            {eartquakeMarkerEvents.map((event, index) => {
                                return (
                                    <Marker
                                        key={`${event.htmlId}`}
                                        position={[+event.lat, +event.lon]}
                                        riseOnHover={true}
                                        zIndexOffset={Number(event.magnitude) > 4 ? ((Number(event.magnitude) + index) * 1000) : -1000}
                                        eventHandlers={{
                                            click: () => navigate(`/earthquake/${event.id}`, { replace: false })
                                        }}

                                        icon={new Leaflet.DivIcon({
                                            html:
                                                `<div id='${event.htmlId}' class='${evaluateMarkerClass(event.mag)}'>
                                    <div id='${event.htmlId}-text' class="marker-text">
                                        ${evaluateMarkerText(event.mag)}
                                    </div>
                                </div>`,
                                            className: 'no-show'
                                        })}>
                                    </Marker>
                                )
                            })}
                        </MapContainer>
                        {/* groups footer */}
                        <div
                            className='group-footer'
                            style={{ display: (group.join.length > 0 || group.mode === MOVE_MODE) ? 'block' : 'none' }}
                        >
                            <div className='counter'>
                                {
                                    group.mode === MOVE_MODE
                                        ? <span>{viewModeFooter()}</span>
                                        : <span>{group.join.length} gruppi selezionati</span>
                                }
                            </div>
                            <div className='confirm'>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => dispatch(viewMode())}
                                >
                                    {t('esc_to_abort')}
                                </span>
                                <span><strong>or</strong></span>
                                <span>{t('space_to_continue')}</span>
                                <span
                                    className='icon'
                                    onClick={() => {
                                        dispatch(showStoreModal({
                                            show: true,
                                            cancel: () => dispatch(closeStoreModal()),
                                            confirm: group.mode === MOVE_MODE ? applyMoveEvents : applyJoinGroups,
                                            title: group.mode === MOVE_MODE ? t('modal_confirm_move_title') : t('modal_confirm_join_title'),
                                            text: group.mode === MOVE_MODE ? t('modal_confirm_move_text') : t('modal_confirm_join_text')
                                        }));
                                    }}
                                >
                                    <ConfirmIcon />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='earthquakelist-list' id='earthquakelist-list'>
                        {
                            loading
                                ? <div style={{ gridRow: '1 / span 2' }}><LoaderTranslated /></div>
                                : (!earthquakeTableEvents || earthquakeTableEvents.length === 0)
                                    ? (error === false
                                        ?
                                        <div style={{ gridRow: '1 / span 2' }}>
                                            <EmptyList title={t('page__list_earthquakes__no_earthquake_title')} description={t('page__list_earthquakes__no_earthquake_text')} />
                                        </div>
                                        :
                                        <div style={{ gridRow: '1 / span 2' }}>
                                            <ErrorComponent title={`${httpCode}`} description={error as string} asHtml />
                                        </div>
                                    )
                                    : buildEarthquakeTable(!!keycloak.authenticated, filters.route, earthquakeTableEvents)
                        }

                    </div>
                </div>
                <PaginationFooter pagination={pagination} />
                <div
                    className="cta-container"
                    style={{
                        // display: cta.show && auth && group.mode === VIEW_MODE ? 'block' : 'none'
                        display: cta.show && !!keycloak.authenticated && group.mode === VIEW_MODE ? 'block' : 'none'
                    }}
                    id="cta-container">
                    {
                        cta.isGroup
                            ? <CtaGroupEvent
                                onUpdate={(id: number | string | undefined, title: string, reason: string, isError: boolean) => {
                                    if (id === undefined) {
                                        onErrorCallbackFullHandler(
                                            'cta_group_ownerize_success_undefined',
                                            'cta_group_ownerize_success_undefined_text',
                                            setNotification,
                                            false,
                                            `${id}`
                                        );
                                        return;
                                    }
                                    if (isError) {
                                        onErrorCallbackFullHandler(
                                            title, reason, setNotification, false, `${id}`
                                        );
                                    } else {
                                        onSuccessCallbackFullHandler(
                                            setNotification, title, reason, `${id}`
                                        );
                                        refreshEarthquakes();
                                        dispatch(openGroup(`${id}`));
                                    }

                                }}
                            />
                            : <CtaGroupOwner />
                    }
                </div>
            </div>

            <CustomModal
                {
                ...modalSpec
                } />
        </>
    )
}

export { EarthquakeList }
