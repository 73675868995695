import {
    CTA_TAG,
    ID_TAG, SORT_ASC, TableHeader,
    TYPOLOGY_TAG,
} from "../TableUtils";

export const DESCRIPTION_TAG = "description"
export const VERSIONNAME_TAG = "versionname"
export const VERSIONVALUE_TAG = "versionvalue"
export const VERSION_SETTINGS_TAG = "version_settings"
export const SOFTWARENAME_TAG = "softwarename"
export const MODEL_TAG = "model"
export const METHOD_TAG = "method"
export const PRIORITY_TAG = 'priority'

export interface SettingsTable {
    [ID_TAG]: string;
    [TYPOLOGY_TAG]: string;
    [DESCRIPTION_TAG]: string;
    [PRIORITY_TAG]?: string;
    [VERSION_SETTINGS_TAG]?: string;
    [VERSIONNAME_TAG]?: string;
    [VERSIONVALUE_TAG]?: string;
    [SOFTWARENAME_TAG]?: string;
    [MODEL_TAG]?: string;
    [METHOD_TAG]?: string;
}

export const settings_eventtable_headers: Array<TableHeader> = [
    {
        tag: ID_TAG,
        header: 'settings_table_id',
        sortable: true,
    },
    {
        tag: TYPOLOGY_TAG,
        header: 'settings_table_event_header',
        sortable: true,
    },
    {
        tag: DESCRIPTION_TAG,
        header: 'settings_table_description',
        sortable: false,
    },
    {
        tag: CTA_TAG,
        header: '',
        sortable: false,
    }
]

export const settings_origintable_headers: Array<TableHeader> = [
    {
        tag: ID_TAG,
        header: 'settings_table_id',
        sortable: true,
    },
    {
        tag: TYPOLOGY_TAG,
        header: 'settings_table_origin_header',
        sortable: true,
    },
    {
        tag: VERSIONNAME_TAG,
        header: 'settings_table_origin_versionname',
        sortable: true,
    },
    {
        tag: VERSIONVALUE_TAG,
        header: 'settings_table_origin_versionvalue',
        sortable: true,
    },
    {
        tag: DESCRIPTION_TAG,
        header: 'settings_table_description',
        sortable: false,
    },
    {
        tag: PRIORITY_TAG,
        header: 'settings_table_priority',
        sortable: true,
    },
    {
        tag: CTA_TAG,
        header: '',
        sortable: false,
    }
]

export const settings_magnitudetable_headers: Array<TableHeader> = [
    {
        tag: ID_TAG,
        header: 'settings_table_id',
        sortable: true,
    },
    {
        tag: TYPOLOGY_TAG,
        header: 'settings_table_magnitude_header',
        sortable: true,
    },
    {
        tag: DESCRIPTION_TAG,
        header: 'settings_table_description',
        sortable: false,
    },
    {
        tag: PRIORITY_TAG,
        header: 'settings_table_priority',
        sortable: true,
    },
    {
        tag: CTA_TAG,
        header: '',
        sortable: false,
    }
]

export const settings_localspacetable_headers: Array<TableHeader> = [
    {
        tag: ID_TAG,
        header: 'settings_table_id',
        sortable: true,
    },
    {
        tag: TYPOLOGY_TAG,
        header: 'settings_table_localspace_header',
        sortable: true,
    },
    {
        tag: DESCRIPTION_TAG,
        header: 'settings_table_description',
        sortable: false,
    },
    {
        tag: PRIORITY_TAG,
        header: 'settings_table_priority',
        sortable: true,
    },
    {
        tag: CTA_TAG,
        header: '',
        sortable: false,
    }
]

export const settings_provenancetable_headers: Array<TableHeader> = [
    {
        tag: ID_TAG,
        header: 'settings_table_id',
        sortable: true,
    },
    {
        tag: TYPOLOGY_TAG,
        header: 'settings_table_provenance_header',
        sortable: true,
    },
    {
        tag: SOFTWARENAME_TAG,
        header: 'settings_table_provenance_softwarename',
        sortable: true,
    },
    {
        tag: VERSION_SETTINGS_TAG,
        header: 'settings_table_provenance_version',
        sortable: false,
    },
    {
        tag: MODEL_TAG,
        header: 'settings_table_provenance_model',
        sortable: false,
    },
    {
        tag: METHOD_TAG,
        header: 'settings_table_provenance_method',
        sortable: false,
    },
    {
        tag: DESCRIPTION_TAG,
        header: 'settings_table_description',
        sortable: false,
    },
    {
        tag: PRIORITY_TAG,
        header: 'settings_table_priority',
        sortable: true,
    },
    {
        tag: CTA_TAG,
        header: '',
        sortable: false,
    }
]

export const castedSettingsSorting = (tag: string, elements: Array<SettingsTable>, direction: number) => {
    let copy = [...elements]
    if(tag === ID_TAG) {
        return copy.sort((e1, e2) => {
            let v1 = Number(e1[tag])
            let v2 = Number(e2[tag])
            return direction === SORT_ASC
                ? v1 - v2
                : -(v1 - v2)
        })
    }

    if(tag === TYPOLOGY_TAG || tag === VERSIONNAME_TAG || tag === SOFTWARENAME_TAG) {
        return copy.sort((e1, e2) => {
            return direction === SORT_ASC
                ? (e1[tag] ?? '--').localeCompare(e2[tag] ?? '--')
                : -((e1[tag] ?? '--').localeCompare(e2[tag] ?? '--'))
        })
    }

    if(tag === PRIORITY_TAG) {
        return copy.sort((e1, e2) => {
            let value: number = 0;
            let p1: number = e1[PRIORITY_TAG] ? +e1[PRIORITY_TAG] : Number.MIN_SAFE_INTEGER;
            let p2: number = e2[PRIORITY_TAG] ? +e2[PRIORITY_TAG] : Number.MIN_SAFE_INTEGER;
            if(p1 > p2) {
                value = 1;
            } else if(p1 < p2) {
                value = -1;
            }
            return direction === SORT_ASC ? value : -value;
        });
    }

    return copy
}