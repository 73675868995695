import './IntervalViewer.css'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import {DATE_FORMAT_DEFAULT} from '../../utils'
import { ReactComponent as CalendarIcon } from '../../assets/calendar-icon.svg';
import { DropdownDatePicker } from '../DropdownDatePicker/DropdownDatePicker';

interface IntervalViewerProps {
    enabled: boolean,
    dateConfirm: (date: string) => void,
    startDate?: string,
}

function IntervalViewer({enabled, dateConfirm, startDate}: IntervalViewerProps) {
    const [date, setDate] = useState<string>(startDate ?? dayjs.utc().format(DATE_FORMAT_DEFAULT))
    const [show, setShow] = useState<boolean>(false)
    const { i18n } = useTranslation()

    dayjs.extend(utc)
    dayjs.locale(i18n.language)

    useEffect(() => {
        console.log('date =>', date, startDate)
        if(startDate) {
            setDate(startDate)
        }
    }, [startDate])


    useEffect(() => {
        console.log('date =>', date, startDate)
        if(enabled) {
            dateConfirm(date)
        }
    }, [date])

    const formatDate = (date: string) => {
        if(date === '') dayjs.utc().format(DATE_FORMAT_DEFAULT)
        return dayjs.utc(date).format(DATE_FORMAT_DEFAULT)
    }

    const handleClick = () => {
        if (enabled) {
            setShow(!show)
        }
    }

    return(
        <div className='interval-viewer-container'>
            <span className={'interval-viewer ' + (show ? 'interval-viewer-active ' : '') + (!enabled ? 'interval-viewer-disabled' : '')} onClick={handleClick}>
                {formatDate(date)}
            </span>
            <CalendarIcon style={{opacity: enabled ? '1' : '0.25'}} />
            {enabled && show &&
                <DropdownDatePicker startDate={date} setShow={setShow} onDateUpdate={setDate}/>
            }
        </div>
    )
}

export { IntervalViewer }