import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import { ApiClient } from '../../services/ApiClient';
import { GetMunicipio200ResponseDataInner } from '../../services/network';
import CustomSelector, { Option } from '../CustomSelector/CustomSelector';

interface Props {
    t: TFunction,
    onSelection: (lat?: number, lon?: number) => void
    selected: string,
}

const MunicipalityAutocomplete = function ({ t, onSelection, selected }: Props) {

    const [municipality, setMunicipality] = useState<string>('');
    const [downloaded, setDownloaded] = useState<GetMunicipio200ResponseDataInner[]>([]);
    const [options, setOptions] = useState<Option[]>([]);

    const onChange = async (query: string) => {
        try {
            console.log('MunicipalityAutocomplete => onChange =>', query)
            setMunicipality('')
            if (query.length >= 3) {
                let response = await ApiClient.getApi().getMunicipio(query)
                console.log('MunicipalityAutocomplete => ', response?.data?.data)
                setDownloaded(response?.data?.data ?? [])
                return
            }
        } catch (e) {
            console.log('MunicipalityAutocomplete => get municipality =>', e)
        }

        setDownloaded([])
    }

    useEffect(() => {
        setOptions(downloaded.map((d, index) => {
            return ({
                key: `${index}`,
                value: d.name ?? ''
            })
        }))
    }, [downloaded])

    useEffect(() => {
    }, [options])

    useEffect(() => {
        if (selected && selected.length > 0) {
            setMunicipality(selected);
        }
    }, [selected])

    const onAutocompleteSelect = function (id: string) {
        try {
            let d: GetMunicipio200ResponseDataInner = downloaded[Number(id)];
            setMunicipality(d.name ?? '');
            onSelection(d.lat, d.lon)
        } catch (e) {
            console.log('onAutocompleteSelect => error', e);
        }
    }

    return (
        <>
            <div className='mg-label-container'>
                <div
                    className='mb-16 mg-label'>
                    <p className='label mg-label'>{t('municipality')}</p>
                    <CustomSelector
                        selected={municipality}
                        isInputText={true}
                        prefix='municipality-autocomplete'
                        options={options}
                        onChange={onChange}
                        onAutocompleteSelect={onAutocompleteSelect}
                        debug={false}
                    />
                </div>
            </div>
        </>
    )
}

export { MunicipalityAutocomplete }