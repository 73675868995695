import dayjs from "dayjs";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks';
import { Filters, defaultFilters } from '../../redux/reducers/filters.slice';
import { createUrlFromFilters } from "../../utils/urlFromFilters";
import { DATETIME_TAG, SORT_DESC } from "../CustomTable/TableUtils";
import { ContentSwitcher } from './ContentSwitcher';
import './Filters.css';
import { FiltersFooter } from './FiltersFooter';
import { FiltersHeader } from './FiltersHeader';
import { QueryFilters } from './QueryFilters';
import { QueryId } from './QueryId';
import { close } from "../../redux/reducers/filtersmodal.slice";
import { resetTimePicker, setStoreFullInterval } from "../../redux/reducers/timepicker.slice";

function QueryFiltersBox(): JSX.Element {

    const [content, setContent] = useState<'ID' | 'FILTERS'>('FILTERS');
    const filters = useAppSelector((state) => state.filters);
    const timepicker = useAppSelector((state) => state.timepicker);
    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    let [localFilters, setLocalFilters] = useState<Filters>({ ...filters });
    const [copyFilters, setCopyFilters] = useState<Filters>({ ...filters });

    useEffect(() => setCopyFilters(filters), [filters]);

    useEffect(() => {
        if (timepicker.fullInterval) {
            if ('days' in timepicker.fullInterval) {
                if (timepicker && timepicker.fullInterval && timepicker.fullInterval.days) {
                    setLocalFilters({
                        ...localFilters,
                        starttime: `${dayjs().subtract(timepicker.fullInterval.days, 'day').format('YYYY-MM-DDT00:00:00')}.000Z`,
                        endtime: undefined,
                    })
                }
            } else if ('startDate' in timepicker.fullInterval) {
                let { startDate, startHour, endDate, endHour } = timepicker.fullInterval;
                let starttime = `${startDate.split('/').reverse().join('-')}T${startHour}.000Z`;

                let endtime = undefined;
                if (endDate && endDate.length > 0) {
                    endtime = `${endDate.split('/').reverse().join('-')}T${endHour}.000Z`;
                }
                setLocalFilters({ ...localFilters, starttime, endtime });
            }
        }
    }, [timepicker])

    useEffect(() => {
        const start = splitDateTime(filters.starttime ?? '');
        const end = splitDateTime(filters.endtime ?? '');
        console.log('filters.end', end);
        const _interval = {
            startDate: start[0] ?? '',
            startHour: start[1] ?? '',
            endDate: end[0] ?? dayjs().format('DD/MM/YYYY'),
            endHour: end[1] ?? '23:59:59'
        }

        if (
            (filters.starttime === dayjs().subtract(7, 'days').format('YYYY-MM-DD[T00:00:00.000Z]') ||
                filters.starttime === dayjs().subtract(7, 'days').format('YYYY-MM-DD[T00:00:00.000+00:00]')) && !filters.endtime
        ) {
            dispatch(setStoreFullInterval({ days: 7 }));
        } else if (filters.starttime === dayjs().subtract(30, 'days').format('YYYY-MM-DD[T00:00:00.000Z]') && !filters.endtime) {
            dispatch(setStoreFullInterval({ days: 30 }));
        } else if (filters.starttime === dayjs().subtract(90, 'days').format('YYYY-MM-DD[T00:00:00.000Z]') && !filters.endtime) {
            dispatch(setStoreFullInterval({ days: 90 }));
        } else if (filters.starttime === dayjs().subtract(365, 'days').format('YYYY-MM-DD[T00:00:00.000Z]') && !filters.endtime) {
            dispatch(setStoreFullInterval({ days: 365 }));
        } else {
            dispatch(setStoreFullInterval(_interval));
        }
    }, [filters]);

    function splitDateTime(datetime: string): [string | undefined, string | undefined] {
        const tokens: string[] = datetime.split('T');
        if (tokens.length !== 2) {
            return [undefined, undefined];
        }

        tokens[1] = tokens[1].replace('Z', '');
        let plusIndex: number = tokens[1].indexOf('+');
        if (plusIndex === -1) {
            plusIndex = tokens[1].length;
        }
        return [dayjs(tokens[0]).format('DD/MM/YYYY'), tokens[1].substring(0, plusIndex).substring(0, tokens[1].indexOf('.'))];
    }

    return (<div className={'filters-area'}>
        <div className={'filters-overlay'} onClick={onCloseCallback}></div>
        <div className={'filters-container'}>
            <FiltersHeader onClose={onCloseCallback} />

            <div className={'filters-container-body'}>
                <ContentSwitcher
                    selectedContent={content}
                    onContentSwitch={(newContent: 'ID' | 'FILTERS') => setContent(newContent)}
                />
                {
                    content === 'FILTERS'
                        ? <QueryFilters filters={copyFilters} onFiltersUpdate={onFiltersUpdateCallback} />
                        : <QueryId filters={copyFilters} onFiltersUpdate={onFiltersUpdateCallback} />
                }
            </div>
            <FiltersFooter
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
                ghostAction={ghostAction}
            />
        </div>
    </div>);

    function onCloseCallback(): void {
        dispatch(close());
    }

    function onFiltersUpdateCallback(newFilters: Filters): void {
        setLocalFilters({ ...newFilters, starttime: localFilters.starttime, endtime: localFilters.endtime });
    }

    function primaryAction(): void {
        navigate(`/earthquakelist?${createUrlFromFilters({ ...localFilters, page: 1, sortDirection: SORT_DESC, sortHeader: DATETIME_TAG })}`);
        onCloseCallback();
    }

    function secondaryAction(): void {
        onCloseCallback();
    }

    function ghostAction(): void {
        setCopyFilters({ ...defaultFilters, route: filters.route });
        setLocalFilters({ ...defaultFilters, route: filters.route });
        dispatch(resetTimePicker());
    }
}

export {
    QueryFiltersBox
};