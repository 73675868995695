import './EarthquakeDetailHeader.css'
import dayjs from 'dayjs'
import 'dayjs/locale/it'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useTranslation } from 'react-i18next'
import { ms } from '../../utils'
import { ReactComponent as IconSeparator } from '../../assets/ed-separator.svg';
import { ReactComponent as IconOther } from '../../assets/other-event-icon.svg';
import { ReactComponent as IconPreferred } from '../../assets/preferred-star.svg';
import { ReactComponent as IconNotExisting } from '../../assets/not-existing-icon.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks'
import { Link } from 'react-router-dom'
import { resetEvent } from '../../redux/reducers/earthquake.slice'
import { closeCta } from '../../redux/reducers/cta.slice'
import { batch } from 'react-redux'

interface EarthquakeDetailHeaderProps {
    dateStr: string;
    eventType: string;
    localspaceName: string;
    id_localspace: string;
    environment: string;
}

function EarthquakeDetailHeader({ dateStr, eventType, localspaceName, id_localspace, environment }: EarthquakeDetailHeaderProps) {
    const { i18n, t } = useTranslation();
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.locale(i18n.language)

    const earthquakeDatetime = dateStr ? dayjs(dateStr) : undefined;
    const earthquake = useAppSelector((state) => state.earthquake);
    const dispatch = useAppDispatch();


    function getStyle(): string {
        if (eventType === 'earthquake') {
            return ''
        };

        if (eventType === 'not existing') {
            return 'not-existing'
        }

        return 'other';
    }

    function getIcon(): JSX.Element {
        if (eventType === 'earthquake') {
            return <></>
        };

        return (<div className='icon'>
            {
                eventType === 'not existing'
                    ? <IconNotExisting />
                    : <IconOther />
            }
        </div>);
    }

    return (
        <div className='earthquake-detail-header'>
            <div>
                <div className='earthquake-detail-group'>
                    <div className='type'>
                        {getIcon()}
                        <span className={getStyle()}><strong>{eventType}</strong></span>
                    </div>
                    <span>—</span>
                    <span className='time'>
                        {
                            earthquakeDatetime
                                ? `${earthquakeDatetime.tz('UTC').format('YYYY-MM-DD HH:mm:ss')}.${ms(dateStr)}`
                                : '--'
                        }
                    </span>
                    <div className='tz'>
                        <span>UTC</span>
                    </div>
                </div>

                <div className={'earthquake-detail-separator'}>
                    <IconSeparator />
                </div>

                <div className='earthquake-detail-group'>
                    <span className='time'>
                        {
                            earthquakeDatetime
                                ? `${earthquakeDatetime.tz('Europe/Rome').format('YYYY-MM-DD HH:mm:ss')}.${ms(dateStr)}`
                                : '--'
                        }
                    </span>
                    <div className='tz'>
                        <span>UTC+2 (Italia)</span>
                    </div>
                </div>

                <div className={'earthquake-detail-separator'}>
                    <IconSeparator />
                </div>

                <div className='earthquake-detail-group'>
                    <div className='type'>
                        <IconPreferred />
                        <span><strong>{t('ed__header__owner')}</strong>:</span>
                        <span className='time'>
                            {idOwner()}
                        </span>
                    </div>

                </div>
            </div>
            <div>
                {(earthquake && earthquake.id && earthquake.id !== null && earthquake.id !== undefined) &&
                    <>
                        <div>
                            <span>ID:</span>
                            <span><strong>{earthquake.id}</strong></span>
                        </div>

                        <div className={'earthquake-detail-separator'}>
                            <IconSeparator />
                        </div>
                    </>
                }
                <div>
                    <span>Localspace:</span>
                    <span><strong>{localspaceName}</strong></span>
                </div>

                <div className={'earthquake-detail-separator'}>
                    <IconSeparator />
                </div>

                <div>
                    <span>{t('ed__header__id_localspace')}:</span>
                    <span><strong>{id_localspace}</strong></span>
                </div>

                <div className={'earthquake-detail-separator'}>
                    <IconSeparator />
                </div>

                <div>
                    <span>{t('ed__header__environment')}:</span>
                    <span><strong>{environment}</strong></span>
                </div>
            </div>
        </div>
    );

    function idOwner(): JSX.Element {
        if (!earthquake) {
            return <></>;
        }
        const { id, event_group_id } = earthquake;
        if (id === undefined || id === null) {
            return <>no id</>;
        }

        if (!event_group_id || event_group_id === id) {
            return <>{id}</>
        }

        return <Link to={`/earthquake/${event_group_id}`} onClick={() => {
            batch(() => {
                dispatch(resetEvent());
                dispatch(closeCta());
            });            
        }}>{event_group_id}</Link>;
    }
}

export { EarthquakeDetailHeader }