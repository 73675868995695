import { Filters } from "../../redux/reducers/filters.slice";
import { isValidDepth, isValidInteger, isValidLatitude, isValidLongitude, isValidMagnitude, isValidMinMaxDepth, isValidMinMaxLatitude, isValidMinMaxLongitude, isValidMinMaxMagnitude, isValidMinMaxRadius, isValidMinMaxTypeOrigin, isValidRadius, isValidTypeOrigin, isValidTypeOriginWhereIn, validNumeric } from "../../utils";
import { CIRCLE_GEOFENCE, RECTANGULAR_GEOFENCE, CROWN_GEOFENCE } from "../GeofenceSelector/GeofenceSelector";
import {NewIntervalType} from "../DateTimeSelector/NewDateTimeSelector";
import {validInterval} from "../DateTimeSelector/utils";


function validMagnitude(minmag?: string, maxmag?: string): boolean {
    return isValidMagnitude(minmag ?? '', true) && isValidMagnitude(maxmag ?? '', true) && isValidMinMaxMagnitude(minmag ?? '', maxmag ?? '', true)
}

function validDepth(mindepth?: string, maxdepth?: string): boolean {
    return isValidDepth(mindepth ?? '', true) && isValidDepth(maxdepth ?? '', true) && isValidMinMaxDepth(mindepth ?? '', maxdepth ?? '', true)
}

function validCoordinatesAndRadius(shape: string, lat: string|undefined, lon:string|undefined, minlat: string|undefined, maxlat:string|undefined, minlon: string|undefined, maxlon:string|undefined, minradius: string|undefined, maxradius:string|undefined): boolean {
    if(shape === RECTANGULAR_GEOFENCE){
        return (
            isValidLatitude(minlat ?? '', true) && isValidLatitude(maxlat ?? '', true) &&
            isValidLongitude(minlon ?? '', true) && isValidLongitude(minlat ?? '', true) &&
            isValidMinMaxLatitude(minlat ?? '', maxlat ?? '', true) && isValidMinMaxLongitude(minlon ?? '', maxlon ?? '', true)
        )
    }

    if(shape === CIRCLE_GEOFENCE) {
        return (
            isValidLatitude(lat ?? '', !validNumeric(maxradius ?? '')) &&
            isValidLongitude(lon ?? '', !validNumeric(maxradius ?? '')) &&
            (!minradius || minradius === '0') &&
            isValidRadius(maxradius ?? '', !shouldHaveRadius(CIRCLE_GEOFENCE, lat, lon))
        ) 
    }

    if(shape === CROWN_GEOFENCE) {
        return (
            isValidLatitude(lat ?? '', !(validNumeric(maxradius ?? '') || validNumeric(minradius ?? ''))) &&
            isValidLongitude(lon ?? '', !(validNumeric(maxradius ?? '') || validNumeric(minradius ?? ''))) &&
            isValidRadius(minradius ?? '', !shouldHaveRadius(CROWN_GEOFENCE, lat, lon)) &&
            isValidRadius(maxradius ?? '', !shouldHaveRadius(CROWN_GEOFENCE, lat, lon))
        ) 
    }
    return false
}

function validRadius(minradius: string|undefined, maxradius: string|undefined, shape: string): boolean {
    if(shape === RECTANGULAR_GEOFENCE){
        return true
    }
    if(shape === CIRCLE_GEOFENCE) {
        if(Number(minradius) !== 0 || parseFloat(minradius ?? '') !== 0)            
            return false
    }
    return isValidRadius(minradius ?? '', false) && isValidRadius(maxradius ?? '', false) && isValidMinMaxRadius(minradius ?? '', maxradius ?? '', false)
}

export const shouldHaveRadius = function(shape: string, lat: string|undefined, lon: string|undefined):boolean {
    let result = isValidLatitude(lat ?? '', false) || isValidLongitude(lon ?? '', false)
    if(shape === RECTANGULAR_GEOFENCE)
        return false        
    return result
}

const validVersion = function(min?: string, max?: string, wherein?: string): boolean {
    // no min, no max, no interval, ok
    if(!min && !max && !wherein) return true
    // min or max (or both), no interval, ok
    if((isValidTypeOrigin(min ?? '') || isValidTypeOrigin(max ?? '')) && !wherein){
        // if min and max, check if they're correct
        if(min && max) {
            return isValidMinMaxTypeOrigin(min, max)
        }
        // otherwise it is ok
        return true
    }
    // no min, no max, interval, ok
    if(!min && !max && isValidTypeOriginWhereIn(wherein ?? '')) return true

    if(isValidTypeOrigin(min ?? '') && isValidTypeOrigin(max ?? '') && isValidTypeOriginWhereIn(wherein ?? '')){
        return true
    }
    return false
}

export const disableButton = function(filters:Filters, fullInterval?: NewIntervalType): boolean {
    let {minmag, maxmag} = filters
    let {mindepth, maxdepth} = filters
    let {shape, lat, lon, minlat, maxlat, minlon, maxlon} = filters
    let {minradiuskm, maxradiuskm} = filters
    let {mintypeoriginvalue, maxtypeoriginvalue, wheretypeoriginvaluein} = filters

    if(!fullInterval) return true

    if(validInterval(fullInterval)) {
        if (validMagnitude(minmag, maxmag)) {
            if (validDepth(mindepth, maxdepth)) {
                if (validCoordinatesAndRadius(shape, lat, lon, minlat, maxlat, minlon, maxlon, minradiuskm, maxradiuskm)) {
                    if (validVersion(mintypeoriginvalue, maxtypeoriginvalue, wheretypeoriginvaluein))
                        return false
                }
            }
        }
    }
    
    return true
}