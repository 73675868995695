import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const defaultState: string[] = [];

export const stationEndpointsErrorSlice = createSlice({
    name: 'stationEndpointsError',
    initialState: defaultState,
    reducers: {
        addStationEndpointError: (state, stationsAction: PayloadAction<string>) => {
            return [...state, stationsAction.payload];
        },
        removeStationEndpointError: (state, stationsAction: PayloadAction<string>) => {
            return [...state.filter((s) => s !== stationsAction.payload)];
        },
        resetStationEndpointsError: () => {
            return defaultState;
        }
    }
});

export const { addStationEndpointError, removeStationEndpointError, resetStationEndpointsError } = stationEndpointsErrorSlice.actions;
export default stationEndpointsErrorSlice.reducer;