/* tslint:disable */
/* eslint-disable */
/**
 * Dante Web Services
 *       # Introduction   Dante is an API Web Service used for iteract with earthquake data stored in database (**quakedb**); the **quakedb** database schema is used at INGV.   Use other schema would require cration of specific `Model` and `Controller` but this is the potential of web services.      Dante provides a set of routes to store message **event**, **origin**, **magnitude**, **arrival**, **...**.      # Input   As input, Dante acept:   - A `json` message (view \'**store**\' spec below)    - An Eartworm `json` message (view \'**earthworm api**\' spec below) produced by **ew2openapi** module      # Output   As output, Dante has a RESTful api foreach database table and implement three specific routes:    - `events-group`: returns the preferred origin and the preferred magnitude from all clusterd events.    - `events`: returns the preferred origin and the preferred magnitude from the same instance.    - `event`: returns the full event (event, origins, magnitudes, arrivals, amplitude, etc...) from an **eventid** or **originid**_/_**originid**.        
 *
 * The version of the OpenAPI document: 3.20.0
 * Contact: valentino.lauciani@ingv.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ObjectOriginFlag } from '../model';
// @ts-ignore
import { ObjectTableLocalspace } from '../model';
// @ts-ignore
import { ObjectTableProvenance } from '../model';
// @ts-ignore
import { ObjectTableTypeEvent } from '../model';
// @ts-ignore
import { ObjectTableTypeMagnitude } from '../model';
// @ts-ignore
import { ObjectTableTypeOrigin } from '../model';
// @ts-ignore
import { Problem } from '../model';
// @ts-ignore
import { UpdateEvent200Response } from '../model';
// @ts-ignore
import { UpdateEventRequest } from '../model';
// @ts-ignore
import { UpdateEventsGroupRequest } from '../model';
// @ts-ignore
import { UpdateMomenttensor200Response } from '../model';
// @ts-ignore
import { UpdateMomenttensorRequest } from '../model';
/**
 * UpdateApi - axios parameter creator
 * @export
 */
export const UpdateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update an existing event.</br> To use this API you must be authenticated and \"event.localspace_name\" owner.</br> More info about `event_group_id` <b>https://gitlab.rm.ingv.it/caravel/dante8/-/issues/69#note_60479</b>
         * @summary Update an existing event
         * @param {number} id INGV eventid that need to be updated.
         * @param {UpdateEventRequest} updateEventRequest JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: async (id: number, updateEventRequest: UpdateEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEvent', 'id', id)
            // verify required parameter 'updateEventRequest' is not null or undefined
            assertParamExists('updateEvent', 'updateEventRequest', updateEventRequest)
            const localVarPath = `/quakedb/v1/event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new events-group or Update an existing one.</br> To use this API you must be authenticated.
         * @summary Update/Create an events-group
         * @param {UpdateEventsGroupRequest} updateEventsGroupRequest JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventsGroup: async (updateEventsGroupRequest: UpdateEventsGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEventsGroupRequest' is not null or undefined
            assertParamExists('updateEventsGroup', 'updateEventsGroupRequest', updateEventsGroupRequest)
            const localVarPath = `/quakedb/v1/eventsgroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEventsGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing localspace.</br> To use this API you must be authenticated.
         * @summary Update an existing localspace
         * @param {number} id INGV localspace id that need to be updated.
         * @param {ObjectTableLocalspace} objectTableLocalspace JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocalspace: async (id: number, objectTableLocalspace: ObjectTableLocalspace, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLocalspace', 'id', id)
            // verify required parameter 'objectTableLocalspace' is not null or undefined
            assertParamExists('updateLocalspace', 'objectTableLocalspace', objectTableLocalspace)
            const localVarPath = `/quakedb/table/v1/localspace/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectTableLocalspace, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing momenttensor.</br> To use this API you must be authenticated and \"momenttensor.localspace_name\" owner.
         * @summary Update an existing momenttensor
         * @param {number} id INGV momenttensor id that need to be updated.
         * @param {UpdateMomenttensorRequest} updateMomenttensorRequest JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMomenttensor: async (id: number, updateMomenttensorRequest: UpdateMomenttensorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMomenttensor', 'id', id)
            // verify required parameter 'updateMomenttensorRequest' is not null or undefined
            assertParamExists('updateMomenttensor', 'updateMomenttensorRequest', updateMomenttensorRequest)
            const localVarPath = `/quakedb/v1/momenttensor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMomenttensorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing origin-flag.</br> To use this API you must be authenticated.
         * @summary Update an existing origin-flag
         * @param {number} id INGV origin-flag id that need to be updated.
         * @param {ObjectOriginFlag} objectOriginFlag JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOriginFlag: async (id: number, objectOriginFlag: ObjectOriginFlag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOriginFlag', 'id', id)
            // verify required parameter 'objectOriginFlag' is not null or undefined
            assertParamExists('updateOriginFlag', 'objectOriginFlag', objectOriginFlag)
            const localVarPath = `/quakedb/table/v1/origin-flag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectOriginFlag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing provenance.</br> To use this API you must be authenticated.
         * @summary Update an existing provenance
         * @param {number} id INGV provenance id that need to be updated.
         * @param {ObjectTableProvenance} objectTableProvenance JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvenance: async (id: number, objectTableProvenance: ObjectTableProvenance, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProvenance', 'id', id)
            // verify required parameter 'objectTableProvenance' is not null or undefined
            assertParamExists('updateProvenance', 'objectTableProvenance', objectTableProvenance)
            const localVarPath = `/quakedb/table/v1/provenance/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectTableProvenance, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing type_event.</br> To use this API you must be authenticated.
         * @summary Update an existing type_event
         * @param {number} id INGV type_event id that need to be updated.
         * @param {ObjectTableTypeEvent} objectTableTypeEvent JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTypeEvent: async (id: number, objectTableTypeEvent: ObjectTableTypeEvent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTypeEvent', 'id', id)
            // verify required parameter 'objectTableTypeEvent' is not null or undefined
            assertParamExists('updateTypeEvent', 'objectTableTypeEvent', objectTableTypeEvent)
            const localVarPath = `/quakedb/table/v1/type-event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectTableTypeEvent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing type_magnitude.</br> To use this API you must be authenticated.
         * @summary Update an existing type_magnitude
         * @param {number} id INGV type_magnitude id that need to be updated.
         * @param {ObjectTableTypeMagnitude} objectTableTypeMagnitude JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTypeMagnitude: async (id: number, objectTableTypeMagnitude: ObjectTableTypeMagnitude, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTypeMagnitude', 'id', id)
            // verify required parameter 'objectTableTypeMagnitude' is not null or undefined
            assertParamExists('updateTypeMagnitude', 'objectTableTypeMagnitude', objectTableTypeMagnitude)
            const localVarPath = `/quakedb/table/v1/type-magnitude/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectTableTypeMagnitude, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing type_origin.</br> To use this API you must be authenticated.
         * @summary Update an existing type_origin
         * @param {number} id INGV type_origin id that need to be updated.
         * @param {ObjectTableTypeOrigin} objectTableTypeOrigin JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTypeOrigin: async (id: number, objectTableTypeOrigin: ObjectTableTypeOrigin, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTypeOrigin', 'id', id)
            // verify required parameter 'objectTableTypeOrigin' is not null or undefined
            assertParamExists('updateTypeOrigin', 'objectTableTypeOrigin', objectTableTypeOrigin)
            const localVarPath = `/quakedb/table/v1/type-origin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(objectTableTypeOrigin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdateApi - functional programming interface
 * @export
 */
export const UpdateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdateApiAxiosParamCreator(configuration)
    return {
        /**
         * Update an existing event.</br> To use this API you must be authenticated and \"event.localspace_name\" owner.</br> More info about `event_group_id` <b>https://gitlab.rm.ingv.it/caravel/dante8/-/issues/69#note_60479</b>
         * @summary Update an existing event
         * @param {number} id INGV eventid that need to be updated.
         * @param {UpdateEventRequest} updateEventRequest JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvent(id: number, updateEventRequest: UpdateEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateEvent200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvent(id, updateEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UpdateApi.updateEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new events-group or Update an existing one.</br> To use this API you must be authenticated.
         * @summary Update/Create an events-group
         * @param {UpdateEventsGroupRequest} updateEventsGroupRequest JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEventsGroup(updateEventsGroupRequest: UpdateEventsGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableTypeEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventsGroup(updateEventsGroupRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UpdateApi.updateEventsGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing localspace.</br> To use this API you must be authenticated.
         * @summary Update an existing localspace
         * @param {number} id INGV localspace id that need to be updated.
         * @param {ObjectTableLocalspace} objectTableLocalspace JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocalspace(id: number, objectTableLocalspace: ObjectTableLocalspace, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableLocalspace>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocalspace(id, objectTableLocalspace, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UpdateApi.updateLocalspace']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing momenttensor.</br> To use this API you must be authenticated and \"momenttensor.localspace_name\" owner.
         * @summary Update an existing momenttensor
         * @param {number} id INGV momenttensor id that need to be updated.
         * @param {UpdateMomenttensorRequest} updateMomenttensorRequest JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMomenttensor(id: number, updateMomenttensorRequest: UpdateMomenttensorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateMomenttensor200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMomenttensor(id, updateMomenttensorRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UpdateApi.updateMomenttensor']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing origin-flag.</br> To use this API you must be authenticated.
         * @summary Update an existing origin-flag
         * @param {number} id INGV origin-flag id that need to be updated.
         * @param {ObjectOriginFlag} objectOriginFlag JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOriginFlag(id: number, objectOriginFlag: ObjectOriginFlag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectOriginFlag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOriginFlag(id, objectOriginFlag, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UpdateApi.updateOriginFlag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing provenance.</br> To use this API you must be authenticated.
         * @summary Update an existing provenance
         * @param {number} id INGV provenance id that need to be updated.
         * @param {ObjectTableProvenance} objectTableProvenance JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProvenance(id: number, objectTableProvenance: ObjectTableProvenance, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableProvenance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProvenance(id, objectTableProvenance, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UpdateApi.updateProvenance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing type_event.</br> To use this API you must be authenticated.
         * @summary Update an existing type_event
         * @param {number} id INGV type_event id that need to be updated.
         * @param {ObjectTableTypeEvent} objectTableTypeEvent JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTypeEvent(id: number, objectTableTypeEvent: ObjectTableTypeEvent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableTypeEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTypeEvent(id, objectTableTypeEvent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UpdateApi.updateTypeEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing type_magnitude.</br> To use this API you must be authenticated.
         * @summary Update an existing type_magnitude
         * @param {number} id INGV type_magnitude id that need to be updated.
         * @param {ObjectTableTypeMagnitude} objectTableTypeMagnitude JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTypeMagnitude(id: number, objectTableTypeMagnitude: ObjectTableTypeMagnitude, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableTypeMagnitude>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTypeMagnitude(id, objectTableTypeMagnitude, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UpdateApi.updateTypeMagnitude']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing type_origin.</br> To use this API you must be authenticated.
         * @summary Update an existing type_origin
         * @param {number} id INGV type_origin id that need to be updated.
         * @param {ObjectTableTypeOrigin} objectTableTypeOrigin JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTypeOrigin(id: number, objectTableTypeOrigin: ObjectTableTypeOrigin, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectTableTypeOrigin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTypeOrigin(id, objectTableTypeOrigin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UpdateApi.updateTypeOrigin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UpdateApi - factory interface
 * @export
 */
export const UpdateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdateApiFp(configuration)
    return {
        /**
         * Update an existing event.</br> To use this API you must be authenticated and \"event.localspace_name\" owner.</br> More info about `event_group_id` <b>https://gitlab.rm.ingv.it/caravel/dante8/-/issues/69#note_60479</b>
         * @summary Update an existing event
         * @param {number} id INGV eventid that need to be updated.
         * @param {UpdateEventRequest} updateEventRequest JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(id: number, updateEventRequest: UpdateEventRequest, options?: any): AxiosPromise<UpdateEvent200Response> {
            return localVarFp.updateEvent(id, updateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new events-group or Update an existing one.</br> To use this API you must be authenticated.
         * @summary Update/Create an events-group
         * @param {UpdateEventsGroupRequest} updateEventsGroupRequest JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventsGroup(updateEventsGroupRequest: UpdateEventsGroupRequest, options?: any): AxiosPromise<ObjectTableTypeEvent> {
            return localVarFp.updateEventsGroup(updateEventsGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing localspace.</br> To use this API you must be authenticated.
         * @summary Update an existing localspace
         * @param {number} id INGV localspace id that need to be updated.
         * @param {ObjectTableLocalspace} objectTableLocalspace JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocalspace(id: number, objectTableLocalspace: ObjectTableLocalspace, options?: any): AxiosPromise<ObjectTableLocalspace> {
            return localVarFp.updateLocalspace(id, objectTableLocalspace, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing momenttensor.</br> To use this API you must be authenticated and \"momenttensor.localspace_name\" owner.
         * @summary Update an existing momenttensor
         * @param {number} id INGV momenttensor id that need to be updated.
         * @param {UpdateMomenttensorRequest} updateMomenttensorRequest JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMomenttensor(id: number, updateMomenttensorRequest: UpdateMomenttensorRequest, options?: any): AxiosPromise<UpdateMomenttensor200Response> {
            return localVarFp.updateMomenttensor(id, updateMomenttensorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing origin-flag.</br> To use this API you must be authenticated.
         * @summary Update an existing origin-flag
         * @param {number} id INGV origin-flag id that need to be updated.
         * @param {ObjectOriginFlag} objectOriginFlag JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOriginFlag(id: number, objectOriginFlag: ObjectOriginFlag, options?: any): AxiosPromise<ObjectOriginFlag> {
            return localVarFp.updateOriginFlag(id, objectOriginFlag, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing provenance.</br> To use this API you must be authenticated.
         * @summary Update an existing provenance
         * @param {number} id INGV provenance id that need to be updated.
         * @param {ObjectTableProvenance} objectTableProvenance JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvenance(id: number, objectTableProvenance: ObjectTableProvenance, options?: any): AxiosPromise<ObjectTableProvenance> {
            return localVarFp.updateProvenance(id, objectTableProvenance, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing type_event.</br> To use this API you must be authenticated.
         * @summary Update an existing type_event
         * @param {number} id INGV type_event id that need to be updated.
         * @param {ObjectTableTypeEvent} objectTableTypeEvent JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTypeEvent(id: number, objectTableTypeEvent: ObjectTableTypeEvent, options?: any): AxiosPromise<ObjectTableTypeEvent> {
            return localVarFp.updateTypeEvent(id, objectTableTypeEvent, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing type_magnitude.</br> To use this API you must be authenticated.
         * @summary Update an existing type_magnitude
         * @param {number} id INGV type_magnitude id that need to be updated.
         * @param {ObjectTableTypeMagnitude} objectTableTypeMagnitude JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTypeMagnitude(id: number, objectTableTypeMagnitude: ObjectTableTypeMagnitude, options?: any): AxiosPromise<ObjectTableTypeMagnitude> {
            return localVarFp.updateTypeMagnitude(id, objectTableTypeMagnitude, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing type_origin.</br> To use this API you must be authenticated.
         * @summary Update an existing type_origin
         * @param {number} id INGV type_origin id that need to be updated.
         * @param {ObjectTableTypeOrigin} objectTableTypeOrigin JSON to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTypeOrigin(id: number, objectTableTypeOrigin: ObjectTableTypeOrigin, options?: any): AxiosPromise<ObjectTableTypeOrigin> {
            return localVarFp.updateTypeOrigin(id, objectTableTypeOrigin, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdateApi - interface
 * @export
 * @interface UpdateApi
 */
export interface UpdateApiInterface {
    /**
     * Update an existing event.</br> To use this API you must be authenticated and \"event.localspace_name\" owner.</br> More info about `event_group_id` <b>https://gitlab.rm.ingv.it/caravel/dante8/-/issues/69#note_60479</b>
     * @summary Update an existing event
     * @param {number} id INGV eventid that need to be updated.
     * @param {UpdateEventRequest} updateEventRequest JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApiInterface
     */
    updateEvent(id: number, updateEventRequest: UpdateEventRequest, options?: AxiosRequestConfig): AxiosPromise<UpdateEvent200Response>;

    /**
     * Create a new events-group or Update an existing one.</br> To use this API you must be authenticated.
     * @summary Update/Create an events-group
     * @param {UpdateEventsGroupRequest} updateEventsGroupRequest JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApiInterface
     */
    updateEventsGroup(updateEventsGroupRequest: UpdateEventsGroupRequest, options?: AxiosRequestConfig): AxiosPromise<ObjectTableTypeEvent>;

    /**
     * Update an existing localspace.</br> To use this API you must be authenticated.
     * @summary Update an existing localspace
     * @param {number} id INGV localspace id that need to be updated.
     * @param {ObjectTableLocalspace} objectTableLocalspace JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApiInterface
     */
    updateLocalspace(id: number, objectTableLocalspace: ObjectTableLocalspace, options?: AxiosRequestConfig): AxiosPromise<ObjectTableLocalspace>;

    /**
     * Update an existing momenttensor.</br> To use this API you must be authenticated and \"momenttensor.localspace_name\" owner.
     * @summary Update an existing momenttensor
     * @param {number} id INGV momenttensor id that need to be updated.
     * @param {UpdateMomenttensorRequest} updateMomenttensorRequest JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApiInterface
     */
    updateMomenttensor(id: number, updateMomenttensorRequest: UpdateMomenttensorRequest, options?: AxiosRequestConfig): AxiosPromise<UpdateMomenttensor200Response>;

    /**
     * Update an existing origin-flag.</br> To use this API you must be authenticated.
     * @summary Update an existing origin-flag
     * @param {number} id INGV origin-flag id that need to be updated.
     * @param {ObjectOriginFlag} objectOriginFlag JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApiInterface
     */
    updateOriginFlag(id: number, objectOriginFlag: ObjectOriginFlag, options?: AxiosRequestConfig): AxiosPromise<ObjectOriginFlag>;

    /**
     * Update an existing provenance.</br> To use this API you must be authenticated.
     * @summary Update an existing provenance
     * @param {number} id INGV provenance id that need to be updated.
     * @param {ObjectTableProvenance} objectTableProvenance JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApiInterface
     */
    updateProvenance(id: number, objectTableProvenance: ObjectTableProvenance, options?: AxiosRequestConfig): AxiosPromise<ObjectTableProvenance>;

    /**
     * Update an existing type_event.</br> To use this API you must be authenticated.
     * @summary Update an existing type_event
     * @param {number} id INGV type_event id that need to be updated.
     * @param {ObjectTableTypeEvent} objectTableTypeEvent JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApiInterface
     */
    updateTypeEvent(id: number, objectTableTypeEvent: ObjectTableTypeEvent, options?: AxiosRequestConfig): AxiosPromise<ObjectTableTypeEvent>;

    /**
     * Update an existing type_magnitude.</br> To use this API you must be authenticated.
     * @summary Update an existing type_magnitude
     * @param {number} id INGV type_magnitude id that need to be updated.
     * @param {ObjectTableTypeMagnitude} objectTableTypeMagnitude JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApiInterface
     */
    updateTypeMagnitude(id: number, objectTableTypeMagnitude: ObjectTableTypeMagnitude, options?: AxiosRequestConfig): AxiosPromise<ObjectTableTypeMagnitude>;

    /**
     * Update an existing type_origin.</br> To use this API you must be authenticated.
     * @summary Update an existing type_origin
     * @param {number} id INGV type_origin id that need to be updated.
     * @param {ObjectTableTypeOrigin} objectTableTypeOrigin JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApiInterface
     */
    updateTypeOrigin(id: number, objectTableTypeOrigin: ObjectTableTypeOrigin, options?: AxiosRequestConfig): AxiosPromise<ObjectTableTypeOrigin>;

}

/**
 * UpdateApi - object-oriented interface
 * @export
 * @class UpdateApi
 * @extends {BaseAPI}
 */
export class UpdateApi extends BaseAPI implements UpdateApiInterface {
    /**
     * Update an existing event.</br> To use this API you must be authenticated and \"event.localspace_name\" owner.</br> More info about `event_group_id` <b>https://gitlab.rm.ingv.it/caravel/dante8/-/issues/69#note_60479</b>
     * @summary Update an existing event
     * @param {number} id INGV eventid that need to be updated.
     * @param {UpdateEventRequest} updateEventRequest JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApi
     */
    public updateEvent(id: number, updateEventRequest: UpdateEventRequest, options?: AxiosRequestConfig) {
        return UpdateApiFp(this.configuration).updateEvent(id, updateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new events-group or Update an existing one.</br> To use this API you must be authenticated.
     * @summary Update/Create an events-group
     * @param {UpdateEventsGroupRequest} updateEventsGroupRequest JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApi
     */
    public updateEventsGroup(updateEventsGroupRequest: UpdateEventsGroupRequest, options?: AxiosRequestConfig) {
        return UpdateApiFp(this.configuration).updateEventsGroup(updateEventsGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing localspace.</br> To use this API you must be authenticated.
     * @summary Update an existing localspace
     * @param {number} id INGV localspace id that need to be updated.
     * @param {ObjectTableLocalspace} objectTableLocalspace JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApi
     */
    public updateLocalspace(id: number, objectTableLocalspace: ObjectTableLocalspace, options?: AxiosRequestConfig) {
        return UpdateApiFp(this.configuration).updateLocalspace(id, objectTableLocalspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing momenttensor.</br> To use this API you must be authenticated and \"momenttensor.localspace_name\" owner.
     * @summary Update an existing momenttensor
     * @param {number} id INGV momenttensor id that need to be updated.
     * @param {UpdateMomenttensorRequest} updateMomenttensorRequest JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApi
     */
    public updateMomenttensor(id: number, updateMomenttensorRequest: UpdateMomenttensorRequest, options?: AxiosRequestConfig) {
        return UpdateApiFp(this.configuration).updateMomenttensor(id, updateMomenttensorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing origin-flag.</br> To use this API you must be authenticated.
     * @summary Update an existing origin-flag
     * @param {number} id INGV origin-flag id that need to be updated.
     * @param {ObjectOriginFlag} objectOriginFlag JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApi
     */
    public updateOriginFlag(id: number, objectOriginFlag: ObjectOriginFlag, options?: AxiosRequestConfig) {
        return UpdateApiFp(this.configuration).updateOriginFlag(id, objectOriginFlag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing provenance.</br> To use this API you must be authenticated.
     * @summary Update an existing provenance
     * @param {number} id INGV provenance id that need to be updated.
     * @param {ObjectTableProvenance} objectTableProvenance JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApi
     */
    public updateProvenance(id: number, objectTableProvenance: ObjectTableProvenance, options?: AxiosRequestConfig) {
        return UpdateApiFp(this.configuration).updateProvenance(id, objectTableProvenance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing type_event.</br> To use this API you must be authenticated.
     * @summary Update an existing type_event
     * @param {number} id INGV type_event id that need to be updated.
     * @param {ObjectTableTypeEvent} objectTableTypeEvent JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApi
     */
    public updateTypeEvent(id: number, objectTableTypeEvent: ObjectTableTypeEvent, options?: AxiosRequestConfig) {
        return UpdateApiFp(this.configuration).updateTypeEvent(id, objectTableTypeEvent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing type_magnitude.</br> To use this API you must be authenticated.
     * @summary Update an existing type_magnitude
     * @param {number} id INGV type_magnitude id that need to be updated.
     * @param {ObjectTableTypeMagnitude} objectTableTypeMagnitude JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApi
     */
    public updateTypeMagnitude(id: number, objectTableTypeMagnitude: ObjectTableTypeMagnitude, options?: AxiosRequestConfig) {
        return UpdateApiFp(this.configuration).updateTypeMagnitude(id, objectTableTypeMagnitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing type_origin.</br> To use this API you must be authenticated.
     * @summary Update an existing type_origin
     * @param {number} id INGV type_origin id that need to be updated.
     * @param {ObjectTableTypeOrigin} objectTableTypeOrigin JSON to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateApi
     */
    public updateTypeOrigin(id: number, objectTableTypeOrigin: ObjectTableTypeOrigin, options?: AxiosRequestConfig) {
        return UpdateApiFp(this.configuration).updateTypeOrigin(id, objectTableTypeOrigin, options).then((request) => request(this.axios, this.basePath));
    }
}

