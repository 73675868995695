import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { batch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { ReactComponent as CheckedIcon } from '../../assets/checked.svg'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import { ReactComponent as CtaEdit } from '../../assets/cta-edit.svg'
import { ReactComponent as CtaIcon } from '../../assets/cta.svg'
import { ReactComponent as DestinationCheckedIcon } from '../../assets/destination-checked.svg'
import { ReactComponent as IconNotExisting } from '../../assets/not-existing-icon.svg'
import { ReactComponent as OpenIcon } from '../../assets/open.svg'
import { ReactComponent as IconOther } from '../../assets/other-event-icon.svg'
import { ReactComponent as IconPreferred } from '../../assets/preferred.svg'
import { ReactComponent as RadioCheckedIcon } from '../../assets/radio-checked.svg'
import { ReactComponent as RadioUncheckedIcon } from '../../assets/radio-unchecked.svg'
import { ReactComponent as UncheckedIcon } from '../../assets/unchecked.svg'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks'
import { closeCta, toggleOriginCta, toggleOwnerCta } from '../../redux/reducers/cta.slice'
import { setFilters } from '../../redux/reducers/filters.slice'
import {
    MOVE_MODE,
    SPLIT_MODE,
    VIEW_MODE,
    addGroupJoin,
    closeGroup,
    joinModeAdd,
    moveMode,
    openGroup,
    removeGroupJoin
} from '../../redux/reducers/group.slice'
import { SETTINGS_EDIT_MODE, edit } from "../../redux/reducers/settings.slice"
import { convertTime } from '../../utils'
import { createUrlFromFilters } from "../../utils/urlFromFilters"
import CustomTooltip from '../CustomTooltip/CustomTooltip'
import { DoubleRowDate } from '../DoubleRowDate/DoubleRowDate'
import { DoubleRowPublished } from '../DoubleRowPublished/DoubleRowPublished'
import { DoubleRowGeneric } from "../DoubleRowRegion/DoubleRowGeneric"
import { DoubleRowRegion } from '../DoubleRowRegion/DoubleRowRegion'
import { getSuffixFromTableType } from "../SettingsTabs/utils"
import VersionComponent from '../VersionComponent/VersionComponent'
import './CustomTable.css'
import GroupRow from './GroupRow'
import HeaderCell from './HeaderCell'
import {
    EARTHQUAKE_LIST_TABLE,
    EVENTDETAIL_CITIES_TABLE,
    EVENTDETAIL_CITIES_TABLE_50,
    EVENTDETAIL_ORIGINS_TABLE,
    GenericEvent,
    GenericEventMag,
    ORIGINDETAIL_ARRIVALS_TABLE,
    ORIGINDETAIL_ML_STATIONMAGNITUDE,
    ORIGINDETAIL_MW_TDMTSTATION,
    ORIGINDETAIL_PRODUCTS_TABLE,
    SETTINGS_EVENT_TABLE,
    SETTINGS_LOCALSPACE_TABLE,
    SETTINGS_MAGNITUDE_TABLE,
    SETTINGS_ORIGIN_TABLE,
    SETTINGS_PROVENANCE_TABLE,
    sortingCallbacks
} from './TableComposer'
import {
    COORDINATES_TAG,
    CTA_TAG,
    DATETIME_TAG,
    DEPTH_TAG,
    EP_AZ_TAG,
    EP_DISTANCE_TAG,
    EVENT_TYPE_TAG,
    EarthquakeTableEvent,
    ID_TAG,
    LOCALSPACE_TAG,
    MAGNITUDE_TAG,
    OPEN_GROUP_TAG,
    PRODUCTS_TAG,
    SCNL_TAG,
    SORT_ASC, SORT_DESC,
    STATION_TAG,
    TYPOLOGY_TAG,
    TableHeader,
    VARIANCE_TAG,
    VERSION_TAG,
    ZCOR_TAG,
    ZONE_TAG,
    evaluateRowColor
} from './TableUtils'
import { isArrivalsTableTag } from './flavor/CustomArrivalTable'
import { DISTANCE_TAG_EDC, INHABITANTS_TAG, MUNICIPALITY_TAG, isCityTableTag } from './flavor/CustomCityTable'
import { EventDetailOriginsTable, PUBLISHED_TAG } from './flavor/CustomOriginTable'
import { PRIORITY_TAG, SOFTWARENAME_TAG, SettingsTable, VERSIONNAME_TAG } from "./flavor/CustomSettingsTable"
import { AMPLITUDE_TAG, CATEGORY_TAG, IS_USED_TAG, PERIOD_TAG, TIMEREF_WINDOW_TAG } from './flavor/CustomStationMagnitudesTable'
import { evaluateHref, evaluateRow } from './utils'
import { removeCustomTableGroup } from '../../redux/reducers/customtable.slice'

interface CustomTableProps {
    logged?: boolean,
    headers: Array<TableHeader>,
    body: Array<EarthquakeTableEvent | any>,
    className: string[],
    type: string,
    sticky?: boolean
}

interface SortingProps {
    header: string,
    direction: number
}

const CustomTable = ({ headers, body, className, type, logged = false, sticky = true }: CustomTableProps) => {

    const [copyBody, setCopyBody] = useState(body)
    const [sortedBy, setSortedBy] = useState<SortingProps>({
        header: '',
        direction: SORT_DESC
    })

    const navigate = useNavigate()
    const filters = useAppSelector((state) => state.filters)
    const group = useAppSelector((state) => state.group)
    const cta = useAppSelector((state) => state.cta)
    const timezone = useAppSelector((state) => state.timezone)
    const dispatch = useAppDispatch()
    const { t } = useTranslation();

    useEffect(() => setCopyBody(body), [body])
    useEffect(() => {
        if (type === EARTHQUAKE_LIST_TABLE) {
            setSortedBy({
                header: filters.sortHeader,
                direction: filters.sortDirection,
            })
        }
    }, [filters])

    useEffect(() => {
        // avoiding default sorting for events list and settings tables
        if (type === EARTHQUAKE_LIST_TABLE || type === ORIGINDETAIL_PRODUCTS_TABLE)
            return

        let defaultHeader = ''
        let sortedBody: Array<GenericEvent> = []
        let direction = SORT_DESC
        switch (type) {
            case EARTHQUAKE_LIST_TABLE: return;
            case EVENTDETAIL_ORIGINS_TABLE: return;
            case SETTINGS_EVENT_TABLE:
            case SETTINGS_ORIGIN_TABLE:
            case SETTINGS_MAGNITUDE_TABLE:
            case SETTINGS_LOCALSPACE_TABLE:
            case SETTINGS_PROVENANCE_TABLE:
                defaultHeader = ID_TAG;
                direction = SORT_ASC
                sortedBody = sortingCallbacks[SETTINGS_EVENT_TABLE](ID_TAG, body, direction)
                break;
            case ORIGINDETAIL_ML_STATIONMAGNITUDE: {
                defaultHeader = TIMEREF_WINDOW_TAG;
                sortedBody = sortingCallbacks[TIMEREF_WINDOW_TAG](body, direction)
                break;
            }
            case EVENTDETAIL_CITIES_TABLE_50: {
                defaultHeader = INHABITANTS_TAG
                sortedBody = sortingCallbacks[EVENTDETAIL_CITIES_TABLE_50](INHABITANTS_TAG, body, direction);
                break;
            }
            case EVENTDETAIL_CITIES_TABLE: {
                defaultHeader = DISTANCE_TAG_EDC
                direction = SORT_ASC
                sortedBody = sortingCallbacks[EVENTDETAIL_CITIES_TABLE](DISTANCE_TAG_EDC, body, direction);
                break
            }
            case ORIGINDETAIL_ARRIVALS_TABLE: {
                defaultHeader = DATETIME_TAG;
                sortedBody = sortingCallbacks[ORIGINDETAIL_ARRIVALS_TABLE](DATETIME_TAG, body, direction)
                break;
            }
            default: {
                defaultHeader = DATETIME_TAG;
                sortedBody = sortingCallbacks[DATETIME_TAG](body, direction)
                break;
            }
        }

        setCopyBody(sortedBody)
        setSortedBy({
            header: defaultHeader,
            direction,
        })
    }, [type])

    const sortingCallbackHandler = (direction: number, tag: string) => {
        console.log('CustomTable => sortingCallbackHandler => ', direction, tag)

        if (type === EARTHQUAKE_LIST_TABLE) {
            const evaulateSortDirection = () => {
                return filters.sortHeader === tag
                    ? (direction === SORT_ASC ? SORT_ASC : SORT_DESC)
                    : SORT_ASC
            }

            const evaluateOrderBy = () => {
                if (tag === DATETIME_TAG) return direction === SORT_ASC ? 'origin_ot-asc' : 'origin_ot-desc'
                if (tag === MAGNITUDE_TAG) return direction === SORT_ASC ? 'magnitude_mag-asc' : 'magnitude_mag-desc'
                if (tag === ID_TAG && logged) return direction === SORT_ASC ? 'event_id-asc' : 'event_id-desc'
            }

            navigate(`/earthquakelist?${createUrlFromFilters({
                ...filters,
                orderby: evaluateOrderBy(),
                sortDirection: evaulateSortDirection(),
                sortHeader: tag,
                page: 1,
            })}`)

            dispatch(setFilters({
                ...filters,
                orderby: evaluateOrderBy(),
                sortDirection: evaulateSortDirection(),
                sortHeader: tag,
                page: 1,
            }))


            // unused
            if (tag === DEPTH_TAG) {
                setCopyBody(sortingCallbacks[tag](body, direction))
            }
            return
        }

        setSortedBy({
            header: tag,
            direction: tag === sortedBy.header ? direction : SORT_ASC // this is the new direction
        })

        if (type === EVENTDETAIL_CITIES_TABLE || type === EVENTDETAIL_CITIES_TABLE_50) {
            if (isCityTableTag(tag)) {
                setCopyBody(sortingCallbacks[type](tag, body, direction))
                return
            }
        }

        if (
            type === SETTINGS_EVENT_TABLE || type === SETTINGS_ORIGIN_TABLE || type === SETTINGS_MAGNITUDE_TABLE ||
            type === SETTINGS_LOCALSPACE_TABLE || type === SETTINGS_PROVENANCE_TABLE
        ) {
            if (tag === ID_TAG || tag === TYPOLOGY_TAG || tag === VERSIONNAME_TAG || tag === SOFTWARENAME_TAG || tag === PRIORITY_TAG) {
                setCopyBody(sortingCallbacks[SETTINGS_EVENT_TABLE](tag, body, direction))
            }
        }

        if (type === ORIGINDETAIL_ARRIVALS_TABLE) {
            if (isArrivalsTableTag(tag)) {
                setCopyBody(sortingCallbacks[ORIGINDETAIL_ARRIVALS_TABLE](tag, body, direction))
            }
            return
        }

        if (type === ORIGINDETAIL_ML_STATIONMAGNITUDE) {
            if (
                tag === MAGNITUDE_TAG || tag === DATETIME_TAG || tag === SCNL_TAG || tag === AMPLITUDE_TAG ||
                tag === PERIOD_TAG || tag === TIMEREF_WINDOW_TAG
            ) {
                setCopyBody(sortingCallbacks[tag](body, direction))
            }
            return
        }


        if (type === ORIGINDETAIL_MW_TDMTSTATION) {
            if (
                tag === STATION_TAG || tag === EP_DISTANCE_TAG || tag === EP_AZ_TAG || tag === VARIANCE_TAG || tag === ZCOR_TAG
            ) {
                setCopyBody(sortingCallbacks[tag](body, direction))
            }
        }
    }

    const styleRows = (element: EarthquakeTableEvent | any) => {
        if (type === EARTHQUAKE_LIST_TABLE) {
            return evaluateRowColor(element)
        }

        return ''
    }

    const buildTableRow = (element: GenericEvent, index: number, className: string[], expandCollapseCallback: (id: string) => void) => {

        const handleOnRowClick = () => {
            if (type === EARTHQUAKE_LIST_TABLE) {
                return logged
                    ? () => expandCollapseCallback((element as EarthquakeTableEvent).id)
                    : () => console.log('on click')
            }

            if (type === EVENTDETAIL_ORIGINS_TABLE) {
                return () => dispatch(closeCta())
            }

            if (type.startsWith('SETTINGS_')) {
                return () => {
                    dispatch(edit({
                        mode: SETTINGS_EDIT_MODE,
                        id: (element as SettingsTable)[ID_TAG],
                        type,
                    }))
                }
            }

            return () => console.log('on click')
        }

        let key = `${type}-${index}`

        return <>
            {
                evaluateRow(
                    'htmlId' in element ? element.htmlId : key, type, logged, `${className.map(c => `row-${c}`)} ${styleRows(element)}`,
                    handleOnRowClick(),
                    headers.map((h) => buildTableCell(element, h.tag, expandCollapseCallback, h.cols)),
                    `${'id' in element ? element.id : ''}-${index}`,
                    filters.route,
                    evaluateHref(type, logged, filters.route, element)
                )
            }
            {type === EARTHQUAKE_LIST_TABLE && group.open.includes((element as EarthquakeTableEvent).id) && logged && filters.route === 'events-group' &&
                <>
                    <GroupRow
                        key={`gr-${key}`}
                        parent={element as EarthquakeTableEvent}
                        groupId={Number((element as EarthquakeTableEvent).elementGroupId)}
                        headers={headers}
                        className={className} />
                </>
            }
        </>
    }

    /*const downloadAndJoin = async (eventId: string, groupId:string) => {
        if(eventId === '0') {
            alert('EvenId: 0')
            return
        }

        if(groupId === '0') {
            console.log('CustomTable => JOIN_MODE => this element is a group itself')
            let copyDestination = group.destination ? group.destination : []
            console.log('CustomTable => JOIN_MODE => destination =>', group.destination)
            batch(() => {
                dispatch(joinModeAdd([...copyDestination, eventId]))
                dispatch(addGroupJoin(eventId))
            })            
            return
        }
        try {
            let response = await ApiClient.getApi().getEventsByGroup(Number(groupId))
            let children = response?.data?.data ?? []
            console.log('CustomTable => JOIN_MODE => downloaded children =>', children)
            let copyDestination = group.destination ? group.destination : []
            console.log('CustomTable => JOIN_MODE => destination =>', group.destination)
            let idsToJoin = children.map(e => `${e?.id ?? 0}`).filter(e => e !== '0')
            copyDestination = [...copyDestination, ...idsToJoin]
            // add children to the table slice
            // add ids to the destination
            batch(() => {
                dispatch(addCustomTableGroup({
                    parent: eventId,
                    children
                }))
                dispatch(joinModeAdd(copyDestination))
                dispatch(addGroupJoin(eventId))
            })
        } catch(e) {
            alert('impossibile aggiunge il gruppo ' + groupId)
            console.log('CustomTable => JOIN_MODE =>', e)
        }
    }*/

    const evaluateCtaCell = (element: EarthquakeTableEvent | any) => {
        if (type === EARTHQUAKE_LIST_TABLE) {
            const id = `${CTA_TAG}-${element.id}`;
            if (group.mode === VIEW_MODE || group.mode === SPLIT_MODE) {
                // this is the situation where there is the three dot column as the CTA 
                return <div
                    key={id}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        dispatch(toggleOwnerCta(element as EarthquakeTableEvent))
                    }}
                    className={`${cta.callerId === id && group.mode === VIEW_MODE ? 'active' : ''} cta-btn`}>
                    <CtaIcon
                        key={`${element.id}:${CTA_TAG}`}
                        id={id}
                        className='empty-header-container' />
                </div>
            }
            else if (group.mode === MOVE_MODE) {
                return <div
                    key={id}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (group.src && group.destination && group.destination.length === 1) {
                            dispatch(moveMode([group.src, (element as EarthquakeTableEvent).id]))
                            console.log('CustomTable => MOVE_MODE =>', [group.src, (element as EarthquakeTableEvent).id])
                        } else {
                            console.log('CustomTable => MOVE_MODE => something is not right', [group.src, group.destination])
                        }
                    }}

                    className={`${cta.callerId === id && group.mode === MOVE_MODE ? 'active' : ''}`}>
                    {
                        group.destination && group.destination.length > 0 && group.destination[0] === (element as EarthquakeTableEvent).id
                            ? <RadioCheckedIcon
                                key={`${element.id}:${CTA_TAG}`}
                                id={id}
                                className='empty-header-container' />
                            : <RadioUncheckedIcon
                                key={`${element.id}:${CTA_TAG}`}
                                id={id}
                                className='empty-header-container' />
                    }
                </div>
            }
            else {
                // this is the JOIN_MODE
                return <div
                    key={id}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (group.join.includes(`${element.id}`)) {
                            // in this section we remove the ids
                            if (group.src === `${element.id}`) {
                                alert('Impossibile rimuovere il raggruppamento di destinazione')
                                return
                            }

                            // this section is now commented since we're not using the ids of the children of the owner
                            // of the group for the operation
                            /*let tableElement = table.find((e) => e.parent === (element as EarthquakeTableEvent).id)
                            let idsToRemove:string[] = []
                            if(tableElement) {
                                // in this section we extract the ids of the events to be removed
                                idsToRemove = tableElement.children.map(e => `${e?.id ?? 0}`).filter(id => id !== '0')
                                console.log('CustomTable => JOIN_MODE => idsToRemove', idsToRemove)
                            } else  {
                                console.log('CustomTable => JOIN_MODE => corrupted data for event', (element as EarthquakeTableEvent).id)
                            }*/
                            let idsToRemove: string[] = [`${element.id}`]

                            // the `group` slice handles the ids for join, split and move operations
                            // when in JOIN_MODE, the `destination` array contains the ids that should be moved
                            // in the `join.src` group
                            let copyDestination = group.destination ? group.destination : []
                            copyDestination = copyDestination.filter(id => !idsToRemove.includes(id))
                            batch(() => {
                                dispatch(removeGroupJoin(`${element.id}`))
                                dispatch(joinModeAdd([...copyDestination]))
                            })
                        } else {
                            // ok, now we have to put ALL the children of the selected event in the group.destination array. 
                            // If the children are not present we must download them before the request. 
                            // Of course we should also remove the children of a removed event. We use the `table` slice to get
                            // the children and, if not present, download them

                            // as before, this section is commented since we're not using the children of the owner of the group for
                            // the join operation
                            /*
                            let tableElement = table.find((e) => e.parent === (element as EarthquakeTableEvent).id)
                            if(tableElement) {
                                // ok, just get children from the slice
                                let idsToJoin:string[] = [tableElement.parent]
                                console.log('CustomTable => JOIN_MODE => before the elementGroupId check=>', idsToJoin)
                                if((element as EarthquakeTableEvent).elementGroupId) {
                                    // we know that it has children and can overwrite the case where the event has no group, i.e. event_group_id: 0
                                    console.log('CustomTable => JOIN_MODE => children to add =>', tableElement.children)
                                    idsToJoin = tableElement.children.map(e => `${e?.id ?? 0}`).filter(id => id !== '0')
                                    console.log('CustomTable => JOIN_MODE => idsToJoin filtered =>', idsToJoin)
                                }
                                let copyDestination = group.destination ? group.destination : []
                                console.log('CustomTable => JOIN_MODE => destination =>', group.destination)
                                batch(() => {
                                    dispatch(joinModeAdd([...copyDestination, ...idsToJoin]))
                                    dispatch(addGroupJoin(`${(element as EarthquakeTableEvent).id}`))
                                })                            
                            } else { 
                                // download the stuff and save it into the slice
                                console.log('CustomTable => JOIN_MODE => tableElement not found. Download it and put it into the store')
                                downloadAndJoin(`${(element as EarthquakeTableEvent)?.id ?? 0}`, `${(element as EarthquakeTableEvent)?.elementGroupId ?? 0}`)
                            }*/

                            let idsToJoin: string[] = [`${(element as EarthquakeTableEvent).id}`]
                            let copyDestination = group.destination ? group.destination : []
                            batch(() => {
                                dispatch(joinModeAdd([...copyDestination, ...idsToJoin]))
                                dispatch(addGroupJoin(`${(element as EarthquakeTableEvent).id}`))
                            })
                        }
                    }}
                    className={`${cta.callerId === id && group.mode === VIEW_MODE ? 'active' : ''}`}>
                    {
                        (group.join.includes(`${element.id}`))
                            ? (
                                group.src === `${element.id}`
                                    ? <DestinationCheckedIcon />
                                    : <CheckedIcon key={`${element.id}:${CTA_TAG}`} id={id} className='empty-header-container' />
                            )
                            : <UncheckedIcon key={`${element.id}:${CTA_TAG}`} id={id} className='empty-header-container' />
                    }
                </div>
            }
        }

        if (type === EVENTDETAIL_ORIGINS_TABLE) {
            const id = `${CTA_TAG}-${element.id}`;
            return <div
                key={id}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    dispatch(toggleOriginCta(element as EventDetailOriginsTable))
                }}
                className={`${cta.callerId === id && group.mode === VIEW_MODE ? 'active' : ''} cta-btn`}>
                <CtaIcon
                    key={`${element.id}:${CTA_TAG}`}
                    id={id}
                    className='empty-header-container' />
            </div>
        }

        if (type.startsWith('SETTINGS_')) {
            let id = `${element.id}-${getSuffixFromTableType(type)}-${CTA_TAG}`
            return <div className={'cta-btn'}>
                <CtaEdit id={id} key={id} className='empty-header-container' style={{ display: 'none' }} />
            </div>
        }

        return <CtaIcon key={`${element.id}-${CTA_TAG}`} className='empty-header-container' />
    }

    const buildTableCell = (element: any, header: string, expandCollapseCallback: (id: string) => void, cols?: number) => {
        let key = `${element.id}-${header}`
        let c = cols ?? 1
        if (type === EVENTDETAIL_ORIGINS_TABLE) {
            if (header === TYPOLOGY_TAG) {
                if ((element as EventDetailOriginsTable).preferred) {
                    return <div className='vertical' key={key}>
                        <div className="magnitudes-list">
                            <CustomTooltip placement="top" title={element[header]} arrow>
                                <span className="mag">
                                    <IconPreferred className="icon" />
                                    <span className="text">{element[header]}</span>
                                </span>
                            </CustomTooltip>
                        </div>
                    </div>

                }
            }
        }

        if (header === TIMEREF_WINDOW_TAG) {
            let time = `${convertTime(timezone, element[header])} (${timezone === 'utc' ? 'UTC' : t('italy')})`
            return <div key={key} className={`cols-${c} single-row-container`}>
                <CustomTooltip placement="top" title={time} arrow>
                    <span className='single-row-content'>{time}</span>
                </CustomTooltip>
            </div>
        }

        if (type === EVENTDETAIL_ORIGINS_TABLE || type === EARTHQUAKE_LIST_TABLE)
            if (header === COORDINATES_TAG) {
                let tokens = element[header].split('#')
                return <DoubleRowGeneric key={key} firstRow={tokens[0]} secondRow={tokens[1]} />
            }

        if (header === VERSION_TAG)
            return <VersionComponent key={key} versionStr={element[header]} />

        if (header === PUBLISHED_TAG)
            return <DoubleRowPublished key={key} str={element[header]} />

        if (header === DATETIME_TAG)
            return <DoubleRowDate key={key} dateStr={element[header]} className={`cols-${c}`} changeTimezone={true} />

        if (header === ZONE_TAG)
            return <DoubleRowRegion key={key} regionStr={element[header]} className={`cols-${c}`} />

        if (header === IS_USED_TAG) {
            return <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                flex: '1 0 0',
                alignSelf: 'stretch'
            }}>
                <div style={{ display: 'flex', alignItems: 'baseline', gap: '4px' }}>
                    {!!element[header] && <div style={{ width: '8px', height: '8px', border: '2px', background: '#198038' }}></div>}
                    <span>{!!element[header] ? t('origindetail_used') : '--'}</span>
                </div>
            </div>
        }

        if (header === PRODUCTS_TAG) {
            let prodPrefix = `pr-${key}`
            let p: string | (JSX.Element[]) = 'N.D.'
            if (element[header]) {
                let splitted = element[header].split(',')
                if (splitted.length > 0) {
                    p = [<span key={`${prodPrefix}-${0}`} className='earthquake-detail-header-tz mr-4'>{splitted[0]}</span>]
                    if (splitted.length - p.length > 0) {
                        p = [...p, <span key={`${prodPrefix}-${2}`} className='earthquake-detail-header-tz mr-4'>+{splitted.length - p.length}</span>]
                    }
                }
            }
            return <div key={`div-${key}`} className={`cols-${c} single-row-container`}>
                {
                    (!element[header])
                        ? <span className='single-row-content'>N.D.</span>
                        : <CustomTooltip title={element[header]} placement="top" arrow>
                            <span className='single-row-content'>{element[header]}</span>
                        </CustomTooltip>
                }
            </div>
        }

        if (header === EVENT_TYPE_TAG) {
            if (element[header] === 'earthquake') {
                return <div></div>;
            }
            return <div
                key={`${element.id}-group-${header}`}
                className={`cols-${c} single-row-container`}
                style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}
            >
                <CustomTooltip placement="top" title={element[header]} arrow>
                    {
                        element[header] === 'not existing'
                            ? <IconNotExisting />
                            : <IconOther />
                    }
                </CustomTooltip>
            </div>
        }

        if (header === MAGNITUDE_TAG) {
            let title = element[header]
            if (type === EVENTDETAIL_ORIGINS_TABLE) {
                title = `${(element as GenericEventMag).mag}`
            }
            return <div key={`div-${key}`} id={key} className={`cols-${c} single-row-container`}>
                <CustomTooltip key={key} placement="top" title={title} arrow>
                    <span className='single-row-content'>{element[header]}</span>
                </CustomTooltip>
            </div>
        }

        if (header === SCNL_TAG || header === MUNICIPALITY_TAG ||
            (type === ORIGINDETAIL_ML_STATIONMAGNITUDE && (header === CATEGORY_TAG || header === TYPOLOGY_TAG)) ||
            (type === EARTHQUAKE_LIST_TABLE && header === LOCALSPACE_TAG)
        ) {
            return <div key={`div-${key}`} id={`div-${key}`} className={`cols-${c} single-row-container`}>
                <CustomTooltip key={key} placement="top" title={element[header]} arrow>
                    <span className='single-row-content'>{element[header]}</span>
                </CustomTooltip>
            </div>
        }

        if (header === OPEN_GROUP_TAG)
            return <div
                key={key}
                id={key}
                className='empty-header-container open-group'
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation();
                    expandCollapseCallback(element.id);
                }}
            >
                {group.open.includes(element.id) ? <CloseIcon /> : <OpenIcon />}
            </div>

        if (header === CTA_TAG) {
            return evaluateCtaCell(element)
        }

        return <div key={key} id={key} className={`cols-${c} single-row-container`}>
            {element[header] !== '--'
                ? <CustomTooltip key={key} placement="top" title={element[header]} arrow>
                    <span className='single-row-content'>{element[header]}</span>
                </CustomTooltip>
                : <span className='single-row-content'>{element[header]}</span>
            }
        </div>
    }

    // here is where we open/close a group
    const expandCollapseCallback = (id: string) => {
        if (group.open.includes(id)) {
            dispatch(closeGroup(id))
            dispatch(closeCta())

            dispatch(removeCustomTableGroup(`${id}`));
        } else {
            dispatch(openGroup(id))
        }
    }

    const evaluateMarginRight = () => {
        let elem = document.getElementById(`${type}-content`)
        if (window.navigator.userAgent.indexOf('Firefox') === -1) {
            if (elem) {
                if (elem.scrollHeight > elem.clientHeight) {
                    return '8px'
                }

                if (type === ORIGINDETAIL_ARRIVALS_TABLE && body.length >= 4) {
                    return '8px'
                }
            }
        }

        return '0px'
    }

    const SCROLL_STEP: number = Number(process.env.REACT_APP_BEA_LOADING_STEP);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {

        const bottom = event.currentTarget.scrollHeight - event.currentTarget.scrollTop <= event.currentTarget.clientHeight;
        if (bottom) {
            setTopLimit(topLimit + SCROLL_STEP <= copyBody.length ? topLimit + SCROLL_STEP : copyBody.length + 1);
        }
    }

    const [topLimit, setTopLimit] = useState(SCROLL_STEP)
    return <>
        <div id={`${type}-header`}
            className="container-12" style={{
                minHeight: '34px',
                position: sticky ? 'sticky' : 'static',
                top: '0px',
                backgroundColor: sticky ? '#f4f4f4' : (type === EVENTDETAIL_CITIES_TABLE || type === EVENTDETAIL_CITIES_TABLE_50 ? '#f4f4f4' : '#fff'),
                zIndex: '23',
                marginRight: evaluateMarginRight(),
            }}
        >
            <div className={className.join(' ')} >
                {
                    headers.map((h, index) =>
                        <HeaderCell
                            sortHeader={sortedBy.header}
                            sortDirection={sortedBy.direction}
                            key={`${h.tag}-${index}`}
                            {...h}
                            sortingCallback={sortingCallbackHandler}
                        />
                    )
                }
            </div>
        </div>
        {
            type === EVENTDETAIL_CITIES_TABLE || type === EVENTDETAIL_CITIES_TABLE_50 || type === ORIGINDETAIL_ML_STATIONMAGNITUDE || type === ORIGINDETAIL_MW_TDMTSTATION ||
                type === ORIGINDETAIL_PRODUCTS_TABLE
                ?
                <div
                    id={`${type}-content`}
                    className="container-12" style={{ overflowY: 'auto', overflowX: 'hidden', zIndex: '9' }}
                    onScroll={handleScroll}>
                    {
                        copyBody.slice(0, topLimit).map((event, index) => buildTableRow(event, index, className, expandCollapseCallback))
                    }
                </div>
                :
                <div id={`${type}-content`}
                    className="container-12" style={{ overflowY: 'auto', overflowX: 'hidden', zIndex: '9' }}
                    onScroll={handleScroll}
                >
                    <div style={{ height: '0px' }}>
                        {
                            copyBody.slice(0, topLimit).map((event, index) => buildTableRow(event, index, className, expandCollapseCallback))
                        }
                    </div>
                </div>
        }

    </>
}

export default CustomTable