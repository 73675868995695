import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ObjectMap } from '../../services/MapsApiClient'

const defaultMapsState = [] as Array<ObjectMap>

export const mapsSlice = createSlice({
    name: 'maps',
    initialState: defaultMapsState,
    reducers: {
        setMaps: (state, actionMaps: PayloadAction<Array<ObjectMap>>) => {
            return actionMaps.payload
        },
        clearMaps: (state) => {
            return defaultMapsState
        }
    }
})

export const {setMaps, clearMaps} = mapsSlice.actions
export default mapsSlice.reducer