import { InlineNotification } from "@carbon/react";
import { useKeycloak } from '@react-keycloak/web';
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import { SETTINGS_EDIT_MODE, edit, view } from '../../redux/reducers/settings.slice';
import { ApiClient } from "../../services/ApiClient";
import { GetProvenance200Response, ObjectTableProvenance } from "../../services/network";
import { BeaInlineNotification, defaultNotificationState, onErrorCallbackHandler, onSuccessCallbackHandler } from '../../utils/InlineNotifications';
import { BeaButton } from "../BeaButton/BeaButton";
import CustomModal from '../CustomModal/CustomModal';
import { SETTINGS_PROVENANCE_TABLE } from '../CustomTable/TableComposer';
import EmptyList from "../EmptyList/EmptyList";
import { LoaderTranslated } from "../Loader/LoaderTranslated";
import { SettingsProvenanceCreateForm } from '../SettingsTabs/SettingsProvenanceCreateForm';
import { addSuccessText, confirmModalText, confirmModalTitle, modalText, modalTitle, successTitle } from '../SettingsTabs/utils';
import { GenericTable, IHeader, Row, SORT_ASC, SortDirection, singleRow } from "./GenericTable";
import './GenericTable.css';
import { idAsc, idDesc, methodAsc, methodDesc, modelAsc, modelDesc, nameAsc, nameDesc, priorityAsc, priorityDesc, softwareNameAsc, softwareNameDesc, versionAsc, versionDesc } from './sortutils';
import { batch } from "react-redux";
import { closeStoreModal, showStoreModal } from "../../redux/reducers/confirmationModal.slice";
import { Toast } from "../Toast/Toast";

interface ProvenanceTableProps {

}

function ProvenanceTable({ }: ProvenanceTableProps) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const [provenances, setProvenances] = useState<ObjectTableProvenance[] | undefined>(undefined);
    const [rows, setRows] = useState<Row[] | undefined>(undefined);
    const [showNotification, setShowNotification] = useState<BeaInlineNotification>(defaultNotificationState);

    const dispatch = useAppDispatch();
    const settings = useAppSelector((state) => state.settings);
    const [modal, setModal] = useState<boolean>(false);
    const [element, setElement] = useState<ObjectTableProvenance>({
        name: ''
    });
    const [disabled, setDisabled] = useState<boolean>(true);

    function sortById(direction: SortDirection): void {
        sortProvenances(direction === SORT_ASC ? idAsc : idDesc);
    }

    function sortByName(direction: SortDirection): void {
        sortProvenances(direction === SORT_ASC ? nameAsc : nameDesc);
    }

    function sortBySoftwarename(direction: SortDirection): void {
        sortProvenances(direction === SORT_ASC ? softwareNameAsc : softwareNameDesc);
    }

    function sortByModel(direction: SortDirection): void {
        sortProvenances(direction === SORT_ASC ? modelAsc : modelDesc);
    }

    function sortByMethod(direction: SortDirection): void {
        sortProvenances(direction === SORT_ASC ? methodAsc : methodDesc);
    }

    function sortByPriority(direction: SortDirection): void {
        sortProvenances(direction === SORT_ASC ? priorityAsc : priorityDesc);
    }

    function sortByVersion(direction: SortDirection): void {
        sortProvenances(direction === SORT_ASC ? versionAsc : versionDesc);
    }

    const tableHeaders: IHeader[] = [
        { tag: 'id_tag', label: 'ot__id__label', sortable: true, sortFunction: sortById },
        { tag: 'name_tag', label: 'ot__name__label', sortable: true, sortFunction: sortByName },
        { tag: 'softwarename_tag', label: 'ot__softwarename__label', sortable: true, sortFunction: sortBySoftwarename },
        { tag: 'version_tag', label: 'ot__version__label', sortable: true, sortFunction: sortByVersion },
        { tag: 'model_tag', label: 'ot__model__label', sortable: true, sortFunction: sortByModel },
        { tag: 'method_tag', label: 'ot__method__label', sortable: true, sortFunction: sortByMethod },
        { tag: 'description_tag', label: 'ot__description__label', sortable: false },
        { tag: 'priority_tag', label: 'ot__priority__label', sortable: true, sortFunction: sortByPriority }
    ];

    function sortProvenances(sortingProcedure: (m1: ObjectTableProvenance, m2: ObjectTableProvenance) => number): void {
        if (provenances) {
            setProvenances([...provenances].sort(sortingProcedure));
        }
    }

    useEffect(() => {
        download().then(() => console.log('ok'));
    }, []);

    useEffect(() => {
        if (element) {
            const {name, softwarename} = element;
            if (name && name.length > 0) {
                if (softwarename && softwarename.length > 0) {
                    setDisabled(false);
                    return;
                }
            }
        }
        setDisabled(true);
    }, [element, disabled]);

    useEffect(() => {
        if (provenances) {
            const temp: Row[] = provenances.map((prv) => {
                const link: string = `#`;
                return [
                    {
                        tag: 'id_tag',
                        id: `${prv.id}`,
                        element: id(link, prv)
                    },
                    {
                        tag: 'name_tag',
                        id: `${prv.id}`,
                        element: name(link, prv)
                    },
                    {
                        tag: 'description_tag',
                        id: `${prv.id}`,
                        element: description(link, prv)
                    },
                    {
                        tag: 'priority_tag',
                        id: `${prv.id}`,
                        element: priority(link, prv)
                    },
                    {
                        tag: 'softwarename_tag',
                        id: `${prv.id}`,
                        element: softwarename(link, prv)
                    },
                    {
                        tag: 'version_tag',
                        id: `${prv.id}`,
                        element: version(link, prv)
                    },
                    {
                        tag: 'method_tag',
                        id: `${prv.id}`,
                        element: method(link, prv)
                    },
                    {
                        tag: 'model_tag',
                        id: `${prv.id}`,
                        element: model(link, prv)
                    }
                ]
            });
            setRows(temp);
        }
    }, [provenances, keycloak]);
    return (
        <>
            <div className={'origin-table-container'}>
                <div className={'origin-button-container'}>
                    <div>
                        {
                            !!keycloak.authenticated &&
                            <BeaButton
                                onClick={() => {
                                    dispatch(view());
                                    setModal(true);
                                }}
                                disabled={false}
                                variant={'primary'}
                                height="48px"
                                title={'create_new_provenance'} />
                        }
                    </div>
                </div>
                {
                    (provenances && rows)
                        ? (
                            rows.length > 0
                                ? (
                                    <GenericTable
                                        headers={tableHeaders}
                                        rows={rows}
                                        gridDefinition={'sp-pr-grid'}
                                        defaultSorting={{
                                            direction: SORT_ASC,
                                            tag: 'id_tag'
                                        }}
                                        onClickCallback={editMode}
                                    />
                                )
                                : (
                                    <EmptyList title={'No type magnitude found'} />
                                )
                        )
                        : (
                            <LoaderTranslated />
                        )
                }
                {
                    modal &&
                    <CustomModal
                        disable={disabled}
                        cancel={() => {
                            dispatch(view());
                            setElement({
                                name: ''
                            });
                            setModal(false);
                        }}
                        lockUi={true}
                        confirm={() => {
                            dispatch(showStoreModal({
                                title: t(confirmModalTitle(SETTINGS_PROVENANCE_TABLE, settings.mode)),
                                text: t(confirmModalText(SETTINGS_PROVENANCE_TABLE, settings.mode)),
                                show: true,
                                cancel: () => dispatch(closeStoreModal()),
                                confirm: updateProvenance
                            }));
                        }}
                        show={modal}
                        title={t(modalTitle(SETTINGS_PROVENANCE_TABLE, settings.mode))}
                        text={t(modalText(SETTINGS_PROVENANCE_TABLE, settings.mode))}
                        children={getForm()}
                    />
                }
            </div>
            {
                showNotification.show &&
                (showNotification.kind === 'success'
                    ? <Toast
                        className='settings-notification'
                        title={t(showNotification.reason)}
                        onClose={() => setShowNotification(defaultNotificationState)}
                    />
                    : <InlineNotification
                    className="settings-notification"
                    kind={showNotification.kind}
                    lowContrast
                    actionbuttonlabel="Action"
                    ariaLabel="closes notification"
                    onClose={() => setShowNotification(defaultNotificationState)}
                    onCloseButtonClick={function noRefCheck() { }}
                    statusIconDescription="notification"
                    subtitle={t(showNotification.reason)}
                    title={t(showNotification.title)}
                />)
            }
        </>
    )

    function id(link: string, o: ObjectTableProvenance): JSX.Element {
        return singleRow(link, o.id ? `${o.id}` : '--', o.id ? `${o.id}` : '--');
    }

    function name(link: string, o: ObjectTableProvenance): JSX.Element {
        return singleRow(link, o.name ? `${o.name}` : '--', o.name ? `${o.name}` : '--');
    }

    function description(link: string, o: ObjectTableProvenance): JSX.Element {
        return singleRow(link, o.description ? `${o.description}` : '--', o.description ? `${o.description}` : '--');
    }

    function priority(link: string, o: ObjectTableProvenance): JSX.Element {
        return singleRow(link, o.priority !== undefined ? `${o.priority}` : '--', o.priority ? `${o.priority}` : '--');
    }

    function softwarename(link: string, o: ObjectTableProvenance): JSX.Element {
        return singleRow(link, o.softwarename ? `${o.softwarename}` : '--', o.softwarename ? `${o.softwarename}` : '--');
    }

    function version(link: string, o: ObjectTableProvenance): JSX.Element {
        return singleRow(link, o.version !== undefined ? `${o.version}` : '--', o.version ? `${o.version}` : '--');
    }

    function model(link: string, o: ObjectTableProvenance): JSX.Element {
        return singleRow(link, o.model ? `${o.model}` : '--', o.model ? `${o.model}` : '--');
    }

    function method(link: string, o: ObjectTableProvenance): JSX.Element {
        return singleRow(link, o.method ? `${o.method}` : '--', o.method ? `${o.method}` : '--');
    }

    async function download() {
        try {
            const response: AxiosResponse<GetProvenance200Response> = await ApiClient.getApi().getProvenance();
            if (response && response.data && response.data.data) {
                setProvenances(response.data.data)
            }
        } catch (e) {
            setProvenances([]);
        }
    }

    function editMode(id: string) {
        if (id.length === 0) {
            return;
        }
        if (!provenances) {
            return;
        }

        const e: ObjectTableProvenance | undefined = provenances.find((et) => `${et.id}` === id);
        if (!e) {
            return;
        }

        setElement(e);
        dispatch(edit({
            mode: SETTINGS_EDIT_MODE,
            id: id,
            type: SETTINGS_PROVENANCE_TABLE
        }));
        setModal(true);
    }

    function getForm(): JSX.Element {
        return <SettingsProvenanceCreateForm
            element={element}
            onUpdateElement={setElement}
        />
    }

    async function updateProvenance(): Promise<void> {
        if (!element || !provenances || provenances?.length == 0) {
            return;
        }

        if (disabled) {
            return;
        }

        setDisabled(true);
        try {
            let response;
            if (settings.mode === SETTINGS_EDIT_MODE) {
                const { id } = element;
                const copyElement: ObjectTableProvenance | undefined = provenances.find((et) => `${et.id}` === `${id}`);
                if (!copyElement) {
                    return;
                }

                if (element.description === copyElement.description) {
                    delete element.description;
                }

                if (element.name === copyElement.name) {
                    delete element.name;
                }

                if (element.softwarename === copyElement.softwarename) {
                    delete element.softwarename;
                }

                if (element.version === copyElement.version) {
                    delete element.version;
                }

                if (element.model === copyElement.model) {
                    delete element.model;
                }

                if (element.method === copyElement.method) {
                    delete element.method;
                }
                response = await ApiClient.updateApi().updateProvenance(+settings.id, element);
            } else {
                response = await ApiClient.storeApi().addProvenance(element);
            }

            const { status } = response;
            if (status === 200 || status === 201) {
                if (settings.mode === SETTINGS_EDIT_MODE) {
                    const { data } = (response as AxiosResponse<ObjectTableProvenance>);
                    const index = provenances.findIndex((d) => d.id === data.id);
                    if (index !== -1) {
                        provenances[index] = data;
                        setProvenances([...provenances]);
                    }
                } else {
                    setProvenances([...provenances, response.data]);
                }

                onSuccessCallbackHandler(
                    setShowNotification, successTitle(SETTINGS_PROVENANCE_TABLE, settings.mode), addSuccessText(SETTINGS_PROVENANCE_TABLE, settings.mode)
                );
            } else {
                dispatch(view());
                onErrorCallbackHandler(`Error ${status}`, response.statusText, setShowNotification);
            }
        } catch (e) {
            let { response } = (e as AxiosError);
            onErrorCallbackHandler(
                response?.statusText ?? 'Timeout error',
                (response as AxiosResponse)?.data?.detail ?? 'Request was interrupted',
                setShowNotification
            );
        }
        setElement({
            name: ''
        });
        setModal(false);
        setDisabled(false);
        
        batch(() => {
            dispatch(closeStoreModal());
            dispatch(view());
        });
    }
}

export { ProvenanceTable };
