import React, { useEffect, useState } from 'react'
import { TextInput } from '@carbon/react';
import './CustomSelector.css'
import { ReactComponent as DownArrowIcon } from '../../assets/down-arrow.svg'
import { ReactComponent as DownArrowDisabledIcon } from '../../assets/down-arrow-disabled.svg'
import { ReactComponent as UpArrowIcon } from '../../assets/up-arrow.svg'
import { ReactComponent as UncheckedIcon } from '../../assets/unchecked.svg'
import { ReactComponent as CheckedIcon } from '../../assets/checked.svg'
import { useTranslation } from 'react-i18next'
import MultiSelectItem from '../MultiSelectItem/MultiSelectItem'

interface CustomSelectorProps {
    isMultiSelect?: boolean,
    isInputText?: boolean,
    disabled?: boolean,
    prefix: string,
    options: Option[]
    selected?: string,
    onChange: (selected: string) => void
    fixed?: boolean,
    debug?: boolean
    onAutocompleteSelect?: (id: string) => void,
}

export interface Option {
    key: string,
    value: string,
}

// if isMultiSelect:
//      prop `selected` has the following form "opt1,opt2,...,optn"
// else:
//      prop `selected` is just a string
const CustomSelector = ({ isMultiSelect, isInputText, prefix, options, selected, onChange, onAutocompleteSelect, fixed = false, disabled = false, debug = false }: CustomSelectorProps) => {

    const { t } = useTranslation()
    const container = `${prefix}-select-container`
    const content = `${prefix}-select-content`
    const [open, setOpen] = useState(false)
    const [query, setQuery] = useState<string>()
    const [insideContent, setInsideContent] = useState(false)
    const [insideContainer, setInsideContainer] = useState(false)
    const [parsedSelectedOptions, setParsedSelectedOptions] = useState<string[]>([]);

    useEffect(() => {
        console.log('selected =>', selected);
        updatePosition();
    }, [selected])

    const isOptionActive = (option: string) => {
        if (!option || option === '') return false;
        return parsedSelectedOptions.filter(pso => pso === option).length > 0
    }

    useEffect(() => {
        setParsedSelectedOptions(selected?.split(',') ?? [])
    }, [selected])

    useEffect(() => {
        if (debug) {
            console.log('CustomSelector => debug => isMultiSelect =>', isMultiSelect)
            console.log('CustomSelector => debug => prefix =>', prefix)
            console.log('CustomSelector => debug => options =>', options)
            console.log('CustomSelector => debug => selected =>', selected)
            console.log('CustomSelector => debug => fixed =>', fixed)
            console.log('CustomSelector => debug => disabled =>', disabled)
            console.log('CustomSelector => debug => debug =>', debug)
        }
    }, [isMultiSelect, prefix, options, selected, fixed, disabled, debug])

    const toggleSelector = () => !isInputText && !disabled && setOpen(!open)

    useEffect(() => {
        if (isInputText) {
            if (options.length > 0) {
                if (selected && selected.length > 0) {
                    setOpen(false)
                } else {
                    setOpen(true)
                }
            } else {
                setOpen(false)
            }
        }
    }, [isInputText, options, selected])

    useEffect(() => {
        if (debug) console.log('CustomSelector => insideContainer =>', insideContainer, '=> insideContent =>', insideContent)
        if (!disabled && open) {
            setOpen(insideContainer || insideContent);
        }
    }, [insideContent, insideContainer])

    const updatePosition = () => {
        let container_element = document.getElementById(container)
        let content_element = document.getElementById(content)
        if (open && container_element && content_element) {
            content_element.style.paddingTop = '4px'
            content_element.style.width = `${container_element.getBoundingClientRect().width - 1}px`
            if (fixed) {
                if (debug) console.log('useEffect => container_element => width => ', container_element.getBoundingClientRect().width)
                if (debug) console.log('useEffect => container_element => height => ', container_element.getBoundingClientRect().height)
                if (debug) console.log('useEffect => container_element => top => ', container_element.getBoundingClientRect().top)
                content_element.style.position = 'fixed'
                content_element.style.top = `${container_element.getBoundingClientRect().top + container_element.getBoundingClientRect().height}px`
            } else {
                content_element.style.position = 'absolute'
                content_element.style.top = `${container_element.getBoundingClientRect().height}px`
            }
        }
    }

    window.onresize = null
    window.onresize = function (e: UIEvent) {
        updatePosition();
    }

    const text = () => {

        if (isInputText) {
            return <TextInput
                labelText=''
                className="input"
                id="input-autocomplete-cities"
                hideLabel
                placeholder={t('municipality')}
                onBlur={(e: Event) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                value={(selected && selected.length > 0) as boolean ? selected : query}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setQuery(e.currentTarget.value)
                    onChange(e.currentTarget.value)
                }}
            />
        }

        if (!selected || selected === '' || options.length === 0 || parsedSelectedOptions.length === 0)
            return <span>{t('choose_a_value')}</span>

        if (isMultiSelect) {
            return options
                .filter(o => parsedSelectedOptions.filter(p => p === o.key).length > 0)
                .map(o => <MultiSelectItem
                    key={o.key}
                    option={o}
                    deleteOption={(key: string) => {
                        onChange(key);
                    }}
                />
                )
        }

        let filtered = options.filter(o => o.key === selected)
        if (filtered.length === 0)
            return <span>{t('choose_a_value')}</span>

        return <span>{filtered[0].value}</span>
    }

    return <div
        className='custom-selector dropdown'
        id={container}
        onClick={toggleSelector}
        tabIndex={100}
        onBlur={(e) => {
            setOpen(false);
            console.log('MunicipalityAutocomplete => on blur')
        }}
    >
        <div className={isInputText ? 'input-text' : 'cont'}>
            {text()}
            {
                !isInputText && (
                    open ? <UpArrowIcon className="arrow" /> : (
                        disabled ? <DownArrowDisabledIcon className="arrow" /> : <DownArrowIcon className="arrow" />
                    )
                )
            }
        </div>
        {open &&
            <div
                id={content}
            >
                <div className={fixed ? 'custom-selector-content' : 'custom-selector-content-absolute'}>
                    {
                        options.sort((o1, o2) => o1.key.localeCompare(o2.key)).map(o =>
                            <div
                                key={o.key}
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    if (isInputText) {
                                        if (onAutocompleteSelect) { onAutocompleteSelect(o.key); }
                                        setOpen(false);
                                        setQuery('');
                                        return;
                                    }
                                    onChange(o.key)
                                    if (!isMultiSelect) {
                                        setOpen(false);
                                    }
                                }}
                                className={
                                    `${isOptionActive(o.key) ? 'active' : ''}
                                            ${isMultiSelect ? 'multi-select-item' : ''}
                                             ellipse`
                                }
                            >
                                {
                                    isMultiSelect
                                        ? (
                                            isOptionActive(o.key)
                                                ? <CheckedIcon key={`${o.key}-check`} className='checkbox' />
                                                : <UncheckedIcon key={`${o.key}-uncheck`} className='checkbox' />
                                        )
                                        : ''
                                }
                                {
                                    o.value
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        }
    </div>
}

export default CustomSelector
