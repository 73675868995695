import {useKeycloak} from "@react-keycloak/web";
import {NotAuthorized} from "../NotAuthorized/NotAuthorized";

interface PrivateComponentProps {
    child: React.ReactElement
}
const PrivateComponent = function({child}: PrivateComponentProps) {
    const {keycloak} = useKeycloak()

    if(!keycloak.authenticated){
        return(<>
            <NotAuthorized />
        </>)
    }

    return child
}

export {
    PrivateComponent
}