import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomModalProps } from '../../components/CustomModal/CustomModal';

const defaultStationsState: CustomModalProps = {
    show: false,
    cancel: () => {}
};

export const confirmationModal = createSlice({
    name: 'confirmationModal',
    initialState: defaultStationsState,
    reducers: {
        showStoreModal: (state, storeModal: PayloadAction<CustomModalProps>) => {
            return storeModal.payload;
        },
        closeStoreModal: () => {
            return defaultStationsState;
        }
    }
});

export const { showStoreModal, closeStoreModal } = confirmationModal.actions;
export default confirmationModal.reducer;