import {
    InlineNotification
} from '@carbon/react';
import { useKeycloak } from '@react-keycloak/web';
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as StarIcon } from '../../assets/preferred-star.svg';
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks";
import { closeStoreModal, showStoreModal } from "../../redux/reducers/confirmationModal.slice";
import { ApiClient } from "../../services/ApiClient";
import { ObjectEvent, ObjectMagnitude, ObjectOrigin, UpdateEventRequest } from "../../services/network";
import { BeaInlineNotification, defaultNotificationState, onErrorCallbackHandler, onSuccessCallbackHandler } from '../../utils/InlineNotifications';
import { BeaButton } from "../BeaButton/BeaButton";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import EmptyList from "../EmptyList/EmptyList";
import { LoaderTranslated } from "../Loader/LoaderTranslated";
import MagnitudeModal, { MagnitudeModalProps } from "../MagnitudeModal/MagnitudeModal";
import { Toast } from '../Toast/Toast';
import { GenericTable, IHeader, ProvenanceCell, Row, SORT_ASC, SortDirection, localspace, singleRow, twoRows } from "./GenericTable";
import './GenericTable.css';
import { Cta } from "./components/Cta";
import { idAsc, idDesc, magAsc, magDesc, mindistAsc, mindistDesc, modifiedDesc, qtyAsc, qtyDesc } from "./sortutils";

interface MagnitudeTableProps {
    updateCallback: () => void
    originId?: string;
}

function MagnitudeTable({ updateCallback, originId }: MagnitudeTableProps) {
    const { t } = useTranslation();
    const earthquake = useAppSelector((state) => state.earthquake);
    const navigate = useNavigate();
    const [magnitudes, setMagnitudes] = useState<ObjectMagnitude[] | undefined>(undefined);
    const [rows, setRows] = useState<Row[] | undefined>(undefined);
    const [showNotification, setShowNotification] = useState<BeaInlineNotification>(defaultNotificationState);
    const [showModal, setShowModal] = useState<MagnitudeModalProps>({
        show: false,
        onClose: () => undefined,
        magnitude: undefined,
        mode: '',
        earthquake: undefined,
        magnitudes: []
    });
    const [selectedRow, setSelectedRow] = useState('');
    const { keycloak } = useKeycloak();
    const [isCatalog, setCatalog] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    function sortById(direction: SortDirection): void {
        sortMagnitudes(direction === SORT_ASC ? idAsc : idDesc);
    }

    function sortByMagnitude(direction: SortDirection): void {
        sortMagnitudes(direction === SORT_ASC ? magAsc : magDesc);
    }

    function sortByDist(direction: SortDirection): void {
        sortMagnitudes(direction === SORT_ASC ? mindistAsc : mindistDesc);
    }

    function sortByQty(direction: SortDirection): void {
        sortMagnitudes(direction === SORT_ASC ? qtyAsc : qtyDesc);
    }

    function sortMagnitudes(sortingProcedure: (m1: ObjectMagnitude, m2: ObjectMagnitude) => number): void {
        if (!magnitudes) {
            return;
        }
        const copyMagnitudes: ObjectMagnitude[] = [...magnitudes];
        copyMagnitudes.sort(sortingProcedure);
        setMagnitudes(copyMagnitudes);
    }

    function isPreferredMagnitude(e: ObjectEvent, m: ObjectMagnitude): boolean {
        const prefMagnitudeId: number = e?.preferred_magnitude_id ?? -1;
        const magnitudeId: number = m?.id ?? -2;
        return prefMagnitudeId === magnitudeId;
    }

    const tableHeaders: IHeader[] = [
        { tag: 'id_tag', label: 'ot__id__label', sortable: false, sortFunction: sortById },
        { tag: 'mag_tag', label: 'ot__mag__label', sortable: false, sortFunction: sortByMagnitude },
        { tag: 'qty_tag', label: 'ot__qty__label', sortable: false, sortFunction: sortByQty },
        { tag: 'nsta_tag', label: 'ot__nsta__label', sortable: false },
        { tag: 'min_dist_tag', label: 'ot__min_dist__label', sortable: false, sortFunction: sortByDist },
        { tag: 'id_origin_tag', label: 'ot__id_origin__label', sortable: false },
        { tag: 'localspace_tag', label: 'ot__localspace__label', sortable: false },
        { tag: 'provenance_tag', label: 'ot__provenance__label', sortable: false },
        { tag: 'cta_tag', label: 'ot__cta__label', sortable: false },
    ];

    useEffect(() => {
        let origins: ObjectOrigin[] = earthquake && earthquake.origins ? [...earthquake.origins] : [];
        if (originId) {
            origins = origins.filter((o) => `${o.id}` === originId);
        }
        let magnitudes: ObjectMagnitude[] = [];
        for (const o of origins) {
            if (o.magnitudes) {
                magnitudes = [...magnitudes, ...o.magnitudes];
            }
        }

        magnitudes.sort(modifiedDesc)
        setMagnitudes(magnitudes);
    }, [earthquake]);

    useEffect(() => {
        if (earthquake) {
            if (earthquake.localspace) {
                if (earthquake.localspace.environment) {
                    setCatalog(earthquake.localspace.environment === 'catalog');
                }
            }
        }
    }, [earthquake]);

    useEffect(() => {
        if (magnitudes) {
            const temp: Row[] = magnitudes.map((m, index) => {
                const link: string = '#';
                return [
                    {
                        tag: 'id_tag',
                        id: `${m.id}`,
                        element: singleRow(link, `${m.id ?? '--'}`, `${m.id ?? '--'}`)
                    },
                    {
                        tag: 'mag_tag',
                        id: `${m.id}`,
                        element:
                            <CustomTooltip placement="top" title={`${m.id === earthquake.preferred_magnitude_id ? '✩' : ''}${m.type_magnitude} ${m.mag}`} arrow>
                                <Link to={link}>
                                    {magnitude(m, earthquake.preferred_magnitude_id)}
                                </Link>
                            </CustomTooltip>
                    },
                    {
                        tag: 'qty_tag',
                        id: `${m.id}`,
                        element: twoRows(
                            link,
                            m.mag_quality ?? '--',
                            `${m.quality ?? '--'}`,
                            m.mag_quality
                                ? `${m.mag_quality} (${m.quality ?? '--'})`
                                : ''
                        )
                    },
                    {
                        tag: 'nsta_tag',
                        id: `${m.id}`,
                        element: nsta(link, m)
                    },
                    {
                        tag: 'min_dist_tag',
                        id: `${m.id}`,
                        element: mindist(link, m.min_distance)
                    },
                    {
                        tag: 'id_origin_tag',
                        id: `${m.id}`,
                        element: <CustomTooltip placement="top" arrow title={getIdOrigin(m.id)}>
                            <Link to={`/earthquake/${earthquake.id}/magnitude/${getIdOrigin(m.id)}`}>
                                <span className="link">{getIdOrigin(m.id)}</span>
                            </Link>
                        </CustomTooltip>
                    },
                    {
                        tag: 'localspace_tag',
                        id: `${m.id}`,
                        element: localspace(link, m.localspace)
                    },
                    {
                        tag: 'provenance_tag',
                        id: `${m.id}`,
                        element: <ProvenanceCell link={link} p={m.provenance} reverse={reverse(index, m.provenance ? Object.entries(m.provenance).length : 0)} />
                    },
                    {
                        tag: 'cta_tag',
                        id: `${m.id}`,
                        element:
                            isPreferredMagnitude(earthquake, m)
                                ? <></>
                                : <Cta
                                    classWidth='ot-magnitude'
                                    key={`${m.id}-cta`}
                                    id={`${m.id}`}
                                    reverse={reverse(index, isPreferredMagnitude(earthquake, m) ? 1 : 2)}
                                    label={'cta__magnitude__title'}
                                    menu={[
                                        { tag: '', label: 'cta__magnitude__preferred', callback: () => updateEventConfirmation(m.id) }
                                    ]}
                                />
                    }
                ];
            });
            setRows(temp);
        }
    }, [magnitudes]);

    function reverse(index: number, options: number): boolean {
        if (!magnitudes || magnitudes.length === 0) {
            return false;
        }

        const ot: HTMLElement | null = document.getElementById('origin-table');
        if (!ot) {
            return false;
        }

        const rowHeight: number = 68;
        const optionHeight: number = 36;
        const baseDropdownHeight: number = 50;
        const dropdownHeight = baseDropdownHeight + (options * optionHeight);

        if (index <= (magnitudes.length - Math.ceil(dropdownHeight / rowHeight) - 1)) {
            return false;
        }

        if (ot.scrollHeight === ot.clientHeight) {
            return false;
        }

        return ot.scrollHeight > (ot.clientHeight - dropdownHeight);
    }

    function gridDefinition(): string {
        if (!!keycloak.authenticated && !isCatalog) {
            return originId ? 'od-mt-grid' : 'ed-mt-grid'
        }

        return originId ? 'od-mt-grid' : 'ed-mt-pub-grid'
    }

    return (
        <>
            <div className={'origin-table-container'}>
                <div className={'origin-button-container'}>
                    <div className={'preferred-label-container'}>
                        <StarIcon />
                        <span className={'label'}>{t('preferred_value')}</span>
                    </div>
                    <div>
                        {
                            originId && !!keycloak.authenticated &&
                            <BeaButton
                                onClick={() => earthquake && navigate(`/create/magnitude/${earthquake.id}/${originId}`)}
                                disabled={false}
                                variant={'primary'}
                                height="48px"
                                title={'create_new_magnitude'} />
                        }
                    </div>
                </div>
                {
                    (earthquake && magnitudes && rows)
                        ? (
                            rows.length > 0
                                ? (
                                    <GenericTable
                                        headers={headers()}
                                        rows={rows}
                                        gridDefinition={gridDefinition()}
                                        defaultSorting={{
                                            tag: 'id_tag',
                                            direction: SORT_ASC
                                        }}
                                        selectedRow={selectedRow}
                                        onClickCallback={(id: string) => {
                                            setSelectedRow(id);
                                            setShowModal({
                                                show: true,
                                                onClose: closeModal,
                                                mode: 'ML',
                                                magnitude: magnitudes.filter((m) => `${m.id}` === id)[0],
                                                magnitudes
                                            });
                                        }}
                                    />
                                )
                                : (
                                    <EmptyList title={'No magnitude found'} />
                                )
                        )
                        : (
                            <LoaderTranslated />
                        )
                }
            </div>

            {showNotification.show &&
                (showNotification.kind === 'success'
                    ? <Toast
                        className='notification-hover'
                        title={t(showNotification.reason)}
                        onClose={() => setShowNotification(defaultNotificationState)}
                    />
                    : <InlineNotification
                        className='notification-hover'
                        kind={showNotification.kind}
                        lowContrast
                        actionbuttonlabel='Action'
                        ariaLabel='closes notification'
                        onClose={() => setShowNotification(defaultNotificationState)}
                        onCloseButtonClick={function noRefCheck() { }}
                        statusIconDescription='notification'
                        subtitle={t(showNotification.reason)}
                        title={showNotification.title}
                    />)}
            {showModal.show && <MagnitudeModal
                show={showModal.show}
                mode={showModal.mode}
                onClose={closeModal}
                earthquake={earthquake}
                magnitude={showModal.magnitude}
                magnitudes={magnitudes ?? []}
                prev={onPrev}
                next={onNext}
            />}
        </>
    )

    function headers(): IHeader[] {
        let heads: IHeader[] = tableHeaders;
        if (originId) {
            heads = heads.filter((h) => h.tag !== 'id_origin_tag')
        }

        if (keycloak) {
            if (keycloak.authenticated) {
                if (!isCatalog) {
                    return heads;
                }
            }
        }
        return heads.filter((h) => h.tag !== 'cta_tag');
    }

    function onPrev(): void {
        if (!magnitudes || !showModal.magnitude || !showModal.show) {
            return;
        }

        const index: number = magnitudes.findIndex((m) => m.id === showModal?.magnitude?.id);
        if (index <= 0) {
            return;
        }
        setSelectedRow(`${magnitudes[index - 1].id}`);
        setShowModal({
            ...showModal,
            magnitude: magnitudes[index - 1]
        });
    }

    function onNext(): void {
        if (!magnitudes || !showModal.magnitude || !showModal.show) {
            return;
        }

        const index: number = magnitudes.findIndex((m) => m.id === showModal?.magnitude?.id);
        if (index >= (magnitudes.length - 1)) {
            return;
        }
        setSelectedRow(`${magnitudes[index + 1].id}`);
        setShowModal({
            ...showModal,
            magnitude: magnitudes[index + 1]
        })
    }

    function closeModal(): void {
        setSelectedRow('');
        setShowModal({
            show: false,
            onClose: () => undefined,
            magnitude: undefined,
            mode: '',
            earthquake: undefined,
            magnitudes: []
        });
    }

    function magnitude(magnitude: ObjectMagnitude, preferredMagnitude?: number | null | undefined): JSX.Element {
        if (!magnitude) {
            return <><span>--</span></>
        }

        const { id, upper_uncertainty, lower_uncertainty } = magnitude;
        const hasPreferred: boolean = !(Number.isNaN(id) || Number.isNaN(preferredMagnitude)) && id === preferredMagnitude;
        const hasSymmetricUncertainty: boolean = !(lower_uncertainty === null || lower_uncertainty === undefined) && (upper_uncertainty === null || upper_uncertainty === undefined);
        const hasAsymmetricUncertainty: boolean = !(lower_uncertainty === null || lower_uncertainty === undefined) && !(upper_uncertainty === null || upper_uncertainty === undefined);

        return <div className={'magnitude'}>
            <div>
                {hasPreferred && <StarIcon />}
                <span>{`${magnitude.type_magnitude} ${magnitude.mag.toFixed(2)}`}</span>
            </div>
            <div className={'subtitle'}>
                {
                    hasSymmetricUncertainty && <span>-/+ [{lower_uncertainty?.toFixed(2) ?? '--'}, {lower_uncertainty?.toFixed(2) ?? '--'}]</span>
                }
                {
                    hasAsymmetricUncertainty && <span>-/+ [{lower_uncertainty?.toFixed(2) ?? '--'}, {upper_uncertainty?.toFixed(2) ?? '--'}]</span>
                }
            </div>
        </div>
    }

    function getIdOrigin(magnitudeId?: number): string {
        if (earthquake) {
            if (earthquake.origins && earthquake.origins.length > 0) {
                for (const o of earthquake.origins) {
                    if (o.magnitudes) {
                        if (o.magnitudes.filter((m) => m.id === magnitudeId).length > 0) {
                            return `${o.id}`;
                        }
                    }
                }
            }
        }

        return '--';
    }

    function updateEventConfirmation(magnitudeId?: number) {
        if (magnitudeId === null || magnitudeId === undefined) {
            return;
        }
        dispatch(showStoreModal({
            show: true,
            cancel: close,
            confirm: () => updateEvent(magnitudeId),
            title: 'ot__make_preferred__confirmation',
            text: 'ot__make_preferred__confirmation__text'
        }))
    }

    function close(): void {
        dispatch(closeStoreModal());
    }

    async function updateEvent(magnitudeId?: number): Promise<void> {
        if (!magnitudeId) {
            dispatch(closeStoreModal());
            return;
        }
        if (!earthquake || !earthquake.id) {
            dispatch(closeStoreModal());
            return;
        }

        let request: UpdateEventRequest = {
            data: {
                event: {
                    preferred_magnitude_id: magnitudeId
                }
            }
        }

        try {
            let response = await ApiClient.updateApi().updateEvent(earthquake.id, request);
            if (response.status === 200) {
                onSuccessCallbackHandler(
                    setShowNotification,
                    t('cta__origin_table__preferred_success_title'),
                    t('cta__magnitude_table__preferred_success', { magnitude: `${magnitudeId}`, event: `${earthquake.id}` })
                );

                updateCallback();
            } else {
                const title: string = `${t('cta__magnitude_table__preferred_error_title')} (${response.status})`;
                const m: string = t('cta__magnitude_table__preferred_error', { magnitude: `${magnitudeId}`, error: (response as AxiosResponse)?.data?.detail ?? 'Unknown error' });
                onErrorCallbackHandler(title, m, setShowNotification);
            }
        } catch (e) {
            let { response } = (e as AxiosError);
            const title: string = `${t('cta__magnitude_table__preferred_error_title')} (${response?.status ?? 500})`;
            const m: string = t('cta__magnitude_table__preferred_error', { magnitude: `${magnitudeId}`, error: (response as AxiosResponse)?.data?.detail ?? 'Unknown error' });
            onErrorCallbackHandler(title, m, setShowNotification);
        }
        dispatch(closeStoreModal());
    }

    function nsta(link: string, m: ObjectMagnitude): JSX.Element {
        let row: string = `--`;

        const { nsta } = m;

        if (nsta) {
            row = `${nsta}`
        }

        return singleRow(link, row, row);
    }

    function mindist(link: string, mindist?: number): JSX.Element {
        let row: string = `--`;
        let tooltip: string = '';
        if (mindist !== undefined) {
            row = `${mindist ? `${mindist.toFixed(2)} Km` : '--'}`
            tooltip = `${mindist} Km`;
        }
        return singleRow(link, row, tooltip);
    }
}

export { MagnitudeTable };
