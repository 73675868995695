import {
    Breadcrumb, BreadcrumbItem,
    Button,
    IconButton, InlineNotification
} from '@carbon/react';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxCustomHooks';
import { closeCta } from '../../redux/reducers/cta.slice';
import { close, open } from '../../redux/reducers/filtersmodal.slice';
import { VIEW_MODE } from '../../redux/reducers/group.slice';
import { BeaInlineNotification, defaultNotificationState, onErrorCallbackHandler, onSuccessCallbackHandler } from '../../utils/InlineNotifications';
import { createUrlFromFilters } from '../../utils/urlFromFilters';
import BeaDropdown from '../BeaDropdown/BeaDropdown';
import { DropdownDownload } from '../DropdownDownload/DropdownDownload';
import '../DropdownFilters/DropdownFilters.css';
import LayoutSelector from '../LayoutSelector/LayoutSelector';
import { RefreshButton } from '../RefreshButton/RefreshButton';
import NewTimezoneSelector from '../TimezoneSelector/NewTimezoneSelector';
import './Breadcrumb.css';
/* icons */
import { Dropdown } from '@carbon/react';
import { AxiosError, AxiosResponse } from 'axios';
import ReactModal from 'react-modal';
import { batch } from 'react-redux';
import { ReactComponent as IconClose } from '../../assets/cancel.svg';
import { ReactComponent as IconDownload } from '../../assets/download.svg';
import { ReactComponent as IconEdit } from '../../assets/edit-white.svg';
import { ReactComponent as IconBack } from '../../assets/left-arrow.svg';
import { ReactComponent as IconPreferredStar } from '../../assets/preferred-star-big.svg';
import { ReactComponent as IconProvenance } from '../../assets/provenance-white.svg';
import { ReactComponent as IconSort } from '../../assets/sort.svg';
import { ReactComponent as IconStar } from '../../assets/star-white.svg';
import { closeStoreModal, showStoreModal } from '../../redux/reducers/confirmationModal.slice';
import { removeCustomTableGroup } from '../../redux/reducers/customtable.slice';
import { fetchEarthquakeEvent, resetEvent } from '../../redux/reducers/earthquake.slice';
import { ApiClient } from '../../services/ApiClient';
import { GetTypeEvent200Response, ObjectOrigin, ObjectProvenance, ObjectTableTypeEvent, UpdateEventRequest, UpdateEventsGroupRequest } from '../../services/network';
import { RequiredError } from '../../services/network/base';
import { buildError } from '../../utils';
import { DropdownProvenance } from '../EventDetailTable/components/DropdownProvenance';
import { Toast } from '../Toast/Toast';

type Crumbs = {
    href: string,
    title: string,
    origin?: number | string
}

type PageId = 'earthquakeList' | 'earthquakeDetail' | 'originDetail' | 'createEvent' | 'createOrigin' | 'createMagnitude' | 'settings' | 'error' | 'info'

type BreadcrumbProps = {
    crumbs: Array<Crumbs>,
    pageId: PageId,
    origin?: number | string
}

const BreadCrumb = ({ crumbs, pageId, origin }: BreadcrumbProps) => {

    const { keycloak } = useKeycloak();
    const dispatch = useAppDispatch();
    const group = useAppSelector((state) => state.group);
    const table = useAppSelector((state) => state.table);
    const filters = useAppSelector((state) => state.filters);
    const earthquake = useAppSelector((state) => state.earthquake);
    const modalSpec = useAppSelector((state) => state.confirmationModal);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [networkRequest, setNetworkRequest] = useState<boolean>(false);
    const [updateEventModal, setUpdateEventModal] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState<BeaInlineNotification>(defaultNotificationState)
    const [eventTypes, setEventTypes] = useState<ObjectTableTypeEvent[]>([]);
    const [eventTypeOptions, setEventTypOptions] = useState<{ key: string; value: string }[]>([]);
    const [selectedEventType, setSelectEventType] = useState<string>('');
    const [isCatalog, setCatalog] = useState<boolean>(false);

    const closeAll = (): void => {
        batch(() => {
            dispatch(close());
            dispatch(closeStoreModal());
        });
    }

    const onError = function (reason: string) {
        onErrorCallbackHandler(t('dropdown__download__failed'), t(reason), setShowNotification)
    }

    const handleFilterButton = () => {
        dispatch(open());
    }

    const backArrowDisplay = () => {
        if (crumbs.length === 0) return false
        if (crumbs.length > 1) return true
        return pageId === 'settings' || pageId === 'info'
    }

    const downloadDisplay = function (): boolean {
        return pageId === 'earthquakeList' || pageId === 'earthquakeDetail' || pageId === 'originDetail'
    }

    const getHref = function (): string {
        if (crumbs.length === 0) return ''
        else if (crumbs.length === 1) return `/earthquakelist?${createUrlFromFilters(filters)}`
        else return crumbs[crumbs.length - 2].href
    }

    useEffect(() => {
        setSelectEventType(earthquake.type_event);
    }, [earthquake]);

    useEffect(() => {
        if (earthquake) {
            if (earthquake.localspace) {
                if (earthquake.localspace.environment) {
                    setCatalog(earthquake.localspace.environment === 'catalog');
                }
            }
        }
    }, [earthquake]);

    useEffect(() => {
        ApiClient.getApi().getTypeEvent()
            .then((response) => {
                const danteResponse: GetTypeEvent200Response = response.data
                const { data } = danteResponse;
                if (data) {
                    setEventTypes(data);
                } else {
                    setEventTypes([]);
                }
            })
    }, []);

    useEffect(() => setEventTypOptions(
        eventTypes.map((et) => ({
            key: et?.name ?? '',
            value: et?.name ?? ''
        }))
            .filter((et) => et.key !== '')
    ), [eventTypes]);


    function isPreferredOrOwner(): boolean {
        if (pageId === 'earthquakeDetail') {
            if (earthquake) {
                return earthquake.id === earthquake.event_group_id || earthquake.event_group_id === 0;
            }
        }

        if (pageId === 'originDetail') {
            if (earthquake) {
                if (earthquake.preferred_origin_id) {
                    if (origin) {
                        return `${origin}` === `${earthquake.preferred_origin_id}`
                    }
                }
            }
        }

        return false;
    }

    function makePreferredConfirmation(): void {
        console.log('makePreferredConfirmation')
        dispatch(showStoreModal({
            show: true,
            cancel: closeAll,
            confirm: makePreferred,
            title: t('bc__make_preferred__confirmation'),
            text: t('bc__make_preferred__confirmation__text')
        }));
    }

    function makeOwnerConfirmation(): void {
        dispatch(showStoreModal({
            show: true,
            cancel: closeAll,
            confirm: makeOwner,
            title: t('bc__make_owner__confirmation'),
            text: t('bc__make_owner__confirmation__text')
        }));
    }

    async function makePreferred(): Promise<void> {
        if (isCatalog) {
            dispatch(closeStoreModal());
            return;
        }
        if (!!!keycloak.authenticated) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__not_authorized'), setShowNotification);
            return;
        }
        if (!origin) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__no_origin'), setShowNotification);
            return;
        }
        if (!earthquake || !earthquake.id) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__no_earthquake'), setShowNotification);
            return;
        }

        if (networkRequest) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_preferred__failed'), t('bc__operation_in_progress_already'), setShowNotification);
            return;
        }

        setNetworkRequest(true);

        let request: UpdateEventRequest = {
            data: {
                event: {
                    preferred_origin_id: +origin
                }
            }
        }

        try {
            let response = await ApiClient.updateApi().updateEvent(+earthquake?.id, request);
            if (response.status === 200) {
                onSuccessCallbackHandler(
                    setShowNotification,
                    t('bc__make_preferred__success'),
                    t('bc__make_preferred__success_text_origin', { id: `${earthquake.id}`, origin })
                );

                resetItemTable();
                getData();
            } else {
                onErrorCallbackHandler(t('bc__make_preferred__failed'), 'Unknown Error ' + response.status, setShowNotification);
            }
        } catch (e) {
            let { response } = (e as AxiosError)
            onErrorCallbackHandler(t('bc__make_preferred__failed'), (response as AxiosResponse)?.data?.detail ?? 'Unknown error', setShowNotification);
        }
        setNetworkRequest(false);
        dispatch(closeStoreModal());
    }

    async function makeOwner(): Promise<void> {
        if (isCatalog) {
            dispatch(closeStoreModal());
            return;
        }
        if (networkRequest) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_owner__failed'), t('bc__operation_in_progress_already'), setShowNotification);
            return;
        }
        if (!!!keycloak.authenticated) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__make_owner__failed'), t('bc__not_authorized'), setShowNotification);
            return;
        }

        setNetworkRequest(true);

        const localGroup = table.find((t) => t.parent === `${earthquake.event_group_id}`);
        if (!localGroup) {
            onErrorCallbackHandler(t('bc__make_owner__failed'), t('cta_ownerize_event_notfound_error_text'), setShowNotification);
            return;
        }

        const request: UpdateEventsGroupRequest = {
            data: {
                event_group_id: earthquake.id, // il nuovo owner
                eventids: localGroup.children.map(gc => Number(gc.id)), // id elementi gruppo
                event_group_ids: [] // resta così
            }
        }

        try {
            const response = await ApiClient.updateApi().updateEventsGroup(request);
            if (response.status === 200) {
                onSuccessCallbackHandler(
                    setShowNotification,
                    t('bc__make_owner__success'),
                    t('bc__make_owner__success_text', { id: `${earthquake.id}` })
                );

                resetItemTable();
                getData();
            } else {
                onErrorCallbackHandler(t('bc__make_owner__failed'), response.statusText, setShowNotification);
            }
        } catch (e) {
            let code = 500;
            let error = buildError(
                e as AxiosError<RequiredError>,
                (httpCode: number) => code = httpCode
            )
            error = error.replaceAll('<br />', ', ');
            onErrorCallbackHandler(t('bc__make_owner__failed'), error, setShowNotification);
        }
        setNetworkRequest(false);
        dispatch(closeStoreModal());
    }

    function resetItemTable(): void {
        const { event_group_id } = earthquake;
        if (event_group_id === undefined || event_group_id === null || event_group_id === 0) {
            dispatch(removeCustomTableGroup(`${earthquake.id}`));
        } else {
            dispatch(removeCustomTableGroup(`${event_group_id}`));
        }
    }

    function getProvenanceDropdown(): JSX.Element | undefined {
        let provenance: ObjectProvenance | undefined;
        if (pageId === 'earthquakeDetail') {
            if (earthquake) {
                provenance = earthquake.provenance;
            }
        } else if (pageId === 'originDetail') {
            if (earthquake) {
                if (earthquake.origins && origin) {
                    const origins: ObjectOrigin[] = earthquake.origins.filter((o) => `${o.id}` === `${origin}`);
                    if (origins.length > 0) {
                        provenance = origins[0].provenance;
                    }
                }
            }
        }

        return (provenance
            ? <DropdownProvenance provenance={provenance} />
            : undefined
        );
    }

    function updateTypeEventConfirmation(): void {
        dispatch(showStoreModal({
            show: true,
            cancel: closeAll,
            confirm: updateTypeEvent,
            title: t('bc__edit_event__confirmation'),
            text: t('bc__edit_event__confirmation__text')
        }));
    }

    async function updateTypeEvent(): Promise<void> {
        if (isCatalog) {
            dispatch(closeStoreModal());
            return;
        }
        if (networkRequest) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__edit_event__failed'), t('bc__operation_in_progress_already'), setShowNotification);
            return;
        }
        if (!!!keycloak.authenticated) {
            dispatch(closeStoreModal());
            onErrorCallbackHandler(t('bc__edit_event__failed'), t('bc__not_authorized'), setShowNotification);
            return;
        }
        setUpdateEventModal(false);
        setNetworkRequest(true);

        if (selectedEventType === earthquake.type_event) {
            return;
        }
        if (!earthquake || !earthquake.id) {
            return;
        }

        const request: UpdateEventRequest = {
            data: {
                event: {
                    type_event: selectedEventType
                }
            }
        }

        try {
            const response = await ApiClient.updateApi().updateEvent(earthquake.id, request);
            if (response.status === 200) {
                onSuccessCallbackHandler(
                    setShowNotification,
                    t('bc__edit_event__success'),
                    t('bc__edit_event__success_text', { id: `${earthquake.id}`, type: selectedEventType })
                );

                resetItemTable();
                getData();
            } else {
                onErrorCallbackHandler(t('bc__edit_event__failed'), response.statusText, setShowNotification);
            }
        } catch (e) {
            let code = 500;
            let error = buildError(
                e as AxiosError<RequiredError>,
                (httpCode: number) => code = httpCode
            )
            error = error.replaceAll('<br />', ', ');
            onErrorCallbackHandler(t('bc__edit_event__failed'), error, setShowNotification);
        }
        setNetworkRequest(false);
        dispatch(closeStoreModal());
    }

    return (
        <>
            {
                pageId === 'earthquakeList'
                    ? <div className={`${pageId === 'earthquakeList' ? 'breadcrumb-container-eqlist' : 'breadcrumb-container'}`}>
                        {backArrowDisplay() &&
                            <IconButton className='bea-btn-transparent' enterDelayMs={100000000} label={t('back')} onClick={() => {
                                navigate(getHref())
                            }}>
                                <IconBack />
                            </IconButton>
                        }
                        <div className='breadcrumb-container-internal'>
                            <Breadcrumb noTrailingSlash>
                                {
                                    crumbs.map((c, index) => {
                                        return <BreadcrumbItem
                                            onClick={() => dispatch(closeCta())}
                                            className='breadcrumb-item-simple'
                                            key={index}
                                            isCurrentPage={index === crumbs.length - 1}>
                                            <Link to={c.href} replace={true}>{c.title}</Link>
                                        </BreadcrumbItem>
                                    })
                                }
                            </Breadcrumb>
                        </div>

                        {pageId === 'earthquakeList' && <LayoutSelector />}
                        {pageId === 'earthquakeList' && <div className={'bc-vertical-divider'}></div>}
                        {pageId === 'earthquakeList' && <NewTimezoneSelector />}
                        {pageId === 'earthquakeList' && <div className={'bc-vertical-divider'}></div>}

                        <div
                            className={'bc-rapid-buttons-container'}
                        >
                            {
                                pageId === 'earthquakeList' &&
                                <BeaDropdown
                                    text={'sort'}
                                    icon={<IconSort />}
                                    callback={handleFilterButton}
                                />
                            }

                            {pageId === 'earthquakeList' && <RefreshButton />}

                            {
                                downloadDisplay() &&
                                <BeaDropdown
                                    text={'download'}
                                    icon={<IconDownload />}
                                    children={
                                        <DropdownDownload
                                            onErrorCallback={onError}
                                            closeDropdown={closeAll}
                                            singleEvent={pageId !== 'earthquakeList'}
                                            origin={origin}
                                        />
                                    }
                                />
                            }
                        </div>
                        {
                            (keycloak && keycloak.authenticated && group.mode === VIEW_MODE && pageId === 'earthquakeList') &&
                            <div className={'bc-vertical-divider'}></div>
                        }
                        {
                            (keycloak && keycloak.authenticated && group.mode === VIEW_MODE && pageId === 'earthquakeList') &&
                            <div
                                className={'breadcrumb-button'}
                                onClick={() => {
                                    dispatch(closeCta())
                                    navigate('/create/event', { replace: false })
                                }}
                            >
                                <div className={'breadcrumb-button-label-container'}>
                                    <span className={'breadcrumb-button-label'}>
                                        {t('page__list_earthquakes__new_event')}
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div className={'breadcrumb-container'}>
                        <div className='breadcrumb-row'>
                            <div className='frame-1095'>
                                {backArrowDisplay() &&
                                    <IconButton className='bea-btn-transparent' enterDelayMs={100000000} label={t('back')} onClick={() => {
                                        navigate(getHref())
                                    }}>
                                        <IconBack />
                                    </IconButton>
                                }
                                <div className='breadcrumb-container-internal'>
                                    <Breadcrumb noTrailingSlash>
                                        {
                                            crumbs.map((c, index) => {
                                                return <BreadcrumbItem
                                                    onClick={() => dispatch(closeCta())}
                                                    className='breadcrumb-item-simple'
                                                    key={index}
                                                    isCurrentPage={index === crumbs.length - 1}>
                                                    <Link to={c.href} replace={true}>{c.title}</Link>
                                                </BreadcrumbItem>
                                            })
                                        }
                                    </Breadcrumb>
                                </div>
                            </div>
                            <div className='frame-1109'>
                                {/* dettaglio evento */}
                                {
                                    (pageId === 'earthquakeDetail' || pageId === 'originDetail') && <BeaDropdown
                                        text={'bc__show_provenance'}
                                        icon={<IconProvenance />}
                                        children={getProvenanceDropdown()}
                                    />
                                }
                                {
                                    ((!!keycloak.authenticated && !isCatalog) && pageId === 'earthquakeDetail') && <BeaDropdown
                                        text={'bc__edit_earthquake'}
                                        icon={<IconEdit />}
                                        callback={() => setUpdateEventModal(!updateEventModal)}
                                    />
                                }
                                {
                                    downloadDisplay() &&
                                    <BeaDropdown
                                        text={'bc__download'}
                                        icon={<IconDownload />}
                                        children={
                                            <DropdownDownload
                                                onErrorCallback={onError}
                                                closeDropdown={closeAll}
                                                singleEvent={true}
                                                origin={origin}
                                            />
                                        }
                                    />
                                }
                                {
                                    (pageId === 'earthquakeDetail' || pageId === 'originDetail') &&
                                    (isPreferredOrOwner()
                                        ? <BeaDropdown
                                            disabled={true}
                                            text={pageId === 'earthquakeDetail'
                                                ? (isPreferredOrOwner() ? 'bc__owner' : 'bc__make_owner')
                                                : (isPreferredOrOwner() ? 'bc__preferred' : 'bc__make_preferred')
                                            }
                                            icon={<IconPreferredStar />}
                                        />
                                        : <BeaDropdown
                                            text={pageId === 'earthquakeDetail' ? 'bc__make_owner' : 'bc__make_preferred'}
                                            icon={<IconStar />}
                                            callback={() => (!!keycloak.authenticated && !isCatalog) && (pageId === 'earthquakeDetail' ? makeOwnerConfirmation() : makePreferredConfirmation())}
                                        />
                                    )
                                }

                            </div>
                        </div>
                        <div className={'breadcrumb-divider'}></div>
                    </div>
            }
            {
                showNotification.show &&
                (showNotification.kind === 'success'
                    ? <Toast
                        className='notification-hover'
                        title={t(showNotification.reason)}
                        onClose={() => setShowNotification(defaultNotificationState)}
                    />
                    : <InlineNotification
                        className='notification-hover'
                        kind={showNotification.kind}
                        lowContrast
                        actionbuttonlabel='Action'
                        ariaLabel='closes notification'
                        onClose={() => setShowNotification(defaultNotificationState)}
                        onCloseButtonClick={function noRefCheck() { }}
                        statusIconDescription='notification'
                        subtitle={t(showNotification.reason)}
                        title={showNotification.title}
                    />)
            }
            {(updateEventModal && !!keycloak.authenticated) && <ReactModal
                isOpen={updateEventModal && !!keycloak.authenticated}
                ariaHideApp={false}
                onRequestClose={() => {
                    setUpdateEventModal(false);
                    setSelectEventType(earthquake.type_event);
                }}
                styles={customStyles}
                className={"bc__edit_type__modal"}
                overlayClassName="magnitude-overlay"
            >
                <div className='bc__edit_type__modal__body'>
                    <div className='bc__edit_type__modal__text'>
                        <h1>{t('bc__edit_event__title')}</h1>
                        <p>{t('bc__edit_event__text')}</p>
                        <IconClose style={{ position: 'absolute', top: '0', right: '0', width: '16px', height: '16px' }} onClick={() => {
                            setUpdateEventModal(false);
                            setSelectEventType(earthquake.type_event);
                        }} />
                    </div>
                    <div className='bc__edit_type__modal__dropdown'>
                        <p>{t('bc__edit_event__dd_label')}</p>
                        <Dropdown
                            label={''}
                            hideLabel
                            style={{ width: '100%' }}
                            id='event-type'
                            selectedItem={eventTypeOptions.find((option) => option.key === selectedEventType)}
                            selected={selectedEventType}
                            items={eventTypeOptions.sort((o1, o2) => o1.key.localeCompare(o2.key))}
                            itemToString={(item: { key: string; value: string }) => item.value}
                            onChange={(option: SelectedItem) => setSelectEventType(option.selectedItem.key)}
                        />
                    </div>
                </div>
                <div className='bc__edit_type__modal__buttons'>
                    <Button className="button bea-btn-black" onClick={() => {
                        setUpdateEventModal(false);
                        setSelectEventType(earthquake.type_event);
                    }}
                        disabled={networkRequest}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        className="button bea-btn" onClick={() => !isCatalog && updateTypeEventConfirmation()}
                        disabled={earthquake.type_event === selectedEventType || networkRequest}
                    >
                        {t('confirm')}
                    </Button>
                </div>
            </ReactModal>}
        </>
    );

    function getData() {
        if (!earthquake || (earthquake.id === null || earthquake.id === undefined)) {
            return;
        }
        try {
            dispatch(fetchEarthquakeEvent(+earthquake.id))
                .unwrap()
                .catch((e) => {
                    let { name } = e
                    if (name === 'AxiosError') {
                        dispatch(resetEvent());
                    }
                });
        } catch (e) {
            console.log('fetchEarthquakeEvent => error =>', e)
        }
    }
}

interface SelectedItem {
    selectedItem: { key: string; value: string }
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export { BreadCrumb };
export type { Crumbs, PageId };

