import './LogoutButton.css'
import { IconButton } from '@carbon/react'
import { ReactComponent as IconLogout } from '../../assets/logout.svg'

type LogoutButtonProps = {
    callback: object
}

const LogoutButton = ({callback}: LogoutButtonProps) => {
    return <div className='logout-container'>
            <IconButton className="logout-btn" label='logout' onClick={callback}>
                <IconLogout />
            </IconButton>
        </div>
}

export { LogoutButton }