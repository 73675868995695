import './CustomTable.css'
import { CTA_TAG, DATETIME_TAG, OPEN_GROUP_TAG, SORT_ASC, SORT_DESC } from './TableUtils'
import { ReactComponent as SortNormalIcon } from '../../assets/sort-normal.svg'
import { ReactComponent as SortAscIcon } from '../../assets/sort-asc.svg'
import { ReactComponent as SortDescIcon } from '../../assets/sort-desc.svg'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../hooks/reduxCustomHooks'
import CustomTooltip from '../CustomTooltip/CustomTooltip'

export interface HeaderCellProps {
    header: string,
    sortable: boolean,
    sortingCallback?: (direction: number, key: string) => void
    cols?: number,
    tag: string,
    sortHeader: string,
    sortDirection: number,
}

const HeaderCell = ({ header, sortable, sortHeader, sortDirection, sortingCallback, cols, tag }: HeaderCellProps) => {

    const { t } = useTranslation()
    const tz = useAppSelector((state) => state.timezone)
    const id = `header-${tag}`
    const textid = `text-${id}`

    const handleSort = () => {
        console.log('HeaderCell => handleSort =>', sortDirection)
        if (sortable) {
            if (sortingCallback) {
                if (tag !== sortHeader) {
                    console.log('HeaderCell => handleSort => new =>', SORT_ASC)
                    sortingCallback(SORT_ASC, tag)
                } else {
                    if (sortDirection === SORT_ASC) {
                        console.log('HeaderCell => handleSort => new =>', SORT_DESC)
                        sortingCallback(SORT_DESC, tag)
                    } else {
                        console.log('HeaderCell => handleSort => new =>', SORT_ASC)
                        sortingCallback(SORT_ASC, tag)
                    }
                }
            }
            return
        }
    }

    const sortableIcon = () => {
        if (!sortable) {
            return <></>
        }
        if (sortHeader !== tag) {
            return <SortNormalIcon />
        }
        if (sortDirection === SORT_ASC)
            return <SortAscIcon />
        if (sortDirection === SORT_DESC)
            return <SortDescIcon />
    }

    const evaluateCols = () => {
        if (!cols || cols === 1) return ''
        if (cols >= 2 || cols <= 10) return `cols-${cols}`
        return ''
    }

    return <div
        id={id}
        className={`
                    ${tag === OPEN_GROUP_TAG || tag === CTA_TAG ? 'empty-header-container' : 'header-container'} 
                    ${sortable ? 'sortable' : ''} 
                    ${evaluateCols()}
                    ellipse`
        } onClick={handleSort}>
        <CustomTooltip title={t(`${tag === DATETIME_TAG ? `${header}_${tz}` : header}_ext`)} placement="top" arrow>
            <span id={textid} className='header-text'>{tag === DATETIME_TAG ? t(`${header}_${tz}`) : t(header)}</span>
        </CustomTooltip>
        {sortableIcon()}
    </div>
}

export default HeaderCell