import { useTranslation } from "react-i18next";
import {useEffect, useState} from 'react';
import CustomSelector, { Option } from "../CustomSelector/CustomSelector";

interface ProductSelectorProps {
    onUpdate: (products: string) => void
    selected: string,
}

export const PRODUCTS_LIST: Array<Option> = [
    { value: "Shake map", key: "SM" },
    { value: "Focal Mechanism", key: "FM" },
    { value: "DPC", key: "DPC" },
]

const ProductSelector = ({onUpdate, selected}: ProductSelectorProps) => {

    const {t} = useTranslation()
    const [product, setProduct] = useState(selected)

    useEffect(() => setProduct(selected), [selected])

    return(
        <div className={'filter-section-inputbox'}>
            <p className='label'>{t('product_label')}</p>
            <CustomSelector
                fixed={false}
                isMultiSelect={true}
                prefix='product'
                options={PRODUCTS_LIST}
                selected={product}
                onChange={(key) => {
                    // key:string is currently selected option
                    if(key === '') return 
                    let options = product.split(',').filter(o => o !== '');
                    options = options.filter((o) => PRODUCTS_LIST.findIndex((p) => p.key === o) >= 0)
                    if(options.filter(o => o === key).length === 0) {
                        // we're going to add the selected option
                        options = [...options, key]
                    } else {
                        // we're going to add the selected option
                        options = options.filter(o => o !== key)
                    }
                    onUpdate(options.join(','))
                }}
            />
        </div>
    );
}

export default ProductSelector
