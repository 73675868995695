import './LoginButton.css'
import { IconButton } from '@carbon/react'
import { ReactComponent as IconLogin } from '../../assets/icon-login.svg'

type LoginButtonProps = {
    callback: object
}

const LoginButton = ({callback}: LoginButtonProps) => {
    return <div className='spacer-56 headerbutton-container'>
            <IconButton className="headerbutton-btn" label='login' onClick={callback}>
                <IconLogin />
            </IconButton>            
        </div>
}

export { LoginButton }