import React, {useCallback, useEffect, useState} from 'react';
import { ReactComponent as IconRefreshOff } from '../../assets/icona-refresh-off.svg'
import { ReactComponent as IconRefreshOn } from '../../assets/icona-refresh-on.svg'
import './RefreshButton.css'
import { useTranslation } from 'react-i18next';
import { createUrlFromFilters } from '../../utils/urlFromFilters';
import { useAppSelector } from '../../hooks/reduxCustomHooks';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import dayjs from "dayjs";

const RefreshButton = () => {

    const [refreshActive, isRefreshActive] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation();
    const filters = useAppSelector((state) => state.filters);
    const timepicker = useAppSelector((state) => state.timepicker);

    const getUrl = useCallback(() => {
        let url = `/earthquakelist?${createUrlFromFilters({
            ...filters,
            route: filters.route,
            starttime: 'days' in timepicker.fullInterval 
                ? dayjs.utc().subtract(timepicker.fullInterval.days, 'day').format('YYYY-MM-DD[T00:00:00.000Z]')
                : filters.starttime
        })}&refresh=true`

        return url;
    }, [timepicker, refreshActive, filters])

    useEffect(() => {
        if (location) {
            if (location.search) {
                if (location.search.includes('&refresh=true')) {
                    if (!refreshActive) {
                        isRefreshActive(true);
                    }
                    return;
                }
            }
        }
        if (refreshActive) {
            isRefreshActive(false);
        }
    }, [location])

    function toggleOpen(): void {

        if(!refreshActive) {
            navigate(getUrl());
        } else {
            navigate(`/earthquakelist?${createUrlFromFilters({
                ...filters,
                route: filters.route
            })}`)
        }
        isRefreshActive(!refreshActive);
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (refreshActive) {
                navigate(getUrl());
            }
        }, Number(process.env.REACT_APP_BEA_REFRESH_DELAY_MS));

        // cleanup the timer when component unmout
        return () => clearInterval(intervalId);
    }, [refreshActive, getUrl]);

    return (
        <CustomTooltip
            key={'refresh-tooltip'}
            placement='bottom'
            title={refreshActive ? t('refresh_on_message', { min: Number(process.env.REACT_APP_BEA_REFRESH_DELAY_MS) / 60000 }) : t('refresh_off_message')} arrow>
            <div className={refreshActive ? 'bc-refresh-btn active' : 'bc-refresh-btn'} onClick={toggleOpen}>
                {refreshActive ? <IconRefreshOn /> : <IconRefreshOff />}
            </div>
        </CustomTooltip>);
}

export {
    RefreshButton
}