import './Filters.css'
import {useTranslation} from "react-i18next";
import { ReactComponent as IconClose } from '../../assets/filters-header-close.svg'

export interface IFiltersHeaderProps {
    onClose: () => void;
}

function FiltersHeader({onClose}: IFiltersHeaderProps) {
    const {t} = useTranslation();

    return (<div className={'filters-container-header'}>
        <p className={'filters-title'}>{t('filters_title')}</p>
        <div className={'filters-icon-container'} onClick={onClose}>
            <IconClose className={'filters-icon'}/>
        </div>
    </div>);
}

export {
    FiltersHeader
}