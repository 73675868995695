import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxCustomHooks"
import { ApiClient } from "../../services/ApiClient"
import { DoubleRowDate } from "../DoubleRowDate/DoubleRowDate"
import { DoubleRowRegion } from "../DoubleRowRegion/DoubleRowRegion"
import { LoaderTranslated } from "../Loader/LoaderTranslated"
import { COORDINATES_TAG, CTA_TAG, DATETIME_TAG, EVENT_TYPE_TAG, EarthquakeTableEvent, LOCALSPACE_TAG, OPEN_GROUP_TAG, PRODUCTS_TAG, TableHeader, VERSION_TAG, ZONE_TAG } from "./TableUtils"
import EmptyList from "../EmptyList/EmptyList"
import { useTranslation } from "react-i18next"
import { JOIN_MODE, MOVE_MODE, openGroup, VIEW_MODE } from "../../redux/reducers/group.slice"
import { ReactComponent as CtaIcon } from '../../assets/cta.svg'
import { closeCta, GroupStateChange, toggleGroupCta } from "../../redux/reducers/cta.slice"
import { addCustomTableGroup } from "../../redux/reducers/customtable.slice"
import { InlineLoading } from '@carbon/react'
import { ObjectMagnitudesOriginsEventsAndEventsGroup } from "../../services/network"
import { evaluateVersion } from "../../pages/EarthquakeList/utils"
import VersionComponent from "../VersionComponent/VersionComponent"
import CustomTooltip from "../CustomTooltip/CustomTooltip"
import { DoubleRowGeneric } from "../DoubleRowRegion/DoubleRowGeneric";
import { Link } from "react-router-dom";
import { ReactComponent as IconOther } from '../../assets/other-event-icon.svg';
import { ReactComponent as IconNotExisting } from '../../assets/not-existing-icon.svg';

interface GroupRowProps {
    groupId: number,
    headers: Array<TableHeader>,
    className: string[],
    parent: EarthquakeTableEvent,
}

// ownerId is the id of the 
const GroupRow = ({ groupId, parent, headers, className }: GroupRowProps) => {

    const { t } = useTranslation()

    const cta = useAppSelector((state) => state.cta)
    const group = useAppSelector((state) => state.group)
    const customtable = useAppSelector((state) => state.table)

    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(true)
    const [events, setEvents] = useState<Array<ObjectMagnitudesOriginsEventsAndEventsGroup> | undefined>()
    const [earthquakeTableEvents, setEarthquakeTableEvents] = useState<Array<EarthquakeTableEvent> | undefined>()

    useEffect(() => {
        console.log('useEffect => groupId =>', groupId)
        if (groupId === 0) {
            setEarthquakeTableEvents([parent])
            console.log('useEffect => group =>', group.open)
            if (!group.open.includes(parent.id)) {
                dispatch(openGroup(parent.id))
            }
        } else {
            let gg = customtable?.find((ct) => ct.parent === parent.id)
            if (gg) {
                setEvents(gg.children);
                return;
            }

            getEvents().then(() => console.log('ok')).catch((e) => console.log('getEvents => error =>', e));
        }
    }, [customtable])

    useEffect(() => {
        console.log('useEffect => earthquakeTableEvents =>', earthquakeTableEvents)
        if (!events) { return; }
        const tableEvents: Array<EarthquakeTableEvent> = events.map((e, i) => ({
            htmlId: `${e.id}-${i}`,
            id: `${e.id}`,
            datetime: e.origin?.ot ?? '',
            magnitude: `${e.magnitude?.type_magnitude} ${e.magnitude?.mag?.toFixed(1)}`,
            zone: e.origin?.region ?? '',
            depth: `${(e.origin?.quality ?? '--')}`,
            lat: (+(e.origin?.lat ?? 0)).toFixed(2),
            lon: (+(e.origin?.lon ?? 0)).toFixed(2),
            mag: e.magnitude?.mag ?? 0,
            elementGroupId: e?.event_group_id ?? 0,
            preferred: e.id === groupId,
            [PRODUCTS_TAG]: e?.flags,
            [COORDINATES_TAG]: `${(+(e.origin?.lat ?? 0)).toFixed(2)}, ${(+(e.origin?.lon ?? 0)).toFixed(2)}#${(e.origin?.depth ?? 0).toFixed(2)} Km`,
            [VERSION_TAG]: evaluateVersion(e.origin),
            [LOCALSPACE_TAG]: e.localspace?.name ?? '--',
            [EVENT_TYPE_TAG]: e?.type_event ?? '--'
        }));

        setEarthquakeTableEvents(tableEvents)
        if (!group.open.includes(parent.id)) {
            dispatch(openGroup(parent.id));
        }
    }, [events])

    useEffect(() => {
        if (earthquakeTableEvents) {
            setLoading(false)
        }
    }, [earthquakeTableEvents])

    const getEvents = async () => {
        try {
            const response = await ApiClient.getApi().getEventsByGroup(groupId);
            console.log('GroupRow => getEvents =>', response.data.data)
            setEvents(response.data.data ?? [])
            dispatch(addCustomTableGroup(({
                parent: parent.id,
                children: response.data.data ?? []
            })))
            console.log('GroupRow => addingGroup =>', {
                parent: parent.id,
                children: response.data.data ?? []
            })
        } catch (e) {
            console.log('GroupRow => getEvents => error =>', e)
            setLoading(false)
        }
    }

    const evaluateCtaCell = (element: EarthquakeTableEvent) => {
        if (!events) return
        const id = `${CTA_TAG}-group-${element.id}`
        let ee = events.find(e => e.id === Number(element.id))

        if (group.mode === JOIN_MODE) {
            return <div
                key={`${element.id}-group-${CTA_TAG}`}
                className='empty-header-container'
            />
        }

        if (group.mode === MOVE_MODE) {
            if (group.src && group.src === element.id) {
                return <div
                    key={`${element.id}-group-${CTA_TAG}`}
                    className='empty-header-container'
                >
                    <InlineLoading className="loader-loading-circle" />
                </div>
            } else {
                return <div
                    key={`${element.id}-group-${CTA_TAG}`}
                    className='empty-header-container'
                />
            }
        }

        return <div
            key={`${element.id}-group-${CTA_TAG}`}
            onClick={(e) => {
                console.log('GroupRow => ee =>', ee)
                e.preventDefault()
                e.stopPropagation()
                if (ee) {
                    dispatch(toggleGroupCta({
                        ownerId: parent.id,
                        fullEvent: ee
                    } as GroupStateChange))
                }
            }}
            className={`${cta.callerId === `cta-group-${element.id}` && 'active'} cta-btn`}>
            <CtaIcon
                key={`${element.id}-group-${CTA_TAG}`}
                id={id}
                className='empty-header-container' />
        </div>
    }

    const buildCell = (element: any, header: string, cols?: number) => {
        let c = cols ?? 1
        if (header === DATETIME_TAG)
            return <DoubleRowDate key={`${element.id}-group-${header}`} dateStr={element[header]} className={`cols-${c}`} changeTimezone={true} />
        if (header === ZONE_TAG)
            return <DoubleRowRegion key={`${element.id}-group-${header}`} regionStr={element[header]} className={`cols-${c}`} />
        if (header === OPEN_GROUP_TAG)
            return <div key={`${element.id}-group-${header}`} className={`empty-header-container open-group`} />
        if (header === CTA_TAG)
            return evaluateCtaCell(element)
        if (header === VERSION_TAG)
            return <VersionComponent key={`${element.id}-group-${header}`} versionStr={element[header]} />
        if (header === COORDINATES_TAG) {
            let tokens = element[header].split('#')
            return <DoubleRowGeneric key={`${element.id}-group-${header}`} firstRow={tokens[0]} secondRow={tokens[1]} />
        }
        if (header === LOCALSPACE_TAG)
            return <div key={`${element.id}-group-${header}`} className={`cols-${c} single-row-container`}>
                <CustomTooltip placement="top" title={element[header]} arrow>
                    <span className='single-row-content'>{element[header]}</span>
                </CustomTooltip>
            </div>
        if (header === EVENT_TYPE_TAG) {
            if (element[header] === 'earthquake') {
                return <div></div>;
            }
            return <div key={`${element.id}-group-${header}`} className={`cols-${c} single-row-container`}
                style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}
            >
                <CustomTooltip placement="top" title={element[header]} arrow>
                    {

                        element[header] === 'not existing'
                            ? <IconNotExisting />
                            : <IconOther />
                    }
                </CustomTooltip>
            </div>
        }
        if (header === PRODUCTS_TAG) {
            let key = `${element.id}-group-${header}`
            let prodPrefix = `pr-${key}`
            let p: string | (JSX.Element[]) = 'N.D.'
            if (element[header]) {
                let splitted = element[header].split(',')
                if (splitted.length > 0) {
                    p = [<span key={`${prodPrefix}-${0}`} className='earthquake-detail-header-tz mr-4'>{splitted[0]}</span>]
                    if (splitted.length - p.length > 0) {
                        p = [...p, <span key={`${prodPrefix}-${2}`} className='earthquake-detail-header-tz mr-4'>+{splitted.length - p.length}</span>]
                    }
                }
            }
            return <div key={`div-${key}`} className={`cols-${c} single-row-container`}>
                {
                    element[header]
                        ? <CustomTooltip key={key} placement="top" title={element[header] ?? 'N.D.'} arrow>
                            <span className='single-row-content'>{p}</span>
                        </CustomTooltip>
                        : <span className='single-row-content'>{p}</span>
                }

            </div>
        }

        return <div key={`${element.id}:${header}`} className={`cols-${c} single-row-container`}>
            {
                (element[header] === '--' || element[header] === 'N.D.')
                    ? <span className='single-row-content'>{element[header]}</span>
                    : <CustomTooltip title={element[header]} placement="top" arrow>
                        <span className='single-row-content'>{element[header]}</span>
                    </CustomTooltip>
            }
        </div>
    }

    return <>
        {
            (loading || !earthquakeTableEvents) && <LoaderTranslated small white />
        }
        {
            !loading && (earthquakeTableEvents && (earthquakeTableEvents.length === 0
                ? <EmptyList verysmall title={t('no_group_found_title')} />
                : <>
                    {
                        earthquakeTableEvents.map((e, i) =>
                            <>
                                <Link key={`a-group-${i}`}
                                    className={`${className.map(c => `row-${c}-child`)} ${e.id === parent.id ? 'preferred' : ''} relative`}
                                    to={group.mode === VIEW_MODE ? `/earthquake/${e.id}` : '#'}
                                    onClick={() => dispatch(closeCta())}
                                >
                                    {
                                        headers.map(h => buildCell(e, h.tag, h.cols))
                                    }
                                </Link>
                            </>
                        )
                    }
                </>))
        }
    </>
}

export default GroupRow