import axios, { AxiosInstance } from 'axios'
import { STATIONS_BASE_URLS } from '../utils/process'

const BASE_URLS = STATIONS_BASE_URLS

const networkClients: AxiosInstance[] = BASE_URLS.map((BASE_URL) => axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "text/plain",
    },
}))

interface StationCoordinates {
    stationNetwork: string;
    stationName: string;
    lat: number;
    lon: number;
}

const getStationsCoordinates = async (clientIndex: number, stationIds: Array<string>) => {
    const stationIdsStr = stationIds.reduce((prev, curr) => prev === '' ? curr : prev + ',' + curr, '')

    const resultStr = await networkClients[clientIndex].get<String>(`/fdsnws/station/1/query`, {
        params: { level: 'station', format: 'text', station: stationIdsStr }
    });

    const stationCoordinatesArray: Array<StationCoordinates> = []

    resultStr.data.split('\n').forEach((stationInfo, index) => {
        if (index !== 0) {
            if (stationInfo !== '') {
                const info = stationInfo.split('|')
                stationCoordinatesArray.push({ stationNetwork: info[0], stationName: info[1], lat: +info[2], lon: +info[3] })
            }
        }
    });

    return stationCoordinatesArray
}

const StationsApiClient = {
    getStationsCoordinates
}

export { StationsApiClient };
export type { StationCoordinates };
