import './Toast.css'
import { ReactComponent as IconExpandLess } from '../../assets/cta-close.svg'

export interface IToastProps {
    className: string;
    title: string;
    ctaText?: string;
    onClose: () => void;
}

function Toast({ className, title, ctaText, onClose }: IToastProps): JSX.Element {
    return (<div className={['bea-toast', className].join(' ')}>
        <div className='bea-toast-container'>
            <span className='label'>{title}</span>
            <div className='cta'>
                <div className='ghost'>
                    <div className='button'>
                        <span className='label'>{ctaText}</span>
                    </div>
                </div>
                <div className='cta-close' onClick={onClose}>
                    <IconExpandLess />
                </div>
            </div>
        </div>
    </div>);
}

export { Toast }