import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from '../../hooks/reduxCustomHooks';
import { ApiClient } from '../../services/ApiClient';
import { GetMunicipiDistanceKmPopolazione200ResponseDataInner } from '../../services/network';
import { CustomExpandableNotification } from '../CustomExpandableNotification/CustomExpandableNotification';
import {
    EVENTDETAIL_CITIES_TABLE,
    EVENTDETAIL_CITIES_TABLE_50,
    buildEventDetailCityTable
} from '../CustomTable/TableComposer';
import { CUMULATIVE_TAG, DISTANCE_TAG_EDC, EventDetailCity, INHABITANTS_TAG, MUNICIPALITY_TAG, REGION_TAG } from '../CustomTable/flavor/CustomCityTable';
import EmptyList from '../EmptyList/EmptyList';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import { LoaderTranslated } from '../Loader/LoaderTranslated';
import './CityTable.css';

interface CityTableHeaderProps {
    title: string
}

function CityTableHeader({ title }: CityTableHeaderProps) {
    return (<>
        <div className='citytable-header'>{title}</div>
    </>)
}

interface CityTableProps {
}

function CityTable({ }: CityTableProps) {

    const { t } = useTranslation()
    const earthquake = useAppSelector((state) => state.earthquake)
    const [inside20KmCities, setInside20KmCities] = useState<Array<GetMunicipiDistanceKmPopolazione200ResponseDataInner>>([])
    const [min50KPopulatiomCities, setMin50KPopulatiomCities] = useState<Array<GetMunicipiDistanceKmPopolazione200ResponseDataInner>>([])
    const [city20KmTableCities, setCityTable20KmCities] = useState<Array<EventDetailCity>>([])
    const [city50KTableCities, setCityTable50kCities] = useState<Array<EventDetailCity>>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const getEarthquakeCoordinates = (): [number, number] => {
        const prefOrigin = earthquake.origins?.find((origin) => origin.id === earthquake.preferred_origin_id)
        return [prefOrigin?.lat ?? 0, prefOrigin?.lon ?? 0]
    }

    const getCities = async () => {
        const latLon = getEarthquakeCoordinates()
        try {
            const response20Km = await ApiClient.getApi().getMunicipiDistanceKmPopolazione(
                1, // min distance
                20, // max distance
                1, // population
                latLon[0],
                latLon[1]
            )
            setInside20KmCities(response20Km.data.data ?? [])

            const response50k = await ApiClient.getApi().getMunicipiDistanceKmPopolazione(
                1, // min distance
                100, // max distance
                50000, // population
                latLon[0],
                latLon[1]
            )
            setMin50KPopulatiomCities(response50k.data.data?.sort((e1, e2) => {
                return -((e1.popolazione ?? 0) - (e2.popolazione ?? 0))
            }) ?? [])
        } catch (e) {
            console.log('error =>', e)
            setError(true)
        }

        setLoading(false)
    }

    useEffect(() => {
        if (!earthquake) return
        if (loading) {
            getCities()
        }
    }, [earthquake])

    useEffect(() => {
        const tableCities: Array<EventDetailCity> = inside20KmCities.map((city, index) => ({
            id: `0${index}`,
            [MUNICIPALITY_TAG]: city.name ?? '--',
            [REGION_TAG]: city.sigla_pro ?? '--',
            [DISTANCE_TAG_EDC]: `${city.dist_km ?? '--'}`,
            [INHABITANTS_TAG]: `${city.popolazione ?? '--'}`,
            [CUMULATIVE_TAG]: `${city.popolazione ?? '--'}`
        })) ?? []

        setCityTable20KmCities(tableCities)
    }, [inside20KmCities])

    useEffect(() => {
        const tableCities: Array<EventDetailCity> = min50KPopulatiomCities.map((city, index) => ({
            id: `0${index}`,
            [MUNICIPALITY_TAG]: city.name ?? '--',
            [REGION_TAG]: city.sigla_pro ?? '--',
            [DISTANCE_TAG_EDC]: `${city.dist_km ?? '--'}`,
            [INHABITANTS_TAG]: `${city.popolazione ?? '--'}`,
            [CUMULATIVE_TAG]: `${city.popolazione ?? '--'}`
        })) ?? []

        setCityTable50kCities(tableCities)
    }, [min50KPopulatiomCities])

    return (
        loading
            ? <LoaderTranslated />
            : <>
                <div className="city-list" id="city-list">
                    <div style={{ padding: '16px', paddingBottom: '0px' }}>
                        <CustomExpandableNotification
                            title={t('eventdetail_citytable_notify_title')}
                            more={t('eventdetail_citytable_notify_more_info')}
                            content={t('lorem_ipsum')}
                        />
                    </div>
                    <CityTableHeader title={t('eventdetail_citytable_header_20km')} />

                    {city20KmTableCities?.length !== 0
                        ? buildEventDetailCityTable(city20KmTableCities, EVENTDETAIL_CITIES_TABLE)
                        : (
                            error
                                ? <ErrorComponent title={'component__cities_table__no_info_title'} description={'component__cities_table__no_info_text'} />
                                : <EmptyList title={'component__cities_table__no_city_title'} description={'component__cities_table__no_city_text'} small />
                        )
                    }

                    <CityTableHeader title={t('eventdetail_citytable_header_50k')} />

                    {city50KTableCities?.length !== 0
                        ? buildEventDetailCityTable(city50KTableCities, EVENTDETAIL_CITIES_TABLE_50)
                        : (
                            error
                                ? <ErrorComponent title={'component__cities_table__no_info_title'} description={'component__cities_table__no_info_text'} />
                                : <EmptyList title={'component__cities_table__no_city_title'} description={'component__cities_table__no_city_50k_text'} small />
                        )
                    }
                </div>
            </>
    )
}

export { CityTable };

