import dayjs from 'dayjs'
import 'dayjs/locale/it'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/reduxCustomHooks';
import { ms } from '../../utils';
import './DoubleRowDate.css'
import CustomTooltip from '../CustomTooltip/CustomTooltip';

interface DoubleRowDateParams {
    dateStr: string,
    className?: string,
    changeTimezone?: boolean
}

function DoubleRowDate({ dateStr, className, changeTimezone = false }: DoubleRowDateParams) {
    const { i18n } = useTranslation();
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.locale(i18n.language)
    const tz = useAppSelector((state) => state.timezone)

    let [date, setDate] = useState('--')
    let [time, setTime] = useState('--')

    useEffect(() => {
        let d = dayjs(dateStr).tz('UTC')
        if (changeTimezone) {
            if (tz === 'italy')
                d = dayjs(dateStr).tz('Europe/Rome')
        }

        setDate(d.format('YYYY-MM-DD'))
        setTime(`${d.format('HH:mm:ss')}.${ms(dateStr)}`)
    }, [tz])


    return (
        <CustomTooltip title={`${date} ${time}`} placement="top" arrow>
            <div className={`doublerowdate-container ${className}`}>
                <span className='cell-main-text'>{date}</span>
                <span className='cell-sub-text'>{time}</span>
            </div>
        </CustomTooltip>
    )
}

export { DoubleRowDate }