import { CSSProperties, useState } from 'react';
import { ReactComponent as ProvenanceIcon } from '../../../assets/provenance.svg'
import '../GenericTable.css'
import { useTranslation } from 'react-i18next';
import { ObjectProvenance } from '../../../services/network';

export interface ICtaProps {
    label: string;
    provenance: ObjectProvenance;
    width?: string;
    reverse?: boolean;
}

function Provenance({ label, provenance, width, reverse }: ICtaProps): JSX.Element {

    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();
    const open = () => setOpen(true);
    const close = () => setOpen(false);

    function onBlurCallback(): void {
        if (isOpen) {
            close();
        }
    }

    function onClickCallback(e: React.MouseEvent<HTMLDivElement>): void {
        e.preventDefault();
        e.stopPropagation();
        return isOpen ? close() : open();
    }

    function getStyle(): CSSProperties | undefined {
        if (width) {
            console.log('getStyle', `${width}px !important`)
            return {
                width: `${width}px !important`,
            }
        }
        return undefined
    }

    function getTop(options: number): CSSProperties {
        if (reverse) {
            // return up
            const optionHeight: number = 36;
            return {
                top: `${-50-(optionHeight * options)}px`
            } 
        } else {
            // return down
            return {
                top: '44px'
            };
        }
    }

    return (<div className={isOpen ? 'ot-cta-container-active' : 'ot-cta-container'} tabIndex={0} onClick={onClickCallback} onBlur={onBlurCallback}>
        <ProvenanceIcon />
        {isOpen && <div className={`ot-cta-menu`} style={getTop(Object.entries(provenance).length)}>
            <div className={'ot-options-container'} style={getStyle()}>
                <h4>{t('provenance')}</h4>
                <div className='divider-container'>
                    <div className='divider' />
                </div>
                {
                    Object.entries(provenance).map((item, index) => {
                        return (<div
                            key={`${index}`}
                            className='item-no-click'
                            onMouseEnter={(e) => { e.preventDefault(); e.stopPropagation(); }}
                        >
                            <p><strong style={{ textTransform: 'capitalize' }}>{item[0]}</strong>: {item[1]}</p>
                        </div>)
                    })
                }
            </div>
        </div>}
    </div>);
}

export {
    Provenance
}