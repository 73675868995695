import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EventDetailOriginsTable } from '../../components/CustomTable/flavor/CustomOriginTable';
import { CTA_TAG, EarthquakeTableEvent } from '../../components/CustomTable/TableUtils';
import { ObjectMagnitude, ObjectMagnitudesOriginsEventsAndEventsGroup } from '../../services/network';

export interface GroupStateChange {
  ownerId: string,
  fullEvent: ObjectMagnitudesOriginsEventsAndEventsGroup
}

const defaultCtaState = {
    show: false,
    callerId: '',
    isGroup: false,
    fullEvent: {},
    ownerId: '',
}

export const ctaSlice = createSlice({
    name: 'cta',
    initialState: defaultCtaState,
    reducers: {
      closeCta: () => {
        return defaultCtaState;
      },
      toggleMagnitudeCta: (state, actionCta: PayloadAction<ObjectMagnitude>) => { 
        return {
          show: state.callerId !== `${CTA_TAG}-${actionCta.payload.id}`,
          isGroup: false,
          callerId: `${CTA_TAG}-${actionCta.payload.id}` === state.callerId ? '' : `${CTA_TAG}-${actionCta.payload.id}`,
          fullEvent: actionCta.payload,
          ownerId: `${CTA_TAG}-${actionCta.payload.id}`,
        }
      },
      toggleOriginCta: (state, actionCta: PayloadAction<EventDetailOriginsTable>) => {
        return {
            show: state.callerId !== `${CTA_TAG}-${actionCta.payload.id}`,
            isGroup: false,
            callerId: `${CTA_TAG}-${actionCta.payload.id}` === state.callerId ? '' : `${CTA_TAG}-${actionCta.payload.id}`,
            fullEvent: actionCta.payload,
            ownerId: actionCta.payload.earthquakeId,
          }
      },
      toggleOwnerCta: (state, actionCta: PayloadAction<EarthquakeTableEvent>) => {
          return {
            show: state.callerId !== `${CTA_TAG}-${actionCta.payload.id}`,
            isGroup: false,
            callerId: `${CTA_TAG}-${actionCta.payload.id}` === state.callerId ? '' : `${CTA_TAG}-${actionCta.payload.id}`,
            fullEvent: actionCta.payload,
            ownerId: `${CTA_TAG}-${actionCta.payload.id}`,
          }
      },
      toggleGroupCta: (state, actionCta: PayloadAction<GroupStateChange>) => {
          return {
            show: state.callerId !== `${CTA_TAG}-group-${actionCta.payload.fullEvent.id}`,
            isGroup: true,
            callerId: `${CTA_TAG}-group-${actionCta.payload.fullEvent.id}` === state.callerId ? '' : `${CTA_TAG}-group-${actionCta.payload.fullEvent.id}`,
            fullEvent: actionCta.payload.fullEvent,
            ownerId: actionCta.payload.ownerId
          }
      }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { toggleOwnerCta, toggleGroupCta, closeCta, toggleOriginCta, toggleMagnitudeCta } = ctaSlice.actions
  
  export default ctaSlice.reducer