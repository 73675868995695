import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ObjectMagnitudesOriginsEventsAndEventsGroup } from '../../services/network'

interface CustomTableGroup {
    parent: string, // this is the id of the 'rappresentante' of the group
    children: Array<ObjectMagnitudesOriginsEventsAndEventsGroup>,
}

const defaultState: CustomTableGroup[] = []

export const customtableSlice = createSlice({
    name: 'table',
    initialState: defaultState,
    reducers: {
        resetTable: (state) => {
            return defaultState
        },
        addCustomTableGroup: (state, actionCustomTableGroup: PayloadAction<CustomTableGroup>) => {
            return [...state, actionCustomTableGroup.payload]
        },
        removeCustomTableGroup: (state, parent: PayloadAction<string>) => {
            return [...state].filter((t) => t.parent !== parent.payload);
        }
    }
})

// Action creators are generated for each case reducer function
export const { resetTable, addCustomTableGroup, removeCustomTableGroup } = customtableSlice.actions
export default customtableSlice.reducer