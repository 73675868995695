import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";

let timeoutMap: Map<string, TimeoutId> = new Map<string, TimeoutId>();

export interface BeaInlineNotification {
    id: string;
    show: boolean,
    title: string,
    reason: string,
    kind: string,
    additionalData?: string,
};

export const defaultNotificationState: BeaInlineNotification = {
    id: '',
    show: false,
    title: 'Ok',
    reason: 'Ok',
    kind: 'success'
};

export const onSuccessCallbackHandler = function (callback: (data: BeaInlineNotification) => void, title?: string, reason?: string) {
    console.log('onSuccessCallbackHandler => update success');
    onSuccessCallbackFullHandler(callback, title, reason, undefined);
};

export const onSuccessCallbackFullHandler = function (callback: (data: BeaInlineNotification) => void, title?: string, reason?: string, additionalData?: string) {
    console.log('onSuccessCallbackHandler => update success')
    const id: string = uuid();
    callback({
        id,
        show: true,
        title: title ? title : 'update__success',
        reason: reason ? reason : 'update__origin__success',
        kind: 'success',
        additionalData
    })

    let timeout: TimeoutId | undefined = timeoutMap.get(id);
    if (timeout) {
        clearTimeout(timeout);
        timeoutMap.delete(id);
    } else {
        timeout = setTimeout(() => callback(defaultNotificationState), 10000);
        timeoutMap.set(id, timeout);
    }
}

export const onErrorCallbackHandler = (title: string, reason: string, callback: (data: BeaInlineNotification) => void) => {
    console.log('onErrorCallbackHandler => update failed with reason:', reason, title)
    onErrorCallbackFullHandler(title, reason, callback, false, undefined)
}

export const onErrorTimeoutCallbackHandler = (title: string, reason: string, callback: (data: BeaInlineNotification) => void) => {
    console.log('onErrorCallbackHandler => update failed with reason:', reason, title)
    onErrorCallbackFullHandler(title, reason, callback, true, undefined)
}

export const onErrorCallbackFullHandler = (title: string, reason: string, callback: (data: BeaInlineNotification) => void, hasTimeout: boolean, additionalData?: string) => {
    console.log('onErrorCallbackHandler => update failed with reason:', reason, title)
    const id: string = uuid();
    if (hasTimeout) {
        let timeout: TimeoutId | undefined = timeoutMap.get(id);
        if (timeout) {
            clearTimeout(timeout);
            timeoutMap.delete(id);
        } else {
            timeout = setTimeout(() => callback(defaultNotificationState), 10000);
            timeoutMap.set(id, timeout);
        }
    }
    callback({
        id,
        show: true,
        title,
        reason,
        kind: 'error',
        additionalData
    })
}

export function getErrorNotification(): BeaInlineNotification {
    return {
        id: uuid(),
        show: true,
        title: '',
        reason: '',
        kind: 'error'
    }
}

function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
