import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './services/i18n'
import reportWebVitals from './reportWebVitals'
import log from 'loglevel'
import { keycloak } from './services/auth/Keycloak'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Loader } from './components/Loader/Loader'

// Set log based on the environment
if (process.env.NODE_ENV === 'production') {
  log.disableAll()
} else {
  log.enableAll()
}

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error)
}

root.render(
  <ReactKeycloakProvider
    initOptions={{
      checkLoginIframe: false
    }}
    authClient={keycloak}
    onEvent={eventLogger}
  >
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path='/*' element={<App />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
